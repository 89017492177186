import React, { useEffect } from 'react';
import { GridComponent, Resize, Sort, ContextMenu, Filter, Page, ExcelExport, PdfExport, Edit, Inject, Search, Toolbar } from '@syncfusion/ej2-react-grids';
import { ordersData, contextMenuItems, ordersGrid, nftFarmData, nftFarmGrid, myFarmsGrid, depositTokens } from '../../data/dummy';
import { Header } from '../../components';
import { ColumnDirective, ColumnsDirective } from '@syncfusion/ej2-react-charts';
import { useParams } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import { Anchor } from 'ual-anchor';
import { Wax } from '@eosdacio/ual-wax';
import { User } from "universal-authenticator-library";
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { Api, JsonRpc } from "eosjs";
import {Helmet} from "react-helmet";
import axios from 'axios';
import { data } from 'autoprefixer';
import { Name, raw } from "eos-common";
import { ProposerTypeMessage, DaoButton, NFT, NewRow, BuyWojak, ProposalInput, NewProposal, Modal, ModalContent, Spinner, SpinnerRed, SpinnerBlue, SpinnerGreen, GameButton, DisabledButton, MainContainer, CreateDaoCont, DaoTitle, CreateFarmMsg, DropDown, UserWhitelist, BurnableCont, MissingInfoMsg, PaymentOptCont, FinalizeButton, CreateDropIPFS, LoadSpinner, LoadSpinnerRed, LoadSpinnerBlue, LoadSpinnerGreen } from '../../components/LeftDiv';
import NumberFormat from 'react-number-format';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { DatePickerComponent, DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import '.././datepicker.css';

function getRndApi() {
  const apiList = ["https://wax.greymass.com", "https://wax.pink.gg", "https://wax.eosrio.io"];
  return apiList[Math.floor(Math.random() * (2 - 0) ) + 0];
}


const apiList = ["https://eos.api.eosnation.io", "https://api.eoseoul.io", "https://eos.eosphere.io"];

const atomicApiList = ["https://eos.api.atomicassets.io"];



let zero = '0';

const myChain = {
  chainId: 'aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906',
  rpcEndpoints: [{
      protocol: 'https',
      host: 'eos.greymass.com',
      port: '443'
  }]
};
const wtf = ['526134'];
const rpc = new JsonRpc('https://eos.greymass.com');


const anchor = new Anchor([myChain], {

  appName: 'WaxDAO',

});



const authorizeWaxDAO = async (collection, setEnterModalText, setLoadingDisplay, 
    setEnterButtonsDisplay, setEnterModalDisplay) => {

    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
    const walletProvider = localStorage.getItem('walletProvider');

    if(walletProvider != 'anchor'){

      setEnterModalText('You are not logged in. Click "Wallet" in the top menu');
  
    }
  
  
      if(walletProvider == 'anchor'){
  
      // Retrieve current session from state
      const session = await anchor.login()
      //console.log(session[0].signTransaction);
    
  
    
      try {
        // Reset our response state to clear any previous transaction data
        
        const action = [{
              account: 'atomicassets',
              name: 'addcolauth',
              authorization: [{
                actor: anchor.users[0].accountName,
                permission: anchor.users[0].requestPermission,
            }],
            data: {
                collection_name: collection,
                account_to_add: "waxdaomarket",
            }
          }]
        // Call transact on the session (compatible with eosjs.transact)
        const response = await session[0].signTransaction({
          actions: action
        }, {
          blocksBehind: 3,
          expireSeconds: 60,
          broadcast: true,
        })
        // Update application state with the responses of the transaction
        setLoadingDisplay('');
        setEnterButtonsDisplay('hidden');
        setEnterModalText('Processing your transaction...')
        const timer = setTimeout(() => {
          
            setEnterModalText('You have authorized waxdaomarket, you can now create drops');
            setLoadingDisplay('none');
            setEnterButtonsDisplay('');
      
        }, 4000);
        return () => clearTimeout(timer);
    
      } catch(e) {
        setEnterModalText(JSON.stringify(e.message));
        console.log(e)
        
      }
    
    
        } //end if wallet = anchor
  
  }//end authorizeWaxDAO





const createDrop = async (dropPrice, dropSymbol, dropPrecision, rewardContract, collection, schema, templateID, 
    totalAvailable, limitPerUser, cooldownSeconds, whitelistType, usernames, farmName, 
    minimumNftsStaked, startTimestamp, endTimestamp, dropDescription, logoHash, receivingAccount,
    revenueOption, poolName, percentToPool, setEnterModalText, setLoadingDisplay, 
    setEnterButtonsDisplay, setEnterModalDisplay) => {


var theMinimum;

if(minimumNftsStaked == null || minimumNftsStaked == '' || minimumNftsStaked == undefined){

  theMinimum = '0';

} else {

  theMinimum = minimumNftsStaked;

}


    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
    const walletProvider = localStorage.getItem('eosWalletProvider');

    if(walletProvider != 'anchor'){

      setEnterModalText('You are not logged in. Click "Wallet" in the top menu');
  
    }

  
  
      if(walletProvider == 'anchor'){
  
      // Retrieve current session from state
      const session = await anchor.login()
      //console.log(session[0].signTransaction);
    
  
    
      try {
        // Reset our response state to clear any previous transaction data
        
        const action = [{
              account: 'waxdaomarket',
              name: 'createdrop',
              authorization: [{
                actor: anchor.users[0].accountName,
                permission: anchor.users[0].requestPermission,
            }],
            data: {
                user: anchor.users[0].accountName,
                price: dropPrice,
                token_symbol: dropPrecision + ',' + dropSymbol,
                contract: rewardContract,
                collection: collection,
                schema: schema,
                template_id: templateID,
                total_available: totalAvailable,
                limit_per_user: limitPerUser,
                cooldown: cooldownSeconds,
                whitelist_type: whitelistType,
                allowed_users: usernames.split(','),
                farmname: farmName,
                minimum_to_stake: theMinimum,
                start_time: startTimestamp,
                end_time: endTimestamp,
                drop_description: dropDescription,
                drop_logo: logoHash,
                receiver: receivingAccount,
                pool_or_farm: revenueOption,
                pool_or_farm_name: poolName,
                percent_to_pool: percentToPool,
                pool_id: [],
                drop_type: 'standard',
                pack_template: '0',
            }
          }]
        // Call transact on the session (compatible with eosjs.transact)
        const response = await session[0].signTransaction({
          actions: action
        }, {
          blocksBehind: 3,
          expireSeconds: 60,
          broadcast: true,
        })
        // Update application state with the responses of the transaction
        setLoadingDisplay('');
        setEnterButtonsDisplay('hidden');
        setEnterModalText('Processing your transaction...')
        const timer = setTimeout(() => {
          
            setEnterModalText('Your drop has been created. Make sure your collection has RAM (manage drops -> collection)');
            setLoadingDisplay('none');
            setEnterButtonsDisplay('');
      
        }, 4000);
        return () => clearTimeout(timer);
    
      } catch(e) {
  
          if(e.message == "assertion failure with message: You must add waxdaomarket as an authorized account on this collection"){
  
              setEnterModalText(
              <span>
                  waxdaomarket is not authorized on this collection.<br/><br/>
                  <GameButton onClick={() => { authorizeWaxDAO(collection, setEnterModalText, setLoadingDisplay, 
      setEnterButtonsDisplay, setEnterModalDisplay) }}>
                      Authorize Now
                  </GameButton>
  
              </span>);
  
          } else{
  
              setEnterModalText(JSON.stringify(e.message));
  
          }
  
        
        console.log(e)
        
      }
    
    
        } //end if wallet = anchor
  
  } //end createDrop
  




const addNotify = async (collection, setEnterModalText, setLoadingDisplay, 
    setEnterButtonsDisplay, setEnterModalDisplay) => {


    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
    const walletProvider = localStorage.getItem('eosWalletProvider');

    if(walletProvider != 'anchor'){

      setEnterModalText('You are not logged in. Click "Wallet" in the top menu');
  
    }

  
      if(walletProvider == 'anchor'){
  
      // Retrieve current session from state
      const session = await anchor.login()
      //console.log(session[0].signTransaction);
    
  
    
      try {
        // Reset our response state to clear any previous transaction data
        
        const action = [{
          account: 'atomicassets',
          name: 'addnotifyacc',
              authorization: [{
                actor: anchor.users[0].accountName,
                permission: anchor.users[0].requestPermission,
            }],
            data: {
              collection_name: collection,
              account_to_add: "waxdaomarket",
            }
          }]
        // Call transact on the session (compatible with eosjs.transact)
        const response = await session[0].signTransaction({
          actions: action
        }, {
          blocksBehind: 3,
          expireSeconds: 60,
          broadcast: true,
        })
        // Update application state with the responses of the transaction
        setLoadingDisplay('');
        setEnterButtonsDisplay('hidden');
        setEnterModalText('Processing your transaction...')
        const timer = setTimeout(() => {
          
            setEnterModalText('waxdaomarket is now added as a notify account');
            setLoadingDisplay('none');
            setEnterButtonsDisplay('');
      
        }, 4000);
        return () => clearTimeout(timer);
    
      } catch(e) {
  
        
  
              setEnterModalText(JSON.stringify(e.message));
        
        console.log(e)
        
      }
    
    
        } //end if wallet = anchor
  
  } //end addNotify




const CreateDrop = () => {
  
  const {  decimals, setDecimals, farmName, setFarmName, rewardToken, setRewardToken, rewardContract, setRewardContract, 
   collection, setCollection, schema, setSchema, farmLogo, setFarmLogo, paymentMethod, setPaymentMethod, timestamp, 
   setTimestamp, setEpochTime, assetDisplay, setAssetDisplay, assetToBurn, setAssetToBurn, burnable, setBurnable, 
   burnableDisplay, setBurnableDisplay, stakingPeriod, setStakingSeconds, stakingSeconds, setStakingPeriod, quantity, 
   setQuantity, farmType, setFarmType, farmTypeDisplay, setFarmTypeDisplay, farmTypeValue, setFarmTypeValue, templates, 
   setTemplates, schemaDisplay, setSchemaDisplay, templateMessage, setTemplateMessage,                 
   rewardType,
   setRewardType,
   rewardTypeDisplay,
   setRewardTypeDisplay,
   rewardTypeValue,
   setRewardTypeValue,
   rewardAmount,
   setRewardAmount,
   enterModalText,
   setEnterModalText,
   loadingDisplay, 
   setLoadingDisplay, 
   enterButtonsDisplay,
   setEnterButtonsDisplay,
   enterModalDisplay, 
   setEnterModalDisplay,
   usernamesDisplay,
   setUsernamesDisplay,
   farmnameDisplay,
   setFarmnameDisplay,
   whitelistType,
   setWhitelistType,
   setStartTime,
   setEndTime,
   startTimestamp,
   setStartTimestamp,
   endTimestamp,
   setEndTimestamp,
   dropPrice,
   setDropPrice,
   dropSymbol,
   setDropSymbol,
   dropPrecision,
   setDropPrecision,
   templateID,
   setTemplateID,
   totalAvailable,
   setTotalAvailable,
   limitPerUser,
   setLimitPerUser,
   cooldownSeconds,
   setCooldownSeconds,
   minimumNftsStaked,
   setMinimumNftsStaked,
   usernames,
   setUsernames,
   dropDescription,
   setDropDescription,
   logoHash,
   setLogoHash,
   receivingAccount,
   setReceivingAccount,
   revenueOption,
   setRevenueOption,
   poolName,
   setPoolName,
   revenueDisplay,
   setRevenueDisplay,
   percentToPool,
   setPercentToPool,
   setIsWhitelistedForDrops,
   isWhitelistedForDrops,
   loading,
   setLoading,
   
  
  } = useStateContext();






  useEffect(() => {
    const abortCont2 = new AbortController();

    //clear any previous state data

    setBurnable([]);
    setBurnableDisplay('hidden');
    setDecimals('');
    setFarmName('');
    setRewardToken('');
    setRewardContract('');
    setFarmLogo('');
    setPaymentMethod('Payment Method');
    setStakingPeriod('');
    setCollection('');
    setSchema('');
    setQuantity('');
    setFarmType('Select Farm Type');
    setUsernamesDisplay('hidden');
    setFarmnameDisplay('hidden');
    setDropDescription('');
    setLogoHash('');
    setRevenueOption('no');
    setPoolName('');
    setRevenueDisplay('hidden');
    setPercentToPool('0');
    setLoading(true);
    setLoadingDisplay('hidden');


      }

    
    , []);



    const getImage = (coverImage, loadingDisplay, setLoadingDisplay, loading, setLoading) => {


        setLoadingDisplay('');
        setLoading(true);
  
        }







  return (
    <div id="seo">
    <Helmet>
    <title>Create NFT Drop - WaxDAO</title>
    <meta name="description" content="Create an NFT drop on WaxDAO, and accept any EOS token for payment." />
    <link rel="canonical" href="https://eos.waxdao.io/create-drop" />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor]} appName={"WaxDAO"}>
    <MainContainer>


    <Modal className={enterModalDisplay}>
      <ModalContent>
        {enterModalText}
        
      </ModalContent>

        <Spinner style={{display:loadingDisplay}}>
        <SpinnerRed  />
        <SpinnerBlue  />
        <SpinnerGreen />
        </Spinner>

      <br/>
      <GameButton onClick={() => setEnterModalDisplay('hidden') }>
        Close Window
      </GameButton>
      </Modal>







    
      <CreateDaoCont>







        <DaoTitle>
        Create An NFT Drop
        </DaoTitle>
        <CreateFarmMsg>
        You can accept payment in any token you like.<br/><br/> 
        This includes your token that you created on WaxDAO... right?<br/> 
        </CreateFarmMsg>

<br/><br/>
        <MissingInfoMsg>
        IMPORTANT!!<br/><br/>
        waxdaomarket needs to be a "Notify Account" on your collection if you want to receive your RAM back 
        when NFTs from mint-on-demand drops are burned.

<div className='text-center text-white'>

        <label htmlFor="collection" ><br /><br/>NFT Collection:<br /></label>
        <ProposalInput type="text" 
        id="collection" 
        maxLength={12}
        value={collection}
        onChange={(e) => {
          setCollection(e.target.value.toLowerCase());
        }}
        />

        <DaoButton onClick={() => {addNotify(collection, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay)}}>
          Add waxdaomarket Now
        </DaoButton>

        </div>

        </MissingInfoMsg>

       <br/><br/>

       <MissingInfoMsg>
        Note: You must be authorized on the collection in order to create a drop for it.
        </MissingInfoMsg>
        <br/>



        <NewProposal>

      <label htmlFor="dropPrice">Price Per NFT: <br /></label>
      <ProposalInput 
      type="text" 
      maxLength={20}
      id="dropPrice" 
      placeholder="Put 0 if it's a free drop"
      value={dropPrice}
      onChange={(e) => {
        setDropPrice(e.target.value);
        }}
        
      />
      <br/>
      *Note: WaxDAO takes a 2% service fee on completed sales
<br/><br/>
      <label htmlFor="tknSym" ><br />Token Symbol:<br /></label>
      <ProposalInput 
      type="text" 
      maxLength={7}
      placeholder="Put FREE if it's a free drop"
      id="farmLogo" 
      value={dropSymbol}
      onChange={(e) => {
        setDropSymbol(e.target.value.toUpperCase());
      }}
      />



      <label htmlFor="tknPrecision" ><br />Token Precision:<br /></label>
      <ProposalInput type="text" 
      placeholder="Put 0 if it's a free drop"
      maxLength={2}
      id="tknPrecision" 
      value={dropPrecision}
      onChange={(e) => {
        setDropPrecision(e.target.value);
      }}
      />


      <label htmlFor="rewardContract" ><br />Token Contract:<br /></label>

      <ProposalInput 
      type="text" 
      id="rewardContract" 
      placeholder="Put free if it's a free drop"
      maxLength={12}
      value={rewardContract}
      onChange={(e) => {
        setRewardContract(e.target.value.toLowerCase());
      }}
      />


      <label htmlFor="collection" ><br />NFT Collection:<br /></label>
      <ProposalInput type="text" 
      id="collection" 
      maxLength={12}
      value={collection}
      onChange={(e) => {
        setCollection(e.target.value.toLowerCase());
      }}
      />





      <label htmlFor="schema" ><br />NFT Schema:<br /></label>
      <ProposalInput type="text" 
      id="schema" 
      maxLength={12}
      value={schema.toLowerCase()}
      onChange={(e) => {
        setSchema(e.target.value);
      }}
      />
 

      <label htmlFor="templates"><br />Template ID:<br /></label>
      <ProposalInput
      type="text"
      maxLength={8}
      id="template" 
      value={templateID}
      onChange={(e) => {
        setTemplateID(e.target.value);
      }}
      />

    <label htmlFor="templates"><br />Total Available:<br /></label>

    <ProposalInput
    placeholder="Put 0 if unlimited"
    type="text"
    maxLength={20}
    value={totalAvailable}
    id="template" 
    onChange={(e) => {
    setTotalAvailable(e.target.value);
    }}
    />


<label htmlFor="templates"><br />Limit Per User:<br /></label>

<ProposalInput
placeholder="Put 0 if unlimited"
type="text"
maxLength={20}
value={limitPerUser}
id="template" 
onChange={(e) => {
setLimitPerUser(e.target.value);
}}
/>


<label htmlFor="templates"><br />Cooldown Timer (in seconds):<br /></label>

<ProposalInput
placeholder="Put 0 if no reset"
type="text"
maxLength={8}
value={cooldownSeconds}
id="template" 
onChange={(e) => {
setCooldownSeconds(e.target.value);
}}
/>


<form>
        <DropDown         
          onChange={(e) => {if(e.target.value == 'none'){setUsernamesDisplay('hidden'); setFarmnameDisplay('hidden');} else if(e.target.value == 'farm'){setUsernamesDisplay('hidden'); setFarmnameDisplay('');} else if(e.target.value == 'usernames'){setUsernamesDisplay(''); setFarmnameDisplay('hidden');}    setWhitelistType(e.target.value)}}
          >
        <option value="" hidden>
          Whitelist Type
        </option>
        <option value="none">Public Drop</option>
        <option value="farm">WaxDAO NFT Farm</option>
        <option value="usernames">List Of Usernames</option>
        </DropDown>
        </form>

<span className={usernamesDisplay}>
        <label htmlFor="templates"><br />Users To Whitelist:<br /></label>

        <TooltipComponent content="Separate each with a comma"
      position="BottomCenter">
      <UserWhitelist
        placeholder="john,bob,alice"
        value={usernames}
        type="text"
        rows={4}
        id="template" 
        onChange={(e) => {
        setUsernames(e.target.value.replace(/ /g,''));
        }}
        />
        </TooltipComponent>
</span>

<span className={farmnameDisplay}>
        <label htmlFor="templates"><br />Farm Name:<br /></label>

        <ProposalInput
        type="text"
        maxLength={12}
        value={farmName}
        id="template" 
        onChange={(e) => {
        setFarmName(e.target.value.toLowerCase());
        }}
        />

        <label htmlFor="templates"><br />Minimum NFTs Staked:<br /></label>

        <ProposalInput
        placeholder="How many NFTs to get whitelisted?"
        type="text"
        maxLength={12}
        value={minimumNftsStaked}
        id="template" 
        onChange={(e) => {
        setMinimumNftsStaked(e.target.value);
        }}
        />

</span>




      <label htmlFor="stakingPeriod" ><br />Start Time:<br /></label>
        <div className='daterangepicker-control-section'>
      <DateTimePickerComponent
        id="datepicker"
         cssClass='customClass'
         
         onChange={(e) => {
             setStartTime(e)
        }}
         
         >
      </DateTimePickerComponent>
      </div>


      <label htmlFor="stakingPeriod" ><br />End Time:<br /></label>
        <div className='daterangepicker-control-section'>
      <DateTimePickerComponent
        id="datepicker"
         cssClass='customClass'
         
         onChange={(e) => {
             setEndTime(e)
        }}
         
         >
      </DateTimePickerComponent>
      </div>


        <label htmlFor="templates"><br />Drop Description:<br /></label>


      <UserWhitelist
        placeholder="Tell people some info about your drop"
        value={dropDescription}
        type="text"
        rows={4}
        id="template" 
        onChange={(e) => {
        setDropDescription(e.target.value);
        }}
        />

        <br/>

        <label htmlFor="templates"><br />Cover Image (300px x 300px):<br /></label>

        <ProposalInput
        type="text"
        maxLength={70}
        placeholder="IPFS hash only. e.g. Qmfkjs..."
        value={logoHash}
        id="template" 
        onChange={(e) => {
        setLogoHash(e.target.value);
        getImage(e.target.value, loadingDisplay, setLoadingDisplay, loading, setLoading);
        }}
        />




        {logoHash != null && logoHash != '' && logoHash.length > 10 && (
          <span className={loadingDisplay}>
            <span className={loading == false && 'hidden'}>
            <LoadSpinner>
            <LoadSpinnerRed  />
            <LoadSpinnerBlue  />
            <LoadSpinnerGreen />
            </LoadSpinner>

              <br/>Loading Image...<br/>
            </span>
            <span className={loading == true && 'hidden'}>
          <CreateDropIPFS src={`https://ipfs.io/ipfs/${logoHash}`} onLoad={() => {setLoading(false);}}  onError={() => {setLoading(false);}} />
          </span>
          </span>
        )}


        <label htmlFor="templates"><br />Receiving Account:<br /></label>

        <ProposalInput
        type="text"
        maxLength={12}
        placeholder="Who gets the money?"
        value={receivingAccount}
        id="template" 
        onChange={(e) => {
        setReceivingAccount(e.target.value.toLowerCase());
        }}
        />

        <MissingInfoMsg>
        You can automatically send a portion of drop revenue to any staking pool or NFT farm on 
        WaxDAO.<br/><br/>
        To do this, select one of the options below. Otherwise, select "Don't split revenue".
        </MissingInfoMsg>


        <form>
        <DropDown         
          onChange={(e) => {setRevenueOption(e.target.value); 
            if(e.target.value == "no"){
                setRevenueDisplay('hidden');
            } else {
                setRevenueDisplay('');
            }
             
        
        }}
          >
        <option value="" hidden>
          Revenue Split
        </option>
        <option value="no">Don't split revenue</option>
        <option value="farm">WaxDAO NFT Farm</option>
        <option value="pool">WaxDAO Staking Pool</option>
        </DropDown>
        </form>

<span className={revenueDisplay}>

        <label htmlFor="templates"><br />Name of {revenueOption}:<br /></label>

        <ProposalInput
        type="text"
        maxLength={12}
        placeholder={`Which ${revenueOption}?`}
        value={poolName}
        id="template" 
        onChange={(e) => {
        setPoolName(e.target.value.toLowerCase());
        }}
        />



        <label htmlFor="templates"><br />Percent for {revenueOption}:<br /></label>

        <ProposalInput
        type="text"
        maxLength={2}
        placeholder='number from 1 to 98'
        value={percentToPool}
        id="template" 
        onChange={(e) => {
            setPercentToPool(e.target.value);
        }}
        />
 



 </span>


        <br />

{(startTimestamp == '' || endTimestamp == '') && (
<span>

<MissingInfoMsg>
  You need to select a start date and end date before you can submit.
</MissingInfoMsg>



<DisabledButton>
  Create Drop Now
</DisabledButton>
</span>
)}


{(startTimestamp != '' && endTimestamp != '') && (

<DaoButton 

onClick={() => {
  
  createDrop(dropPrice, dropSymbol, dropPrecision, rewardContract, collection, schema, templateID, 
      totalAvailable, limitPerUser, cooldownSeconds, whitelistType, usernames, farmName, 
      minimumNftsStaked, startTimestamp, endTimestamp, dropDescription, logoHash, receivingAccount,
      revenueOption, poolName, percentToPool, setEnterModalText, setLoadingDisplay, 
      setEnterButtonsDisplay, setEnterModalDisplay )

}}

>
Create Drop Now
</DaoButton>

)}









<br/><br/>

    </NewProposal>
    
    </CreateDaoCont>
    
    </MainContainer>
    </UALProvider>
    <br/><br/><br/>
    </div>
  )
}

export default CreateDrop