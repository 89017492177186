import React, { useEffect } from 'react';
import { GridComponent, Resize, Sort, ContextMenu, Filter, Page, ExcelExport, PdfExport, Edit, Inject, Search, Toolbar } from '@syncfusion/ej2-react-grids';
import { ordersData, contextMenuItems, ordersGrid, nftFarmData, nftFarmGrid } from '../data/dummy';
import { Header } from '../components';
import { ColumnDirective, ColumnsDirective } from '@syncfusion/ej2-react-charts';
import { useParams } from 'react-router-dom';
import { useStateContext } from '../contexts/ContextProvider';
import { Anchor } from 'ual-anchor';
import { Wax } from '@eosdacio/ual-wax';
import { User } from "universal-authenticator-library";
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { Api, JsonRpc } from "eosjs";
import {Helmet} from "react-helmet";
import axios from 'axios';
import { data } from 'autoprefixer';
import { AllFarmsCont, PoolAmount, FarmButton, SingleFarmCont, FarmLogo, FarmTitle, FarmsPageCont, FarmsTitle, HighlightedFarm, HighlightedLogo, HighlightDetails, HighlightTitle, FarmEndDate, HighlightMessage, HighlightAmount, Modal, ModalContent, Spinner, SpinnerRed, SpinnerBlue, SpinnerGreen, GameButton, UserWhitelist, DaoButton, SuggestionsCont, Suggestion, SuggestionDesc, SuggestionDeets, SuggestionUser, SuggestionBtn, MissingInfoMsg, SuggestionReply } from '../components/LeftDiv';
import NumberFormat from 'react-number-format';

let zero = '0';

var thisUsersFarms;

const myChain = {
  chainId: '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4',
  rpcEndpoints: [{
      protocol: 'https',
      host: 'wax.greymass.com',
      port: '443'
  }]
};

const rpc = new JsonRpc('https://wax.greymass.com');


const anchor = new Anchor([myChain], {

  appName: 'WaxDAO',

});

const wax = new Wax([myChain], {

  appName: 'WaxDAO',

});


const ualLogin = async () => {
 
 // console.log('Loading...');
  await anchor.login();
  getCustomersData();
  //localStorage.setItem('waxAccount', 'anchor.users[0].accountName')
  //console.log(anchor.users[0].accountName);

}

const ualLogout = async () => {
 
 // console.log('Loading...');
  await anchor.logout();
  //console.log(anchor.users[0].accountName);

}



const addSuggest = async (description, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {

    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
    const walletProvider = localStorage.getItem('walletProvider');
  
    if(walletProvider == 'wax'){
  
  
  
    // Retrieve current session from state
    const session = await wax.login()
  
  
    try {
      // Reset our response state to clear any previous transaction data
      
      const action = [{
            account: 'waxdaomarket',
            name: 'addsuggest',
            authorization: [{
              actor: wax.users[0].accountName,
              permission: wax.users[0].requestPermission,
          }],
          data: {
              user: wax.users[0].accountName,
              description: description,
          }
        }]
      // Call transact on the session (compatible with eosjs.transact)
      const response = await session[0].signTransaction({
        actions: action
      }, {
  
        blocksBehind: 3,
        expireSeconds: 60,
        broadcast: true,
      })
      // Update application state with the responses of the transaction
      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Processing your transaction...')
      const timer = setTimeout(() => {
        
          setEnterModalText('Your suggestion has been submitted');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');
    
      }, 4000);
      return () => clearTimeout(timer);
  
    } catch(e) {


            setEnterModalText(JSON.stringify(e.message));

      console.log(e)
      
    }
  
      } //end if wallet = wax
  
  
  
      if(walletProvider == 'anchor'){
  
      // Retrieve current session from state
      const session = await anchor.login()
      //console.log(session[0].signTransaction);
    
  
    
      try {
        // Reset our response state to clear any previous transaction data
        
        const action = 
          [{
            account: 'waxdaomarket',
            name: 'addsuggest',
            authorization: [{
              actor: anchor.users[0].accountName,
              permission: anchor.users[0].requestPermission,
          }],
          data: {
              user: anchor.users[0].accountName,
              description: description,
          }
        }]
        // Call transact on the session (compatible with eosjs.transact)
        const response = await session[0].signTransaction({
          actions: action
        }, {
          blocksBehind: 3,
          expireSeconds: 60,
          broadcast: true,
        })
        // Update application state with the responses of the transaction
        setLoadingDisplay('');
        setEnterButtonsDisplay('hidden');
        setEnterModalText('Processing your transaction...')
        const timer = setTimeout(() => {
          
            setEnterModalText('Your suggestion has been submitted');
            setLoadingDisplay('none');
            setEnterButtonsDisplay('');
      
        }, 4000);
        return () => clearTimeout(timer);
    
      } catch(e) {
        setEnterModalText(JSON.stringify(e.message));
        console.log(e)
        
      }
    
    
        } //end if wallet = anchor
  
  } //end addSuggest


  const rmvSuggest = async (ID, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {

    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
    const walletProvider = localStorage.getItem('walletProvider');
  
    if(walletProvider == 'wax'){
  
  
  
    // Retrieve current session from state
    const session = await wax.login()
  
  
    try {
      // Reset our response state to clear any previous transaction data
      
      const action = [{
            account: 'waxdaomarket',
            name: 'rmvsuggest',
            authorization: [{
              actor: wax.users[0].accountName,
              permission: wax.users[0].requestPermission,
          }],
          data: {
              ID: ID,
              user: wax.users[0].accountName,
          }
        }]
      // Call transact on the session (compatible with eosjs.transact)
      const response = await session[0].signTransaction({
        actions: action
      }, {
  
        blocksBehind: 3,
        expireSeconds: 60,
        broadcast: true,
      })
      // Update application state with the responses of the transaction
      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Processing your transaction...')
      const timer = setTimeout(() => {
        
          setEnterModalText('Your suggestion has been removed');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');
    
      }, 4000);
      return () => clearTimeout(timer);
  
    } catch(e) {


            setEnterModalText(JSON.stringify(e.message));

      console.log(e)
      
    }
  
      } //end if wallet = wax
  
  
  
      if(walletProvider == 'anchor'){
  
      // Retrieve current session from state
      const session = await anchor.login()
      //console.log(session[0].signTransaction);
    
  
    
      try {
        // Reset our response state to clear any previous transaction data
        
        const action = 
          [{
            account: 'waxdaomarket',
            name: 'rmvsuggest',
            authorization: [{
              actor: anchor.users[0].accountName,
              permission: anchor.users[0].requestPermission,
          }],
          data: {
              ID: ID,
              user: anchor.users[0].accountName,
          }
        }]
        // Call transact on the session (compatible with eosjs.transact)
        const response = await session[0].signTransaction({
          actions: action
        }, {
          blocksBehind: 3,
          expireSeconds: 60,
          broadcast: true,
        })
        // Update application state with the responses of the transaction
        setLoadingDisplay('');
        setEnterButtonsDisplay('hidden');
        setEnterModalText('Processing your transaction...')
        const timer = setTimeout(() => {
          
            setEnterModalText('Your suggestion has been removed');
            setLoadingDisplay('none');
            setEnterButtonsDisplay('');
      
        }, 4000);
        return () => clearTimeout(timer);
    
      } catch(e) {
        setEnterModalText(JSON.stringify(e.message));
        console.log(e)
        
      }
    
    
        } //end if wallet = anchor
  
  } //end rmvSuggest





const Suggestions = () => {

  const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode, currentUser, tokenName, 
    setTokenName, maxSupply, setMaxSupply, decimals, setDecimals, tokenString, setTokenString, farmData, 
    setFarmData, farmCollection, setFarmCollection, farmSchema, setFarmSchema, farmRewards, setFarmRewards, 
    farmExpires, setFarmExpires, farmIcon, setFarmIcon, farmContract, setFarmContract, createdBy, setCreatedBy, 
    farmList, setFarmList, stakedOnly, setStakedOnly, farmsUserIsIn, setFarmsUserIsIn, highlightedFarm,
    setHighlightedFarm,
    suggestions,
    setSuggestions,
    enterModalText,
    setEnterModalText,
    loadingDisplay, 
    setLoadingDisplay, 
    enterButtonsDisplay,
    setEnterButtonsDisplay,
    enterModalDisplay, 
    setEnterModalDisplay,
    description,
    setDescription,
  } = useStateContext();

useEffect(() => {

  // Get highlighted farm info

  setSuggestions([]);
    const abortCont5 = new AbortController();
  
    axios.post('https://wax.pink.gg/v1/chain/get_table_rows',{
      table:"suggestions",
      scope:"waxdaomarket",
      code:"waxdaomarket",
      limit:1000,
      json:true
    }).then((suggestionsResponse) => {

        setSuggestions(suggestionsResponse.data.rows);
          console.log(suggestionsResponse.data.rows);
  

  
  
    })
  
  .catch((error) => console.log(error));
  

  
  }, []);







  return (
  <div id="seo">
    <Helmet>
    <title>Suggestions</title>
    <meta name="description" content="Request features and report bugs on WaxDAO" />
    <link rel="canonical" href="https://waxdao.io/suggestions" />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor, wax]} appName={"WaxDAO"}>
    
    <Modal className={enterModalDisplay}>
      <ModalContent>
        {enterModalText}
        
      </ModalContent>

        <Spinner style={{display:loadingDisplay}}>
        <SpinnerRed  />
        <SpinnerBlue  />
        <SpinnerGreen />
        </Spinner>

      <br/>
      <GameButton onClick={() => setEnterModalDisplay('hidden') }>
        Close Window
      </GameButton>
      </Modal>

<FarmsPageCont>
<FarmsTitle>
    Suggestions
</FarmsTitle>
<SuggestionsCont>

    <MissingInfoMsg>
        Note: You are responsible for the RAM cost of storing your request. You can free up 
        this RAM at any time by deleting your request.<br/><br/>
        If you abuse our system (spam etc), WaxDAO reserves the right to blacklist you 
        and prevent you from freeing up your RAM. Suggest wisely ;)
    </MissingInfoMsg>

    <label htmlFor="templates" className="text-white"><br />New Suggestion:<br /></label>
    <UserWhitelist
    placeholder="140 characters max"
    maxLength="140"
    rows={4}
    value={description}
    id="template" 
    onChange={(e) => {
    setDescription(e.target.value);
    }}
    />
    <br/>
    <DaoButton onClick={() => { addSuggest(description, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) }}>
        Submit Suggestion
    </DaoButton>

    <br/>
    <br/>

    {suggestions != null && Array.isArray(suggestions) && suggestions.map((item, index) => (
        <Suggestion key={index}>
        <SuggestionDesc>
        {item.description != null && item.description}
        </SuggestionDesc>
{item.description != null && item.description.indexOf('making it 100% clear that when you get your RAM back in WAX you get only half back') > -1 && (
        <SuggestionReply>
        Response: This is not true at all, I just looked at your account and you deposited 1 Wax, staked an NFT and then 
        claimed your balance expecting to get 100% of it back? That would defeat the entire purpose of the deposit...
        <br/><br/>
        You will get the remainder back when you unstake, like the FAQ and the transaction response said.
        </SuggestionReply>


)}

        <SuggestionDeets>
            <SuggestionUser>
            By {item.user != null && item.user}
            </SuggestionUser>
            <SuggestionUser>
            {item.time != null && new Date(item.time * 1000).toLocaleDateString()}
            </SuggestionUser>
            <SuggestionUser>
            {item.user != null && currentUser != null && (currentUser == item.user 
              || currentUser == "mikedcrypto5") && (
                  <SuggestionBtn onClick={() => { rmvSuggest(item.ID, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) }}>
                      Remove
                  </SuggestionBtn>
              ) }
            </SuggestionUser>
         
        </SuggestionDeets>
        </Suggestion>
    ))}


</SuggestionsCont>
</FarmsPageCont>





    </UALProvider>
    <br/><br/><br/>
    </div>
  )
}

export default Suggestions