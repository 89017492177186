import React, { useEffect } from 'react';
import { Header } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { Anchor } from 'ual-anchor';
import { User } from "universal-authenticator-library";
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { Api, JsonRpc } from "eosjs";
import {Helmet} from "react-helmet";
import axios from 'axios';

let zero = '0';

const myChain = {
  chainId: 'aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906',
  rpcEndpoints: [{
      protocol: 'https',
      host: 'eos.greymass.com',
      port: '443'
  }]
};

const rpc = new JsonRpc('https://eos.greymass.com');


const anchor = new Anchor([myChain], {

  appName: 'WaxDAO',

});





const transferTokens = async (transferTokenString, receiver) => {
  // Retrieve current session from state
  const session = await anchor.login()
  //console.log(session[0].signTransaction);


  try {
    // Reset our response state to clear any previous transaction data
    
    const action = [{
      account: 'eosio.token',
      name: 'transfer',
      authorization: [{
      actor: anchor.users[0].accountName,
      permission: anchor.users[0].requestPermission,
      }],
      data: {
      from: anchor.users[0].accountName,
      quantity: transferTokenString,
      memo: '',
      to: receiver,
        }
      }]
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction({
      actions: action
    }, {

      broadcast: true,
      blocksBehind: 3,
      expireSeconds: 120,
    })
    // Update application state with the responses of the transaction
    alert('Success!');
  } catch(e) {
    console.log(e)
    
  }
}

const Transfer = () => {
  const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode, currentUser, transferTokenName, setTransferTokenName, transferAmount, setTransferAmount, transferDecimals, setTransferDecimals, transferTokenString, setTransferTokenString, receiver, setReceiver, tokenList, setTokenList, noTokensDisplay, setNoTokensDisplay } = useStateContext();

  useEffect(() => {
    const abortCont2 = new AbortController();

    //clear any previous state data
    setTransferTokenName('');
    setTransferAmount('');
    setTransferDecimals('');
    setReceiver('');

    


      }

    
    , []);



  useEffect(() => {
    setTokenList([]);
     const eosUsername = localStorage.getItem('eosAccount');
     const eosWalletProvider = localStorage.getItem('eosWalletProvider');
      
     if(eosUsername){
       //console.log('You have an active session');
       '';
       //setProfileDisplay('flex');
     }else{
       //console.log('You are not logged in');
       return('');
     }
      
      //console.log(waxUsername)
      axios.post('https://eos.api.eosnation.io/v1/chain/get_table_rows',{
        table:"accounts",
        scope:waxUsername,
        code:waxUsername,
        limit:50,
        json:true
      })
        .then((response) => {

          if(response.data.rows[0] != undefined){
            setNoTokensDisplay('hidden');
          }
    
          setTokenList(response.data.rows);
          //console.log(response.data.rows);
        })
        .catch((error) => console.log(error.response.data));
    
      }
    
    , []);



  return (
    <div id="seo">
    <Helmet>
    <title>Transfer Tokens - WaxDAO</title>
    <meta name="description" content="Transfer EOS tokens to other EOS wallets, with this easy-to-use tool." />
    <link rel="canonical" href="https://waxdao.io/transfer-tokens" />
    </Helmet>
    <UALProvider chains={[myChain]} authenticators={[anchor]} appName={"WaxDAO"}>
    <br/><br/><br/>
    <div className="m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl">
    <Header title="Transfer Tokens" category="App" />
    <p className="mt-4 text-2xl">Easily send your new EOS tokens to a different wallet.</p>
    <p className="mt-4 text-xl">All it takes is 10 seconds.</p>

    <div
  className='p-3 m-auto border-4 rounded-lg gap-4 mt-4 text-center'
  style={{ display:'block', borderColor:currentColor }}
  >
    <h2 className="text-2xl font-semibold">My Tokens</h2>
   
   <div className="flex gap-4 text-center m-auto flex-wrap">

   <div id="noTokens" className={`text-center items-center align-center justify-center m-auto ${noTokensDisplay}`}>
    You have no tokens from your contract on this account.

   </div>


    {tokenList.map((item, index) => (


   
        <div key={index}
        
        className='p-3 m-auto border-2 rounded-lg gap-4 mt-4 text-center'
        style={{ borderColor:currentColor }}

        >{item.balance}</div>


        ))}

</div>    

</div>




    <div id="createForm" className="text-center items-center justify-center border-4 p-6 rounded-full mt-4"
      style={{borderColor: currentColor}}
    >
      <label htmlFor="tokenName">Token Name: <br /></label>
      <TooltipComponent content="Must be the exact name of the token you created"
      position="BottomCenter">
      <input type="text" 
      id="tokenName" 
      className="border-2 rounded-lg text-xl"
      style={{borderColor: currentColor}}
      maxLength={7}
      value={transferTokenName}
      onChange={(e) => {
        setTransferTokenName(e.target.value.toUpperCase());
        if(transferDecimals == '0'){
          setTransferTokenString(transferAmount + ' ' + e.target.value.toUpperCase());
        }
        
        if(transferDecimals !== '0'){
          setTransferTokenString(transferAmount + '.' + zero.repeat(transferDecimals) + ' ' + e.target.value.toUpperCase());
        }
      
      
      }}
      />
      </TooltipComponent>

      <label htmlFor="issueAmount" ><br />Amount To Send: <br /></label>
      <TooltipComponent content="Whole numbers only"
      position="BottomCenter">
      <input type="number" 
      id="issueAmount" 
      className="border-2 rounded-lg text-xl"
      style={{borderColor: currentColor}}
      value={transferAmount}
      onChange={(e) => {
        setTransferAmount(e.target.value);
        if(transferDecimals == '0'){
          setTransferTokenString(e.target.value + ' ' + transferTokenName.toUpperCase());
        }
        
        if(transferDecimals !== '0'){
          setTransferTokenString(e.target.value + '.' + zero.repeat(transferDecimals) + ' ' + transferTokenName.toUpperCase());
        }
      
      }}
      />
      </TooltipComponent>

      <label htmlFor="decimals" ><br />Decimal Places: <br /></label>
      <TooltipComponent content="Must be exactly the same as the token you created"
      position="BottomCenter">
      <input type="number" 
      id="decimals" 
      className="border-2 rounded-lg text-xl"
      style={{borderColor: currentColor}}
      value={transferDecimals}
      onChange={(e) => {
        setTransferDecimals(e.target.value);
        if(e.target.value == '0'){
          setTransferTokenString(transferAmount + ' ' + transferTokenName.toUpperCase());
        }
        
        if(e.target.value !== '0'){
          setTransferTokenString(transferAmount + '.' + zero.repeat(e.target.value) + ' ' + transferTokenName.toUpperCase());
        }
        console.log(transferTokenString);
      
      }}
      />
      </TooltipComponent>

      <label htmlFor="receiver" ><br />Receiver: <br /></label>
      <TooltipComponent content="EOS address that you are sending tokens to"
      position="BottomCenter">
      <input type="text" 
      id="receiver" 
      maxLength={12}
      className="border-2 rounded-lg text-xl"
      style={{borderColor: currentColor}}
      value={receiver}
      onChange={(e) => {
        setReceiver(e.target.value.toLowerCase());
        if(transferDecimals == '0'){
          setTransferTokenString(transferAmount + ' ' + transferTokenName.toUpperCase());
        }
        
        if(transferDecimals !== '0'){
          setTransferTokenString(transferAmount + '.' + zero.repeat(transferDecimals) + ' ' + transferTokenName.toUpperCase());
        }
      
      }}

      />
      </TooltipComponent>

      <button 
          type="button"
          className="text-lg p-3
          hover:drop-shadow-xl
          hover:bg-light-gray
          text-white rounded-lg mt-4"
          onClick={() => transferTokens(transferTokenString, receiver)}
          style={{ background: currentColor}}
        
        >
          Send Tokens
        </button>

    </div>

    </div>
    </UALProvider>
    </div>
  )
}

export default Transfer