import React, { useEffect } from 'react';
import { Header } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { Anchor } from 'ual-anchor';
import { Wax } from '@eosdacio/ual-wax';
import { User } from "universal-authenticator-library";
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { Api, JsonRpc } from "eosjs";
import {Helmet} from "react-helmet";
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import Select from 'react-select';
import axios from 'axios';
import { CreateDaoCont, BurnableCont, BuyWojak, NewRow, NFT, DropDown, CreateFarmMsg, DaoButton, DaoTitle, MainContainer, NewProposal, ProposalInput, ProposerTypeMessage, SingleProposal, UserWhitelist, FarmsPageCont, MissingInfoMsg } from '../components/LeftDiv';

const myChain = {
    chainId: 'aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906',
    rpcEndpoints: [{
        protocol: 'https',
        host: 'eos.greymass.com',
        port: '443'
    }]
  };
  
  const rpc = new JsonRpc('eos://wax.greymass.com');
  
  
  const anchor = new Anchor([myChain], {
  
    appName: 'WaxDAO',
  
  });

const Unavailable = () => {
  return (
    <div id="seo">
    <Helmet>
    <title>Check Back Soon - WaxDAO</title>
    <meta name="description" content="WaxDAO builds tools for creators on EOSIO blockchains" />
    <link rel="canonical" href="https://waxdao.io" />
    </Helmet>
  
    <UALProvider chains={[myChain]} authenticators={[anchor]} appName={"WaxDAO"}>
      
      <FarmsPageCont>
  
    <MissingInfoMsg>
    This page is currently only available for WAX Mainnet. Check back soon.
  
  
    </MissingInfoMsg>
  
  
      </FarmsPageCont>
  
  
  
      
    </UALProvider>
  
  </div>
  )
}

export default Unavailable