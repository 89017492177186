import React, { useEffect } from 'react';
import { GridComponent, Resize, Sort, ContextMenu, Filter, Page, ExcelExport, PdfExport, Edit, Inject } from '@syncfusion/ej2-react-grids';
import { ordersData, contextMenuItems, ordersGrid, nftFarmData, nftFarmGrid } from '../data/dummy';
import { Header } from '../components';
import { ColumnDirective, ColumnsDirective } from '@syncfusion/ej2-react-charts';
import { useParams } from 'react-router-dom';
import { useStateContext } from '../contexts/ContextProvider';
import { Anchor } from 'ual-anchor';
import { Wax } from '@eosdacio/ual-wax';
import { User } from "universal-authenticator-library";
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { Api, JsonRpc } from "eosjs";
import {Helmet} from "react-helmet";
import axios from 'axios';
import { data } from 'autoprefixer';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { BsInfoCircle } from 'react-icons/bs';
import { render } from 'react-dom';
import { MainContainer, FarmMenu, FarmMenuItem, FarmTopMenu, FarmTopMenuItem, LeftDiv, DaoName, Creator, DaoMenu, DaoMenuItem, RightDiv, AboutDAO, DaoTitle, AboutDaoCont, AboutDaoTitle, AboutDaoBody, SingleTemplate, TemplatesCont, StakingCont, DaoButton, NFT, NFTsCont, NFTLogo, NoDaosMessage, ProposalInput, DaoButtonCont, RamModal, WalletCont, FarmButtonCont, ToggleSwitch, InnerSwitch, ToggleSwitchCont, ToggleMessage, StakeManyButton, StakeManyDiv, LeftProfile, RightProfile, ProfileCont, ProfileImage, LeftProfileImg, SocialButton, Modal, ModalContent, Spinner, SpinnerRed, SpinnerBlue, SpinnerGreen, GameButton } from '../components/LeftDiv';
import NumberFormat from 'react-number-format';
var thisCollection;
var list = [];
var list2 = [];

import { Name, raw } from "eos-common";

const handleRemoveAsset = (asset_id, assetVector, setAssetVector) => {

    // remove the asset
    const assetList = [...assetVector];
    assetList.splice(assetList.indexOf(asset_id), 1);
    setAssetVector(assetList);
    console.log('handled removing it');
    


}




const handleAddAsset = (asset_id, assetVector, setAssetVector) => {


        setAssetVector([...assetVector, asset_id])
        console.log('handled adding it');
        



}

let zero = '0';

const myChain = {
  chainId: 'aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906',
  rpcEndpoints: [{
      protocol: 'https',
      host: 'eos.greymass.com',
      port: '443'
  }]
};

const rpc = new JsonRpc('https://eos.greymass.com');


const anchor = new Anchor([myChain], {

  appName: 'WaxDAO',

});


const stake = async (FarmName, asset_id, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {
  setEnterModalDisplay('');
  setEnterModalText('Awaiting confirmation...');
  const walletProvider = localStorage.getItem('eosWalletProvider');

  if(walletProvider == 'anchor'){

    // Retrieve current session from state
    const session = await anchor.login()
    //console.log(session[0].signTransaction);
  
    try {
      // Reset our response state to clear any previous transaction data
      
      const action = [{
            account: 'atomicassets',
            name: 'transfer',
            authorization: [{
              actor: anchor.users[0].accountName,
              permission: anchor.users[0].requestPermission,
          }],
          data: {
              to: 'waxdaofarmer',
              from: anchor.users[0].accountName,
              asset_ids: [asset_id],
              memo: '|stake|' + FarmName + '|',
          }
        }]
      // Call transact on the session (compatible with eosjs.transact)
      const response = await session[0].signTransaction({
        actions: action
      }, {

        blocksBehind: 3,
        expireSeconds: 60,
        broadcast: true,
      })
      // Update application state with the responses of the transaction
      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Processing your transaction...')
      const timer = setTimeout(() => {
        
          setEnterModalText('Your NFT has been staked');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');
    
      }, 4000);
      return () => clearTimeout(timer);
  
    } catch(e) {
  
  
            setEnterModalText(JSON.stringify(e.message));
  
      console.log(e)
      
    }
    
    }//end if wallet = anchor
  

}




const stakeMany = async (FarmName, assetVector, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {
  setEnterModalDisplay('');
  setEnterModalText('Awaiting confirmation...');
  const walletProvider = localStorage.getItem('eosWalletProvider');


  if(walletProvider == 'anchor'){

    // Retrieve current session from state
    const session = await anchor.login()
    //console.log(session[0].signTransaction);
  
    try {
      // Reset our response state to clear any previous transaction data
      
      const action = [{
            account: 'atomicassets',
            name: 'transfer',
            authorization: [{
              actor: anchor.users[0].accountName,
              permission: anchor.users[0].requestPermission,
          }],
          data: {
              to: 'waxdaofarmer',
              from: anchor.users[0].accountName,
              asset_ids: assetVector,
              memo: '|stake|' + FarmName + '|',
          }
        }]
      // Call transact on the session (compatible with eosjs.transact)
      const response = await session[0].signTransaction({
        actions: action
      }, {

        blocksBehind: 3,
        expireSeconds: 60,
        broadcast: true,
      })
      // Update application state with the responses of the transaction
      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Processing your transaction...')
      const timer = setTimeout(() => {
        
          setEnterModalText('Your NFTs have been staked');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');
    
      }, 4000);
      return () => clearTimeout(timer);
  
    } catch(e) {
  
  
            setEnterModalText(JSON.stringify(e.message));
  
      console.log(e)
      
    }
    
    }//end if wallet = anchor
  

} //end stakeMany






const unstake = async (FarmName, asset_id, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {
  setEnterModalDisplay('');
  setEnterModalText('Awaiting confirmation...');
  const walletProvider = localStorage.getItem('eosWalletProvider');


if(walletProvider == 'anchor'){

  // Retrieve current session from state
  const session = await anchor.login()
  //console.log(session[0].signTransaction);

  try {
    // Reset our response state to clear any previous transaction data
    
    const action = [{
          account: 'waxdaofarmer',
          name: 'unstake',
          authorization: [{
            actor: anchor.users[0].accountName,
            permission: anchor.users[0].requestPermission,
        }],
        data: {
            user: anchor.users[0].accountName,
            asset_id: asset_id,
            farmname: FarmName,
        }
      }]
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction({
      actions: action
    }, {

      blocksBehind: 3,
      expireSeconds: 60,
      broadcast: true,
    })
    // Update application state with the responses of the transaction
    setLoadingDisplay('');
    setEnterButtonsDisplay('hidden');
    setEnterModalText('Processing your transaction...')
    const timer = setTimeout(() => {
      
        setEnterModalText('Your NFT has been unstaked');
        setLoadingDisplay('none');
        setEnterButtonsDisplay('');
  
    }, 4000);
    return () => clearTimeout(timer);

  } catch(e) {


          setEnterModalText(JSON.stringify(e.message));

    console.log(e)
    
  }

} // end if wallet = anchor

}




const unstakeAll = async (FarmName, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {
  setEnterModalDisplay('');
  setEnterModalText('Awaiting confirmation...');
  const walletProvider = localStorage.getItem('eosWalletProvider');


if(walletProvider == 'anchor'){

  // Retrieve current session from state
  const session = await anchor.login()
  //console.log(session[0].signTransaction);

  try {
    // Reset our response state to clear any previous transaction data
    
    const action = [{
          account: 'waxdaofarmer',
          name: 'unstakeall',
          authorization: [{
            actor: anchor.users[0].accountName,
            permission: anchor.users[0].requestPermission,
        }],
        data: {
            user: anchor.users[0].accountName,
            farmname: FarmName,
        }
      }]
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction({
      actions: action
    }, {

      blocksBehind: 3,
      expireSeconds: 60,
      broadcast: true,
    })
    // Update application state with the responses of the transaction
    setLoadingDisplay('');
    setEnterButtonsDisplay('hidden');
    setEnterModalText('Processing your transaction...')
    const timer = setTimeout(() => {
      
        setEnterModalText('Your NFTs have been unstaked');
        setLoadingDisplay('none');
        setEnterButtonsDisplay('');
  
    }, 4000);
    return () => clearTimeout(timer);

  } catch(e) {


          setEnterModalText(JSON.stringify(e.message));

    console.log(e)
    
  }

} // end if wallet = anchor

}



const openRow = async (decimals, tokenSymbol, contract, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {
  setEnterModalDisplay('');
  setEnterModalText('Awaiting confirmation...');
  const walletProvider = localStorage.getItem('eosWalletProvider');


  if(walletProvider == 'anchor'){

    // Retrieve current session from state
    const session = await anchor.login()
    //console.log(session[0].signTransaction);
  
    try {
      // Reset our response state to clear any previous transaction data
      
      const action = [{
            account: contract,
            name: 'open',
            authorization: [{
              actor: anchor.users[0].accountName,
              permission: anchor.users[0].requestPermission,
          }],
          data: {
            owner: anchor.users[0].accountName,
            symbol: decimals + tokenSymbol,
            ram_payer: anchor.users[0].accountName,
          }
        }]
      // Call transact on the session (compatible with eosjs.transact)
      const response = await session[0].signTransaction({
        actions: action
      }, {

        blocksBehind: 3,
        expireSeconds: 60,
        broadcast: true,
      })
      // Update application state with the responses of the transaction
      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Processing your transaction...')
      const timer = setTimeout(() => {
        
          setEnterModalText('You can now receive ' + tokenSymbol + ' tokens');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');
    
      }, 4000);
      return () => clearTimeout(timer);
  
    } catch(e) {
  
  
            setEnterModalText(JSON.stringify(e.message));
  
      console.log(e)
      
    }
    
    }//end if wallet = anchor



}







const claimRewards = async (FarmName, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {
  setEnterModalDisplay('');
  setEnterModalText('Awaiting confirmation...');
  const walletProvider = localStorage.getItem('eosWalletProvider');


if(walletProvider == 'anchor'){

  // Retrieve current session from state
  const session = await anchor.login()
  //console.log(session[0].signTransaction);

  try {
    // Reset our response state to clear any previous transaction data
    
    const action = [{
          account: 'waxdaofarmer',
          name: 'claimrewards',
          authorization: [{
            actor: anchor.users[0].accountName,
            permission: anchor.users[0].requestPermission,
        }],
        data: {
            user: anchor.users[0].accountName,
            farm: FarmName,
        }
      }]
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction({
      actions: action
    }, {

      blocksBehind: 3,
      expireSeconds: 60,
      broadcast: true,
    })
    // Update application state with the responses of the transaction
    setLoadingDisplay('');
    setEnterButtonsDisplay('hidden');
    setEnterModalText('Processing your transaction...')
    const timer = setTimeout(() => {
      
        setEnterModalText('Your rewards have been sent');
        setLoadingDisplay('none');
        setEnterButtonsDisplay('');
  
    }, 4000);
    return () => clearTimeout(timer);

  } catch(e) {


          setEnterModalText(JSON.stringify(e.message));

    console.log(e)
    
  }

}// end if wallet = anchor

}


const depositRam = async (FarmName, depositAmount, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {
  setEnterModalDisplay('');
  setEnterModalText('Awaiting confirmation...');
  const walletProvider = localStorage.getItem('eosWalletProvider');

  if(walletProvider == 'anchor'){

    // Retrieve current session from state
    const session = await anchor.login()
    //console.log(session[0].signTransaction);
  
    try {
      // Reset our response state to clear any previous transaction data
      
      const action = [{
            account: 'eosio.token',
            name: 'transfer',
            authorization: [{
              actor: anchor.users[0].accountName,
              permission: anchor.users[0].requestPermission,
          }],
          data: {
              to: 'waxdaofarmer',
              from: anchor.users[0].accountName,
              quantity: depositAmount + ' EOS',
              memo: '|deposit_user_farm_ram|' + FarmName + '|',
          }
        }]
      // Call transact on the session (compatible with eosjs.transact)
      const response = await session[0].signTransaction({
        actions: action
      }, {

        blocksBehind: 3,
        expireSeconds: 60,
        broadcast: true,
      })
      // Update application state with the responses of the transaction
      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Processing your transaction...')
      const timer = setTimeout(() => {
        
          setEnterModalText('Your deposit has been received. Your RAM will be sold and returned to you (as EOS) when you unstake');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');
    
      }, 4000);
      return () => clearTimeout(timer);
  
    } catch(e) {
  
  
            setEnterModalText(JSON.stringify(e.message));
  
      console.log(e)
      
    }
    
    }//end if wallet = anchor



}






const withdrawRam = async (FarmName, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {
  setEnterModalDisplay('');
  setEnterModalText('Awaiting confirmation...');
  const walletProvider = localStorage.getItem('eosWalletProvider');


  if(walletProvider == 'anchor'){

    // Retrieve current session from state
    const session = await anchor.login()
    //console.log(session[0].signTransaction);
  
    try {
      // Reset our response state to clear any previous transaction data
      
      const action = [{
            account: 'waxdaofarmer',
            name: 'claimfarmram',
            authorization: [{
              actor: anchor.users[0].accountName,
              permission: anchor.users[0].requestPermission,
          }],
          data: {
            user: anchor.users[0].accountName,
            farm: FarmName,
          }
        }]
      // Call transact on the session (compatible with eosjs.transact)
      const response = await session[0].signTransaction({
        actions: action
      }, {

        blocksBehind: 3,
        expireSeconds: 60,
        broadcast: true,
      })
      // Update application state with the responses of the transaction
      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Processing your transaction...')
      const timer = setTimeout(() => {
        
          setEnterModalText('Your EOS has been returned to your wallet');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');
    
      }, 4000);
      return () => clearTimeout(timer);
  
    } catch(e) {
  
  
            setEnterModalText(JSON.stringify(e.message));
  
      console.log(e)
      
    }
    
    }//end if wallet = anchor



}




const FarmPage = ({ location }) => {

  const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode, currentUser, 
    setCurrentUser, tokenName, setTokenName, maxSupply, setMaxSupply, decimals, setDecimals, 
    tokenString, setTokenString, farmData, setFarmData, farmCollection, setFarmCollection, 
    farmSchema, setFarmSchema, farmRewards, setFarmRewards, farmExpires, setFarmExpires, 
    farmIcon, setFarmIcon, farmContract, setFarmContract, createdBy, setCreatedBy, unstaked, 
    setUnstaked, stakedOrUnstaked, setStakedOrUnstaked, stakedDisplay, setStakedDisplay, 
    unstakedDisplay, setUnstakedDisplay, validAssets, setValidAssets, commaSeparated, 
    setCommaSeparated, stakedAssets, setStakedAssets, claimable, setClaimable, minTime, 
    setMinTime, depositAmount, setDepositAmount, depositDisplay, setDepositDisplay, totalStaked, 
    setTotalStaked, ramBalance, setRamBalance, ramInfoDisplay, setRamInfoDisplay, farmTemplates, 
    setFarmTemplates, templatesDisplay, setTemplatesDisplay, farmType, setFarmType, 
    nftsCoveredByRam, setNftsCoveredByRam, hourlyPool, setHourlyPool, schemaDisplay, 
    setSchemaDisplay, includeOrExcludeMessage, setIncludeOrExcludeMessage, templateToView, 
    setTemplateToView, refreshAssets, setRefreshAssets, aboutDaoDisplay, setAboutDaoDisplay,
    aboutFarmDisplay, setAboutFarmDisplay, stakeNftsDisplay, setStakeNftsDisplay, 
    unstakeNftsDisplay, setUnstakeNftsDisplay, walletDisplay, setWalletDisplay, assetVector,
    setAssetVector, stakeMultipleDisplay, setStakeMultipleDisplay, highlightedFarm, 
    userCanUnlock,
    setUserCanUnlock,
    setHighlightedFarm,
    profile,
    setProfile,
    rewardType,
    setRewardType,
    rewardAmount,
    setRewardAmount,
    farmPrecision,
    setFarmPrecision,
    enterModalText,
    setEnterModalText,
    loadingDisplay, 
    setLoadingDisplay, 
    enterButtonsDisplay,
    setEnterButtonsDisplay,
    enterModalDisplay, 
    setEnterModalDisplay,
    farmSchemaArray,
    setFarmSchemaArray,
   } = useStateContext();

const { FarmName } = useParams();

useEffect(() => {

  const abortCont = new AbortController();
  const abortCont2 = new AbortController();
  const abortCont3 = new AbortController();
  const abortCont4 = new AbortController();

  setWalletDisplay('hidden');
  setUnstakeNftsDisplay('hidden');
  setStakeNftsDisplay('hidden');
  setAboutFarmDisplay('');
  setDepositDisplay('hidden');
  setRamInfoDisplay('hidden');
  setStakeMultipleDisplay('hidden');
  setAssetVector([]);
  setUserCanUnlock('');


  setStakedAssets([]);
  setUnstaked([]);
  setValidAssets([]);
  setCommaSeparated('');
  setMinTime('');
  setFarmTemplates([]);
  setTemplatesDisplay('hidden');
  setHourlyPool('');
  setSchemaDisplay('hidden');
  setTemplateToView('any');
  setRewardType('');
  setRewardAmount('');
  setFarmPrecision('');
  setFarmSchema([]);
  setFarmSchemaArray([]);

  const eosUsername = localStorage.getItem('eosAccount');
  
  if(eosUsername){
    //console.log('You have an active session');
    '';
    //setProfileDisplay('flex');
  }else{
    //console.log('You are not logged in');
    '';     //return('');
  }



  axios.post('https://eos.api.eosnation.io/v1/chain/get_table_rows',{
    table:"farms",
    scope:"waxdaofarmer",
    code:"waxdaofarmer",
    key_type: 'name',
    indexName: 'farmname',
    limit:1,
    lower_bound:FarmName,
    upper_bound:FarmName,
    json:true
  })
    .then((response) => {
      setFarmData(response);
      setFarmCollection(response.data.rows[0].collection);
      setFarmSchemaArray(response.data.rows[0].schema);
      setFarmIcon(response.data.rows[0].logo);
      setCreatedBy(response.data.rows[0].creator);
      setFarmRewards(response.data.rows[0].poolsize);
      setHourlyPool(response.data.rows[0].hourlyreward);
      setFarmContract(response.data.rows[0].contract);
      setFarmExpires(response.data.rows[0].enddate);
      setMinTime(response.data.rows[0].mintime);
      setTotalStaked(response.data.rows[0].totalstaked);
      setFarmType(response.data.rows[0].farmtype);
      setRewardType(response.data.rows[0].reward_type);
      setRewardAmount(response.data.rows[0].hourly_cap);
      //console.log(response.data.rows[0].farmtype);
      setTokenName(response.data.rows[0].poolsize.substr(response.data.rows[0].poolsize.indexOf(' ') + 1));
      const thisCollection = response.data.rows[0].collection;

      if(response.data.rows[0].poolsize.indexOf('.')){

        var decimalString = response.data.rows[0].poolsize.substr(0, response.data.rows[0].poolsize.indexOf(' '));

        //setDecimals(response.data.rows[0].poolsize.substr(response.data.rows[0].poolsize.indexOf('.') + 1 )   );
        setDecimals(decimalString.substr(decimalString.indexOf('.') + 1).length + ',');
        setFarmPrecision(decimalString.substr(decimalString.indexOf('.') + 1).length);
      }

      else{

        setDecimals('0,');
        setFarmPrecision('0');
      }

      


      if(response.data.rows[0].farmtype == 'template'){
        setFarmTemplates(response.data.rows[0].templates);
        //console.log(response.data.rows[0].templates);
        //setFarmTemplates(['526134']);
        setTemplatesDisplay('flex gap-4');
        setIncludeOrExcludeMessage('Stakeable');


        if(eosUsername){

        axios.get(`https://eos.api.atomicassets.io/atomicassets/v1/assets?collection_name=${response.data.rows[0].collection}&owner=${eosUsername}&page=1&limit=1000&order=desc&sort=asset_id`)
        .then((atomicResponse) => {
          list = atomicResponse.data.data;
          setUnstaked(atomicResponse.data.data);
          console.log(atomicResponse.data.data);
          
          if(atomicResponse.data.data.length == 1000){
            //console.log("yo!");
            //console.log(list);
            axios.get(`https://eos.api.atomicassets.io/atomicassets/v1/assets?collection_name=${thisCollection}&owner=${eosUsername}&page=2&limit=1000&order=desc&sort=asset_id`)
            .then((atomicResponse2) => {
              list = list.concat(atomicResponse2.data.data);
              
              setUnstaked(list);
              //console.log('ay');

          })

          } //end if 1000

         
        }) //end then atomicresponse
        
        
        .then(() => {
          
          axios.post('https://eos.api.eosnation.io/v1/chain/get_table_rows',{
            table:"stakerzz",
            scope:"waxdaofarmer",
            code:"waxdaofarmer",
            key_type: 'name',
            index_position: 2,
            limit:100,
            lower_bound:eosUsername,
            upper_bound:eosUsername,
            json:true
          }).then((farmVectors) => {
            var itr = 0;
  
            while(itr < farmVectors.data.rows.length){
              //console.log("Size: " + farmVectors.data.rows[itr].assets)
              if(farmVectors.data.rows[itr].farmname == FarmName && farmVectors.data.rows[itr].assets != ""){
                setValidAssets(farmVectors.data.rows[itr].assets);
                setUserCanUnlock(farmVectors.data.rows[itr].unlocktime);

                //console.log(farmVectors.data.rows[itr].unlocktime);
                
  
                axios.get(`https://eos.api.atomicassets.io/atomicassets/v1/assets?ids=${farmVectors.data.rows[itr].assets.join("%2C")}&page=1&limit=1000&order=desc&sort=asset_id`)
                .then((validResponse) => {

                  setStakedAssets(validResponse.data.data);
                  //console.log(validResponse.data.data);
                 
                })
  
  
                //console.log(farmVectors.data.rows[itr].assets);
                break;
              }
              else{
              itr ++;
              }
            }
            
            //console.log(farmVectors.data.rows.length);
            
  
          })
  
        })
  
  
  
        .catch((error) => console.log(error));
  
  
      }


      } //end if farmtype == template



      if(response.data.rows[0].farmtype == 's.exclude'){

        setSchemaDisplay('');

        setFarmTemplates(response.data.rows[0].templates);
        //console.log('it does');
        setTemplatesDisplay('flex gap-4');
        setIncludeOrExcludeMessage('Excluded');

        if(eosUsername){

  
  
        axios.get(`https://eos.api.atomicassets.io/atomicassets/v1/assets?collection_name=${response.data.rows[0].collection}&owner=${eosUsername}&page=1&limit=1000&order=desc&sort=asset_id`)
        .then((atomicResponse) => {
          setUnstaked(atomicResponse.data.data);
          console.log(atomicResponse.data.data);
 
        }).then(() => {
          axios.post('https://eos.api.eosnation.io/v1/chain/get_table_rows',{
            table:"stakerzz",
            scope:"waxdaofarmer",
            code:"waxdaofarmer",
            key_type: 'name',
            index_position: 2,
            limit:100,
            lower_bound:eosUsername,
            upper_bound:eosUsername,
            json:true
          }).then((farmVectors) => {
            var itr = 0;
  
            while(itr < farmVectors.data.rows.length){
              if(farmVectors.data.rows[itr].farmname == FarmName && farmVectors.data.rows[itr].assets != ""){
                setValidAssets(farmVectors.data.rows[itr].assets);
                setUserCanUnlock(farmVectors.data.rows[itr].unlocktime);

                //console.log(farmVectors.data.rows[itr].unlocktime);
  
                axios.get(`https://eos.api.atomicassets.io/atomicassets/v1/assets?ids=${farmVectors.data.rows[itr].assets.join("%2C")}&page=1&limit=1000&order=desc&sort=asset_id`)
                .then((validResponse) => {

                  setStakedAssets(validResponse.data.data);
                 
                })

                break;
              }
              else{
              itr ++;
              }
            } //end while loop
            
            
  
          })
  
        })
  
  
  
        .catch((error) => console.log(error));
  
  
      }


      } //end if farmtype == s.exclude











      if(response.data.rows[0].farmtype == 'schema'){

        setSchemaDisplay('');

        if(eosUsername){

  
  
        axios.get(`https://eos.api.atomicassets.io/atomicassets/v1/assets?collection_name=${response.data.rows[0].collection}&owner=${eosUsername}&page=1&limit=1000&order=desc&sort=asset_id`)
        .then((atomicResponse) => {
          setUnstaked(atomicResponse.data.data);
          //console.log(atomicResponse.data.data);
 
        }).then(() => {
          axios.post('https://eos.api.eosnation.io/v1/chain/get_table_rows',{
            table:"stakerzz",
            scope:"waxdaofarmer",
            code:"waxdaofarmer",
            key_type: 'name',
            index_position: 2,
            limit:100,
            lower_bound:eosUsername,
            upper_bound:eosUsername,
            json:true
          }).then((farmVectors) => {
            var itr = 0;
  
            while(itr < farmVectors.data.rows.length){
              if(farmVectors.data.rows[itr].farmname == FarmName && farmVectors.data.rows[itr].assets != ""){
                setValidAssets(farmVectors.data.rows[itr].assets);
                setUserCanUnlock(farmVectors.data.rows[itr].unlocktime);

                //console.log(farmVectors.data.rows[itr].unlocktime);
  
                axios.get(`https://eos.api.atomicassets.io/atomicassets/v1/assets?ids=${farmVectors.data.rows[itr].assets.join("%2C")}&page=1&limit=1000&order=desc&sort=asset_id`)
                .then((validResponse) => {

                  setStakedAssets(validResponse.data.data);
                 
                })

                break;
              }
              else{
              itr ++;
              }
            }
            
            
  
          })
  
        })
  
  
  
        .catch((error) => console.log(error));
  
  
      }


      } //end if farmtype == schema


      if(response.data.rows[0].farmtype == 'collection'){

        if(eosUsername){

  
  
        axios.get(`https://eos.api.atomicassets.io/atomicassets/v1/assets?collection_name=${response.data.rows[0].collection}&owner=${eosUsername}&page=1&limit=1000&order=desc&sort=asset_id`)
        .then((atomicResponse) => {
          setUnstaked(atomicResponse.data.data);
          console.log(atomicResponse.data.data);
 
        }).then(() => {
          axios.post('https://eos.api.eosnation.io/v1/chain/get_table_rows',{
            table:"stakerzz",
            scope:"waxdaofarmer",
            code:"waxdaofarmer",
            key_type: 'name',
            index_position: 2,
            limit:100,
            lower_bound:eosUsername,
            upper_bound:eosUsername,
            json:true
          }).then((farmVectors) => {
            var itr = 0;
  
            while(itr < farmVectors.data.rows.length){
              if(farmVectors.data.rows[itr].farmname == FarmName && farmVectors.data.rows[itr].assets != ""){
                setValidAssets(farmVectors.data.rows[itr].assets);
                setUserCanUnlock(farmVectors.data.rows[itr].unlocktime);

                //console.log(farmVectors.data.rows[itr].unlocktime);
  
                axios.get(`https://eos.api.atomicassets.io/atomicassets/v1/assets?ids=${farmVectors.data.rows[itr].assets.join("%2C")}&page=1&limit=1000&order=desc&sort=asset_id`)
                .then((validResponse) => {

                  setStakedAssets(validResponse.data.data);
                 
                })

                break;
              }
              else{
              itr ++;
              }
            }
            
            
  
          })
  
        })
  
  
  
        .catch((error) => console.log(error));
  
  
      }


      } //end if farmtype == collection




     

    })
    .catch((error) => console.log(error));

}, [refreshAssets]);








useEffect(() => {

setClaimable([]);
  const abortCont5 = new AbortController();

  const eosUsername = localStorage.getItem('eosAccount');
  
  if(eosUsername){
    //console.log('You have an active session');
    '';
    //setProfileDisplay('flex');
  }else{
    //console.log('You are not logged in');
    return('');
  }



  axios.post('https://eos.api.eosnation.io/v1/chain/get_table_rows',{
    table:"stakerzz",
    scope:"waxdaofarmer",
    code:"waxdaofarmer",
    key_type: 'name',
    index_position: 2,
    limit:100,
    lower_bound:eosUsername,
    upper_bound:eosUsername,
    json:true
  }).then((claimableResponse) => {
    var claimitr = 0;

    while(claimitr < claimableResponse.data.rows.length){
      if(claimableResponse.data.rows[claimitr].farmname == FarmName){
        setClaimable(claimableResponse.data.rows[claimitr].claimable);
        setRamBalance(claimableResponse.data.rows[claimitr].rambalance);

        break;
      }
      else{
        claimitr ++;
      }
    }


  })

.catch((error) => console.log(error));

//return() => abortCont5.abort();

}, [refreshAssets]);



useEffect(() => {

  setProfile([]);
    const abortCont5 = new AbortController();
  
    const eosUsername = localStorage.getItem('eosAccount');
    const nameValue = new Name(FarmName).raw().toString();
    if(eosUsername){
      
      //console.log('You have an active session');
      '';
      //setProfileDisplay('flex');
    }else{
      //console.log('You are not logged in');
      return('');
    }
  
    //console.log(nameValue);
  
    axios.post('https://eos.api.eosnation.io/v1/chain/get_table_rows',{
      table:"farmprofiles",
      scope:"waxdaofarmer",
      code:"waxdaofarmer",
      limit:1,
      lower_bound:nameValue,
      upper_bound:nameValue,
      json:true
    }).then((profileResponse) => {
 
          setProfile(profileResponse.data.rows[0]);
          console.log(profileResponse.data.rows[0]);
          

    })
  
  .catch((error) => console.log(error));
  
  //return() => abortCont5.abort();
  
  }, []);



  return (
    <div id="seo">
    <Helmet>
    <title>{FarmName} Details</title>
    <meta name="description" content={`Stake NFTs in the ${FarmName} pool on WaxDao`} />
    <link rel="canonical" href={`https://eos.waxdao.io/farm/${FarmName}`} />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor]} appName={"WaxDAO"}>
    <Modal className={enterModalDisplay}>
      <ModalContent>
        {enterModalText}
        
      </ModalContent>

        <Spinner style={{display:loadingDisplay}}>
        <SpinnerRed  />
        <SpinnerBlue  />
        <SpinnerGreen />
        </Spinner>

      <br/>
      <GameButton onClick={() => setEnterModalDisplay('hidden') }>
        Close Window
      </GameButton>
      </Modal>

    <FarmTopMenu>

        <FarmTopMenuItem onClick={() => {setAboutFarmDisplay('hidden'); setStakeNftsDisplay(''); setUnstakeNftsDisplay('hidden'); setWalletDisplay('hidden'); } }>
            Stake
        </FarmTopMenuItem>

        <FarmTopMenuItem onClick={() => {setAboutFarmDisplay('hidden'); setStakeNftsDisplay('hidden'); setUnstakeNftsDisplay(''); setWalletDisplay('hidden');} }>
            Unstake
        </FarmTopMenuItem>

        <FarmTopMenuItem onClick={() => {setAboutFarmDisplay(''); setStakeNftsDisplay('hidden'); setUnstakeNftsDisplay('hidden'); setWalletDisplay('hidden');} }>
            About
        </FarmTopMenuItem>

        <FarmTopMenuItem onClick={() => {setAboutFarmDisplay('hidden'); setStakeNftsDisplay('hidden'); setUnstakeNftsDisplay('hidden'); setWalletDisplay('');} }>
            Wallet
        </FarmTopMenuItem>


    </FarmTopMenu>
  
    <MainContainer>
            
    <LeftDiv className={activeMenu ? 'hidden' : ''}>

    <img src={`https://ipfs.io/ipfs/${farmIcon}`} 
        alt={`${FarmName} Logo`} 
        style={{ width:'150px', 
          height:'150px',
          maxWidth:'150px',
          maxHeight:'150px',
          marginLeft:'auto',
          marginRight:'auto',
          marginTop:'15px'
        }}
        className="rounded-full hover:drop-shadow-xl"      
      />

    <DaoName>
    {FarmName != null && FarmName}
    </DaoName>

    <Creator>
    {createdBy != null && (

        <span>By {createdBy}</span> 

    )} 
    </Creator>


    <FarmMenu>

        <FarmMenuItem onClick={() => {setAboutFarmDisplay('hidden'); setStakeNftsDisplay(''); setUnstakeNftsDisplay('hidden'); setWalletDisplay('hidden'); } }>
            Stake NFTs
        </FarmMenuItem>

        <FarmMenuItem onClick={() => {setAboutFarmDisplay('hidden'); setStakeNftsDisplay('hidden'); setUnstakeNftsDisplay(''); setWalletDisplay('hidden');} }>
            Unstake NFTs
        </FarmMenuItem>

        <FarmMenuItem onClick={() => {setAboutFarmDisplay(''); setStakeNftsDisplay('hidden'); setUnstakeNftsDisplay('hidden'); setWalletDisplay('hidden');} }>
            About
        </FarmMenuItem>

        <FarmMenuItem onClick={() => {setAboutFarmDisplay('hidden'); setStakeNftsDisplay('hidden'); setUnstakeNftsDisplay('hidden'); setWalletDisplay('');} }>
            My Wallet
        </FarmMenuItem>


    </FarmMenu>
    {profile != null && profile != '' && (
    <LeftProfileImg
      src={`https://ipfs.io/ipfs/${profile.image1}`} 
      />
    )}

    </LeftDiv>
        

<StakeManyDiv className={stakeMultipleDisplay} onClick={() => {stakeMany(FarmName, assetVector, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay)}}>
  Stake {assetVector.length} NFTs <br/>
  
</StakeManyDiv>

      <RightDiv>

  <AboutDAO className={aboutFarmDisplay}>
  <DaoTitle>
        About This Farm
  </DaoTitle>

  <ProfileCont>
      <LeftProfile>

      <AboutDaoTitle>
        Creator
      </AboutDaoTitle>
      <AboutDaoBody>
        {createdBy}
      </AboutDaoBody>

{/* PROFILE INFO IF THEY HAVE ONE */}

  {profile != null && profile != '' && (
<span>


        <AboutDaoTitle>
          Description
        </AboutDaoTitle>
        <AboutDaoBody>
        {profile.description}
        </AboutDaoBody>

        <AboutDaoTitle>
          Social Links
        </AboutDaoTitle>
        <AboutDaoBody>

          <a href={`${profile.website}`} target="none">
          <SocialButton>
            Website
          </SocialButton>
          </a>

          <a href={`${profile.discord}`} target="none">
          <SocialButton>
            Discord
          </SocialButton>
          </a>

          <a href={`${profile.telegram}`} target="none">
          <SocialButton>
            Telegram
          </SocialButton>
          </a>

          <a href={`${profile.twitter}`} target="none">
          <SocialButton>
            Twitter
          </SocialButton>
          </a>

          <a href={`${profile.latest_drop}`} target="none">
          <SocialButton>
            NFT Drop
          </SocialButton>
          </a>



        </AboutDaoBody>


</span>
  )}
      <AboutDaoTitle>
        Minimum Staking Period
      </AboutDaoTitle>
      <AboutDaoBody>
        {minTime / 86400} {minTime / 86400 == 1 ? 'Day' : 'Days'}
      </AboutDaoBody>

      <AboutDaoTitle>
        Expires
      </AboutDaoTitle>
      <AboutDaoBody>
        {new Date(farmExpires * 1000).toLocaleDateString()}
      </AboutDaoBody>

      <AboutDaoTitle>
        Collection
      </AboutDaoTitle>
      <AboutDaoBody>
        {farmCollection != null && (
          <a href={`https://eos.atomichub.io/explorer/collection/${farmCollection}`} target="none">
          {farmCollection}
          </a>
        )}
      </AboutDaoBody> 

      <AboutDaoTitle className={schemaDisplay}>
        Schemas
      </AboutDaoTitle>
      <AboutDaoBody className={schemaDisplay}>
        <TemplatesCont>
        {farmSchemaArray.map((item, index) => (
            <a key={index} target="none" href={`https://eos.atomichub.io/explorer/schema/${farmCollection}/${item}`}>
            <SingleTemplate>
              {item}
            </SingleTemplate>
            </a>
          ))}
        </TemplatesCont>



      </AboutDaoBody> 

      <AboutDaoTitle className={templatesDisplay}>
        {includeOrExcludeMessage} Templates
      </AboutDaoTitle>
      <AboutDaoBody className={templatesDisplay}>
        <TemplatesCont>
          {farmTemplates.map((item, index) => (
            <a key={index} target="none" href={`https://eos.atomichub.io/explorer/template/${farmCollection}/${item}`}>
            <SingleTemplate>
              {item}
            </SingleTemplate>
            </a>
          ))}
        </TemplatesCont>
      </AboutDaoBody> 

      <AboutDaoTitle>
        Total Reward Pool
      </AboutDaoTitle>
      <AboutDaoBody>
          <NumberFormat displayType='text' thousandSeparator={true} value={Math.round(farmRewards.substring(0, farmRewards.indexOf(' ')))} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} /> {farmRewards.substring(farmRewards.indexOf(' '))}@{farmContract}
      </AboutDaoBody> 

      {rewardType != null && rewardType == "sharedpool" && (
        <span>
          <AboutDaoTitle>
          Hourly Reward Pool
          </AboutDaoTitle>
          <AboutDaoBody>
          {hourlyPool}
          </AboutDaoBody>
        

          <AboutDaoTitle>
          Hourly Reward Per NFT
          </AboutDaoTitle>
          <AboutDaoBody>
          {Math.round((hourlyPool.substring(0, hourlyPool.indexOf(' ')) / totalStaked) * 100000000 ) / 100000000}
          </AboutDaoBody>
        </span>
      )}



      {rewardType != null && rewardType == "static" && (
        <span>
          <AboutDaoTitle>
          Hourly Reward Per NFT
          </AboutDaoTitle>
          <AboutDaoBody>
          {farmPrecision != null && farmPrecision == '0' && rewardAmount != null && rewardAmount}

          {farmPrecision != null && farmPrecision != '0' && rewardAmount != null && rewardAmount / Math.pow(10, farmPrecision)}
          </AboutDaoBody>
        </span>
      )}




      <AboutDaoTitle>
        Total NFTs Staked
      </AboutDaoTitle>
      <AboutDaoBody>
        {totalStaked}
      </AboutDaoBody>

      </LeftProfile>

      <RightProfile>

      {profile != null && profile != '' && (
        <span>
      <ProfileImage
     src={`https://ipfs.io/ipfs/${profile.image2}`} 
      />
      <ProfileImage
      src={`https://ipfs.io/ipfs/${profile.image3}`} 
      />
      </span>

      )}
      </RightProfile>

  </ProfileCont>


  </AboutDAO>


  <StakingCont className={stakeNftsDisplay}>
  <DaoTitle>
      Stake NFTs
      
      {stakeMultipleDisplay == "hidden" ? (
      <ToggleSwitchCont>
        <ToggleMessage>Single &nbsp;</ToggleMessage>
      <ToggleSwitch onClick={() => {setStakeMultipleDisplay('')}}  >
        <InnerSwitch />
      </ToggleSwitch> 
      </ToggleSwitchCont>
      ) : (
      <ToggleSwitchCont>
        <ToggleMessage>Multiple &nbsp;</ToggleMessage>
      <ToggleSwitch onClick={() => {setStakeMultipleDisplay('hidden') }}>
        <InnerSwitch style={{marginLeft:'0.97em', background:'#fa872d'}} />
      </ToggleSwitch>
      </ToggleSwitchCont>
      )} 
  
  </DaoTitle>

  <NFTsCont>

  { unstaked.length > 0 ? unstaked.map((item, index) => (
  

<span key={index} className={farmType == "template" && item.template != null && farmTemplates.indexOf(parseInt(item.template.template_id)) == -1 && "hidden" }>
   

    {farmType == "template" && item.template != null && farmTemplates.indexOf(parseInt(item.template.template_id)) > -1 ? (

//render all this if the farm type is template and the template matches

<NFT key={index} style={stakeMultipleDisplay == "hidden" ? {} : stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) > -1 ? {border:'1px solid #fa872d'} : stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) == -1 ? {border:'1px solid #696969'} : ''}>
<p key={index}><span className="font-bold">{item.name != null ? item.name : ''}</span>
        
<br/><span className="font-semibold">Template: {item.template != null ? item.template.template_id : 'None'}</span> 
<br/>Mint {item.template_mint != null ? item.template_mint : ''}
</p>

{item.data.img != null && (
  <NFTLogo key={index} 
  src={`https://ipfs.io/ipfs/${item.data.img.trim()}`} 
  />

)}

{item.data.img == null && item.data.video != null && (
  
  <video style={{ width: "150px", maxWidth: "150px", height: "150px", maxHeight: "150px", borderRadius:'25%'}} loop>
  <source src={`https://ipfs.io/ipfs/${item.data.video.trim()}`} type="video/mp4"/>
</video>

  

)}

{item.data.img == null && item.data.video == null && item.data.image != null && (
  <NFTLogo key={index} 
  src={`https://ipfs.io/ipfs/${item.data.image.trim()}`} 
  />

)}

<StakeManyButton key={index}
  onClick={() => {
    if(stakeMultipleDisplay == "hidden"){
      stake(FarmName, item.asset_id, refreshAssets)
    } else {

      if(assetVector.indexOf(item.asset_id) > -1){
        handleRemoveAsset(item.asset_id, assetVector, setAssetVector)
      } else {
        handleAddAsset(item.asset_id, assetVector, setAssetVector)
      }
    }
    }}
    
    style={stakeMultipleDisplay == "hidden" ? {} : stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) > -1 ? {border:'1px solid #fa872d'} : stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) == -1 ? {border:'1px solid #696969'} : ''}
    className={stakeMultipleDisplay == "hidden" ? "text-orange" : stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) > -1 ? "text-orange" : stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) == -1 ? "text-gray hover:text-black" : ''}
    >
  {stakeMultipleDisplay == "hidden" && "Stake Now"}
  {stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) > -1 && "Remove"}
  {stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) == -1 && "Add"}
  </StakeManyButton>

</NFT>


    ) : ''}


    
  

{farmType == "s.exclude" && item.template != null && farmTemplates.indexOf(parseInt(item.template.template_id)) == -1 && item.schema != null && farmSchemaArray.indexOf(item.schema.schema_name) > -1 && (
//render all this if the farm type is s.exclude and the template exists, but doesnt match the excluded ones




<NFT key={index} style={stakeMultipleDisplay == "hidden" ? {} : stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) == -1 ? {border:'1px solid #fa872d'} : stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) == -1 ? {border:'1px solid #696969'} : {}}>

<p key={index}><span className="font-bold">{item.name != null ? item.name : ''}</span>
        
<br/><span className="font-semibold">Template: {item.template != null ? item.template.template_id : 'None'}</span> 
<br/>Mint {item.template_mint != null ? item.template_mint : ''}
</p>

{item.data.img != null && (
  <NFTLogo key={index} 
  src={`https://ipfs.io/ipfs/${item.data.img.trim()}`} 
  />

)}

{item.data.img == null && item.data.video != null && (
  
  <video style={{ width: "150px", maxWidth: "150px", height: "150px", maxHeight: "150px", borderRadius:'25%'}} loop>
  <source src={`https://ipfs.io/ipfs/${item.data.video.trim()}`} type="video/mp4"/>
</video>

  

)}

{item.data.img == null && item.data.video == null && item.data.image != null && (
  <NFTLogo key={index} 
  src={`https://ipfs.io/ipfs/${item.data.image.trim()}`} 
  />

)}

<StakeManyButton key={index}
  onClick={() => {
    if(stakeMultipleDisplay == "hidden"){
      stake(FarmName, item.asset_id, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay)
    } else {

      if(assetVector.indexOf(item.asset_id) > -1){
        handleRemoveAsset(item.asset_id, assetVector, setAssetVector)
      } else {
        handleAddAsset(item.asset_id, assetVector, setAssetVector)
      }
    }
    }}
    
    style={stakeMultipleDisplay == "hidden" ? {} : stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) > -1 ? {border:'1px solid #fa872d'} : stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) == -1 ? {border:'1px solid #696969'} : {}}
    className={stakeMultipleDisplay == "hidden" ? "text-orange" : stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) > -1 ? "text-orange" : stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) == -1 ? "text-gray hover:text-black" : ''}
    >
  {stakeMultipleDisplay == "hidden" && "Stake Now"}
  {stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) > -1 && "Remove"}
  {stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) == -1 && "Add"}
  </StakeManyButton>

</NFT>          
    

    
    ) }
    
    
    
    
    

{farmType == "s.exclude" && item.template == null && item.schema != null && farmSchemaArray.indexOf(item.schema.schema_name) > -1 && (

//render all this if the farm type is s.exclude and the template doesnt exist

<NFT key={index} style={stakeMultipleDisplay == "hidden" ? {} : stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) > -1 ? {border:'1px solid #fa872d'} : stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) == -1 ? {border:'1px solid #696969'} : {}}>
<p key={index}><span className="font-bold">{item.name != null ? item.name : ''}</span>
        
<br/><span className="font-semibold">Template: {item.template != null ? item.template.template_id : 'None'}</span> 
<br/>Mint {item.template_mint != null ? item.template_mint : ''}
</p>

{item.data.img != null && (
  <NFTLogo key={index} 
  src={`https://ipfs.io/ipfs/${item.data.img.trim()}`} 
  />

)}

{item.data.img == null && item.data.video != null && (
  
  <video style={{ width: "150px", maxWidth: "150px", height: "150px", maxHeight: "150px", borderRadius:'25%'}} loop>
  <source src={`https://ipfs.io/ipfs/${item.data.video.trim()}`} type="video/mp4"/>
</video>

  

)}

{item.data.img == null && item.data.video == null && item.data.image != null && (
  <NFTLogo key={index} 
  src={`https://ipfs.io/ipfs/${item.data.image.trim()}`} 
  />

)}

<StakeManyButton key={index}
  onClick={() => {
    if(stakeMultipleDisplay == "hidden"){
      stake(FarmName, item.asset_id, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay)
    } else {

      if(assetVector.indexOf(item.asset_id) > -1){
        handleRemoveAsset(item.asset_id, assetVector, setAssetVector)
      } else {
        handleAddAsset(item.asset_id, assetVector, setAssetVector)
      }
    }
    }}
    
    style={stakeMultipleDisplay == "hidden" ? {} : stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) > -1 ? {border:'1px solid #fa872d'} : stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) == -1 ? {border:'1px solid #696969'} : {}}
    className={stakeMultipleDisplay == "hidden" ? "text-orange" : stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) > -1 ? "text-orange" : stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) == -1 ? "text-gray hover:text-black" : ''}
    >
  {stakeMultipleDisplay == "hidden" && "Stake Now"}
  {stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) > -1 && "Remove"}
  {stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) == -1 && "Add"}
  </StakeManyButton>

</NFT>
    
)}




{farmType == "schema" && item.schema != null && farmSchemaArray.indexOf(item.schema.schema_name) > -1 && (


  <NFT key={index} style={stakeMultipleDisplay == "hidden" ? {} : stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) > -1 ? {border:'1px solid #fa872d'} : stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) == -1 ? {border:'1px solid #696969'} : {}}>
  <p key={index}><span className="font-bold">{item.name != null ? item.name : ''}</span>
          
  <br/><span className="font-semibold">Template: {item.template != null ? item.template.template_id : 'None'}</span> 
  <br/>Mint {item.template_mint != null ? item.template_mint : ''}
  </p>

{item.data.img != null && (
  <NFTLogo key={index} 
  src={`https://ipfs.io/ipfs/${item.data.img.trim()}`} 
  />

)}

{item.data.img == null && item.data.video != null && (
  
  <video style={{ width: "150px", maxWidth: "150px", height: "150px", maxHeight: "150px", borderRadius:'25%'}} loop>
  <source src={`https://ipfs.io/ipfs/${item.data.video.trim()}`} type="video/mp4"/>
</video>

  

)}

{item.data.img == null && item.data.video == null && item.data.image != null && (
  <NFTLogo key={index} 
  src={`https://ipfs.io/ipfs/${item.data.image.trim()}`} 
  />

)}




  <StakeManyButton key={index}
  onClick={() => {
    if(stakeMultipleDisplay == "hidden"){
      stake(FarmName, item.asset_id, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay)
    } else {

      if(assetVector.indexOf(item.asset_id) > -1){
        handleRemoveAsset(item.asset_id, assetVector, setAssetVector)
      } else {
        handleAddAsset(item.asset_id, assetVector, setAssetVector)
      }
    }
    }}
    
    style={stakeMultipleDisplay == "hidden" ? {} : stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) > -1 ? {border:'1px solid #fa872d'} : stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) == -1 ? {border:'1px solid #696969'} : {}}
    className={stakeMultipleDisplay == "hidden" ? "text-orange" : stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) > -1 ? "text-orange" : stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) == -1 ? "text-gray hover:text-black" : ''}
    >
  {stakeMultipleDisplay == "hidden" && "Stake Now"}
  {stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) > -1 && "Remove"}
  {stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) == -1 && "Add"}
  </StakeManyButton>

  </NFT>


)}




















{farmType == "collection" ? 


  <NFT key={index} style={stakeMultipleDisplay == "hidden" ? {} : stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) > -1 ? {border:'1px solid #fa872d'} : stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) == -1 ? {border:'1px solid #696969'} : {}}>
  <p key={index}><span className="font-bold">{item.name != null ? item.name : ''}</span>
          
  <br/><span className="font-semibold">Template: {item.template != null ? item.template.template_id : 'None'}</span> 
  <br/>Mint {item.template_mint != null ? item.template_mint : ''}
  </p>

{item.data.img != null && (
  <NFTLogo key={index} 
  src={`https://ipfs.io/ipfs/${item.data.img.trim()}`} 
  />

)}

{item.data.img == null && item.data.video != null && (
  
  <video style={{ width: "150px", maxWidth: "150px", height: "150px", maxHeight: "150px", borderRadius:'25%'}} loop>
  <source src={`https://ipfs.io/ipfs/${item.data.video.trim()}`} type="video/mp4"/>
</video>

  

)}

{item.data.img == null && item.data.video == null && item.data.image != null && (
  <NFTLogo key={index} 
  src={`https://ipfs.io/ipfs/${item.data.image.trim()}`} 
  />

)}




  <StakeManyButton key={index}
  onClick={() => {
    if(stakeMultipleDisplay == "hidden"){
      stake(FarmName, item.asset_id, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay)
    } else {

      if(assetVector.indexOf(item.asset_id) > -1){
        handleRemoveAsset(item.asset_id, assetVector, setAssetVector)
      } else {
        handleAddAsset(item.asset_id, assetVector, setAssetVector)
      }
    }
    }}
    
    style={stakeMultipleDisplay == "hidden" ? {} : stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) > -1 ? {border:'1px solid #fa872d'} : stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) == -1 ? {border:'1px solid #696969'} : {}}
    className={stakeMultipleDisplay == "hidden" ? "text-orange" : stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) > -1 ? "text-orange" : stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) == -1 ? "text-gray hover:text-black" : ''}
    >
  {stakeMultipleDisplay == "hidden" && "Stake Now"}
  {stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) > -1 && "Remove"}
  {stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) == -1 && "Add"}
  </StakeManyButton>

  </NFT>


      : ''}






   


</span>









)) : (
  <NoDaosMessage>
    You don't have any NFTs that are stakeable in this farm.
  </NoDaosMessage>


)}


</NFTsCont>
  </StakingCont>


{/* UNSTAKE NFTS CONTAINER */}

<StakingCont className={unstakeNftsDisplay}>
  <DaoTitle>
      Unstake NFTs
      &nbsp;&nbsp;
      <DaoButton onClick={() => { unstakeAll(FarmName, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) }}>
       Unstake All
      </DaoButton>


  </DaoTitle>

  {userCanUnlock != null && (Math.round(Date.now() / 1000) >= userCanUnlock && stakedAssets.length > 0) && (
    <span><br/><br/>Your NFTs are all unlocked and can be unstaked at any time</span>
  )}

{userCanUnlock != null && (Math.round(Date.now() / 1000) < userCanUnlock) && (
    <span><br/><br/>Your NFTs cannot be unstaked until {new Date(userCanUnlock * 1000).toLocaleString()}</span>
  )}

  <NFTsCont>
 
  { stakedAssets.length > 0 ? stakedAssets.map((item, index) => (

<NFT>
   
        <p key={index}><span className="font-bold">{item.name != null ? item.name : ''}</span>
        <br/>Mint {item.template_mint != null ? item.template_mint : ''}
        </p> 



{item.data.img != null && (
  <NFTLogo key={index} 
  src={`https://ipfs.io/ipfs/${item.data.img.trim()}`} 
  />

)}

{item.data.img == null && item.data.video != null && (
  
  <video style={{ width: "150px", maxWidth: "150px", height: "150px", maxHeight: "150px", borderRadius:'25%'}} loop>
  <source src={`https://ipfs.io/ipfs/${item.data.video.trim()}`} type="video/mp4"/>
</video>

  

)}

{item.data.img == null && item.data.video == null && item.data.image != null && (
  <NFTLogo key={index} 
  src={`https://ipfs.io/ipfs/${item.data.image.trim()}`} 
  />

)}
    
<DaoButton key={index}
    onClick={() => unstake(FarmName, item.asset_id, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay)}>
    Unstake
  </DaoButton>

  </NFT>

)) : (
  <NoDaosMessage>
  You don't have any NFTs staked in this farm.
</NoDaosMessage>
)}


</NFTsCont>
  </StakingCont>



<StakingCont className={walletDisplay}>
  <DaoTitle>
   Wallet
  </DaoTitle>
 



<WalletCont>

<FarmButtonCont>
      <DaoButton 
          onClick={() => claimRewards(FarmName, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay)}>
          Claim {typeof claimable === 'object' ? '' : claimable}
        </DaoButton>

        <DaoButton 
          onClick={() => openRow(decimals, tokenName, farmContract, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay)}>
          Open {tokenName} Row
        </DaoButton>





        <DaoButton 
          onClick={() => setDepositDisplay('')}>
          Deposit RAM
        </DaoButton>

        <DaoButton 
          onClick={() => withdrawRam(FarmName, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay)}>
          Withdraw RAM
        </DaoButton>
</FarmButtonCont>

<div className={`${depositDisplay} flex text-center items-center m-auto align-center justify-center`} style={{marginTop:'15px'}}>
  <span className="font-semibold">My Ram Balance:&nbsp; </span> {ramBalance}&nbsp;


      <BsInfoCircle 
        onMouseOver={() => {
          setRamInfoDisplay('');      
        }}
        onMouseLeave={() => {
          setRamInfoDisplay('hidden');      
        }}
      
      />
      
      
      </div>
      <div className='p-3 m-auto'>
       
       <RamModal className={`relative ${ramInfoDisplay} rounded-xl p-3 text-xl`}
       
       style={{top: '0px', width: '400px'}}
       >
         <span className="font-bold">How Does Ram Work?</span>
         <br /><br/>
         Before you can stake NFTs, you need to deposit EOS. This will be used to purchase RAM for your data.
         <br />
         When you unstake, that RAM will be sold again and the EOS will be sent back to you.
       
       </RamModal>
       
       
       
             </div>

        <div className={` ${depositDisplay}`}>

      <label htmlFor="ram" ><br />Amount Of EOS:<br /></label>
      <TooltipComponent content="How many EOS do you want to deposit?"
      >
      <ProposalInput type="text"
      maxLength={10} 
      placeholder='10'
      id="ram" 
      onChange={(e) => {

          setDepositAmount(Number(e.target.value).toFixed(4));
          setNftsCoveredByRam( Math.floor( ( Math.round(e.target.value) - 0.0534 ) / 0.0207 ) )
        
      }}
      />
      </TooltipComponent>
     
      <span className="font-semibold">Covers ~{nftsCoveredByRam} NFTs</span>
      
      <span className="text-sm italic">
      <br/>
        *Ram will be purchased when you stake NFTs, and sold/returned to you when you unstake them<br/></span>
      <DaoButton 
          onClick={() => depositRam(FarmName, depositAmount, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay)}>
          Deposit {depositAmount} EOS
        </DaoButton>

        </div>

  </WalletCont>



</StakingCont>











   



    </RightDiv>
    </MainContainer>
    </UALProvider>
    <br/><br/><br/>
    </div>
  )
}

export default FarmPage