import React, { useEffect } from 'react';
import { GridComponent, Resize, Sort, ContextMenu, Filter, Page, ExcelExport, PdfExport, Edit, Inject, Search, Toolbar } from '@syncfusion/ej2-react-grids';
import { ordersData, contextMenuItems, ordersGrid, nftFarmData, nftFarmGrid, myFarmsGrid, depositTokens } from '../../data/dummy';
import { Header } from '../../components';
import { ColumnDirective, ColumnsDirective } from '@syncfusion/ej2-react-charts';
import { useParams } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import { Anchor } from 'ual-anchor';
import { Wax } from '@eosdacio/ual-wax';
import { User } from "universal-authenticator-library";
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { Api, JsonRpc } from "eosjs";
import {Helmet} from "react-helmet";
import axios from 'axios';
import { data } from 'autoprefixer';
import { Name, raw } from "eos-common";
import { ProposerTypeMessage, DaoButton, NFT, NewRow, BuyWojak, ProposalInput, NewProposal, Modal, ModalContent, Spinner, SpinnerRed, SpinnerBlue, SpinnerGreen, GameButton, DisabledButton, MainContainer, CreateDaoCont, DaoTitle, CreateFarmMsg, DropDown, UserWhitelist, BurnableCont, MissingInfoMsg, PaymentOptCont, NFTsCont, NoDaosMessage, NFTLogo, StakeManyButton, StakeManyDiv } from '../../components/LeftDiv';
import NumberFormat from 'react-number-format';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { DatePickerComponent, DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import '.././datepicker.css';

function getRndApi() {
  const apiList = ["https://wax.greymass.com", "https://wax.pink.gg", "https://wax.eosrio.io"];
  return apiList[Math.floor(Math.random() * (2 - 0) ) + 0];
}


const apiList = ["https://eos.api.eosnation.io", "https://api.eoseoul.io", "https://eos.eosphere.io"];

const atomicApiList = ["https://eos.api.atomicassets.io"];



let zero = '0';

const myChain = {
  chainId: 'aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906',
  rpcEndpoints: [{
      protocol: 'https',
      host: 'eos.greymass.com',
      port: '443'
  }]
};
const wtf = ['526134'];
const rpc = new JsonRpc('https://eos.greymass.com');


const anchor = new Anchor([myChain], {

  appName: 'WaxDAO',

});



const handleRemoveAsset = (asset_id, assetVector, setAssetVector) => {

    // remove the asset
    const assetList = [...assetVector];
    assetList.splice(assetList.indexOf(asset_id), 1);
    setAssetVector(assetList);
    console.log('handled removing it');
    


}




const handleAddAsset = (asset_id, assetVector, setAssetVector) => {


        setAssetVector([...assetVector, asset_id])
        console.log('handled adding it');
        



}




const authorizeWaxDAO = async (collection, setEnterModalText, setLoadingDisplay, 
    setEnterButtonsDisplay, setEnterModalDisplay) => {

    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
    const walletProvider = localStorage.getItem('eosWalletProvider');

    if(walletProvider != 'anchor'){

      setEnterModalText('You are not logged in. Click "Wallet" in the top menu');
  
    }

  
      if(walletProvider == 'anchor'){
  
      // Retrieve current session from state
      const session = await anchor.login()
      //console.log(session[0].signTransaction);
    
  
    
      try {
        // Reset our response state to clear any previous transaction data
        
        const action = [{
              account: 'atomicassets',
              name: 'addcolauth',
              authorization: [{
                actor: anchor.users[0].accountName,
                permission: anchor.users[0].requestPermission,
            }],
            data: {
                collection_name: collection,
                account_to_add: "waxdaomarket",
            }
          }]
        // Call transact on the session (compatible with eosjs.transact)
        const response = await session[0].signTransaction({
          actions: action
        }, {
          blocksBehind: 3,
          expireSeconds: 60,
          broadcast: true,
        })
        // Update application state with the responses of the transaction
        setLoadingDisplay('');
        setEnterButtonsDisplay('hidden');
        setEnterModalText('Processing your transaction...')
        const timer = setTimeout(() => {
          
            setEnterModalText('You have authorized waxdaomarket, you can now create drops');
            setLoadingDisplay('none');
            setEnterButtonsDisplay('');
      
        }, 4000);
        return () => clearTimeout(timer);
    
      } catch(e) {
        setEnterModalText(JSON.stringify(e.message));
        console.log(e)
        
      }
    
    
        } //end if wallet = anchor
  
  }//end authorizeWaxDAO








const createPremintPool = async (displayName, collection, setEnterModalText, setLoadingDisplay, 
    setEnterButtonsDisplay, setEnterModalDisplay) => {

    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
    const walletProvider = localStorage.getItem('eosWalletProvider');

    if(walletProvider != 'anchor'){

      setEnterModalText('You are not logged in. Click "Wallet" in the top menu');
  
    }

  
      if(walletProvider == 'anchor'){
  
      // Retrieve current session from state
      const session = await anchor.login()
      //console.log(session[0].signTransaction);
    
  
    
      try {
        // Reset our response state to clear any previous transaction data
        
        const action = [{
              account: 'waxdaomarket',
              name: 'createpool',
              authorization: [{
                actor: anchor.users[0].accountName,
                permission: anchor.users[0].requestPermission,
            }],
            data: {
                user: anchor.users[0].accountName,
                collection: collection,
                display_name: displayName,
            }
          }]
        // Call transact on the session (compatible with eosjs.transact)
        const response = await session[0].signTransaction({
          actions: action
        }, {
          blocksBehind: 3,
          expireSeconds: 60,
          broadcast: true,
        })
        // Update application state with the responses of the transaction
        setLoadingDisplay('');
        setEnterButtonsDisplay('hidden');
        setEnterModalText('Processing your transaction...')
        const timer = setTimeout(() => {
          
            setEnterModalText('Your pool has been created. You can add assets to it on the Manage Drops page.');
            setLoadingDisplay('none');
            setEnterButtonsDisplay('');
      
        }, 4000);
        return () => clearTimeout(timer);
    
      } catch(e) {
  
          if(e.message == "assertion failure with message: You must add waxdaomarket as an authorized account on this collection"){
  
              setEnterModalText(
              <span>
                  waxdaomarket is not authorized on this collection.<br/><br/>
                  <GameButton onClick={() => { authorizeWaxDAO(collection, setEnterModalText, setLoadingDisplay, 
      setEnterButtonsDisplay, setEnterModalDisplay) }}>
                      Authorize Now
                  </GameButton>
  
              </span>);
  
          } else{
  
              setEnterModalText(JSON.stringify(e.message));
  
          }
  
        
        console.log(e)
        
      }
    
    
        } //end if wallet = anchor
  
  } //end createDrop
  







const CreatePremint = () => {
  
  const {  decimals, setDecimals, farmName, setFarmName, rewardToken, setRewardToken, rewardContract, setRewardContract, 
   collection, setCollection, schema, setSchema, farmLogo, setFarmLogo, paymentMethod, setPaymentMethod, timestamp, 
   setTimestamp, setEpochTime, assetDisplay, setAssetDisplay, assetToBurn, setAssetToBurn, burnable, setBurnable, 
   burnableDisplay, setBurnableDisplay, stakingPeriod, setStakingSeconds, stakingSeconds, setStakingPeriod, quantity, 
   setQuantity, farmType, setFarmType, farmTypeDisplay, setFarmTypeDisplay, farmTypeValue, setFarmTypeValue, templates, 
   setTemplates, schemaDisplay, setSchemaDisplay, templateMessage, setTemplateMessage,                 
   rewardType,
   setRewardType,
   rewardTypeDisplay,
   setRewardTypeDisplay,
   rewardTypeValue,
   setRewardTypeValue,
   rewardAmount,
   setRewardAmount,
   enterModalText,
   setEnterModalText,
   loadingDisplay, 
   setLoadingDisplay, 
   enterButtonsDisplay,
   setEnterButtonsDisplay,
   enterModalDisplay, 
   setEnterModalDisplay,
   usernamesDisplay,
   setUsernamesDisplay,
   farmnameDisplay,
   setFarmnameDisplay,
   whitelistType,
   setWhitelistType,
   setStartTime,
   setEndTime,
   startTimestamp,
   setStartTimestamp,
   endTimestamp,
   setEndTimestamp,
   dropPrice,
   setDropPrice,
   dropSymbol,
   setDropSymbol,
   dropPrecision,
   setDropPrecision,
   templateID,
   setTemplateID,
   totalAvailable,
   setTotalAvailable,
   limitPerUser,
   setLimitPerUser,
   cooldownSeconds,
   setCooldownSeconds,
   minimumNftsStaked,
   setMinimumNftsStaked,
   usernames,
   setUsernames,
   dropDescription,
   setDropDescription,
   logoHash,
   setLogoHash,
   receivingAccount,
   setReceivingAccount,
   revenueOption,
   setRevenueOption,
   poolName,
   setPoolName,
   revenueDisplay,
   setRevenueDisplay,
   percentToPool,
   setPercentToPool,
   setIsWhitelistedForDrops,
   isWhitelistedForDrops,
   myCollections,
   setMyCollections,
   unstaked,
   setUnstaked,
   stakeMultipleDisplay, 
   setStakeMultipleDisplay,
   assetVector,
   setAssetVector,
   displayName,
   setDisplayName,
  
  } = useStateContext();






  useEffect(() => {
    const abortCont2 = new AbortController();

    //clear any previous state data
    
    setBurnable([]);
    setBurnableDisplay('hidden');
    setDecimals('');
    setFarmName('');
    setRewardToken('');
    setRewardContract('');
    setFarmLogo('');
    setPaymentMethod('Payment Method');
    setStakingPeriod('');
    setCollection('');
    setSchema('');
    setQuantity('');
    setFarmType('Select Farm Type');
    setUsernamesDisplay('hidden');
    setFarmnameDisplay('hidden');
    setDropDescription('');
    setLogoHash('');
    setRevenueOption('no');
    setPoolName('');
    setRevenueDisplay('hidden');
    setPercentToPool('0');
    setStakeMultipleDisplay('');
    setDisplayName('');


      }

    
    , []);



    useEffect(() => {
        setMyCollections([]);
        
        
        
         const eosUsername = localStorage.getItem('eosAccount');
         const walletProvider = localStorage.getItem('eosWalletProvider');
          
         if(eosUsername){
           //console.log('You have an active session');
           '';
           //setProfileDisplay('flex');
         }else{
           //console.log('You are not logged in');
           return('');
         }
        
         const nameValue = new Name(eosUsername).raw().toString();
        
    
        
         axios.get(`${atomicApiList[Math.floor(Math.random() * (0 - 0) ) + 0]}/atomicassets/v1/collections?authorized_account=${eosUsername}&page=1&limit=100&order=desc&sort=created`)
                    .then((response) => {
                
                        setMyCollections([response.data.data]);
                        console.log(response.data.data)
                      
                      
                    })
                    .catch((error) => console.log(error));
    
          }
        
        , []);





useEffect(() => {
    setAssetVector([]);
    setUnstaked([]);
        const abortCont5 = new AbortController();
    
        const eosUsername = localStorage.getItem('eosAccount');
        if(eosUsername){
        
        //console.log('You have an active session');
        '';
        //setProfileDisplay('flex');
        }else{
        //console.log('You are not logged in');
        return('');
        }
    
        //console.log(nameValue);
        if(collection != ''){
        axios.get(`${atomicApiList[Math.floor(Math.random() * (0 - 0) ) + 0]}/atomicassets/v1/assets?collection_name=${collection}&owner=${eosUsername}&page=1&limit=1000&order=desc&sort=asset_id`)
        .then((atomicResponse) => {
        
          setUnstaked(atomicResponse.data.data);
          console.log(atomicResponse.data.data);
        
 
        })
        
    
    .catch((error) => console.log(error));
    }
    //return() => abortCont5.abort();
    
    }, [collection]);
          






  return (
    <div id="seo">
    <Helmet>
    <title>Create Preminted Packs - WaxDAO</title>
    <meta name="description" content="Create preminted NFT packs on WaxDAO, and accept any EOS token for payment." />
    <link rel="canonical" href="https://eos.waxdao.io/create-premint" />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor]} appName={"WaxDAO"}>
    <MainContainer>


    <Modal className={enterModalDisplay}>
      <ModalContent>
        {enterModalText}
        
      </ModalContent>

        <Spinner style={{display:loadingDisplay}}>
        <SpinnerRed  />
        <SpinnerBlue  />
        <SpinnerGreen />
        </Spinner>

      <br/>
      <GameButton onClick={() => setEnterModalDisplay('hidden') }>
        Close Window
      </GameButton>
      </Modal>
    
      <CreateDaoCont>







        <DaoTitle>
        Create Preminted Pool
        </DaoTitle>
        <CreateFarmMsg>
        Once you create your pool, you can add assets to it on the "Manage Drops" page.<br/><br/>
        You can use the pool for preminted drops, or for preminted pack openings.<br/> 
        </CreateFarmMsg>



       <br/><br/>




        <NewProposal>

      <label htmlFor="dropPrice">Display Name: <br /></label>
      <ProposalInput 
      type="text" 
      maxLength={25}
      id="dropPrice" 
      placeholder="My custom pool"
      value={displayName}
      onChange={(e) => {
        setDisplayName(e.target.value);
        }}
        
      />
 

<form>
        <DropDown         
          onChange={(e) => {setCollection(e.target.value)}}
          >
        <option value="" hidden>
          Collection
        </option>


        {myCollections[1] == null && myCollections[0] != null && Array.isArray(myCollections[0]) ? (
   
   <>




    {myCollections[0].map((item, index) => (




    <>

      {item.collection_name != null && (
          

              <option key={index} value={item.collection_name}>{item.collection_name}</option>

    )}

    


    </>


    ))}

    </>
    
    ) : myCollections[1] != null && Array.isArray(myCollections) && 
    
    (
<>


    {myCollections.map((item, index) => (

<>
{item.collection_name != null && (
    <option key={index} value={item.collection_name}>{item.collection_name}</option>
)}




</>


      ))}
     

      </>
    )}

        </DropDown>
        </form>

<br/>
<DaoButton onClick={() => {createPremintPool( displayName, collection, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay)}}>
    Create Pool
</DaoButton>

{/* 
        <NFTsCont>

<MissingInfoMsg>
    Tip: You can add more assets to the pool after you create it.
    <br/><br/>
    We highly, HIGHLY recommend adding small batches at a time if you are going to add a lot of assets.
    <br/><br/>
    Avoid frustration in case something goes wrong and you run into an error!
</MissingInfoMsg>



{ unstaked.length > 0 ? unstaked.map((item, index) => (


<span key={index}>
 

<NFT key={index} style={stakeMultipleDisplay == "hidden" ? {} : stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) > -1 ? {border:'1px solid #fa872d'} : stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) == -1 ? {border:'1px solid #696969'} : ''}>
<p key={index}><span className="font-bold">{item.name != null ? item.name : ''}</span>
      
<br/><span className="font-semibold">Template: {item.template != null ? item.template.template_id : 'None'}</span> 
<br/>Mint {item.template_mint != null ? item.template_mint : ''}
</p>

{item.data.img != null && (
<NFTLogo key={index} 
src={`https://ipfs.io/ipfs/${item.data.img.trim()}`} 
/>

)}

{item.data.img == null && item.data.video != null && (

<video style={{ width: "150px", maxWidth: "150px", height: "150px", maxHeight: "150px", borderRadius:'25%'}} loop>
<source src={`https://ipfs.io/ipfs/${item.data.video.trim()}`} type="video/mp4"/>
</video>



)}

{item.data.img == null && item.data.video == null && item.data.image != null && (
<NFTLogo key={index} 
src={`https://ipfs.io/ipfs/${item.data.image.trim()}`} 
/>

)}

<StakeManyButton key={index}
onClick={() => {
  if(stakeMultipleDisplay == "hidden"){
    stake()
  } else {

    if(assetVector.indexOf(item.asset_id) > -1){
      handleRemoveAsset(item.asset_id, assetVector, setAssetVector)
    } else {
      handleAddAsset(item.asset_id, assetVector, setAssetVector)
    }
  }
  }}
  
  style={stakeMultipleDisplay == "hidden" ? {} : stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) > -1 ? {border:'1px solid #fa872d'} : stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) == -1 ? {border:'1px solid #696969'} : ''}
  className={stakeMultipleDisplay == "hidden" ? "text-orange" : stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) > -1 ? "text-orange" : stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) == -1 ? "text-gray hover:text-black" : ''}
  >
{stakeMultipleDisplay == "hidden" && "Stake Now"}
{stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) > -1 && "Remove"}
{stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) == -1 && "Add"}
</StakeManyButton>

</NFT>





  



</span>









)) : (
<NoDaosMessage>
  You don't have any assets in this collection.
</NoDaosMessage>


)}


</NFTsCont>

 */}



<br/><br/>

    </NewProposal>
    
    </CreateDaoCont>
    
    </MainContainer>
    </UALProvider>
    <br/><br/><br/>
    </div>
  )
}

export default CreatePremint