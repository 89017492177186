import React, { useEffect } from 'react';
import { GridComponent, Resize, Sort, ContextMenu, Filter, Page, ExcelExport, PdfExport, Edit, Inject } from '@syncfusion/ej2-react-grids';
import { ordersData, contextMenuItems, ordersGrid, nftFarmData, nftFarmGrid } from '../../data/dummy';
import { Header } from '../../components';
import { ColumnDirective, ColumnsDirective } from '@syncfusion/ej2-react-charts';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useParams } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import { Anchor } from 'ual-anchor';
import { Wax } from '@eosdacio/ual-wax';
import { User } from "universal-authenticator-library";
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { Api, JsonRpc } from "eosjs";
import {Helmet} from "react-helmet";
import axios from 'axios';
import { data } from 'autoprefixer';
import { AboutDAO, ProposalInput, StakingCont, AboutDaoBody, AboutDaoCont, AboutDaoTitle, Creator, DaoButton, DaoName, DaoTitle, FarmButtonCont, FarmMenu, FarmMenuItem, FarmTopMenu, FarmTopMenuItem, LeftDiv, MainContainer, RamModal, RightDiv, WalletCont } from '../../components/LeftDiv';
import NumberFormat from 'react-number-format';
import { BsInfoCircle } from 'react-icons/bs';

let zero = '0';

const myChain = {
  chainId: 'aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906',
  rpcEndpoints: [{
      protocol: 'https',
      host: 'eos.greymass.com',
      port: '443'
  }]
};

const rpc = new JsonRpc('https://eos.greymass.com');


const anchor = new Anchor([myChain], {

  appName: 'WaxDAO',

});


const stakeTokens = async (stakingAmount, tokenContract, tokenToStake, PoolName) => {

  const walletProvider = localStorage.getItem('eosWalletProvider');



  if(walletProvider == 'anchor'){

    // Retrieve current session from state
    const session = await anchor.login()
    //console.log(session[0].signTransaction);
  
    try {
      // Reset our response state to clear any previous transaction data
      
      const action = [{
            account: tokenContract,
            name: 'transfer',
            authorization: [{
              actor: anchor.users[0].accountName,
              permission: anchor.users[0].requestPermission,
          }],
          data: {
              to: 'waxdaofarmer',
              from: anchor.users[0].accountName,
              quantity: stakingAmount + ' ' + tokenToStake,
              memo: '|stake_tokens|' + PoolName + '|',
          }
        }]
      // Call transact on the session (compatible with eosjs.transact)
      const response = await session[0].signTransaction({
        actions: action
      }, {

        blocksBehind: 3,
        expireSeconds: 60,
        broadcast: true,
      })
      // Update application state with the responses of the transaction
      alert('Success! Your tokens are now staked');
    } catch(e) {
      alert(e)
      console.log(e)
      
    }
    
    }//end if wallet = anchor



}




const depositRam = async (PoolName) => {

  const walletProvider = localStorage.getItem('eosWalletProvider');

  if(walletProvider == 'anchor'){

    // Retrieve current session from state
    const session = await anchor.login()
    //console.log(session[0].signTransaction);
  
    try {
      // Reset our response state to clear any previous transaction data
      
      const action = [{
            account: 'eosio.token',
            name: 'transfer',
            authorization: [{
              actor: anchor.users[0].accountName,
              permission: anchor.users[0].requestPermission,
          }],
          data: {
              to: 'waxdaofarmer',
              from: anchor.users[0].accountName,
              quantity: '0.1000 EOS',
              memo: '|deposit_user_ram|' + PoolName + '|',
          }
        }]
      // Call transact on the session (compatible with eosjs.transact)
      const response = await session[0].signTransaction({
        actions: action
      }, {

        blocksBehind: 3,
        expireSeconds: 60,
        broadcast: true,
      })
      // Update application state with the responses of the transaction
      alert('Success! You can now stake tokens into this pool');
    } catch(e) {
      alert(e)
      console.log(e)
      
    }
    
    }//end if wallet = anchor



}






const unstakeTokens = async (stakingAmount, tokenContract, tokenToStake, PoolName) => {

  const walletProvider = localStorage.getItem('eosWalletProvider');

  const stakingString = stakingAmount.toString().replace('.', '');


if(walletProvider == 'anchor'){

  // Retrieve current session from state
  const session = await anchor.login()
  //console.log(session[0].signTransaction);

  try {
    // Reset our response state to clear any previous transaction data
    
    const action = [{
          account: 'waxdaofarmer',
          name: 'unstaketoken',
          authorization: [{
            actor: anchor.users[0].accountName,
            permission: anchor.users[0].requestPermission,
        }],
        data: {
          user: anchor.users[0].accountName,
          amount: stakingString,
          poolname: PoolName,
        }
      }]
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction({
      actions: action
    }, {

      blocksBehind: 3,
      expireSeconds: 60,
      broadcast: true,
    })
    // Update application state with the responses of the transaction
    alert('Success! Your tokens have been unstaked');
  } catch(e) {
    alert(e)
    console.log(e)
    
  }

} // end if wallet = anchor

}



const claimRewards = async (PoolName) => {

  const walletProvider = localStorage.getItem('eosWalletProvider');


if(walletProvider == 'anchor'){

  // Retrieve current session from state
  const session = await anchor.login()
  //console.log(session[0].signTransaction);

  try {
    // Reset our response state to clear any previous transaction data
    
    const action = [{
          account: 'waxdaofarmer',
          name: 'claimpoolrwd',
          authorization: [{
            actor: anchor.users[0].accountName,
            permission: anchor.users[0].requestPermission,
        }],
        data: {
            user: anchor.users[0].accountName,
            pool: PoolName,
        }
      }]
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction({
      actions: action
    }, {

      blocksBehind: 3,
      expireSeconds: 60,
      broadcast: true,
    })
    // Update application state with the responses of the transaction
    alert('Success! Your rewards have been sent.');
  } catch(e) {
    alert(e)
    console.log(e)
    
  }

}// end if wallet = anchor

}




const openRow = async (rwdToken, contract) => {

  const walletProvider = localStorage.getItem('eosWalletProvider');


  if(walletProvider == 'anchor'){

    // Retrieve current session from state
    const session = await anchor.login()
    //console.log(session[0].signTransaction);
  
    try {
      // Reset our response state to clear any previous transaction data
      
      const action = [{
            account: contract,
            name: 'open',
            authorization: [{
              actor: anchor.users[0].accountName,
              permission: anchor.users[0].requestPermission,
          }],
          data: {
            owner: anchor.users[0].accountName,
            symbol: rwdToken,
            ram_payer: anchor.users[0].accountName,
          }
        }]
      // Call transact on the session (compatible with eosjs.transact)
      const response = await session[0].signTransaction({
        actions: action
      }, {

        blocksBehind: 3,
        expireSeconds: 60,
        broadcast: true,
      })
      // Update application state with the responses of the transaction
      alert('Success! You can now receive ' + rwdToken.substr(rwdToken.indexOf(',') + 1) + ' tokens');
    } catch(e) {
      alert(e)
      console.log(e)
      
    }
    
    }//end if wallet = anchor



}




const PoolPage = ({ location }) => {

  const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode, currentUser, 
    setCurrentUser, tokenName, setTokenName, maxSupply, setMaxSupply, decimals, setDecimals, 
    tokenString, setTokenString, farmData, setFarmData, farmCollection, setFarmCollection, 
    farmSchema, setFarmSchema, farmRewards, setFarmRewards, farmExpires, setFarmExpires, 
    farmIcon, setFarmIcon, farmContract, setFarmContract, createdBy, setCreatedBy, unstaked, 
    setUnstaked, stakedOrUnstaked, setStakedOrUnstaked, stakedDisplay, setStakedDisplay, 
    unstakedDisplay, setUnstakedDisplay, validAssets, setValidAssets, commaSeparated, 
    setCommaSeparated, stakedAssets, setStakedAssets, claimable, setClaimable, minTime, 
    setMinTime, tokenToStake, setTokenToStake, tokenContract, setTokenContract, tokenList, 
    setTokenList, noTokensDisplay, setNoTokensDisplay, stakingAmount, setStakingAmount, 
    tokenPrecision, setTokenPrecision, minAmount, setMinAmount, stakedBalance, setStakedBalance, 
    hourlyPool, setHourlyPool, totalStaked, setTotalStaked, rwdToken, setRwdToken, aboutFarmDisplay,
    setAboutFarmDisplay, walletDisplay, setWalletDisplay, depositDisplay, setDepositDisplay,
    ramBalance, setRamBalance, ramInfoDisplay, setRamInfoDisplay, depositAmount, setDepositAmount,
    nftsCoveredByRam, setNftsCoveredByRam, setStakeNftsDisplay, stakeNftsDisplay, setUnstakeNftsDisplay,
    unstakeNftsDisplay   
  
  } = useStateContext();

const { PoolName } = useParams();

useEffect(() => {

  const abortCont = new AbortController();
  const abortCont2 = new AbortController();
  const abortCont3 = new AbortController();
  const abortCont4 = new AbortController();

  setStakingAmount('');

  setStakedAssets([]);
  setUnstaked([]);
  setValidAssets([]);
  setCommaSeparated('');
  setTokenToStake('');
  setTokenContract('');
  setTokenList([]);
  setMinAmount('');
  setHourlyPool('0');
  setTotalStaked('0');
  setRwdToken('');


  const eosUsername = localStorage.getItem('eosAccount');
  
  if(eosUsername){
    //console.log('You have an active session');
    '';
    //setProfileDisplay('flex');
  }else{
    //console.log('You are not logged in');
    '';     //return('');
  }



  axios.post('https://eos.api.eosnation.io/v1/chain/get_table_rows',{
    table:"pools",
    scope:"waxdaofarmer",
    code:"waxdaofarmer",
    key_type: 'name',
    indexName: 'poolname',
    limit:1,
    lower_bound:PoolName,
    upper_bound:PoolName,
    json:true,
    signal: abortCont.signal
  })
    .then((response) => {
      setFarmData(response);
      setFarmCollection(response.data.rows[0].collection);
      setFarmSchema(response.data.rows[0].schema);
      setFarmIcon(response.data.rows[0].logo);
      setCreatedBy(response.data.rows[0].creator);
      setFarmRewards(response.data.rows[0].poolsize);
      setFarmContract(response.data.rows[0].contract);
      setFarmExpires(response.data.rows[0].enddate);
      setMinTime(response.data.rows[0].mintime / 86400);
      setMinAmount(response.data.rows[0].minamount);
      setTokenPrecision(response.data.rows[0].tokentostake.substring(0, response.data.rows[0].tokentostake.indexOf(",")));
      setTokenToStake(response.data.rows[0].tokentostake.substring(response.data.rows[0].tokentostake.indexOf(",") + 1));
      setTokenContract(response.data.rows[0].stkcontract);
      setHourlyPool(response.data.rows[0].hourlyreward);
      setTotalStaked(response.data.rows[0].totalstaked);
      setRwdToken(response.data.rows[0].rwdtoken);

      //console.log(response.data.rows[0].collection);
      if(eosUsername){

        axios.post('https://eos.api.eosnation.io/v1/chain/get_table_rows',{
          table:"accounts",
          scope:eosUsername,
          code:response.data.rows[0].stkcontract,
          limit:50,
          json:true,
          signal: abortCont3.signal
        }).then((poolResponse) => {

          if(poolResponse.data.rows[0] != undefined){
            setNoTokensDisplay('hidden');
          }
    
          setTokenList(poolResponse.data.rows);
        })



      .catch((error) => console.log(error));


    }



    })
    .catch((error) => console.log(error));

    return() => {
      abortCont.abort();
      abortCont2.abort();
      abortCont3.abort();
      abortCont4.abort();
      
    }

}, []);









useEffect(() => {
  setWalletDisplay('')

  const abortCont5 = new AbortController();

  const eosUsername = localStorage.getItem('eosAccount');
  
  if(eosUsername){
    //console.log('You have an active session');
    '';
    //setProfileDisplay('flex');
  }else{
    //console.log('You are not logged in');
    return('');
  }

  setStakedBalance('0');

  axios.post('https://eos.api.eosnation.io/v1/chain/get_table_rows',{
    table:"tokens",
    scope:"waxdaofarmer",
    code:"waxdaofarmer",
    key_type: 'name',
    index_position: 2,
    limit:100,
    lower_bound:eosUsername,
    upper_bound:eosUsername,
    json:true,
    signal: abortCont5.signal
  }).then((claimableResponse) => {
    var claimitr = 0;

    while(claimitr < claimableResponse.data.rows.length){
      if(claimableResponse.data.rows[claimitr].poolname == PoolName){
        setClaimable(claimableResponse.data.rows[claimitr].claimable);
        setStakedBalance(claimableResponse.data.rows[claimitr].amountstaked);

          //console.log(claimableResponse);

        break;
      }
      else{
        claimitr ++;
      }
    }


  })

.catch((error) => console.log(error));

return() => abortCont5.abort();

}, []);












  return (
    <div id="seo">
    <Helmet>
    <title>{PoolName} Details</title>
    <meta name="description" content={`Stake NFTs in the ${PoolName} pool on WaxDao`} />
    <link rel="canonical" href={`https://eos.waxdao.io/pool/${PoolName}`} />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor]} appName={"WaxDAO"}>

    <FarmTopMenu>

    <FarmTopMenuItem onClick={() => {setAboutFarmDisplay(''); setStakeNftsDisplay('hidden'); setUnstakeNftsDisplay('hidden'); setWalletDisplay('hidden');} }>
            About
        </FarmTopMenuItem>

        <FarmTopMenuItem onClick={() => {setAboutFarmDisplay('hidden'); setStakeNftsDisplay('hidden'); setUnstakeNftsDisplay('hidden'); setWalletDisplay('');} }>
            My Wallet
        </FarmTopMenuItem>


    </FarmTopMenu>

    <MainContainer>

      <LeftDiv>
      <img src={`https://ipfs.io/ipfs/${farmIcon}`} 
        alt={`${PoolName} Logo`} 
        style={{ width:'150px', 
          height:'150px',
          maxWidth:'150px',
          maxHeight:'150px',
          marginLeft:'auto',
          marginRight:'auto',
          marginTop:'15px'
        }}
        className="rounded-full hover:drop-shadow-xl"      
      />

      <DaoName>
        {PoolName != null && PoolName}
      </DaoName>

      <Creator>
      {createdBy != null && (

      <span>By {createdBy}</span> 

      )} 
      </Creator>

      <FarmMenu>

        <FarmMenuItem onClick={() => {setAboutFarmDisplay(''); setStakeNftsDisplay('hidden'); setUnstakeNftsDisplay('hidden'); setWalletDisplay('hidden');} }>
            About
        </FarmMenuItem>

        <FarmMenuItem onClick={() => {setAboutFarmDisplay('hidden'); setStakeNftsDisplay('hidden'); setUnstakeNftsDisplay('hidden'); setWalletDisplay('');} }>
            My Wallet
        </FarmMenuItem>

      </FarmMenu>

      </LeftDiv>

      <RightDiv>

        <AboutDAO className={aboutFarmDisplay}>
        <DaoTitle>
          About This Pool
        </DaoTitle>


        
        <AboutDaoCont>

          <AboutDaoTitle>
            Creator
          </AboutDaoTitle>
          <AboutDaoBody>
            {createdBy}
          </AboutDaoBody>

          <AboutDaoTitle>
            Token To Stake
          </AboutDaoTitle>
          <AboutDaoBody>
            {tokenToStake}@{tokenContract}
          </AboutDaoBody>   

          <AboutDaoTitle>
          Minimum Staking Period
          </AboutDaoTitle>
          <AboutDaoBody>
          {minTime} {minTime <= 1 ? 'Day' : 'Days'}
          </AboutDaoBody>

          <AboutDaoTitle>
            Expires
          </AboutDaoTitle>
          <AboutDaoBody>
            {new Date(farmExpires * 1000).toLocaleDateString()}
          </AboutDaoBody>

          <AboutDaoTitle>
            Total Reward Pool
          </AboutDaoTitle>
          <AboutDaoBody>
              <NumberFormat displayType='text' thousandSeparator={true} value={Math.round(farmRewards.substring(0, farmRewards.indexOf(' ')))} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} /> {farmRewards.substring(farmRewards.indexOf(' '))}@{farmContract}
          </AboutDaoBody> 

          <AboutDaoTitle>
            Hourly Reward Pool
          </AboutDaoTitle>
          <AboutDaoBody>
            {hourlyPool}
          </AboutDaoBody>

          <AboutDaoTitle>
            Total Tokens Staked
          </AboutDaoTitle>
          <AboutDaoBody>
            {totalStaked}
          </AboutDaoBody>

        </AboutDaoCont>

        </AboutDAO>





  <StakingCont className={walletDisplay}>
  <DaoTitle>
   Wallet
  </DaoTitle>
 



<WalletCont>

<FarmButtonCont>
      <DaoButton 
          onClick={() => claimRewards(PoolName)}>
          Claim {typeof claimable === 'object' ? '' : claimable}
        </DaoButton>

        <DaoButton 
          onClick={() => openRow(rwdToken, farmContract)}>
         Open {farmRewards != null ? farmRewards.substr(farmRewards.indexOf(' ') + 1) : ''} Row
        </DaoButton>



        <DaoButton 
          onClick={() => depositRam(PoolName)}>
          Deposit RAM
        </DaoButton>


</FarmButtonCont>






















<AboutDAO>
<br/><br/>

<div>
<h2 className="text-2xl font-semibold">My Tokens</h2>
   
   <div className="flex gap-4 text-center m-auto flex-wrap">

   <div id="noTokens" className={`text-center items-center align-center justify-center m-auto ${noTokensDisplay}`}>
    You have no {tokenToStake} balance on this account.<br/>

   </div>


    {tokenList.map((item, index) => (


   
        <div key={index}
        
        className='p-3 m-auto border-2 rounded-lg gap-4 mt-4 text-center'


        >{item.balance}</div>


        ))}

        
</div>
</div>    

<h2 className="text-2xl font-semibold m-auto mt-4">My Staked Balance</h2>
<div className='mt-4 items-center justify-center text-center align-center m-auto flex'>
  


  <div
        
        className='p-3 m-auto border-2 rounded-lg gap-4 text-center flex'


        >{stakedBalance}</div>


  </div>  
    


<br/>
<p>Amount To Stake/Unstake:</p>

      <ProposalInput type="number" 
      id="stkAmount" 
      placeholder="1"
      value={stakingAmount}
      onChange={(e) => {

        if(tokenPrecision == 0){
            if(e.target.value < 1){
            setStakingAmount(1);
            
            }
            else{
              setStakingAmount(Math.round(e.target.value));
            }
       }

       else{

        if(e.target.value < 1){
          setStakingAmount(1 + '.' + zero.repeat(tokenPrecision));
          
          }
          else{
            setStakingAmount(Math.round(e.target.value) + '.' + zero.repeat(tokenPrecision));
          }

       }

      }}
      />

<p style={{ fontStyle:'italic' }}>*Minimum {minAmount} {tokenToStake}</p>



      <DaoButton 
          onClick={() => stakeTokens(stakingAmount, tokenContract, tokenToStake, PoolName)}
        >
          Stake Now
        </DaoButton>

&nbsp; &nbsp; 
        <DaoButton 
          onClick={() => unstakeTokens(stakingAmount, tokenContract, tokenToStake, PoolName)}
        >
          Unstake
        </DaoButton>


        </AboutDAO>
        </WalletCont>



</StakingCont>

    </RightDiv>
    </MainContainer>
    </UALProvider>
    <br/><br/><br/>
    </div>
  )
}

export default PoolPage