import React, { useEffect, useRef } from 'react';
import { GridComponent, Resize, Sort, ContextMenu, Filter, Page, ExcelExport, PdfExport, Edit, Inject } from '@syncfusion/ej2-react-grids';
import { ordersData, contextMenuItems, ordersGrid, nftFarmData, nftFarmGrid } from '../data/dummy';
import { Header } from '../components';
import { ColumnDirective, ColumnsDirective } from '@syncfusion/ej2-react-charts';
import { useParams } from 'react-router-dom';
import { useStateContext } from '../contexts/ContextProvider';
import { Anchor } from 'ual-anchor';
import { Wax } from '@eosdacio/ual-wax';
import { User } from "universal-authenticator-library";
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { Api, JsonRpc } from "eosjs";
import {Helmet} from "react-helmet";
import axios from 'axios';
import { data } from 'autoprefixer';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { BsInfoCircle } from 'react-icons/bs';
import { render } from 'react-dom';
import Select from 'react-select';
import { GiConsoleController, GiDandelionFlower } from 'react-icons/gi';
import { MainContainer, LeftDiv, RightDiv, DaoName, Creator, Proposals, NewProposal, AboutDAO, DaoMenu, DaoMenuItem, Wallet, DaoTitle,
    SingleProposal, ProposalButton, ProposalButtonContainer, ProposalTitle, ProposalAuthor, ProposalDescription, ProposerTypeMessage,
    ProposerList, ProposalInput, PropDescription, ProposalChoices, RemoveButton, AddButton, SubmitButton, DropDown, AboutDaoTitle,
    AboutDaoBody, AboutDaoCont, DaoButton, DaoButtonCont, StakedAmount, FarmTopMenu, FarmTopMenuItem, TemplatesCont, SingleTemplate, Modal, ModalContent, Spinner, SpinnerRed, SpinnerBlue, SpinnerGreen, GameButton
    } from '../components/LeftDiv';
import NumberFormat from 'react-number-format';



const handleChoiceSelection = (e, index, selectedOption, setSelectedOption) => {

    setSelectedOption(index);
    

}


const handleChoiceAdd = (setChoicesList, choicesList) => {

    setChoicesList([...choicesList, { choice: ''}])

}

const handleChoiceRemove = (setChoicesList, choicesList, index) => {
    const list = [...choicesList];
    list.splice(index, 1);
    setChoicesList(list);

}

const handleChoiceChange = (e, index, choicesList, setChoicesList) => {
    const {name, value} = e.target;
    const list = [...choicesList];
    list[index][name] = value;
    setChoicesList(list);

}


const proposalTypeOptions = [
    { value: 'Standard', label: 'Standard' }
  ]



let zero = '0';

const myChain = {
  chainId: '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4',
  rpcEndpoints: [{
      protocol: 'https',
      host: 'wax.greymass.com',
      port: '443'
  }]
};
const wtf = ['526134'];
const rpc = new JsonRpc('https://wax.greymass.com');


const anchor = new Anchor([myChain], {

  appName: 'WaxDAO',

});

const wax = new Wax([myChain], {

  appName: 'WaxDAO',

});


const ualLogin = async () => {
 
  console.log('Loading...');
  await anchor.login();
  getCustomersData();

}

const ualLogout = async () => {
 
  console.log('Loading...');
  await anchor.logout();
  //console.log(anchor.users[0].accountName);

}


const stake = async (DAOName, asset_id, refreshAssets) => {

  const walletProvider = localStorage.getItem('walletProvider');

  if(walletProvider == 'wax'){

  // Retrieve current session from state
  const session = await wax.login()
  //console.log(session[0].signTransaction);

  try {
    // Reset our response state to clear any previous transaction data
    
    const action = [{
          account: 'atomicassets',
          name: 'transfer',
          authorization: [{
            actor: wax.users[0].accountName,
            permission: wax.users[0].requestPermission,
        }],
        data: {
            to: 'waxdaofarmer',
            from: wax.users[0].accountName,
            asset_ids: [asset_id],
            memo: '|stake|' + DAOName + '|',
        }
      }]
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction({
      actions: action
    }, {

      blocksBehind: 3,
      expireSeconds: 60,
      broadcast: true,
    })
    // Update application state with the responses of the transaction
    alert('Success! Your NFT is now staked');
    refreshAssets ++; 
  } catch(e) {
    alert(e)
    console.log(e)
    
  }
  
  }//end if wallet = wax


  if(walletProvider == 'anchor'){

    // Retrieve current session from state
    const session = await anchor.login()
    //console.log(session[0].signTransaction);
  
    try {
      // Reset our response state to clear any previous transaction data
      
      const action = [{
            account: 'atomicassets',
            name: 'transfer',
            authorization: [{
              actor: anchor.users[0].accountName,
              permission: anchor.users[0].requestPermission,
          }],
          data: {
              to: 'waxdaofarmer',
              from: anchor.users[0].accountName,
              asset_ids: [asset_id],
              memo: '|stake|' + DAOName + '|',
          }
        }]
      // Call transact on the session (compatible with eosjs.transact)
      const response = await session[0].signTransaction({
        actions: action
      }, {

        blocksBehind: 3,
        expireSeconds: 60,
        broadcast: true,
      })
      // Update application state with the responses of the transaction
      alert('Success! Your NFT is now staked');
      refreshAssets ++; 
    } catch(e) {
      alert(e)
      console.log(e)
      
    }
    
    }//end if wallet = anchor
  

}



const stakeTokens = async (DAOName, depositAmount, farmContract, tokenName, decimals, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {

  setEnterModalDisplay('');
  setEnterModalText('Awaiting confirmation...');

    var depositDecimals = 0;
    var amountToSend = 0;

    if(decimals == 0){
        amountToSend = depositAmount + ' ' + tokenName;
    }

    else{
        amountToSend = depositAmount + '.' + zero.repeat(decimals) + ' ' + tokenName;
    }


    const walletProvider = localStorage.getItem('walletProvider');
  
    if(walletProvider == 'wax'){
  
    // Retrieve current session from state
    const session = await wax.login()
    //console.log(session[0].signTransaction);
  
    try {
      // Reset our response state to clear any previous transaction data
      
      const action = [{
            account: farmContract,
            name: 'transfer',
            authorization: [{
              actor: wax.users[0].accountName,
              permission: wax.users[0].requestPermission,
          }],
          data: {
              to: 'buildawaxdao',
              from: wax.users[0].accountName,
              quantity: amountToSend,
              memo: '|stake_tokens|' + DAOName + '|',
          }
        }]
      // Call transact on the session (compatible with eosjs.transact)
      const response = await session[0].signTransaction({
        actions: action
      }, {
  
        blocksBehind: 3,
        expireSeconds: 60,
        broadcast: true,
      })
      // Update application state with the responses of the transaction
      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Staking your tokens...')
      const timer = setTimeout(() => {
        
          setEnterModalText('Your tokens have been staked.');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');
    
      }, 4000);
      return () => clearTimeout(timer);
  
    } catch(e) {
      setEnterModalText(JSON.stringify(e.message));
      console.log(e)
      
    }
    
    }//end if wallet = wax
  
  
    if(walletProvider == 'anchor'){
  
      // Retrieve current session from state
      const session = await anchor.login()
      //console.log(session[0].signTransaction);
    
      try {
        // Reset our response state to clear any previous transaction data
        
        const action = [{
              account: farmContract,
              name: 'transfer',
              authorization: [{
                actor: anchor.users[0].accountName,
                permission: anchor.users[0].requestPermission,
            }],
            data: {
                to: 'buildawaxdao',
                from: anchor.users[0].accountName,
                quantity: amountToSend,
                memo: '|stake_tokens|' + DAOName + '|',
            }
          }]
        // Call transact on the session (compatible with eosjs.transact)
        const response = await session[0].signTransaction({
          actions: action
        }, {
  
          blocksBehind: 3,
          expireSeconds: 60,
          broadcast: true,
        })
        // Update application state with the responses of the transaction
        setLoadingDisplay('');
        setEnterButtonsDisplay('hidden');
        setEnterModalText('Staking your tokens...')
        const timer = setTimeout(() => {
          
            setEnterModalText('Your tokens have been staked.');
            setLoadingDisplay('none');
            setEnterButtonsDisplay('');
      
        }, 4000);
        return () => clearTimeout(timer);
    
      } catch(e) {
        setEnterModalText(JSON.stringify(e.message));
        console.log(e)
        
      }
      
      }//end if wallet = anchor
    
  
  }






const unstake = async (DAOName, asset_id, refreshAssets) => {

  const walletProvider = localStorage.getItem('walletProvider');

  if(walletProvider == 'wax'){

  // Retrieve current session from state
  const session = await wax.login()
  //console.log(session[0].signTransaction);

  try {
    // Reset our response state to clear any previous transaction data
    
    const action = [{
          account: 'waxdaofarmer',
          name: 'unstake',
          authorization: [{
            actor: wax.users[0].accountName,
            permission: wax.users[0].requestPermission,
        }],
        data: {
            user: wax.users[0].accountName,
            asset_id: asset_id,
            DAOName: DAOName,
        }
      }]
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction({
      actions: action
    }, {

      blocksBehind: 3,
      expireSeconds: 60,
      broadcast: true,
    })
    // Update application state with the responses of the transaction
    alert('Success! Your NFT has been unstaked');
    refreshAssets ++; 
  } catch(e) {
    alert(e)
    console.log(e)
    
  }

} // end if wallet = wax


if(walletProvider == 'anchor'){

  // Retrieve current session from state
  const session = await anchor.login()
  //console.log(session[0].signTransaction);

  try {
    // Reset our response state to clear any previous transaction data
    
    const action = [{
          account: 'waxdaofarmer',
          name: 'unstake',
          authorization: [{
            actor: anchor.users[0].accountName,
            permission: anchor.users[0].requestPermission,
        }],
        data: {
            user: anchor.users[0].accountName,
            asset_id: asset_id,
            DAOName: DAOName,
        }
      }]
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction({
      actions: action
    }, {

      blocksBehind: 3,
      expireSeconds: 60,
      broadcast: true,
    })
    // Update application state with the responses of the transaction
    alert('Success! Your NFT has been unstaked');
    refreshAssets ++; 
  } catch(e) {
    alert(e)
    console.log(e)
    
  }

} // end if wallet = anchor





}




const submitProposal = async (DAOName, proposalTypeValue, choicesList, description, proposalTitle, proposalAmount, proposalSymbol, proposalDecimals, proposalContract, receivingAccount, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {
    
  setEnterModalDisplay('');
  setEnterModalText('Awaiting confirmation...');
  
  const choiceArray = [];
   // console.log(Object.values(choiceArray[{choice}]));


   {Object.values(choicesList).map((value, index) => {
    console.log(value['choice']);
    choiceArray.push(value['choice']);
  })}

var tknsym;
var tknqty;
var tknctrct;

if(proposalTypeValue != 'tkn.transfer'){

  tknsym = '8,WAX';
  tknqty = '0';
  tknctrct = '';
  


} else {

  tknsym = proposalDecimals + ',' + proposalSymbol;
  tknqty = proposalAmount;
  tknctrct = proposalContract;
}


    //return;

    if(proposalTypeValue != 'standard' && proposalTypeValue != 'tkn.transfer'){

        setLoadingDisplay('none');
        setEnterButtonsDisplay('');
        setEnterModalText('You need to select a proposal type');
        return;
    }

    const walletProvider = localStorage.getItem('walletProvider');
  
    if(walletProvider == 'wax'){
  
    // Retrieve current session from state
    const session = await wax.login()
    //console.log(session[0].signTransaction);
  
    try {
      // Reset our response state to clear any previous transaction data
      
      const action = [{
            account: 'buildawaxdao',
            name: 'proposal',
            authorization: [{
              actor: wax.users[0].accountName,
              permission: wax.users[0].requestPermission,
          }],
          data: {
              user: wax.users[0].accountName,
              daoname: DAOName,
              proposal_type: proposalTypeValue,
              choices: choiceArray,
              receivers: [receivingAccount],
              asset_ids: [],
              quantity: tknqty,
              tokensymbol: tknsym,
              contract: tknctrct,
              description: description,
              title: proposalTitle,
          }
        }]
      // Call transact on the session (compatible with eosjs.transact)
      const response = await session[0].signTransaction({
        actions: action
      }, {
  
        blocksBehind: 3,
        expireSeconds: 60,
        broadcast: true,
      })
      // Update application state with the responses of the transaction
      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Creating your proposal...')
      const timer = setTimeout(() => {
        
          setEnterModalText('Your proposal has been created.');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');
    
      }, 4000);
      return () => clearTimeout(timer);
  
    } catch(e) {
      setEnterModalText(JSON.stringify(e.message));
      console.log(e)
      
    }
  
  } // end if wallet = wax
  
  
  if(walletProvider == 'anchor'){
  
    // Retrieve current session from state
    const session = await anchor.login()
    //console.log(session[0].signTransaction);
  
    try {
      // Reset our response state to clear any previous transaction data
      
      const action = [{
            account: 'buildawaxdao',
            name: 'proposal',
            authorization: [{
              actor: anchor.users[0].accountName,
              permission: anchor.users[0].requestPermission,
          }],
          data: {
              user: anchor.users[0].accountName,
              daoname: DAOName,
              proposal_type: proposalTypeValue,
              choices: choiceArray,
              receivers: [receivingAccount],
              asset_ids: [],
              quantity: tknqty,
              tokensymbol: tknsym,
              contract: tknctrct,
              description: description,
              title: proposalTitle,
          }
        }]
      // Call transact on the session (compatible with eosjs.transact)
      const response = await session[0].signTransaction({
        actions: action
      }, {
  
        blocksBehind: 3,
        expireSeconds: 60,
        broadcast: true,
      })
      // Update application state with the responses of the transaction
      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Creating your proposal...')
      const timer = setTimeout(() => {
        
          setEnterModalText('Your proposal has been created.');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');
    
      }, 4000);
      return () => clearTimeout(timer);
  
    } catch(e) {
      setEnterModalText(JSON.stringify(e.message));
      console.log(e)
      
    }
  
  } // end if wallet = anchor
  
  



}






const unstakeTokens = async (DAOName, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {

  setEnterModalDisplay('');
  setEnterModalText('Awaiting confirmation...');

    const walletProvider = localStorage.getItem('walletProvider');
  
    if(walletProvider == 'wax'){
  
    // Retrieve current session from state
    const session = await wax.login()
    //console.log(session[0].signTransaction);
  
    try {
      // Reset our response state to clear any previous transaction data
      
      const action = [{
            account: 'buildawaxdao',
            name: 'unstaketoken',
            authorization: [{
              actor: wax.users[0].accountName,
              permission: wax.users[0].requestPermission,
          }],
          data: {
              user: wax.users[0].accountName,
              daoname: DAOName,
          }
        }]
      // Call transact on the session (compatible with eosjs.transact)
      const response = await session[0].signTransaction({
        actions: action
      }, {
  
        blocksBehind: 3,
        expireSeconds: 60,
        broadcast: true,
      })
      // Update application state with the responses of the transaction
      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Unstaking your tokens...')
      const timer = setTimeout(() => {
        
          setEnterModalText('Your tokens have been unstaked.');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');
    
      }, 4000);
      return () => clearTimeout(timer);
  
    } catch(e) {
      setEnterModalText(JSON.stringify(e.message));
      console.log(e)
      
    }
  
  } // end if wallet = wax
  
  
  if(walletProvider == 'anchor'){
  
    // Retrieve current session from state
    const session = await anchor.login()
    //console.log(session[0].signTransaction);
  
    try {
      // Reset our response state to clear any previous transaction data
      
      const action = [{
            account: 'buildawaxdao',
            name: 'unstaketoken',
            authorization: [{
              actor: anchor.users[0].accountName,
              permission: anchor.users[0].requestPermission,
          }],
          data: {
              user: anchor.users[0].accountName,
              daoname: DAOName,
          }
        }]
      // Call transact on the session (compatible with eosjs.transact)
      const response = await session[0].signTransaction({
        actions: action
      }, {
  
        blocksBehind: 3,
        expireSeconds: 60,
        broadcast: true,
      })
      // Update application state with the responses of the transaction
      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Unstaking your tokens...')
      const timer = setTimeout(() => {
        
          setEnterModalText('Your tokens have been unstaked.');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');
    
      }, 4000);
      return () => clearTimeout(timer);
  
    } catch(e) {
      setEnterModalText(JSON.stringify(e.message));
      console.log(e)
      
    }
  
  } // end if wallet = anchor
  
  
  
  
  
  }




const openRow = async (decimals, tokenSymbol, contract, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {

  setEnterModalDisplay('');
  setEnterModalText('Awaiting confirmation...');

  const walletProvider = localStorage.getItem('walletProvider');

  if(walletProvider == 'wax'){

  // Retrieve current session from state
  const session = await wax.login()
  //console.log(session[0].signTransaction);

  try {
    // Reset our response state to clear any previous transaction data
    
    const action = [{
          account: contract,
          name: 'open',
          authorization: [{
            actor: wax.users[0].accountName,
            permission: wax.users[0].requestPermission,
        }],
        data: {
            owner: wax.users[0].accountName,
            symbol: decimals + ',' + tokenSymbol,
            ram_payer: wax.users[0].accountName,
        }
      }]
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction({
      actions: action
    }, {

      blocksBehind: 3,
      expireSeconds: 60,
      broadcast: true,
    })
    // Update application state with the responses of the transaction
    setLoadingDisplay('');
    setEnterButtonsDisplay('hidden');
    setEnterModalText('Creating your table entry...')
    const timer = setTimeout(() => {
      
        setEnterModalText('Success! You can now receive ' + tokenSymbol + ' tokens');
        setLoadingDisplay('none');
        setEnterButtonsDisplay('');
  
    }, 4000);
    return () => clearTimeout(timer);

  } catch(e) {
    setEnterModalText(JSON.stringify(e.message));
    console.log(e)
    
  }
  
  }//end if wallet = wax


  if(walletProvider == 'anchor'){

    // Retrieve current session from state
    const session = await anchor.login()
    //console.log(session[0].signTransaction);
  
    try {
      // Reset our response state to clear any previous transaction data
      
      const action = [{
            account: contract,
            name: 'open',
            authorization: [{
              actor: anchor.users[0].accountName,
              permission: anchor.users[0].requestPermission,
          }],
          data: {
            owner: anchor.users[0].accountName,
            symbol: decimals + ',' + tokenSymbol,
            ram_payer: anchor.users[0].accountName,
          }
        }]
      // Call transact on the session (compatible with eosjs.transact)
      const response = await session[0].signTransaction({
        actions: action
      }, {

        blocksBehind: 3,
        expireSeconds: 60,
        broadcast: true,
      })
      // Update application state with the responses of the transaction
      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Creating your table entry...')
      const timer = setTimeout(() => {
        
          setEnterModalText('Success! You can now receive ' + tokenSymbol + ' tokens');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');
    
      }, 4000);
      return () => clearTimeout(timer);
  
    } catch(e) {
      setEnterModalText(JSON.stringify(e.message));
      console.log(e)
      
    }
    
    }//end if wallet = anchor



}







const claimRewards = async (DAOName) => {

  const walletProvider = localStorage.getItem('walletProvider');

  if(walletProvider == 'wax'){

  // Retrieve current session from state
  const session = await wax.login()
  //console.log(session[0].signTransaction);

  try {
    // Reset our response state to clear any previous transaction data
    
    const action = [{
          account: 'waxdaofarmer',
          name: 'claimrewards',
          authorization: [{
            actor: wax.users[0].accountName,
            permission: wax.users[0].requestPermission,
        }],
        data: {
            user: wax.users[0].accountName,
            farm: DAOName,
        }
      }]
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction({
      actions: action
    }, {

      blocksBehind: 3,
      expireSeconds: 60,
      broadcast: true,
    })
    // Update application state with the responses of the transaction
    alert('Success! Your rewards have been sent.');
  } catch(e) {
    alert(e)
    console.log(e)
    
  }

}// end if wallet = wax


if(walletProvider == 'anchor'){

  // Retrieve current session from state
  const session = await anchor.login()
  //console.log(session[0].signTransaction);

  try {
    // Reset our response state to clear any previous transaction data
    
    const action = [{
          account: 'waxdaofarmer',
          name: 'claimrewards',
          authorization: [{
            actor: anchor.users[0].accountName,
            permission: anchor.users[0].requestPermission,
        }],
        data: {
            user: anchor.users[0].accountName,
            farm: DAOName,
        }
      }]
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction({
      actions: action
    }, {

      blocksBehind: 3,
      expireSeconds: 60,
      broadcast: true,
    })
    // Update application state with the responses of the transaction
    alert('Success! Your rewards have been sent.');
  } catch(e) {
    alert(e)
    console.log(e)
    
  }

}// end if wallet = anchor

}


const depositRam = async (DAOName, depositAmount, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {

  setEnterModalDisplay('');
  setEnterModalText('Awaiting confirmation...');

  const walletProvider = localStorage.getItem('walletProvider');

  if(walletProvider == 'wax'){

  // Retrieve current session from state
  const session = await wax.login()
  //console.log(session[0].signTransaction);

  try {
    // Reset our response state to clear any previous transaction data
    
    const action = [{
          account: 'eosio.token',
          name: 'transfer',
          authorization: [{
            actor: wax.users[0].accountName,
            permission: wax.users[0].requestPermission,
        }],
        data: {
            to: 'waxdaofarmer',
            from: wax.users[0].accountName,
            quantity: depositAmount + '.00000000 WAX',
            memo: '|deposit_user_farm_ram|' + DAOName + '|',
        }
      }]
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction({
      actions: action
    }, {

      blocksBehind: 3,
      expireSeconds: 60,
      broadcast: true,
    })
    // Update application state with the responses of the transaction
    alert('Success! Your RAM will automatically be sold and returned as Wax when you unstake.');
  } catch(e) {
    alert(e)
    console.log(e)
    
  }
  
  }//end if wallet = wax


  if(walletProvider == 'anchor'){

    // Retrieve current session from state
    const session = await anchor.login()
    //console.log(session[0].signTransaction);
  
    try {
      // Reset our response state to clear any previous transaction data
      
      const action = [{
            account: 'eosio.token',
            name: 'transfer',
            authorization: [{
              actor: anchor.users[0].accountName,
              permission: anchor.users[0].requestPermission,
          }],
          data: {
              to: 'waxdaofarmer',
              from: anchor.users[0].accountName,
              quantity: depositAmount + '.00000000 WAX',
              memo: '|deposit_user_farm_ram|' + DAOName + '|',
          }
        }]
      // Call transact on the session (compatible with eosjs.transact)
      const response = await session[0].signTransaction({
        actions: action
      }, {

        blocksBehind: 3,
        expireSeconds: 60,
        broadcast: true,
      })
      // Update application state with the responses of the transaction
      alert('Success! Your RAM will automatically be sold and returned as Wax when you unstake.');
    } catch(e) {
      alert(e)
      console.log(e)
      
    }
    
    }//end if wallet = anchor



}





const depositTokenRam = async (DAOName, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {

  setEnterModalDisplay('');
  setEnterModalText('Awaiting confirmation...');

    const walletProvider = localStorage.getItem('walletProvider');
  
    if(walletProvider == 'wax'){
  
    // Retrieve current session from state
    const session = await wax.login()
    //console.log(session[0].signTransaction);
  
    try {
      // Reset our response state to clear any previous transaction data
      
      const action = [{
            account: 'eosio.token',
            name: 'transfer',
            authorization: [{
              actor: wax.users[0].accountName,
              permission: wax.users[0].requestPermission,
          }],
          data: {
              to: 'buildawaxdao',
              from: wax.users[0].accountName,
              quantity: '1.00000000 WAX',
              memo: '|deposit_token_ram|' + DAOName + '|',
          }
        }]
      // Call transact on the session (compatible with eosjs.transact)
      const response = await session[0].signTransaction({
        actions: action
      }, {
  
        blocksBehind: 3,
        expireSeconds: 60,
        broadcast: true,
      })
      // Update application state with the responses of the transaction
      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Creating your table entry...')
      const timer = setTimeout(() => {
        
          setEnterModalText('Success! Your RAM will automatically be sold and returned as Wax when you unstake.');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');
    
      }, 4000);
      return () => clearTimeout(timer);
  
    } catch(e) {
      setEnterModalText(JSON.stringify(e.message));
      console.log(e)
      
    }
    
    }//end if wallet = wax
  
  
    if(walletProvider == 'anchor'){
  
      // Retrieve current session from state
      const session = await anchor.login()
      //console.log(session[0].signTransaction);
    
      try {
        // Reset our response state to clear any previous transaction data
        
        const action = [{
              account: 'eosio.token',
              name: 'transfer',
              authorization: [{
                actor: anchor.users[0].accountName,
                permission: anchor.users[0].requestPermission,
            }],
            data: {
                to: 'buildawaxdao',
                from: anchor.users[0].accountName,
                quantity: '1.00000000 WAX',
                memo: '|deposit_token_ram|' + DAOName + '|',
            }
          }]
        // Call transact on the session (compatible with eosjs.transact)
        const response = await session[0].signTransaction({
          actions: action
        }, {
  
          blocksBehind: 3,
          expireSeconds: 60,
          broadcast: true,
        })
        // Update application state with the responses of the transaction
        setLoadingDisplay('');
        setEnterButtonsDisplay('hidden');
        setEnterModalText('Creating your table entry...')
        const timer = setTimeout(() => {
          
            setEnterModalText('Success! Your RAM will automatically be sold and returned as Wax when you unstake.');
            setLoadingDisplay('none');
            setEnterButtonsDisplay('');
      
        }, 4000);
        return () => clearTimeout(timer);
    
      } catch(e) {
        setEnterModalText(JSON.stringify(e.message));
        console.log(e)
        
      }
      
      }//end if wallet = anchor
  
  
  
  }





const depositToTreasury = async (DAOName, treasuryDepositAmount, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {

  setEnterModalDisplay('');
  setEnterModalText('Awaiting confirmation...');

  const walletProvider = localStorage.getItem('walletProvider');

  if(walletProvider == 'wax'){

  // Retrieve current session from state
  const session = await wax.login()
  //console.log(session[0].signTransaction);

  try {
    // Reset our response state to clear any previous transaction data
    
    const action = [{
          account: 'eosio.token',
          name: 'transfer',
          authorization: [{
            actor: wax.users[0].accountName,
            permission: wax.users[0].requestPermission,
        }],
        data: {
            to: 'buildawaxdao',
            from: wax.users[0].accountName,
            quantity: treasuryDepositAmount + '.00000000 WAX',
            memo: '|treasury|' + DAOName + '|',
        }
      }]
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction({
      actions: action
    }, {

      blocksBehind: 3,
      expireSeconds: 60,
      broadcast: true,
    })
    // Update application state with the responses of the transaction
    setLoadingDisplay('');
    setEnterButtonsDisplay('hidden');
    setEnterModalText('Creating your table entry...')
    const timer = setTimeout(() => {
      
        setEnterModalText('Your funds have been deposited to the treasury. They can only be retrieved with a DAO vote');
        setLoadingDisplay('none');
        setEnterButtonsDisplay('');
  
    }, 4000);
    return () => clearTimeout(timer);

  } catch(e) {
    setEnterModalText(JSON.stringify(e.message));
    console.log(e)
    
  }
  
  }//end if wallet = wax


  if(walletProvider == 'anchor'){

    // Retrieve current session from state
    const session = await anchor.login()
    //console.log(session[0].signTransaction);
  
    try {
      // Reset our response state to clear any previous transaction data
      
      const action = [{
            account: 'eosio.token',
            name: 'transfer',
            authorization: [{
              actor: anchor.users[0].accountName,
              permission: anchor.users[0].requestPermission,
          }],
          data: {
              to: 'buildawaxdao',
              from: anchor.users[0].accountName,
              quantity: treasuryDepositAmount + '.00000000 WAX',
              memo: '|treasury|' + DAOName + '|',
          }
        }]
      // Call transact on the session (compatible with eosjs.transact)
      const response = await session[0].signTransaction({
        actions: action
      }, {

        blocksBehind: 3,
        expireSeconds: 60,
        broadcast: true,
      })
      // Update application state with the responses of the transaction
      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Creating your table entry...')
      const timer = setTimeout(() => {
        
          setEnterModalText('Your funds have been deposited to the treasury. They can only be retrieved with a DAO vote');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');
    
      }, 4000);
      return () => clearTimeout(timer);
  
    } catch(e) {
      setEnterModalText(JSON.stringify(e.message));
      console.log(e)
      
    }
    
    }//end if wallet = anchor



}



const DAOPage = ({ location }) => {

  const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode, currentUser, 
    setCurrentUser, tokenName, setTokenName, maxSupply, setMaxSupply, decimals, setDecimals, 
    tokenString, setTokenString, farmData, setFarmData, farmCollection, setFarmCollection, farmSchema, 
    setFarmSchema, farmRewards, setFarmRewards, farmExpires, setFarmExpires, farmIcon, setFarmIcon, 
    farmContract, setFarmContract, createdBy, setCreatedBy, unstaked, setUnstaked, stakedOrUnstaked, 
    setStakedOrUnstaked, stakedDisplay, setStakedDisplay, unstakedDisplay, setUnstakedDisplay, validAssets, 
    setValidAssets, commaSeparated, setCommaSeparated, stakedAssets, setStakedAssets, claimable, 
    setClaimable, minTime, setMinTime, depositAmount, setDepositAmount, depositDisplay, setDepositDisplay, 
    totalStaked, setTotalStaked, ramBalance, setRamBalance, ramInfoDisplay, setRamInfoDisplay, farmTemplates, 
    setFarmTemplates, templatesDisplay, setTemplatesDisplay, farmType, setFarmType, nftsCoveredByRam, 
    setNftsCoveredByRam, hourlyPool, setHourlyPool, schemaDisplay, setSchemaDisplay, includeOrExcludeMessage, 
    setIncludeOrExcludeMessage, templateToView, setTemplateToView, refreshAssets, setRefreshAssets, 
    nftDaoDisplay, setNftDaoDisplay, stakeTokensDisplay, setStakeTokensDisplay, unstakeTokensDisplay, 
    setUnstakeTokensDisplay, proposalList, setProposalList, newProposalDisplay, setNewProposalDisplay, 
    newProposalText, setNewProposalText, proposalType, setProposalType, proposalTypeValue, setProposalTypeValue,
    choicesList, setChoicesList, description, setDescription, selectedOption, setSelectedOption, proposalTitle,
    setProposalTitle, aboutDaoDisplay, setAboutDaoDisplay, proposalsDisplay, setProposalsDisplay,
    newProposalContainerDisplay, setNewProposalContainerDisplay, walletDisplay, setWalletDisplay, minimumVotes,
    setMinimumVotes, threshold, setThreshold, proposerType, setProposerType, proposers, setProposers,
    choicesDisplay, setChoicesDisplay, receiverDisplay, setReceiverDisplay, receivingAccount, setReceivingAccount,
    proposalSymbol,
    proposalAmount,
    proposalDecimals,
    proposalContract,
    setProposalSymbol,
    setProposalAmount,
    setProposalDecimals,
    setProposalContract,
    treasuryAssets, setTreasuryAssets,
    treasuryDepositAmount,
    setTreasuryDepositAmount,
    treasuryDepositDisplay,
    setTreasuryDepositDisplay,
    voteDuration,
    setVoteDuration,
    poolName,
    setPoolName,
    minWeight,
    setMinWeight,
    enterModalDisplay,
    setEnterModalDisplay,
    enterModalText,
    setEnterModalText,
    loadingDisplay,
    setLoadingDisplay,
    enterButtonsDisplay,
    setEnterButtonsDisplay,

    } = useStateContext();

    const inputRef = useRef();


const { DAOName } = useParams();

useEffect(() => {

  const abortCont = new AbortController();
  const abortCont2 = new AbortController();
  const abortCont3 = new AbortController();
  const abortCont4 = new AbortController();

  setMinWeight('');
  setStakedAssets([]);
  setUnstaked([]);
  setValidAssets([]);
  setCommaSeparated('');
  setMinTime('');
  setFarmTemplates([]);
  setTemplatesDisplay('hidden');
  setHourlyPool('');
  setSchemaDisplay('hidden');
  setTemplateToView('any');
  setMinimumVotes('');
  setThreshold('');
  setProposerType('');
  setProposers([]);
  setVoteDuration('');
  setPoolName('');

  const waxUsername = localStorage.getItem('waxAccount');
  
  if(waxUsername){
    //console.log('You have an active session');
    '';
    //setProfileDisplay('flex');
  }else{
    //console.log('You are not logged in');
    '';     //return('');
  }



  axios.post('https://wax.pink.gg/v1/chain/get_table_rows',{
    table:"daos",
    scope:"buildawaxdao",
    code:"buildawaxdao",
    limit:1,
    key_type: 'name',
    index_position: 2,
    lower_bound:DAOName,
    upper_bound:DAOName,
    json:true
  })
    .then((response) => {

        console.log(response.data.rows[0].staketype);

      setFarmData(response);
      setFarmCollection(response.data.rows[0].collection);
      setFarmSchema(response.data.rows[0].schema);
      setFarmIcon(response.data.rows[0].logo);
      setCreatedBy(response.data.rows[0].creator);
      setFarmRewards(response.data.rows[0].poolsize);
      setHourlyPool(response.data.rows[0].hourlyreward);
      setFarmContract(response.data.rows[0].contract);
      setFarmExpires(response.data.rows[0].enddate);
      setMinTime(response.data.rows[0].mintime);
      setTotalStaked(response.data.rows[0].totalstaked);
      setFarmType(response.data.rows[0].staketype);
      setTokenName(response.data.rows[0].tokensymbol.substr(response.data.rows[0].tokensymbol.indexOf(',') + 1));
      setDecimals(response.data.rows[0].tokensymbol.substr(0, response.data.rows[0].tokensymbol.indexOf(',')));
      var daocontract = response.data.rows[0].contract;
      setMinimumVotes(response.data.rows[0].minimumvotes);
      var daosymbol = response.data.rows[0].tokensymbol.substr(response.data.rows[0].tokensymbol.indexOf(',') + 1);
      setThreshold(response.data.rows[0].threshold);
      setProposerType(response.data.rows[0].proposer_type);
      setProposers(response.data.rows[0].proposers);
      setVoteDuration(response.data.rows[0].length);
      setPoolName(response.data.rows[0].poolname);
      setMinWeight(response.data.rows[0].minweight);
      



      if(response.data.rows[0].staketype == "token"){

        setNftDaoDisplay('hidden');
       

        if(waxUsername){
  
        axios.post('https://wax.pink.gg/v1/chain/get_table_rows',{
            table:"accounts",
            scope: waxUsername,
            code: daocontract,
            limit:100,
            lower_bound:daosymbol,
            upper_bound:daosymbol,
            json:true
          })
        .then((atomicResponse) => {
          setUnstaked(atomicResponse.data.rows[0].balance);
          console.log(atomicResponse.data.rows[0]);
 
        })
          
  
        
  
  
  
        .catch((error) => console.log(error));
  
  
      }


      } //end if farmtype == token




      if(response.data.rows[0].farmtype == 'template'){
        setFarmTemplates(response.data.rows[0].templates);
        //setFarmTemplates(['526134']);
        setTemplatesDisplay('flex gap-4');
        setIncludeOrExcludeMessage('Stakeable');


        if(waxUsername){
          //console.log(response.data.rows[0].collection);
          //if the farm type is "template", only search for assets with these template IDs
  
  
        axios.get(`https://atomicassets.ledgerwise.io/atomicassets/v1/assets?collection_name=${response.data.rows[0].collection}&owner=${waxUsername}&page=1&limit=1000&order=desc&sort=asset_id`)
        .then((atomicResponse) => {
          setUnstaked(atomicResponse.data.data);
          console.log(waxUsername);
          console.log(atomicResponse.data.data);
          //console.log(atomicResponse.data.data);
          //console.log(waxUsername);
        }).then(() => {
          axios.post('https://wax.pink.gg/v1/chain/get_table_rows',{
            table:"stakerzz",
            scope:"waxdaofarmer",
            code:"waxdaofarmer",
            key_type: 'name',
            index_position: 2,
            limit:100,
            lower_bound:waxUsername,
            upper_bound:waxUsername,
            json:true
          }).then((farmVectors) => {
            var itr = 0;
  
            while(itr < farmVectors.data.rows.length){
              //console.log("Size: " + farmVectors.data.rows[itr].assets)
              if(farmVectors.data.rows[itr].DAOName == DAOName && farmVectors.data.rows[itr].assets != ""){
                setValidAssets(farmVectors.data.rows[itr].assets);
                //console.log(farmVectors.data.rows[itr].assets);
                
  
                axios.get(`https://wax.api.atomicassets.io/atomicassets/v1/assets?ids=${farmVectors.data.rows[itr].assets.join("%2C")}&page=1&limit=1000&order=desc&sort=asset_id`)
                .then((validResponse) => {

                  setStakedAssets(validResponse.data.data);
                  //console.log(validResponse.data.data);
                 
                })
  
  
                //console.log(farmVectors.data.rows[itr].assets);
                break;
              }
              else{
              itr ++;
              }
            }
            
            //console.log(farmVectors.data.rows.length);
            
  
          })
  
        })
  
  
  
        .catch((error) => console.log(error));
  
  
      }


      } //end if farmtype == template



      if(response.data.rows[0].farmtype == 's.exclude'){

        setSchemaDisplay('');

        setFarmTemplates(response.data.rows[0].templates);
        //console.log('it does');
        setTemplatesDisplay('flex gap-4');
        setIncludeOrExcludeMessage('Excluded');

        if(waxUsername){

  
  
        axios.get(`https://wax.api.atomicassets.io/atomicassets/v1/assets?collection_name=${response.data.rows[0].collection}&schema_name=${response.data.rows[0].schema}&owner=${waxUsername}&page=1&limit=1000&order=desc&sort=asset_id`)
        .then((atomicResponse) => {
          setUnstaked(atomicResponse.data.data);
          //console.log(atomicResponse.data.data);
 
        }).then(() => {
          axios.post('https://wax.pink.gg/v1/chain/get_table_rows',{
            table:"stakerzz",
            scope:"waxdaofarmer",
            code:"waxdaofarmer",
            key_type: 'name',
            index_position: 2,
            limit:100,
            lower_bound:waxUsername,
            upper_bound:waxUsername,
            json:true
          }).then((farmVectors) => {
            var itr = 0;
  
            while(itr < farmVectors.data.rows.length){
              if(farmVectors.data.rows[itr].DAOName == DAOName && farmVectors.data.rows[itr].assets != ""){
                setValidAssets(farmVectors.data.rows[itr].assets);
                //console.log("assets: " + farmVectors.data.rows[itr].assets);
  
                axios.get(`https://wax.api.atomicassets.io/atomicassets/v1/assets?ids=${farmVectors.data.rows[itr].assets.join("%2C")}&page=1&limit=1000&order=desc&sort=asset_id`)
                .then((validResponse) => {

                  setStakedAssets(validResponse.data.data);
                 
                })

                break;
              }
              else{
              itr ++;
              }
            } //end while loop
            
            
  
          })
  
        })
  
  
  
        .catch((error) => console.log(error));
  
  
      }


      } //end if farmtype == s.exclude











      if(response.data.rows[0].farmtype == 'schema'){

        setSchemaDisplay('');

        if(waxUsername){

  
  
        axios.get(`https://wax.api.atomicassets.io/atomicassets/v1/assets?collection_name=${response.data.rows[0].collection}&schema_name=${response.data.rows[0].schema}&owner=${waxUsername}&page=1&limit=1000&order=desc&sort=asset_id`)
        .then((atomicResponse) => {
          setUnstaked(atomicResponse.data.data);
          //console.log(atomicResponse.data.data);
 
        }).then(() => {
          axios.post('https://wax.pink.gg/v1/chain/get_table_rows',{
            table:"stakerzz",
            scope:"waxdaofarmer",
            code:"waxdaofarmer",
            key_type: 'name',
            index_position: 2,
            limit:100,
            lower_bound:waxUsername,
            upper_bound:waxUsername,
            json:true
          }).then((farmVectors) => {
            var itr = 0;
  
            while(itr < farmVectors.data.rows.length){
              if(farmVectors.data.rows[itr].DAOName == DAOName && farmVectors.data.rows[itr].assets != ""){
                setValidAssets(farmVectors.data.rows[itr].assets);
  
                axios.get(`https://wax.api.atomicassets.io/atomicassets/v1/assets?ids=${farmVectors.data.rows[itr].assets.join("%2C")}&page=1&limit=1000&order=desc&sort=asset_id`)
                .then((validResponse) => {

                  setStakedAssets(validResponse.data.data);
                 
                })

                break;
              }
              else{
              itr ++;
              }
            }
            
            
  
          })
  
        })
  
  
  
        .catch((error) => console.log(error));
  
  
      }


      } //end if farmtype == schema


      if(response.data.rows[0].farmtype == 'collection'){

        if(waxUsername){

  
  
        axios.get(`https://wax.api.atomicassets.io/atomicassets/v1/assets?collection_name=${response.data.rows[0].collection}&owner=${waxUsername}&page=1&limit=1000&order=desc&sort=asset_id`)
        .then((atomicResponse) => {
          setUnstaked(atomicResponse.data.data);
          console.log(atomicResponse.data.data);
 
        }).then(() => {
          axios.post('https://wax.pink.gg/v1/chain/get_table_rows',{
            table:"stakerzz",
            scope:"waxdaofarmer",
            code:"waxdaofarmer",
            key_type: 'name',
            index_position: 2,
            limit:100,
            lower_bound:waxUsername,
            upper_bound:waxUsername,
            json:true
          }).then((farmVectors) => {
            var itr = 0;
  
            while(itr < farmVectors.data.rows.length){
              if(farmVectors.data.rows[itr].DAOName == DAOName && farmVectors.data.rows[itr].assets != ""){
                setValidAssets(farmVectors.data.rows[itr].assets);
  
                axios.get(`https://wax.api.atomicassets.io/atomicassets/v1/assets?ids=${farmVectors.data.rows[itr].assets.join("%2C")}&page=1&limit=1000&order=desc&sort=asset_id`)
                .then((validResponse) => {

                  setStakedAssets(validResponse.data.data);
                 
                })

                break;
              }
              else{
              itr ++;
              }
            }
            
            
  
          })
  
        })
  
  
  
        .catch((error) => console.log(error));
  
  
      }


      } //end if farmtype == collection

     


     

    })
    .catch((error) => console.log(error));

}, [refreshAssets]);








useEffect(() => {

setClaimable([]);
  const abortCont5 = new AbortController();

  const waxUsername = localStorage.getItem('waxAccount');
  
  if(waxUsername){
    //console.log('You have an active session');
    '';
    //setProfileDisplay('flex');
  }else{
    //console.log('You are not logged in');
    return('');
  }



  axios.post('https://wax.pink.gg/v1/chain/get_table_rows',{
    table:"tokenpower",
    scope:"buildawaxdao",
    code:"buildawaxdao",
    key_type: 'name',
    index_position: 2,
    limit:100,
    lower_bound:waxUsername,
    upper_bound:waxUsername,
    json:true
  }).then((claimableResponse) => {
    var claimitr = 0;

    while(claimitr < claimableResponse.data.rows.length){
      if(claimableResponse.data.rows[claimitr].daoname == DAOName){
        setClaimable(claimableResponse.data.rows[claimitr].stakeweight);
        setRamBalance(claimableResponse.data.rows[claimitr].ram_balance);

          //console.log(claimableResponse);

        break;
      }
      else{
        claimitr ++;
      }
    }


  })

.catch((error) => console.log(error));

//return() => abortCont5.abort();

}, []);



useEffect(() => {

    setProposalList([]);
      const abortCont7 = new AbortController();
    
      const waxUsername = localStorage.getItem('waxAccount');
          
      axios.post('https://wax.pink.gg/v1/chain/get_table_rows',{
        table:"proposals",
        scope:"buildawaxdao",
        code:"buildawaxdao",
        key_type: 'name',
        index_position: 2,
        limit:100,
        lower_bound:DAOName,
        upper_bound:DAOName,
        json:true
      }).then((proposalResponse) => {


           setProposalList(proposalResponse.data.rows);
           console.log(proposalResponse);
    
    
      })
    
    .catch((error) => console.log(error));
    
    //return() => abortCont5.abort();
    
    }, []);


    useEffect(() => {

      setTreasuryAssets([]);
        const abortCont8 = new AbortController();
      
        const waxUsername = localStorage.getItem('waxAccount');
            
        axios.post('https://wax.pink.gg/v1/chain/get_table_rows',{
          table:"cointreasury",
          scope:"buildawaxdao",
          code:"buildawaxdao",
          key_type: 'name',
          index_position: 2,
          limit:100,
          lower_bound:DAOName,
          upper_bound:DAOName,
          json:true
        }).then((treasuryResponse) => {
  
  
             setTreasuryAssets(treasuryResponse.data.rows);
             console.log(treasuryResponse.data.rows);
      
      
        })
      
      .catch((error) => console.log(error));
      
      //return() => abortCont5.abort();
      
      }, []);


  return (





    <div id="seo">
    <Helmet>
    <title>{DAOName} DAO - WAX Blockchain</title>
    <meta name="description" content={`Votes, propsals and details for ${DAOName} DAO on WaxDao`} />
    <link rel="canonical" href={`https://waxdao.io/daos/${DAOName}`} />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor, wax]} appName={"WaxDAO"}>

    <FarmTopMenu>
    <FarmTopMenuItem  onClick={() => {setAboutDaoDisplay('hidden'); setProposalsDisplay(''); setNewProposalContainerDisplay('hidden'); setWalletDisplay('hidden'); } }>
      Proposals
    </FarmTopMenuItem>
    <FarmTopMenuItem  onClick={() => {setAboutDaoDisplay('hidden'); setProposalsDisplay('hidden'); setNewProposalContainerDisplay(''); setWalletDisplay('hidden');} }>
      New Proposal
    </FarmTopMenuItem>
    <FarmTopMenuItem  onClick={() => {setAboutDaoDisplay(''); setProposalsDisplay('hidden'); setNewProposalContainerDisplay('hidden'); setWalletDisplay('hidden');} }>
      About
    </FarmTopMenuItem>
    <FarmTopMenuItem  onClick={() => {setAboutDaoDisplay('hidden'); setProposalsDisplay('hidden'); setNewProposalContainerDisplay('hidden'); setWalletDisplay('');} }>
      Wallet
    </FarmTopMenuItem>


    </FarmTopMenu>

    <MainContainer>

    <Modal className={enterModalDisplay}>
      <ModalContent>
        {enterModalText}
        
      </ModalContent>

        <Spinner style={{display:loadingDisplay}}>
        <SpinnerRed  />
        <SpinnerBlue  />
        <SpinnerGreen />
        </Spinner>

      <br/>
      <GameButton onClick={() => setEnterModalDisplay('hidden') }>
        Close Window
      </GameButton>
    </Modal>
   
<LeftDiv className={activeMenu ? 'hidden' : ''}>

<img src={`https://ipfs.io/ipfs/${farmIcon}`} 
    alt={`${DAOName} Logo`} 
    style={{ width:'150px', 
      height:'150px',
      maxWidth:'150px',
      maxHeight:'150px',
      marginLeft:'auto',
      marginRight:'auto',
      marginTop:'15px'
    }}
    className="rounded-full hover:drop-shadow-xl"      
  />

<DaoName>
{DAOName != null && DAOName}
</DaoName>

<Creator>
{createdBy != null && (

    <span>By {createdBy}</span> 

)} 
</Creator>


<DaoMenu>

    <DaoMenuItem onClick={() => {setAboutDaoDisplay('hidden'); setProposalsDisplay(''); setNewProposalContainerDisplay('hidden'); setWalletDisplay('hidden'); } }>
        Proposals
    </DaoMenuItem>

    <DaoMenuItem onClick={() => {setAboutDaoDisplay('hidden'); setProposalsDisplay('hidden'); setNewProposalContainerDisplay(''); setWalletDisplay('hidden');} }>
        New Proposal
    </DaoMenuItem>

    <DaoMenuItem onClick={() => {setAboutDaoDisplay(''); setProposalsDisplay('hidden'); setNewProposalContainerDisplay('hidden'); setWalletDisplay('hidden');} }>
        About
    </DaoMenuItem>

    <DaoMenuItem onClick={() => {setAboutDaoDisplay('hidden'); setProposalsDisplay('hidden'); setNewProposalContainerDisplay('hidden'); setWalletDisplay('');} }>
        Wallet
    </DaoMenuItem>


</DaoMenu>


</LeftDiv>

<RightDiv>

{(farmType == 'token' || farmType == 'tokenpool' || farmType == 'nftfarm') ? (



<>

<AboutDAO className={aboutDaoDisplay}>

    <DaoTitle>
        About This DAO
    </DaoTitle>

    <AboutDaoCont>

        <AboutDaoTitle>
            Creator
        </AboutDaoTitle>

        <AboutDaoBody>
            {createdBy}
        </AboutDaoBody>

        <AboutDaoTitle>
            Dao Type
        </AboutDaoTitle>

        <AboutDaoBody>
            {farmType}
        </AboutDaoBody>

        <AboutDaoTitle>
            Governance Asset(s)
        </AboutDaoTitle>

        <AboutDaoBody>
            {(farmType == "token" || farmType == "tokenpool") && (
                <span>
                    {tokenName}@{farmContract}
                </span>
            )}

            {(farmType == "nftfarm") && (
                <span>
                    Stake NFTs in the <a href={`https://waxdao.io/farm/${poolName}`}>{poolName} farm</a> for voting power
                </span>
            )}
        </AboutDaoBody>

        <AboutDaoTitle>
            Treasury Assets
        </AboutDaoTitle>

        <AboutDaoBody>
            {treasuryAssets.length > 0 && (
              <TemplatesCont>
              {treasuryAssets.map((item, index) => (
              <SingleTemplate key={index}>
                {item.amount}
              </SingleTemplate>
              ))}
              </TemplatesCont>
            )}
      
          {treasuryAssets.length == 0 && 
              'This DAO has no assets in its treasury'
            }
        </AboutDaoBody>

        <AboutDaoTitle>
            Voting Period
        </AboutDaoTitle>

        <AboutDaoBody>
            {voteDuration != null && voteDuration / 86400} Days
        </AboutDaoBody>

        <AboutDaoTitle>
            Minimum Staking Period
        </AboutDaoTitle>

        <AboutDaoBody>
            {minTime / 86400} Days
        </AboutDaoBody>

        <AboutDaoTitle>
            Vote Threshold
        </AboutDaoTitle>

        <AboutDaoBody>
            {Math.round(threshold)}% required for a proposal to pass
        </AboutDaoBody>

        <AboutDaoTitle>
            Minimum Votes
        </AboutDaoTitle>

        <AboutDaoBody>
            {farmType != null && farmType == "token" && decimals != null && decimals == '0' && (
                <span>
                    Proposals with less than {minimumVotes} {tokenName} voted will be considered invalid
                </span>
            )}

            {farmType != null && farmType == "token" && decimals != null && decimals != '0' && (
                <span>
                    Proposals with less than <NumberFormat value={Math.round(minimumVotes)} displayType="text" thousandSeparator={true}></NumberFormat> {tokenName} voted will be considered invalid
                </span>
            )}

            {farmType != null && farmType == "tokenpool" && decimals != null && decimals == '0' && (
                <span>
                    Proposals with less than {minimumVotes} {tokenName} voted will be considered invalid
                </span>
            )}

            {farmType != null && farmType == "tokenpool" && decimals != null && decimals != '0' && (
                <span>
                    Proposals with less than <NumberFormat value={Math.round(minimumVotes)} displayType="text" thousandSeparator={true}></NumberFormat> {tokenName} voted will be considered invalid
                </span>
            )}

            {farmType != null && farmType == "nftfarm" && (
                <span>
                    Proposals with less than <NumberFormat value={Math.round(minimumVotes)} displayType="text" thousandSeparator={true}></NumberFormat> NFTs staked will be considered invalid
                </span>
            )}
        </AboutDaoBody>

    </AboutDaoCont>

</AboutDAO>


<Proposals className={proposalsDisplay}>

<DaoTitle>
    Proposals
</DaoTitle>


{/* MAP OUT PROPOSALS HERE */}

{ proposalList.length > 0 ?  [...proposalList].reverse().map((item, index) => (



<SingleProposal key={index}>

    <a href={`https://waxdao.io/proposals/${item.ID}`}>

    <ProposalTitle>
        {item.title}
    </ProposalTitle>

    <ProposalAuthor>
        by {item.proposer}
    </ProposalAuthor>

    <ProposalDescription>
        {item.description}
    </ProposalDescription>

    <ProposalButtonContainer>

        <ProposalButton>
            {item.status[0].toUpperCase() + item.status.substring(1)}, {item.outcome[0].toUpperCase() + item.outcome.substring(1, item.outcome.indexOf(' ') > -1 ? item.outcome.indexOf(' ') : item.outcome.length)}
        </ProposalButton>

        <ProposalButton>
            {item.proposal_type}
        </ProposalButton>

        <ProposalButton>
            ID #{item.ID}
        </ProposalButton>

    </ProposalButtonContainer>

    </a>

</SingleProposal>

)

) : 'There are no proposals for this DAO'}


</Proposals>


<NewProposal className={newProposalContainerDisplay}>

<DaoTitle>
    Create New Proposal
</DaoTitle>


{proposerType != null && proposerType == 'usernames' && (
    <ProposerTypeMessage>
        Only the following WAX accounts can create proposals for this DAO:
        <ProposerList>
            {proposers != null && proposers.map((item, index) => (
                <span>{item}{index < proposers.length -1 && ', '} </span>
            ))}
        </ProposerList>
    </ProposerTypeMessage>

)}

{farmType != null && farmType == "nftfarm" && proposerType != null && proposerType == 'stakeweight' && (
    <ProposerTypeMessage>
        You must have at least {minWeight != null && minWeight} NFTs staked to create a proposal
    </ProposerTypeMessage>

)}



<br />

         <form>
        <DropDown         
            onChange={(e) => {
                if(e.target.value == "standard"){ setChoicesDisplay(''); setReceiverDisplay('hidden'); } 
                if(e.target.value == "tkn.transfer"){ setChoicesDisplay('hidden'); setReceiverDisplay(''); } 
                
                setProposalType(e.target.value);
                setProposalTypeValue(e.target.value);
            }}>
        <option value="" hidden>
          Proposal Type
        </option>
        <option value="standard">Standard</option>
        <option value="tkn.transfer">Transfer Tokens</option>
        </DropDown>
        </form>


    <label htmlFor="propTitle" ><br />Proposal Title: <br /></label>
      <ProposalInput
      type="text"
      maxLength={40}
      id="propTitle" 
      onChange={(e) => {
        setProposalTitle(e.target.value);
      }}
      />



    <label htmlFor="description" ><br />Description: <br /></label>
      <PropDescription
      placeholder="What is the reason for this proposal?"
      rows={4}
      id="description" 
      onChange={(e) => {
        setDescription(e.target.value);
      }}
      />


<span className={choicesDisplay}>
<p className="font-semibold mt-4">
    Choices
</p>

{choicesList.map((singleChoice,index) => (


<div key={index} className="choices">

        <ProposalChoices
        placeholder={`Option ${index}`}
        rows={4}
        name="choice"
        id="choice" 
        value={singleChoice.choice}
        onChange={(e) => handleChoiceChange(e, index, choicesList, setChoicesList)}
        />


{choicesList.length > 1 && 

<RemoveButton 
onClick={() => {
    handleChoiceRemove(setChoicesList, choicesList, index);
}}
>
&nbsp; - &nbsp;
</RemoveButton>


}







{choicesList.length - 1 === index && choicesList.length < 4 && 
        <span>
            <br/>
        <AddButton 

            onClick={() => {
                handleChoiceAdd(setChoicesList, choicesList);
            }}

            
            >
            &nbsp; + &nbsp;
        </AddButton>
        </span>

}




</div>

)


)}

</span>

<span className={receiverDisplay}>
<p className="font-semibold mt-4">Receiving Account:</p>
      <ProposalInput
      type="text"
      maxLength={12}
      value={receivingAccount}
      id="propReceiver" 
      onChange={(e) => {
        setReceivingAccount(e.target.value.replace(/[' ']/g, ''));
      }}
      />

<label htmlFor="description" ><br />Amount To Send: <br /></label>
      <ProposalInput
      id="description" 
      value={proposalAmount}
      type="text"
      onChange={(e) => {
        setProposalAmount(Math.round(e.target.value.replace(/[' ']/g, '')));
      }}
      />

<label htmlFor="description" ><br />Token Symbol: <br /></label>
      <ProposalInput
      id="description"
      maxLength={7} 
      value={proposalSymbol}
      onChange={(e) => {
        setProposalSymbol(e.target.value.toUpperCase());
      }}
      />

<label htmlFor="description" ><br />Token Precision: <br /></label>
      <ProposalInput
      id="description" 
      type="text"
      value={proposalDecimals}
      onChange={(e) => {
        setProposalDecimals(e.target.value.replace(/[' ']/g, ''));
      }}
      />

<label htmlFor="description" ><br />Token Contract: <br /></label>
      <ProposalInput
      id="description" 
      maxLength={12}
      value={proposalContract}
      onChange={(e) => {
        setProposalContract(e.target.value.replace(/[' ']/g, ''));
      }}
      />




</span>

<br/>



<br/>
        <SubmitButton 
        onClick={() => {
            submitProposal(DAOName, proposalTypeValue, choicesList, description, proposalTitle, proposalAmount, proposalSymbol, proposalDecimals, proposalContract, receivingAccount, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay);
        }}        
        >
          Submit Proposal
        </SubmitButton>





</NewProposal>


<Wallet className={walletDisplay}>

<DaoTitle>
    My Wallet
</DaoTitle>



{(farmType != "tokenpool" && farmType != "nftfarm" ) && (

<DaoButtonCont>
    <DaoButton 
        onClick={() => {    
        setStakeTokensDisplay('');
        setUnstakeTokensDisplay('hidden');
        setTreasuryDepositDisplay('hidden');
    }}
    >
        Stake {tokenName}
    </DaoButton>

    <DaoButton 
        onClick={() => {
            setStakeTokensDisplay('hidden');
            setUnstakeTokensDisplay('');
            setTreasuryDepositDisplay('hidden');
        }}
    >
        Unstake
    </DaoButton>

    <DaoButton onClick={() => openRow(decimals, tokenName, farmContract, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay)}>
        Open {tokenName} Row
    </DaoButton>

    </DaoButtonCont>

)}



<DaoButtonCont>
<DaoButton onClick={() => depositTokenRam(DAOName, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay)} >
        Deposit RAM
    </DaoButton>
    

    <DaoButton onClick={() => {
                  setStakeTokensDisplay('hidden');
                  setUnstakeTokensDisplay('hidden');
                  setTreasuryDepositDisplay('');
                 }
                }>
        Treasury Deposit
    </DaoButton>

</DaoButtonCont>

<div className={`${stakeTokensDisplay} mt-4`}>
    <StakedAmount>
        Balance: {unstaked}
    </StakedAmount>


    <label htmlFor="ram" ><br />Amount To Stake:<br /></label>
      <ProposalInput type="number" 
      id="ram" 
      value={depositAmount}
      onChange={(e) => {
        setDepositAmount(Math.round(e.target.value));
      }}
      />
      <br/>

        <DaoButton onClick={() => stakeTokens(DAOName, depositAmount, farmContract, tokenName, decimals, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay)} >
            Stake Now
        </DaoButton>
</div>


<div className={`${unstakeTokensDisplay} mt-4`}>
    <StakedAmount>
        Staked: {claimable}
    </StakedAmount>

    <DaoButton onClick={() => { unstakeTokens(DAOName, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay); }} >
        Unstake All
    </DaoButton>
</div>


<div className={`${treasuryDepositDisplay} mt-4`}>

<ProposerTypeMessage>
  Only WAX deposits are supported at this time.<br/><br/>
  How many WAX would you like to add to the treasury?
</ProposerTypeMessage>


<label htmlFor="treasuryAmt" ><br />Amount To Deposit:<br /></label>
      <ProposalInput type="number" 
      id="treasuryAmt" 
      value={treasuryDepositAmount}
      onChange={(e) => {
        setTreasuryDepositAmount(Math.round(e.target.value));
      }}
      />
      <br/>

    <DaoButton onClick={() => { depositToTreasury(DAOName, treasuryDepositAmount, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay); }} >
        Deposit Now
    </DaoButton>
</div>



</Wallet>

</>


) : ''}

{/* END IF FARM TYPE == TOKEN */}











    </RightDiv>
    </MainContainer>
    <br/><br/><br/>
    </UALProvider>
    </div>
  )
}

export default DAOPage