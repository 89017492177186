import React, { useEffect } from 'react';
import { GridComponent, Resize, Sort, ContextMenu, Filter, Page, ExcelExport, PdfExport, Edit, Inject, Search, Toolbar } from '@syncfusion/ej2-react-grids';
import { ordersData, contextMenuItems, ordersGrid, nftFarmData, nftFarmGrid, myFarmsGrid, depositTokens } from '../../data/dummy';
import { Header } from '../../components';
import { ColumnDirective, ColumnsDirective } from '@syncfusion/ej2-react-charts';
import { useParams } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import { Anchor } from 'ual-anchor';
import { Wax } from '@eosdacio/ual-wax';
import { User } from "universal-authenticator-library";
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { Api, JsonRpc } from "eosjs";
import {Helmet} from "react-helmet";
import axios from 'axios';
import { data } from 'autoprefixer';
import { Name, raw } from "eos-common";
import { ProposerTypeMessage, DaoButton, NFT, NewRow, BuyWojak, ProposalInput, NewProposal, Modal, ModalContent, Spinner, SpinnerRed, SpinnerBlue, SpinnerGreen, GameButton, DisabledButton, MainContainer, CreateDaoCont, DaoTitle, CreateFarmMsg, DropDown, UserWhitelist, BurnableCont, MissingInfoMsg, PaymentOptCont, NoDaosMessage, StakeManyButton, NFTLogo, NFTsCont, PoolAssetsTitle } from '../../components/LeftDiv';
import NumberFormat from 'react-number-format';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { DatePickerComponent, DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import '.././datepicker.css';

function getRndApi() {
  const apiList = ["https://wax.greymass.com", "https://wax.pink.gg", "https://wax.eosrio.io"];
  return apiList[Math.floor(Math.random() * (2 - 0) ) + 0];
}


const apiList = ["https://eos.api.eosnation.io", "https://api.eoseoul.io", "https://eos.eosphere.io"];

const atomicApiList = ["https://eos.api.atomicassets.io"];



let zero = '0';

const myChain = {
  chainId: 'aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906',
  rpcEndpoints: [{
      protocol: 'https',
      host: 'eos.greymass.com',
      port: '443'
  }]
};
const wtf = ['526134'];
const rpc = new JsonRpc('https://eos.greymass.com');


const anchor = new Anchor([myChain], {

  appName: 'WaxDAO',

});



const handleRemoveAsset = (asset_id, assetVector, setAssetVector) => {

  // remove the asset
  const assetList = [...assetVector];
  assetList.splice(assetList.indexOf(asset_id), 1);
  setAssetVector(assetList);
  console.log('handled removing it');
  


}




const handleAddAsset = (asset_id, assetVector, setAssetVector) => {


      setAssetVector([...assetVector, asset_id])
      console.log('handled adding it');
      



}





const authorizeWaxDAO = async (collection, setEnterModalText, setLoadingDisplay, 
    setEnterButtonsDisplay, setEnterModalDisplay) => {

    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
    const walletProvider = localStorage.getItem('eosWalletProvider');

    if(walletProvider != 'anchor'){

      setEnterModalText('You are not logged in. Click "Wallet" in the top menu');
  
    }

  
      if(walletProvider == 'anchor'){
  
      // Retrieve current session from state
      const session = await anchor.login()
      //console.log(session[0].signTransaction);
    
  
    
      try {
        // Reset our response state to clear any previous transaction data
        
        const action = [{
              account: 'atomicassets',
              name: 'addcolauth',
              authorization: [{
                actor: anchor.users[0].accountName,
                permission: anchor.users[0].requestPermission,
            }],
            data: {
                collection_name: collection,
                account_to_add: "waxdaomarket",
            }
          }]
        // Call transact on the session (compatible with eosjs.transact)
        const response = await session[0].signTransaction({
          actions: action
        }, {
          blocksBehind: 3,
          expireSeconds: 60,
          broadcast: true,
        })
        // Update application state with the responses of the transaction
        setLoadingDisplay('');
        setEnterButtonsDisplay('hidden');
        setEnterModalText('Processing your transaction...')
        const timer = setTimeout(() => {
          
            setEnterModalText('You have authorized waxdaomarket, you can now create drops');
            setLoadingDisplay('none');
            setEnterButtonsDisplay('');
      
        }, 4000);
        return () => clearTimeout(timer);
    
      } catch(e) {
        setEnterModalText(JSON.stringify(e.message));
        console.log(e)
        
      }
    
    
        } //end if wallet = anchor
  
  }//end authorizeWaxDAO




const createDrop = async (assetVector, collection, templateID, totalAvailable, startTimestamp, dropDescription, logoHash, setEnterModalText, setLoadingDisplay, 
    setEnterButtonsDisplay, setEnterModalDisplay) => {




    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
    const walletProvider = localStorage.getItem('eosWalletProvider');

    if(walletProvider != 'anchor'){

      setEnterModalText('You are not logged in. Click "Wallet" in the top menu');
  
    }


  
      if(walletProvider == 'anchor'){
  
      // Retrieve current session from state
      const session = await anchor.login()
      //console.log(session[0].signTransaction);
    
  
    
      try {
        // Reset our response state to clear any previous transaction data
        
        const action = [{
              account: 'waxdaomarket',
              name: 'createdrop',
              authorization: [{
                actor: anchor.users[0].accountName,
                permission: anchor.users[0].requestPermission,
            }],
            data: {
                user: anchor.users[0].accountName,
                price: '0',
                token_symbol: '0,FREE',
                contract: 'free',
                collection: collection,
                schema: 'na',
                template_id: templateID,
                total_available: totalAvailable,
                limit_per_user: '0',
                cooldown: '0',
                whitelist_type: 'none',
                allowed_users: [],
                farmname: 'na',
                minimum_to_stake: '0',
                start_time: startTimestamp,
                end_time: '4102488000',
                drop_description: dropDescription,
                drop_logo: logoHash,
                receiver: 'na',
                pool_or_farm: 'no',
                pool_or_farm_name: 'na',
                percent_to_pool: '0',
                pool_id: assetVector,
                drop_type: 'premint.pack',
                pack_template: templateID,
            }
          }]
        // Call transact on the session (compatible with eosjs.transact)
        const response = await session[0].signTransaction({
          actions: action
        }, {
          blocksBehind: 3,
          expireSeconds: 60,
          broadcast: true,
        })
        // Update application state with the responses of the transaction
        setLoadingDisplay('');
        setEnterButtonsDisplay('hidden');
        setEnterModalText('Processing your transaction...')
        const timer = setTimeout(() => {
          
            setEnterModalText('Your unbox link has been created.');
            setLoadingDisplay('none');
            setEnterButtonsDisplay('');
      
        }, 4000);
        return () => clearTimeout(timer);
    
      } catch(e) {
  
          if(e.message == "assertion failure with message: You must add waxdaomarket as an authorized account on this collection"){
  
              setEnterModalText(
              <span>
                  waxdaomarket is not authorized on this collection.<br/><br/>
                  <GameButton onClick={() => { authorizeWaxDAO(collection, setEnterModalText, setLoadingDisplay, 
      setEnterButtonsDisplay, setEnterModalDisplay) }}>
                      Authorize Now
                  </GameButton>
  
              </span>);
  
          } else{
  
              setEnterModalText(JSON.stringify(e.message));
  
          }
  
        
        console.log(e)
        
      }
    
    
        } //end if wallet = anchor
  
  } //end createDrop
  







const CreatePack = () => {
  
  const {  decimals, setDecimals, farmName, setFarmName, rewardToken, setRewardToken, rewardContract, setRewardContract, 
   collection, setCollection, schema, setSchema, farmLogo, setFarmLogo, paymentMethod, setPaymentMethod, timestamp, 
   setTimestamp, setEpochTime, assetDisplay, setAssetDisplay, assetToBurn, setAssetToBurn, burnable, setBurnable, 
   burnableDisplay, setBurnableDisplay, stakingPeriod, setStakingSeconds, stakingSeconds, setStakingPeriod, quantity, 
   setQuantity, farmType, setFarmType, farmTypeDisplay, setFarmTypeDisplay, farmTypeValue, setFarmTypeValue, templates, 
   setTemplates, schemaDisplay, setSchemaDisplay, templateMessage, setTemplateMessage,                 
   rewardType,
   setRewardType,
   rewardTypeDisplay,
   setRewardTypeDisplay,
   rewardTypeValue,
   setRewardTypeValue,
   rewardAmount,
   setRewardAmount,
   enterModalText,
   setEnterModalText,
   loadingDisplay, 
   setLoadingDisplay, 
   enterButtonsDisplay,
   setEnterButtonsDisplay,
   enterModalDisplay, 
   setEnterModalDisplay,
   usernamesDisplay,
   setUsernamesDisplay,
   farmnameDisplay,
   setFarmnameDisplay,
   whitelistType,
   setWhitelistType,
   setStartTime,
   setEndTime,
   startTimestamp,
   setStartTimestamp,
   endTimestamp,
   setEndTimestamp,
   dropPrice,
   setDropPrice,
   dropSymbol,
   setDropSymbol,
   dropPrecision,
   setDropPrecision,
   templateID,
   setTemplateID,
   totalAvailable,
   setTotalAvailable,
   limitPerUser,
   setLimitPerUser,
   cooldownSeconds,
   setCooldownSeconds,
   minimumNftsStaked,
   setMinimumNftsStaked,
   usernames,
   setUsernames,
   dropDescription,
   setDropDescription,
   logoHash,
   setLogoHash,
   receivingAccount,
   setReceivingAccount,
   revenueOption,
   setRevenueOption,
   poolName,
   setPoolName,
   revenueDisplay,
   setRevenueDisplay,
   percentToPool,
   setPercentToPool,
   setIsWhitelistedForDrops,
   isWhitelistedForDrops,
   poolStatus,
   setPoolStatus,
   stakedAssets,
   setStakedAssets,
   poolIDs,
   setPoolIDs,
   assetVector,
   setAssetVector, 
   stakeMultipleDisplay, 
   setStakeMultipleDisplay,
  
  } = useStateContext();

  const { PoolID } = useParams();




  useEffect(() => {
    const abortCont2 = new AbortController();

    //clear any previous state data
    setCollection('');
    setSchema('');
    setQuantity('');
    setDropDescription('');
    setLogoHash('');
    setTotalAvailable('');
    setPoolStatus('');
    setStakedAssets([]);
    setStakeMultipleDisplay('');
    setAssetVector([Number(PoolID)]);
    



    
    axios.post(`${apiList[Math.floor(Math.random() * (2 - 0) ) + 0]}/v1/chain/get_table_rows`,{
        table:"premintpools",
        scope:"waxdaomarket",
        code:"waxdaomarket",
        limit:1,
        lower_bound: PoolID,
        upper_bound: PoolID,
        json:true
        })
        .then((response) => {
    
            setTotalAvailable(response.data.rows[0].amount_of_assets);
            setPoolStatus(response.data.rows[0].status);
            setCollection(response.data.rows[0].collection);


        })
        .catch((error) => console.log(error));  


      }

    
    , []);





    useEffect(() => {
      setPoolIDs([]);
      
      
      
      
       const eosUsername = localStorage.getItem('eosAccount');
       const walletProvider = localStorage.getItem('eosWalletProvider');
        
       if(eosUsername){
         //console.log('You have an active session');
         '';
         //setProfileDisplay('flex');
       }else{
         //console.log('You are not logged in');
         return('');
       }
      
      
          
      
        axios.post(`${apiList[Math.floor(Math.random() * (2 - 0) ) + 0]}/v1/chain/get_table_rows`,{
                  table:"premintpools",
                  scope:"waxdaomarket",
                  code:"waxdaomarket",
                  key_type: 'name',
                  index_position: 2,
                  limit:100,
                  lower_bound: eosUsername,
                  upper_bound: eosUsername,
                  json:true
                })
                  .then((response) => {
              
                    setPoolIDs(response.data.rows);
                    console.log(response.data.rows)
                    
                    
                  })
                  .catch((error) => console.log(error));
      
    
        }
      
      , []);



  return (
    <div id="seo">
    <Helmet>
    <title>Create Pre-minted Packs - WaxDAO</title>
    <meta name="description" content="Create preminted NFT packs on EOS." />
    <link rel="canonical" href="https://eos.waxdao.io/create-pack" />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor]} appName={"WaxDAO"}>
    <MainContainer>


    <Modal className={enterModalDisplay}>
      <ModalContent>
        {enterModalText}
        
      </ModalContent>

        <Spinner style={{display:loadingDisplay}}>
        <SpinnerRed  />
        <SpinnerBlue  />
        <SpinnerGreen />
        </Spinner>

      <br/>
      <GameButton onClick={() => setEnterModalDisplay('hidden') }>
        Close Window
      </GameButton>
      </Modal>



    
      <CreateDaoCont>







        <DaoTitle>
        Create An Unboxing Page
        </DaoTitle>
        <CreateFarmMsg>
        Prerequisites: You created a preminted pool on WaxDAO.<br/><br/> 
        You have created an NFT template to use as your "pack template".<br/><br/>
        Do not use this page until those are completed.<br/><br/>
        Note: You don't need to worry about RAM for pack openings, but each NFT "revealed" will cost 0.001 EOS. This means 1000 NFTs opened for 1 EOS. 
        </CreateFarmMsg>



       <br/><br/>

       <MissingInfoMsg>
        Very Important! Once you create an unboxing link, the preminted pool you attach to it will be locked PERMANENTLY.
        <br/><br/>
        This means forever. Read that again and then read it 5 more times. Then read it another 87 more times.
        <br/><br/>
        You. Can. Not. Modify. The. Pool. Or. Unbox Page. Ever.
        <br/><br/>
        Pack creation is FINAL!
        </MissingInfoMsg>

       <MissingInfoMsg>
        Note: You must be the pool creator in order to make a pack for it.
        </MissingInfoMsg>
        <br/>



        <NewProposal>












      <label htmlFor="templates"><br />Pack Template:<br /></label>
      <ProposalInput
      type="text"
      maxLength={8}
      placeholder="e.g. 123456"
      id="template" 
      value={templateID}
      onChange={(e) => {
        setTemplateID(e.target.value);
      }}
      />

    <label htmlFor="templates"><br />Total Available:<br /></label>

    <ProposalInput
    //placeholder="Put 0 if unlimited"
    type="text"
    maxLength={20}
    value={totalAvailable}
    id="template" 
    onChange={(e) => {
    setTotalAvailable(e.target.value);
    }}
    />



<NFTsCont>
  <PoolAssetsTitle>
    Pack Slots
  </PoolAssetsTitle>

<MissingInfoMsg>
  Note: All pools must have the exact same amount of NFTs if you want to add them to the same drop.
</MissingInfoMsg>

{ poolIDs.length > 0 ? poolIDs.map((item, index) => (


//render all this if the farm type is template and the template matches
<span key={index} className={item.status != "available" && 'hidden'}>
<NFT style={stakeMultipleDisplay == "hidden" ? {} : stakeMultipleDisplay == "" && assetVector.indexOf(item.ID) > -1 ? {border:'1px solid #fa872d'} : stakeMultipleDisplay == "" && assetVector.indexOf(item.ID) == -1 ? {border:'1px solid #696969'} : ''}>

      
<br/><span className="font-semibold">{item.display_name != null ? item.display_name : ''}</span> 

<br/><br/>


<StakeManyButton key={index}
onClick={() => {
  if(stakeMultipleDisplay == "hidden"){
    //stake(FarmName, item.asset_id, refreshAssets)
  } else {

    if(assetVector.indexOf(item.ID) > -1){
      handleRemoveAsset(item.ID, assetVector, setAssetVector)
    } else {
      handleAddAsset(item.ID, assetVector, setAssetVector)
    }
  }
  }}
  
  style={stakeMultipleDisplay == "hidden" ? {} : stakeMultipleDisplay == "" && assetVector.indexOf(item.ID) > -1 ? {border:'1px solid #fa872d'} : stakeMultipleDisplay == "" && assetVector.indexOf(item.ID) == -1 ? {border:'1px solid #696969'} : ''}
  className={stakeMultipleDisplay == "hidden" ? "text-orange" : stakeMultipleDisplay == "" && assetVector.indexOf(item.ID) > -1 ? "text-orange" : stakeMultipleDisplay == "" && assetVector.indexOf(item.ID) == -1 ? "text-gray hover:text-black" : ''}
  >
{stakeMultipleDisplay == "hidden" && "Stake Now"}
{stakeMultipleDisplay == "" && assetVector.indexOf(item.ID) > -1 && "Remove"}
{stakeMultipleDisplay == "" && assetVector.indexOf(item.ID) == -1 && "Add"}
</StakeManyButton>

</NFT>
</span>






)) : (
<NoDaosMessage>
  You don't have any pools to include in your pack.
</NoDaosMessage>


)}


</NFTsCont>












      <label htmlFor="stakingPeriod" ><br />Start Time:<br /></label>
        <div className='daterangepicker-control-section'>
      <DateTimePickerComponent
        id="datepicker"
         cssClass='customClass'
         
         onChange={(e) => {
             setStartTime(e)
        }}
         
         >
      </DateTimePickerComponent>
      </div>



        <label htmlFor="templates"><br />Pack Description:<br /></label>


      <UserWhitelist
        placeholder="Tell people some info about your pack"
        value={dropDescription}
        type="text"
        rows={4}
        id="template" 
        onChange={(e) => {
        setDropDescription(e.target.value);
        }}
        />

        <br/>

        <label htmlFor="templates"><br />Cover Image (300px x 300px):<br /></label>

        <ProposalInput
        type="text"
        maxLength={70}
        placeholder="IPFS hash only. e.g. Qmfkjs..."
        value={logoHash}
        id="template" 
        onChange={(e) => {
        setLogoHash(e.target.value);
        }}
        />



        <br />


{(poolStatus != 'available') && (

<span>

<MissingInfoMsg>
  This pool is currently locked.
</MissingInfoMsg>



<DisabledButton>
  Create Pack Now
</DisabledButton>

</span>

)}



{(poolStatus == 'available') && (

<span>

{(startTimestamp == '') && (
<span>

<MissingInfoMsg>
  You need to select a start date before you can submit.
</MissingInfoMsg>



<DisabledButton>
  Create Pack Now
</DisabledButton>
</span>
)}


</span>
)}


{(startTimestamp != '' && poolStatus == "available") && (

<DaoButton 

onClick={() => {
  
  createDrop(assetVector, collection, templateID, totalAvailable, startTimestamp, dropDescription, logoHash, setEnterModalText, setLoadingDisplay, 
      setEnterButtonsDisplay, setEnterModalDisplay )

}}

>
Create Pack Now
</DaoButton>

)}









<br/><br/>

    </NewProposal>
    
    </CreateDaoCont>
    
    </MainContainer>
    </UALProvider>
    <br/><br/><br/>
    </div>
  )
}

export default CreatePack