import styled from 'styled-components'


export const HomePageStars = styled.img`

    width: 100%;
    max-width: 100%;
    height: auto;

`


export const Fuck = styled.span`

.e-input-group input.e-input, .e-input-group.e-control-wrapper input.e-input {
    height: 40px;
    font-size: 20px;
}

`

export const CreateDropIPFS = styled.img`
    width: auto;
    max-width: 150px;
    height: 150px;
    max-height: 150px;
    border-radius: 5%;
    margin-left: auto;
    margin-right: auto;
`


export const LoadSpinner = styled.div`

    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    //mix-blend-mode: overlay;
    

`

export const LoadSpinnerRed = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 8px solid transparent;
    border-top-color: whitesmoke;
    animation: rotate 1.5s ease-in-out infinite;
    
    @keyframes rotate {
    0%{
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }

}


`

export const LoadSpinnerBlue = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 8px solid transparent;
    border-right-color: #696969;
    animation: rotate 2s ease-out infinite;
    
    @keyframes rotate {
    0%{
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }

}


`

export const LoadSpinnerGreen = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 8px solid transparent;
    border-left-color: #fa872d;
    animation: rotate 2.5s ease-in infinite;
    
    @keyframes rotate {
    0%{
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }

}

`


export const UnboxTemplate = styled.div`
    display: inline-block;
    font-size: 14px;
    font-weight: 200;
    color: whitesmoke;
    width: 100px;
    max-width: 100px;
    background-color: transparent;
    border: 1px solid whitesmoke;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 15px;
    margin-top:10px;
    margin-right: 1em;
    text-align: center;

    :hover{
        color: #fa872d;
        border-color: #fa872d;
        opacity: 100%;
    }
`


export const RemoveManyDiv = styled.button`
    width: 150px;
    max-width: 150px;
    background-color: indianred;  
    color: white;
    border-radius: 15px;
    padding: 10px;
    bottom: 75px;
    left: 25px;
    position: fixed;
    text-align: center;
    box-shadow: 0px 0px 5px 0.8px whitesmoke;
`


export const RemoveManyButton = styled.button`

    font-size: 18px;
    font-weight: 500;
    //color: #fa872d;
    background-color: transparent;
    border: 1px solid indianred;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 20px;
    margin-top:10px;

    :hover{
        color: whitesmoke;
        background-color: indianred;
    }

`



export const SoldOut = styled.div`
    width: 125px;
    max-width: 125px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: indianred;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
`


export const DropType = styled.div`
    width: 100px;
    max-width: 100px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: white;
    font-size: 12px;
    font-weight: 500;
    text-align: center;

    @media (max-width: 900px) {
        font-size: 12px;
        font-weight: 500;

        
    }
`


export const PoolAssetsTitle = styled.div`
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: white;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
`

export const FilterMenu = styled.select`
    
    font-size: 16px;
    font-weight: normal;
    color: white;
    background-color: #202329;
    border: 1px solid #fa872d;
    width: 150px;
    max-width: 150px;
    padding: 5px;
    border-radius: 20px;
    margin-top:10px;
    padding-left: 1em;
    margin-left: auto;
    margin-right: auto;
    
`

export const GameButton = styled.button`

    font-size: 18px;
    font-weight: 500;
    color: black;
    background-color: #F7EA03;
    border: 3px solid black;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 20px;
    margin-top:10px;

    :hover{
        color: #131313;
        background-color: #ded51b;
    }
`

export const Modal = styled.div`
   
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10000;
   
    width: 300px;
    max-width: 80%;
    height: 300px;
    background-color: #1c1c1c;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 15px;
    border-radius: 30px;
    box-shadow: 0px 0px 5px 3px whitesmoke;

    @media (max-width: 900px) {
        margin-top: 50px; 
    }
    

`

export const ModalOverlay = styled.div`
   
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
   
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    background-color: #1c1c1c;
    opacity: 80%;
    

`

export const ModalContent = styled.div`

    color: white;
    font-size: 18px;
    

`

export const Spinner = styled.div`

    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    //mix-blend-mode: overlay;
    

`

export const SpinnerRed = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 8px solid transparent;
    border-top-color: whitesmoke;
    animation: rotate 1.5s ease-in-out infinite;
    
    @keyframes rotate {
    0%{
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }

}


`

export const SpinnerBlue = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 8px solid transparent;
    border-right-color: #696969;
    animation: rotate 2s ease-out infinite;
    
    @keyframes rotate {
    0%{
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }

}


`

export const SpinnerGreen = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 8px solid transparent;
    border-left-color: #fa872d;
    animation: rotate 2.5s ease-in infinite;
    
    @keyframes rotate {
    0%{
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }

}


`




export const HomePageCont = styled.div`

    width: 100%;
    max-width: 100%;
    border-bottom: 1px solid dimgrey; 
    margin-left: 0px; 
    margin-right: 0px;
    margin-top: 70px;
    background-color: #1f1e20;
    padding-bottom: 25px;
    padding-top: 50px;

`

export const HomePageCont2 = styled.div`
    width: 100%;
    max-width: 100%;
    border-bottom: 1px solid dimgrey; 
    margin-left: 0px; 
    margin-right: 0px;
    padding-bottom:20px;
    padding:2em;
    margin-top: 0px;

`

export const HomeTitle = styled.div`
    
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: white;
    font-size: 34px;
    font-weight: bold;
    text-align: center;
`

export const HomeTitle2 = styled.div`
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: white;
    font-size: 28px;
    font-weight: 500;
    text-align: center;
`

export const HomeDescription = styled.div`
    width: 50%;
    max-width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: white;
    font-size: 18px;
    font-weight: normal;
    text-align: center;

`

export const TrustedByCont = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;
    padding: 1em;
    justify-content: center;

`

export const TrustedByTitle = styled.div`
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    color: white;
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    margin-bottom: 1em;
`

export const TrustedByDiv = styled.div`
    display: inline-block;
    width: 20%;
    max-width: 20%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: white;
    font-size: 14px;
    font-weight: normal;
    text-align: left;
    border: 1px solid #fa872d;
    border-radius: 10px;
    padding: 1em;

    @media (max-width: 768px) {
        width: 80%;
        max-width: 80%;
        margin-top: 25px;

    }
    
`

export const ModalLink = styled.a`
    color: #fa872d;



    :hover{
        cursor: pointer;
    }
    
`


export const TrustedByLink = styled.a`
    display: inline-block;
    width: 20%;
    max-width: 20%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: white;
    font-size: 14px;
    font-weight: normal;
    text-align: left;
    border: 1px solid #696969;
    border-radius: 10px;
    padding: 1em;

    @media (max-width: 768px) {
        width: 80%;
        max-width: 80%;
        margin-top: 25px;

    }

    :hover{
        border: 1px solid #fa872d;
    }
    
`

export const MainContainer = styled.div`
    width:100%;
    padding:0;
    box-sizing: border-box;
    margin-bottom: 50px;

`


export const LeftDiv = styled.div`
    width: 25%;
    max-width: 25%;
    border: 1px solid #fa872d; 
    position: fixed; 
    //height: 90vh; 
    
    margin-left: 20px; 
    border-radius: 5px;
    height:80%;
    //max-height: auto;
    overflow-x: scroll;

    @media (max-width: 900px) {
       
        display: none;
    }

`


export const NewNavbar = styled.div`
    width: 100%;
    max-width: 100%;
    height: 70px; 
    bottom: 0px;
    opacity: 90%;
    display: flex;
    color: white;
    text-align: center;
    font-size: 12px;
    top: 0px;
    background-color: black;
    position: fixed;
    z-index: 5000;


`


export const ExpandLargeNav = styled.div`
    width: 100%;
    max-width: 100%;
    top: 70px; 
    opacity: 90%;
    color: white;
    text-align: center;
    font-size: 12px;
    background-color: black;
    position: fixed;
    z-index: 5000;

`


export const ExpandMobileNav = styled.div`
    width: 100%;
    max-width: 100%;
    top: 70px; 
    opacity: 90%;
    color: white;
    text-align: center;
    font-size: 12px;
    background-color: black;
    position: fixed;
    z-index: 5000;
    height: 100%;

    overflow: scroll;

    @media (min-width: 991px) {
       
       display: none;
   }    

`



export const RightNav = styled.div`
    width: 100%;
    max-width: 100%;
    position: absolute; 
    background-color: #202329;
    height: 70px; 
    opacity: 90%;
    display: flex;
    color: white;
    text-align: center;
    font-size: 12px;
    justify-content: center;
    top: 0px;
    
    


    @media (min-width: 901px) {

        
        width: 45%;
        
        position: absolute;
        right: 0px;


        
    }

`



export const NewNavLink = styled.button`
    width: 90px;
    max-width: 90px;
    padding-left: 5px;
    padding-right: 5px;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    align-content: center;
    display: none;
    z-index: 5000;


    :hover{
        background-color: #1c1c1c;
        color: whitesmoke;
        opacity: 100%;
        cursor: pointer;
    }


    @media (min-width: 991px) {

        display: flex;

    :hover{

            background-color: #1c1c1c;
            color: whitesmoke;
            opacity: 100%;
            cursor: pointer;
            border-bottom: 2px solid whitesmoke;
        }
    
    }

`



export const ExpandedNavLink = styled.a`
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 15px;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: left;


    :hover{

            background-color: #1c1c1c;
            color: whitesmoke;
            opacity: 100%;
            cursor: pointer;
            border-bottom: 2px solid whitesmoke;
        }
    


`



export const ExpandedNavHeader = styled.button`
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 15px;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: left;


    :hover{

            background-color: #1c1c1c;
            color: whitesmoke;
            opacity: 100%;
            cursor: pointer;
            border-bottom: 2px solid whitesmoke;
        }
    
`


export const ExpandedNavButton = styled.button`
    width: 100%;
    max-width: 100%;
    padding: 15px;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: left;


    :hover{

            background-color: #1c1c1c;
            color: whitesmoke;
            opacity: 100%;
            cursor: pointer;
            border-bottom: 2px solid whitesmoke;
        }
    
`



export const ExpandedNavBody = styled.a`
    width: 100%;
    max-width: 100%;
    padding: 15px;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: left;


    :hover{

            background-color: #1c1c1c;
            color: whitesmoke;
            opacity: 100%;
            cursor: pointer;
            border-bottom: 2px solid whitesmoke;
        }
    
`





export const NavWallet = styled.button`
    width: 150px;
    max-width: 150px;
    padding-left: 5px;
    padding-right: 5px;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    align-content: center;
    margin-left: auto;
    display: none;

    @media (min-width: 991px) {

    display: flex;

    :hover{

            background-color: #1c1c1c;
            color: whitesmoke;
            opacity: 100%;
            cursor: pointer;
            border-bottom: 2px solid whitesmoke;
        }
    
    }


`


export const NavChain = styled.a`
    width: 70px;
    max-width: 70px;
    padding-left: 5px;
    padding-right: 5px;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    align-content: center;
    margin-left: auto;
    display: flex;


    :hover{

            background-color: #1c1c1c;
            color: whitesmoke;
            opacity: 100%;
            cursor: pointer;
            border-bottom: 2px solid whitesmoke;
        }

        @media (max-width: 991px) {

            margin-right: 100px;

        }
    



`





export const MobileNavCont = styled.button`
    position: absolute;
    right: 30px;
    top: 15px;
    display: block;
    width: 50px;
    padding-bottom: 6px;



    @media (min-width: 991px) {
   
        display:none;
     
    }

    :hover{
        cursor: pointer;
    }

`


export const MobileNavLine = styled.div`
    display: block;
    width: 24px;
    max-width: 24px;
    height: 4px; 
    background-color: white;
    opacity: 100%;
    margin-left: auto;
    margin-top: 6px;
    margin-right: auto;
    
    


    @media (min-width: 991px) {
   
        display:none;
     
    }

`






export const RightDiv = styled.div`
    width: 65%;
    max-width: 65%;
    border: 1px solid #fa872d; 
    margin-left: 30%; 
    border-radius: 5px;
    padding-bottom: 50px;
    margin-top: 100px;

    @media (max-width: 900px) {
        width: 90%;
        max-width: 90%;
        border: 1px solid #fa872d; 
        margin-left: auto;
        margin-right: auto;
    }

`

export const ArticleCont = styled.div`
    width: 90%;
    max-width: 90%;
    color: whitesmoke;
    border: 1px solid #fa872d; 
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    padding: 20px;
    padding-bottom: 50px;
    margin-top: 100px;

    ul{
        margin-left: 2em;
        line-height: 2em;
    }

    a{
        color: #fa872d;
    }


`



export const MyDropsCont = styled.div`
    
    width: 100%;
    max-width: 100%;
    color: whitesmoke;
    border: 1px solid #fa872d; 
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    padding: 20px;
    padding-bottom: 50px;
    margin-top: 10px;
    display: flex;
    overflow-x: scroll;
    margin-bottom: 50px;



`



export const ArticleH1 = styled.div`
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    font-size: 30px;
    font-weight: 500;
    

`


export const ArticleH2 = styled.div`
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2em;
    text-align: left;
    font-size: 24px;
    font-weight: 500;
    color: #fa872d;


`

export const ArticleP = styled.div`
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2em;
    text-align: left;
    font-size: 16px;
    font-weight: 200;


`






export const StakeManyDiv = styled.button`
    width: 150px;
    max-width: 150px;
    background-color: #fa872d;  
    color: white;
    border-radius: 15px;
    padding: 10px;
    bottom: 75px;
    left: 25px;
    position: fixed;
    text-align: center;
    box-shadow: 0px 0px 5px 0.8px whitesmoke;


`

export const NavPageCont = styled.div`
    width: 90%;
    max-width: 90%;
    border: 1px solid #fa872d; 
    margin-left: auto; 
    margin-right: auto;
    border-radius: 5px;
    padding-bottom: 50px;
    padding:2em;
    margin-top: 100px;

`

export const StakersCont = styled.div`
    border: 1px solid #fa872d;
    border-radius: 15px;
    width:100%;
    max-width: 100%;
    padding:5px;
    padding-top: 2em;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    padding-bottom: 3em;

`

export const StakersHeader = styled.div`
    margin-left: auto;
    margin-right:auto;
    display: block;
    margin-bottom: 10px;
    width: 90%;
    max-width: 90%;
    border-bottom: 1px solid #696969;
    color: whitesmoke;

`

export const StakersRow = styled.div`
    margin-left: auto;
    margin-right:auto;
    //display: block;
    width: 90%;
    max-width: 90%;
    border-bottom: 1px solid #696969;

`

export const StakeLeftRow = styled.div`
    margin-left: 0px;
    margin-right: 0px;
    display: inline-block;
    width: 60%;
    max-width: 60%;
    text-align: left;
    font-weight: 200;

`

export const StakeRightRow = styled.div`
    margin-left: 0px;
    margin-right: 0px;
    display: inline-block;
    width: 40%;
    max-width: 40%;
    text-align: right;
    font-weight: 200;


`

export const StakersLeft = styled.div`
    margin-left: 0px;
    margin-right: 0px;
    display: inline-block;
    width: 60%;
    max-width: 60%;
    text-align: left;
    font-weight: 500;

`

export const StakersRight = styled.div`
    margin-left: 0px;
    margin-right: 0px;
    display: inline-block;
    width: 40%;
    max-width: 40%;
    text-align: right;
    font-weight: 500;


`


export const FarmsPageCont = styled.div`
    width: 90%;
    max-width: 90%;
    border: 1px solid dimgrey; 
    margin-left: auto; 
    margin-right: auto;
    border-radius: 5px;
    padding-bottom: 50px;
    padding:2em;
    margin-top: 100px;

`


export const HighlightSlot = styled.button`
    width: 90%;
    max-width: 90%;
    //border: 1px solid dimgrey; 
    margin-left: auto; 
    margin-right: auto;
    border-radius: 10px;
    padding:1.5em;
    margin-top: 10px;

    :hover{
        box-shadow: 0px 0px 5px 4px whitesmoke;
        cursor: pointer;
    }

`


export const HighlightedFarm = styled.div`
    display: flex;
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    color: lightgray;
    font-size: 14px;
    //text-align: center;
    //border: 1px solid #fa872d;
    border-radius: 15px;
    padding: 20px;

    @media (max-width: 560px) {
       
       display: block;
       text-align: center;
       
   }

   :hover{
        box-shadow: 0px 0px 5px 4px whitesmoke;

        img{
            box-shadow: 0px 0px 5px 4px whitesmoke;
        }
   }

`
export const HighlightedLogo = styled.img`
    display: inline-block;
    width: 150px;
    max-width: 150px;
    height: 150px;
    max-height: 150px;
    border-radius: 50%; 

    
`

export const HighlightDetails = styled.div`
    margin-left: auto;
    margin-right:auto;
    display: inline-block;
    width: 100%;
    max-width: 100%;
    padding: 15px;

    @media (max-width: 560px) {
       text-align: center;
   }
`

export const HighlightTitle = styled.div`
    width: 100%;
    max-width: 100%;
    color: white;
    font-size: 20px;
    font-weight: 500;
    text-align: left;

    @media (max-width: 560px) {
       text-align: center;
   }
`
export const HighlightMessage = styled.div`
    width: 100%;
    max-width: 100%;
    color: white;
    font-size: 16px;
    font-weight: 200;
    text-align: left;
    margin-top: 10px;

    @media (max-width: 560px) {
       text-align: center;
   }
`

export const HighlightAmount = styled.div`
    width: 100%;
    max-width: 100%;
    color: white;
    font-size: 16px;
    font-weight: 200;
    text-align: left;
    margin-top: 10px;

    @media (max-width: 560px) {
       text-align: center;
   }


`


export const AllFarmsCont = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    width: 100%;
    max-width: 100%;
    margin-top: 50px;
    margin-left: auto; 
    margin-right: auto;
    border-radius: 5px;
    padding-bottom: 50px;
    justify-content: center;

`


export const SuggestionsCont = styled.div`
    width: 100%;
    max-width: 100%;
    margin-top: 50px;
    margin-left: auto; 
    margin-right: auto;
    border-radius: 5px;
    padding-bottom: 50px;
    justify-content: center;
    text-align: center;

    a{
        color: #fa872d;
    }

`


export const MyDropsContainer = styled.div`
    width: 100%;
    max-width: 100%;
    margin-top: 50px;
    margin-left: auto; 
    margin-right: auto;
    border-radius: 5px;
    padding-bottom: 50px;
    justify-content: center;


`

export const SingleFarmCont = styled.div`
    border: 1px solid dimgrey;
    border-radius: 15px;
    max-width: 100%;
    padding:40px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    flex-wrap: wrap;
    justify-content: center;

    :hover{
        border-color: #fa872d;
        cursor: pointer;

        img{
            box-shadow: 0px 0px 5px 1px whitesmoke;
        }

    }
    

`


export const SingleDropCont = styled.div`
    display: inline-block;
    border: 1px solid dimgrey;
    border-radius: 15px;
    width: 300px;
    max-width: 100%;
    padding:40px;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 25px;
    justify-content: center;
    text-align: center;

    :hover{
        border-color: #fa872d;
        cursor: pointer;

        img{
            box-shadow: 0px 0px 5px 1px whitesmoke;
        }

    }
    

`

export const RamModal = styled.div`
    border: 1px solid dimgrey;
    border-radius: 15px;
    max-width: 100%;
    padding:40px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    flex-wrap: wrap;
    justify-content: center;

    :hover{
        border-color: #fa872d;
        cursor: pointer;

        img{
            box-shadow: 0px 0px 5px 1px whitesmoke;
        }

    }
    

`

export const FarmLogo = styled.img`

    width: 120px;
    max-width: 120px;
    height: 120px;
    max-height: 120px;
    border-radius: 50%;

`

export const SingleDropLogo = styled.img`

    width: auto;
    max-width: auto;
    height: 150px;
    max-height: 150px;
    border-radius: 5%;
    margin-left: auto;
    margin-right: auto;

`



export const DropLogo = styled.img`

    width: 120px;
    max-width: 120px;
    height: 120px;
    max-height: 120px;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;

`

export const FarmTitle = styled.div`
    width: 125px;
    max-width: 125px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: white;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
`

export const PoolAmount = styled.div`
    width: 125px;
    max-width: 125px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: white;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
`

export const FarmButton = styled.button`

    font-size: 18px;
    font-weight: 500;
    color: whitesmoke;
    background-color: transparent;
    border: 1px solid whitesmoke;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 20px;
    margin-top:10px;
    opacity: 90%;

    :hover{
        color: #fa872d;
        border-color: #fa872d;
        opacity: 100%;
    }
`

export const FarmEndDate = styled.div`
    width: 125px;
    max-width: 125px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: white;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
`


export const TemplatesCont = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
    max-width: 100%;
    margin-left: auto; 
    margin-right: auto;
    padding: 5px;
    justify-content: center;

`


export const SingleTemplate = styled.div`

    font-size: 18px;
    font-weight: 200;
    color: whitesmoke;
    background-color: transparent;
    border: 1px solid whitesmoke;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 20px;
    margin-top:10px;

    :hover{
        color: #fa872d;
        border-color: #fa872d;
        opacity: 100%;
    }
`


export const NavPageInnerCont = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    width: 100%;
    max-width: 100%;
    margin-left: auto; 
    margin-right: auto;
    border-radius: 5px;
    padding-bottom: 50px;
    justify-content: center;


    @media (max-width: 768px) {
        margin-top: 100px;
    }
`


export const NavPageItem = styled.a`
    display: flex;
    width: 150px;
    max-width: 150px;
    min-width: 150px;
    height: 150px;
    max-height: 150px;
    margin-top: 15px;
    color: #A9A9A9;
    border: 1px solid #696969;
    border-radius: 10px;
    font-size: 20px;
    text-align: center;
    justify-content: center;
    align-items: center;

    :hover{
        cursor: pointer;
        color: whitesmoke;
        border-color: whitesmoke;
    }

`


export const DropPageCont = styled.div`
    width: 100%;
    width: 100%;
    margin-left: auto; 
    margin-right: auto;
    border-radius: 5px;
    padding-bottom: 50px;
    padding:2em;
    margin-top: 100px;
    color: whitesmoke;
`



export const CreateDaoCont = styled.div`
    width: 90%;
    max-width: 90%;
    border: 1px solid #fa872d; 
    margin-left: auto; 
    margin-right: auto;
    border-radius: 5px;
    padding-bottom: 50px;
    padding:2em;
    margin-top: 100px;

`

export const DeployCont = styled.div`
    width: 100%;
    max-width: 100%;
    margin-left: auto; 
    margin-right: auto;
    border-radius: 5px;
    padding-bottom: 50px;
    padding:2em;
    margin-top: 10px;
    color: #e2dfdf;
    text-align: center;

`



export const CustomizeDaoCont = styled.div`
    width: 90%;
    max-width: 90%;
    border: 1px solid #fa872d; 
    margin-left: auto; 
    margin-right: auto;
    border-radius: 5px;
    padding-bottom: 50px;
    padding:2em;
    color: white;
    text-align: center;
    margin-top: 100px;

`


export const DaoName = styled.div`
    width: 90%;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: white;
    font-size: 24px;
    text-align: center;
    font-weight: bold;

`

export const Creator = styled.div`
    width: 90%;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    color: lightgray;
    font-size: 14px;
    text-align: center;
    font-style: italic;

`


export const Proposals = styled.div`
    width: 90%;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    color: lightgray;
    font-size: 14px;
    text-align: center;

`

export const NewProposal = styled.div`
    width: 90%;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    color: lightgray;
    font-size: 14px;
    text-align: center;

`

export const AboutDAO = styled.div`
    width: 90%;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    color: lightgray;
    font-size: 14px;
    text-align: center;

`

export const Wallet = styled.div`
    width: 90%;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    color: lightgray;
    font-size: 14px;
    text-align: center;

`

export const DaoMenu = styled.div`
    width: 90%;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    color: white;
    font-size: 18px;


`

export const DaoMenuItem = styled.div`
    width: 90%;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: white;
    font-size: 18px;
    text-align: left;

    :hover{
        cursor: pointer;
    }

`


export const FarmMenu = styled.div`
    width: 90%;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    color: white;
    font-size: 18px;


`

export const FarmMenuItem = styled.div`
    width: 90%;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: white;
    font-size: 18px;
    text-align: left;

    :hover{
        cursor: pointer;
        color: #fa872d;
    }

`


export const FarmTopMenu = styled.div`
    display: none; 
    width: 96%;
    max-width: 96%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    color: white;
    font-size: 16px;

    @media (max-width: 900px) {
        display: flex;
        margin-top: 100px;
    }


`

export const FarmTopMenuItem = styled.div`
    display: none; 
    width: 25%;
    max-width: 25%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: white;
    font-size: 18px;
    text-align: center;
    justify-content: center;
    
    :hover{
        cursor: pointer;
        color: #fa872d;
    }

    @media (max-width: 900px) {

        display: inline-flex;
    }

    :hover{
        cursor: pointer;
        color: #fa872d;
    }

`




export const DaoTitle = styled.div`
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: white;
    font-size: 24px;
    font-weight: 500;
    text-align: left;
`

export const FarmsTitle = styled.div`
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: white;
    font-size: 24px;
    font-weight: 500;
    text-align: left;
`



export const DropTitle = styled.div`
    display: inline-block;
    width: 50%;
    max-width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: white;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
`

export const DropBody = styled.div`
    display: inline-block;
    width: 50%;
    max-width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: lightgray;
    font-size: 14px;
    font-weight: 200;
    text-align: left;
`


export const StakingCont = styled.div`
    width: 90%;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    color: lightgray;
    font-size: 14px;
    text-align: center;

`

export const NFTsCont = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
    //border: 1px solid #fa872d;
    border-radius: 15px;
    width:100%;
    max-width: 100%;
    padding:5px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;

`

export const NFTLogo = styled.img`
    width: 150px;
    max-width: 150px;
    height: 150px;
    max-height: 150px;
    border-radius: 25%; 
`

export const NavLogo = styled.img`
    width: auto;
    max-width: auto;
    height: 100%;
    max-height: 100%;
`


export const DropImage = styled.img`
    width: 200px;
    max-width: 200px;
    height: auto;
    border-radius: 25%; 
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
`


export const ProfileImage = styled.img`
    width: auto;
    max-width: 80%;
    height: auto;
    max-height: auto;
    border-radius: 10px;
    margin-left: auto;
    margin-right: 15px;
    margin-bottom: 20px;
`

export const LeftProfileImg = styled.img`
    width: auto;
    max-width: 80%;
    height: auto;
    max-height: auto;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    margin-bottom: 20px;
`




export const MyDaoTitle = styled.div`
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
    margin-bottom: 10px;
    color: white;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
`

export const SingleProposal = styled.div`
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: white;
    font-size: 24px;
    font-weight: bold;
    text-align: left;
    border: 1px solid #fa872d;
    border-radius: 10px;
    padding: 15px;
    padding-top: 5px;

`

export const Suggestion = styled.div`
    width: 90%;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: white;
    font-size: 14px;
    font-weight: 200;
    text-align: left;
    border: 1px solid #fa872d;
    border-radius: 10px;
    padding: 15px;

`

export const SuggestionDesc = styled.div`
    width: 100%;
    max-width: 100%;
    color: white;
    font-size: 14px;
    font-weight: 200;
    text-align: left;

`


export const SuggestionReply = styled.div`
    width: 100%;
    max-width: 100%;
    color: indianred;
    font-size: 14px;
    font-weight: 200;
    text-align: left;
    margin-top: 10px;

`




export const SuggestionDeets = styled.div`
    width: 100%;
    max-width: 100%;
    color: #9e9c9c;
    font-size: 12px;
    font-weight: 200;
    text-align: left;
    margin-top: 15px;
`

export const SuggestionUser = styled.div`
    display: inline-block;
    width: 30%;
    max-width: 30%;
    color: #9e9c9c;
    font-size: 12px;
    font-weight: 200;
    text-align: center;
`


export const StakingPoolMsg = styled.div`
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: white;
    font-size: 16px;
    font-weight: normal;
    text-align: left;
    border: 1px solid #fa872d;
    border-radius: 10px;
    padding: 15px;

`



export const CreateFarmMsg = styled.div`
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: white;
    font-size: 16px;
    font-weight: normal;
    text-align: left;
    border: 1px solid #fa872d;
    border-radius: 10px;
    padding: 15px;



`



export const ProposalTitle = styled.div`

    font-size: 20px;
    font-weight: 500;

`

export const ProposalAuthor = styled.div`

    font-size: 12px;
    font-weight: normal;
    margin-bottom: 15px;
    color: lightgray;

`

export const ProposalDescription = styled.div`

    font-size: 16px;
    font-weight: normal;
    margin-bottom: 15px;
    color: lightgray;

`


export const DropDescription = styled.div`

    font-size: 16px;
    font-weight: normal;
    margin-bottom: 15px;
    color: lightgray;
    border: 1px solid #696969;
    margin-top: 30px;
    padding: 20px;
    width: 90%;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;

`



export const ProposalButton = styled.div`
    display: inline-block;
    border: 1px solid #fa872d;
    width: auto;
    max-width: 180px;
    border-radius: 10px;
    text-align: center;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
    font-weight: normal;

`

export const ProposalButtonContainer = styled.div`
    display: flex;
    gap: 5px;
    width: 100%;
    max-width: 100%;
    justify-content: right;

`


export const ProposerTypeMessage = styled.div`

    font-size: 16px;
    font-weight: normal;
    margin-bottom: 15px;
    margin-top: 15px;
    color: lightgray;
    border: 1px solid #fa872d;
    border-radius: 10px;
    padding: 15px;

`

export const NotLoggedIn = styled.div`

    font-size: 16px;
    font-weight: normal;
    color: lightgray;
    border: 1px solid #fa872d;
    border-radius: 10px;
    padding: 15px;
    width: 80vw;
    max-width: 80vw;
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;

`


export const MissingInfoMsg = styled.div`

    font-size: 20px;
    width: 90%;
    max-width: 90%;
    font-weight: normal;
    margin-bottom: 15px;
    margin-top: 15px;
    color: indianred;
    border: 1px solid indianred;
    border-radius: 10px;
    padding: 15px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 769px) {
        font-size: 14px; 
    }


`

export const StakedAmount = styled.div`
    border: 1px solid #fa872d;
    border-radius: 15px;
    width:100%;
    max-width: 100%;
    padding:5px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    text-align: center;
    font-size: 18px;

`

export const AboutDaoCont = styled.div`
    border: 1px solid #fa872d;
    border-radius: 15px;
    width:100%;
    max-width: 100%;
    padding:5px;
    padding-left: 2em;
    padding-top: 2em;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;

`

export const ProfileCont = styled.div`
    border: 1px solid #fa872d;
    border-radius: 15px;
    width:100%;
    max-width: 100%;
    padding:5px;
    padding-left: 2em;
    padding-top: 2em;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    display: flex;
    justify-content: space-between;

`

export const TimeSlotsCont = styled.div`
    border: 1px solid #696969;
    border-radius: 15px;
    width:100%;
    max-width: 100%;
    padding:5px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    padding-top: 25px;
    padding-bottom: 25px;

`

export const WalletCont = styled.div`
    border: 1px solid #fa872d;
    border-radius: 15px;
    width:100%;
    max-width: 100%;
    padding:10px;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    text-align: center;
    font-size: 18px;

`



export const NewRow = styled.div`
    flex-basis: 100%;

`


export const BurnableCont = styled.div`
    border: 1px solid #fa872d;
    border-radius: 15px;
    width:100%;
    max-width: 100%;
    padding:5px;
    padding-top: 2em;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    flex-wrap: wrap;
    justify-content: center;
    gap:5px;
`

export const DAO = styled.div`
    border: 1px solid #fa872d;
    border-radius: 15px;
    max-width: 100%;
    padding:15px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    flex-wrap: wrap;
    justify-content: center;
    display: inline-block;
    text-align: center;
    

`

export const DaosCont = styled.div`
    width:100%;
    max-width: 100%;
    padding:5px;
    padding-top: 2em;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    margin-bottom: 25px;
    flex-wrap: wrap;
    justify-content: space-around;
    display: flex;
`

export const NFT = styled.div`
    border: 1px solid #fa872d;
    border-radius: 15px;
    max-width: 100%;
    padding:15px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    flex-wrap: wrap;
    justify-content: center;
    

`


export const Drop = styled.div`
    border: 1px solid #fa872d;
    border-radius: 15px;
    max-width: 100%;
    padding:15px;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    

`


export const BuyWojak = styled.div`
    border: 1px solid #fa872d;
    border-radius: 15px;
    max-width: 100%;
    padding:15px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
    margin-top: 25px;
    flex-wrap: wrap;
    justify-content: center;
    

`

export const CreateDaoInfo = styled.div`
    border: 1px solid #fa872d;
    border-radius: 15px;
    width:100%;
    max-width: 100%;
    padding:15px;
    padding-left: 2em;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    margin-bottom: 50px;
    color: white;

`


export const Terms = styled.div`
    border: 1px solid #fa872d;
    border-radius: 15px;
    width:100%;
    max-width: 100%;
    padding:15px;
    padding-left: 2em;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    margin-bottom: 50px;
    color: white;

`

export const AssetNote = styled.div`

    width:100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
    color: white;
    text-align: center;
    justify-content: center;

`

export const DaoLink = styled.a`

    color: #fa872d;
    text-decoration: none;

`

export const AboutDaoTitle = styled.div`

    font-size: 20px;
    font-weight: 500;
    color: white;
    text-align: left;

`

export const NoDaosMessage = styled.div`

    font-size: 20px;
    font-weight: 500;
    color: white;
    text-align: left;

`



export const AboutDaoBody = styled.div`

    font-size: 16px;
    font-weight: normal;
    color: lightgray;
    text-align: left;
    margin-bottom: 20px;

`

export const ProposerList = styled.div`

    font-size: 16px;
    font-weight: normal;
    color: lightgray;

`

export const ProposalInput = styled.input`

    font-size: 16px;
    font-weight: normal;
    color: white;
    background-color: transparent;
    border: 1px solid #fa872d;
    width:80%;
    max-width: 80%;
    padding: 5px;
    border-radius: 20px;
    padding-left: 1em;
    margin-bottom: 15px;


`


export const QuantityToBuy = styled.input`

    font-size: 16px;
    font-weight: normal;
    color: white;
    background-color: transparent;
    border: 1px solid #fa872d;
    width: 70px;
    max-width: 70px;
    padding: 5px;
    border-radius: 20px;
    padding-left: 1em;
    margin-bottom: 15px;
    margin-top: 5px;


`

export const QuantityToDeposit = styled.input`

    font-size: 16px;
    font-weight: normal;
    color: white;
    background-color: transparent;
    border: 1px solid #fa872d;
    width: 120px;
    max-width: 120px;
    padding: 5px;
    border-radius: 20px;
    padding-left: 1em;
    margin-bottom: 15px;
    margin-top: 5px;


`


export const PropDescription = styled.textarea`

    font-size: 16px;
    font-weight: normal;
    color: white;
    background-color: transparent;
    border: 1px solid #fa872d;
    width:80%;
    max-width: 80%;
    padding: 5px;
    border-radius: 20px;
    padding-left: 1em;

`


export const ProposalChoices = styled.textarea`

    font-size: 16px;
    font-weight: normal;
    color: white;
    background-color: transparent;
    border: 1px solid #fa872d;
    width:80%;
    max-width: 80%;
    padding: 5px;
    border-radius: 20px;
    padding-left: 1em;
    margin-bottom: 5px;
    margin-left: 10%;
    margin-right: 10%;

`



export const UserWhitelist = styled.textarea`

    font-size: 16px;
    font-weight: normal;
    color: white;
    background-color: transparent;
    border: 1px solid #fa872d;
    width:80%;
    max-width: 80%;
    padding: 5px;
    border-radius: 20px;
    padding-left: 1em;
    margin-bottom: 5px;
    margin-left: 10%;
    margin-right: 10%;

`

export const RemoveButton = styled.button`

    font-size: 24px;
    font-weight: bold;
    color: red;
    background-color: transparent;
    border: 1px solid red;
    width: 3em;
    max-width: 3em;
   
    border-radius: 20px;
    display: inline-block;
    margin-bottom: 15px;

    :hover{
        background-color: red;
        color: black;
    }
`

export const ToggleSwitchCont = styled.div`

    right: 10%;
    position: absolute;
    display: inline-block;

`

export const ToggleMessage = styled.span`

    font-size: 16px;

`

export const ToggleSwitch = styled.button`

    font-size: 24px;
    font-weight: bold;
    color: #fa872d;
    background-color: transparent;
    border: 1px solid #fa872d;
    width: 2em;
    max-width: 2em;
    height: 1em;
    max-height: 1em;
    padding: 0px;
    margin-top: 0.5em;
    border-radius: 20px;
    display: inline-block;
    vertical-align: middle;
    line-height: 0em;
    margin-bottom: 15px;


`

export const InnerSwitch = styled.div`

    font-size: 24px;
    font-weight: bold;
    color: white;
    background-color: white;
    width: 50%;
    max-width: 50%;
    height:95%;
    max-height: 95%;
    border-radius: 20px;
    margin: 0px;
    opacity: 90%;

    

`

export const AddButton = styled.button`

    font-size: 24px;
    font-weight: bold;
    color: #fa872d;
    background-color: transparent;
    border: 1px solid #fa872d;
    width: 65%;
    max-width: 65%;
    padding: 5px;
    border-radius: 20px;
    margin-top:10px;

    :hover{
        background-color: #fa872d;
        color: black;
    }


`



export const AddNFT = styled.button`

    font-size: 16px;
    font-weight: bold;
    color: #fa872d;
    background-color: transparent;
    border: 1px solid #fa872d;
    width: 40px;
    max-width: 40px;
    padding: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 10px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;

    :hover{
        background-color: #fa872d;
        color: black;
    }


`


export const LockButton = styled.button`

    font-size: 20px;
    font-weight: 500;
    color: #fa872d;
    background-color: transparent;
    border: 1px solid #fa872d;
    width: 65%;
    max-width: 65%;
    padding: 5px;
    border-radius: 20px;
    margin-top:10px;

    :hover{
        color: #131313;
        background-color: #fa872d;
    }


`

export const StakeManyButton = styled.button`

    font-size: 18px;
    font-weight: 500;
    //color: #fa872d;
    background-color: transparent;
    border: 1px solid #fa872d;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 20px;
    margin-top:10px;

    :hover{
        color: #131313;
        background-color: #fa872d;
    }

`


export const DaoButton = styled.button`

    font-size: 18px;
    font-weight: 500;
    color: #fa872d;
    background-color: transparent;
    border: 1px solid #fa872d;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 20px;
    margin-top:10px;

    :hover{
        color: #131313;
        background-color: #fa872d;
    }

`


export const SuggestionBtn = styled.button`

    font-size: 14px;
    font-weight: 200;
    color: indianred;
    background-color: transparent;
    border: 1px solid indianred;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 20px;

    :hover{
        color: whitesmoke;
        background-color: indianred;
    }

`

export const FinalizeButton = styled.button`

    font-size: 14px;
    font-weight: 200;
    color: #6bc36b;
    background-color: transparent;
    border: 1px solid #6bc36b;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 20px;

    :hover{
        color: whitesmoke;
        background-color: #6bc36b;
    }

`

export const VestingButton = styled.button`

    font-size: 14px;
    font-weight: 200;
    color: #f3a558;
    background-color: transparent;
    border: 1px solid #f3a558;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 20px;

    :hover{
        cursor: default;
    }

`

export const CloudButton = styled.button`

    font-size: 18px;
    font-weight: 500;
    color: white;
    background-color: #fc8931;
    //border: 1px solid whitesmoke;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 20px;
    margin-top:10px;

    :hover{
        color: whitesmoke;
        background-color: #e88336;
    }


`

export const AnchorButton = styled.button`

    font-size: 18px;
    font-weight: 500;
    color: white;
    background-color: #263ec5;
    //border: 1px solid whitesmoke;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 20px;
    margin-top:10px;

    :hover{
        color: whitesmoke;
        background-color: #2d40b0da;
    }


`


export const CancelButton = styled.button`

    font-size: 18px;
    font-weight: 500;
    color: indianred;
    background-color: transparent;
    border: 1px solid indianred;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 20px;
    margin-top:10px;

    :hover{
        color: #131313;
        background-color: indianred;
    }


`


export const Remaining = styled.div`

    
    font-size: 12px;
    font-weight: 200;
    color: #fa872d;
    background-color: transparent;
    border: 1px solid #fa872d;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 20px;
    margin-top:10px;
    width: 120px;
    margin-left: auto;
    margin-right: auto;

`


export const MinMint = styled.div`

    
    font-size: 12px;
    font-weight: 200;
    color: #a4a1a1;
    background-color: transparent;
    border: 1px solid #a4a1a1;
    padding: 3px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 20px;
    margin-top:10px;
    width: 150px;
    margin-left: auto;
    margin-right: auto;

`

export const Price = styled.div`

    
    font-size: 16px;
    font-weight: 200;
    color: #fa872d;
    background-color: transparent;
    border: 1px solid #fa872d;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 7px;
    margin-top:10px;
    width: 240px;
    margin-left: auto;
    margin-right: auto;

`


export const SocialButton = styled.button`

    font-size: 16px;
    font-weight: 500;
    color: #fa872d;
    background-color: transparent;
    border: 1px solid #fa872d;
    padding: 5px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 10px;
    margin-top:10px;
    margin-right: 7px;

    :hover{
        color: #131313;
        background-color: #fa872d;
    }


`


export const LeftProfile = styled.div`

    width: 300px;
    max-width: 300px;
    word-wrap: break-word;
    //margin-right: 50px;

    @media (max-width: 600px) {
        width: 100%;
        max-width: 100%;
    }

`

export const RightProfile = styled.div`

    width: 100%;
    max-width: 100%;
    margin-left: auto;
    align-items: right;
    text-align: right;
    align-content: right;
    justify-content: right;

    @media (max-width: 600px) {
        display: none;
    }
    

`


export const DisabledButton = styled.button`

    font-size: 18px;
    font-weight: 500;
    color: #696969;
    background-color: transparent;
    border: 1px solid #696969;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 20px;
    margin-top:10px;



`

export const DaoButtonCont = styled.div`

    display: flex;
    flex-wrap: wrap;
    gap:5px;
    justify-content: space-between;


`

export const PaymentOptCont = styled.div`

    text-align: center;


`

export const FarmButtonCont = styled.div`

    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 100%;
    gap:5px;
    justify-content: space-around;
    align-items: center;

    


`

export const DropDown = styled.select`

    font-size: 16px;
    font-weight: normal;
    color: white;
    background-color: #202329;
    border: 1px solid #fa872d;
    width: 80%;
    max-width: 80%;
    padding: 5px;
    border-radius: 20px;
    margin-top:10px;
    padding-left: 1em;
    


`

export const SubmitButton = styled.button`

    font-size: 18px;
    font-weight: normal;
    color: #696969;
    background-color: transparent;
    border: 1px solid #696969;
    width: 65%;
    max-width: 65%;
    padding: 5px;
    border-radius: 20px;
    margin-top:10px;

    :hover{
        border: 1px solid #fa872d;
        color: #fa872d;

    }


`