import React, { useEffect } from 'react';
import { GridComponent, Resize, Sort, ContextMenu, Filter, Page, ExcelExport, PdfExport, Edit, Inject } from '@syncfusion/ej2-react-grids';
import { ordersData, contextMenuItems, ordersGrid, nftFarmData, nftFarmGrid } from '../data/dummy';
import { Header } from '../components';
import { ColumnDirective, ColumnsDirective } from '@syncfusion/ej2-react-charts';
import { useParams } from 'react-router-dom';
import { useStateContext } from '../contexts/ContextProvider';
import { Anchor } from 'ual-anchor';
import { Wax } from '@eosdacio/ual-wax';
import { User } from "universal-authenticator-library";
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { Api, JsonRpc } from "eosjs";
import {Helmet} from "react-helmet";
import axios from 'axios';
import { data } from 'autoprefixer';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { BsInfoCircle } from 'react-icons/bs';
import Select from 'react-select';
import vote from '../data/vote.png';
import { MainContainer, LeftDiv, RightDiv, DaoName, Creator, Proposals, NewProposal, AboutDAO, DaoMenu, DaoMenuItem, Wallet, DaoTitle,
  SingleProposal, ProposalButton, ProposalButtonContainer, ProposalTitle, ProposalAuthor, ProposalDescription, ProposerTypeMessage,
  ProposerList, ProposalInput, PropDescription, ProposalChoices, RemoveButton, AddButton, SubmitButton, DropDown, AboutDaoTitle,
  AboutDaoBody, AboutDaoCont, DaoButton, DaoButtonCont, StakedAmount, CreateDaoCont, CreateDaoInfo, DaoLink, BurnableCont, NewRow,
  NFT, BuyWojak, DAO, DaosCont, MyDaoTitle, CustomizeDaoCont, UserWhitelist, LockButton, Terms
  } from '../components/LeftDiv';

const options = [
  { value: 'Pay 250 Wax', label: 'Pay 250 Wax' },
  { value: 'Pay 25,000 WOJAK', label: 'Pay 25,000 WOJAK' },
  { value: 'Burn 1 Wojak NFT', label: 'Burn 1 Wojak NFT' }
]

const farmTypeOptions = [
  { value: 'Token', label: 'Token' },
  { value: 'NFT Collection', label: 'NFT Collection' },
  { value: 'NFT Schema', label: 'NFT Schema' },
  { value: 'NFT Templates', label: 'NFT Templates' }

]

const proposerOptions = [
  { value: 'Stakeweight', label: 'Stakeweight' },
  { value: 'Usernames', label: 'Usernames' }

]


let zero = '0';

const myChain = {
  chainId: '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4',
  rpcEndpoints: [{
      protocol: 'https',
      host: 'wax.greymass.com',
      port: '443'
  }]
};

const rpc = new JsonRpc('https://wax.greymass.com');


const anchor = new Anchor([myChain], {

  appName: 'WaxDAO',

});

const wax = new Wax([myChain], {

  appName: 'WaxDAO',

});


const ualLogin = async () => {
 
  console.log('Loading...');
  await anchor.login();
  getCustomersData();
  //localStorage.setItem('waxAccount', 'anchor.users[0].accountName')
  //console.log(anchor.users[0].accountName);

}

const ualLogout = async () => {
 
  console.log('Loading...');
  await anchor.logout();
  //console.log(anchor.users[0].accountName);

}


//DAOName, farmName, farmTypeValue, decimals, rewardToken, rewardContract, collection, schema, templates, threshold, minimumVotes, minWeight, proposalLength, farmLogo, stakingSeconds

const lockSettings = async (DAOName, farmName, farmTypeValue, decimals, rewardToken, rewardContract, collection, schema, templates, threshold, minimumVotes, minWeight, proposalLength, farmLogo, stakingSeconds, usernames, proposerTypeValue, whichPool) => {
  const walletProvider = localStorage.getItem('walletProvider');

  if (document.getElementById('terms').checked) {
    '';
} else {
  alert("You need to agree to the terms first");
  return '';
}



var stakingPool;

if(farmTypeValue == 'tokenpool' || farmTypeValue == 'nftfarm'){

  stakingPool = whichPool;

} else {

  stakingPool = 'no';

}

var tokenStr;

if(farmTypeValue != 'token'){

  tokenStr = '8,WAX';

} else {

  tokenStr = decimals + ',' + rewardToken;

}

if(farmTypeValue != 'templates'){

  templates = '1';
  

}

if(proposerTypeValue != 'usernames'){

  usernames = '1';

}



  if(walletProvider == 'wax'){



  // Retrieve current session from state
  const session = await wax.login()
  //console.log(session[0].signTransaction);
//console.log(wax.users[0].accountName);



  try {
    // Reset our response state to clear any previous transaction data
    
    const action = [{
          account: 'buildawaxdao',
          name: 'locksettings',
          authorization: [{
            actor: wax.users[0].accountName,
            permission: wax.users[0].requestPermission,
        }],
        data: {
          primary_key: DAOName,
          daoname: farmName,
          staketype: farmTypeValue,
          token: tokenStr,
          contract: rewardContract,
          collection: collection,
          schema: schema,
          temp_ids: templates.split(','),
          threshold: threshold,
          minimumvotes: minimumVotes,
          minweight: minWeight,
          length: proposalLength * 86400,
          logo: farmLogo,
          mintime: stakingSeconds,
          proposer_type: proposerTypeValue,
          proposers: usernames.split(','),
          poolname: stakingPool,

        }
      }]
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction({
      actions: action
    }, {

      blocksBehind: 3,
      expireSeconds: 60,
      broadcast: true,
    })
    // Update application state with the responses of the transaction
    alert('Your new DAO settings have been applied.');
  } catch(e) {
    alert(e)
    console.log(e)
    
  }


    } //end if wallet = wax



    if(walletProvider == 'anchor'){

    // Retrieve current session from state
    const session = await anchor.login()
    //console.log(session[0].signTransaction);
  
  
    try {
      // Reset our response state to clear any previous transaction data
      
      const action = [{
        account: 'buildawaxdao',
        name: 'locksettings',
            authorization: [{
              actor: anchor.users[0].accountName,
              permission: anchor.users[0].requestPermission,
          }],
          data: {
            primary_key: DAOName,
            daoname: farmName,
            staketype: farmTypeValue,
            token: tokenStr,
            contract: rewardContract,
            collection: collection,
            schema: schema,
            temp_ids: templates.split(','),
            threshold: threshold,
            minimumvotes: minimumVotes,
            minweight: minWeight,
            length: proposalLength * 86400,
            logo: farmLogo,
            mintime: stakingSeconds,
            proposer_type: proposerTypeValue,
            proposers: usernames.split(','),
            poolname: stakingPool,
          }
        }]
      // Call transact on the session (compatible with eosjs.transact)
      const response = await session[0].signTransaction({
        actions: action
      }, {

        broadcast: true,
      })
      // Update application state with the responses of the transaction
      alert('Your new DAO settings have been applied.');
    } catch(e) {
      alert(e.cause)
      console.log(JSON.stringify(e))
      
    }
  

  
      } //end if wallet = anchor

} //end lockSettings
 





const DAOOptions = ({ location }) => {

  const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode, currentUser, setCurrentUser, tokenName, 
    setTokenName, maxSupply, setMaxSupply, decimals, setDecimals, tokenString, setTokenString, farmData, setFarmData, 
    farmCollection, setFarmCollection, farmSchema, setFarmSchema, farmRewards, setFarmRewards, farmExpires, setFarmExpires, 
    farmIcon, setFarmIcon, farmContract, setFarmContract, createdBy, setCreatedBy, unstaked, setUnstaked, stakedOrUnstaked, 
    setStakedOrUnstaked, stakedDisplay, setStakedDisplay, unstakedDisplay, setUnstakedDisplay, validAssets, setValidAssets, 
    commaSeparated, setCommaSeparated, stakedAssets, setStakedAssets, claimable, setClaimable, minTime, setMinTime, depositAmount, 
    setDepositAmount, depositDisplay, setDepositDisplay, totalStaked, setTotalStaked, ramBalance, setRamBalance, ramInfoDisplay, 
    setRamInfoDisplay, farmTemplates, setFarmTemplates, templatesDisplay, setTemplatesDisplay, farmType, setFarmType, 
    nftsCoveredByRam, setNftsCoveredByRam, extendDisplay, setExtendDisplay, daysToExtend, setDaysToExtend, optionsAmountToAdd, 
    setOptionsAmountToAdd, rewardToken, setRewardToken, tokenPrecision, setTokenPrecision, logoHash, setLogoHash, logoDisplay, 
    setLogoDisplay, farmName, setFarmName, farmLogo, setFarmLogo, collection, setCollection, schemaDisplay, setSchemaDisplay, 
    schema, setSchema, farmTypeDisplay, setFarmTypeDisplay, templateMessage, setTemplateMessage, rewardContract, setRewardContract, 
    stakingPeriod, setStakingPeriod, paymentMethod, setPaymentMethod, assetDisplay, setAssetDisplay, assetToBurn, setAssetToBurn, 
    burnableDisplay, setBurnableDisplay, burnable, setBurnable, quantity, setQuantity, farmTypeValue, setFarmTypeValue, 
    collectionDisplay, setCollectionDisplay, tokenDisplay, setTokenDisplay, templates, setTemplates, threshold, setThreshold, 
    minimumVotes, setMinimumVotes, minWeight, setMinWeight, proposalLength, setProposalLength, stakingSeconds, setStakingSeconds, 
    proposerDisplay, setProposerDisplay, proposerTypeValue, setProposerTypeValue, proposerType, setProposerType, minWeightDisplay, 
    setMinWeightDisplay, usernames, setUsernames, usernamesMessage, setUsernamesMessage, whichPoolDisplay, setWhichPoolDisplay,
    whichPool, setWhichPool, stakingPoolText, setStakingPoolText,
   } = useStateContext();

const { DAOName } = useParams();

useEffect(() => {
  const abortCont2 = new AbortController();

  //clear any previous state data

  setBurnable([]);
  setBurnableDisplay('hidden');
  setDecimals('');
  setFarmName('');
  setRewardToken('');
  setRewardContract('');
  setFarmLogo('');
  setPaymentMethod('Payment Method');
  setStakingPeriod('');
  setCollection('');
  setSchema('');
  setQuantity('');
  setFarmType('Select DAO Type');
  setProposerType('Select Proposer Type');
  setStakingPoolText('Staking Pool');
  




   const waxUsername = localStorage.getItem('waxAccount');
   const walletProvider = localStorage.getItem('walletProvider');
    
   if(waxUsername){
     //console.log('You have an active session');
     '';
     //setProfileDisplay('flex');
   }else{
     //console.log('You are not logged in');
     return('');
   }

        axios.get(`https://wax.api.atomicassets.io/atomicassets/v1/assets?collection_name=hoodpunknfts&schema_name=waxywojaks&owner=${waxUsername}&page=1&limit=8&order=desc&sort=asset_id`, {signal: abortCont2.signal})
          .then((burnResponse) => {
      
            setBurnable(burnResponse.data.data);
            //console.log(burnResponse);
          })


      .catch((error) => console.log(error));
  
      return() => {
      
        abortCont2.abort();
        
      }

    }

  
  , []);



useEffect(() => {

  console.log(DAOName);

  const abortCont = new AbortController();
  const abortCont2 = new AbortController();
  const abortCont3 = new AbortController();
  const abortCont4 = new AbortController();


  setStakedAssets([]);
  setUnstaked([]);
  setValidAssets([]);
  setCommaSeparated('');
  setMinTime('');
  setFarmTemplates([]);
  setTemplatesDisplay('hidden');
  setCollectionDisplay('hidden');

  const waxUsername = localStorage.getItem('waxAccount');
  
  if(waxUsername){
    //console.log('You have an active session');
    '';
    //setProfileDisplay('flex');
  }else{
    //console.log('You are not logged in');
    '';     //return('');
  }



  axios.post('https://wax.pink.gg/v1/chain/get_table_rows',{
    table:"daos",
    scope:"buildawaxdao",
    code:"buildawaxdao",
    limit:1,
    lower_bound:DAOName,
    upper_bound:DAOName,
    json:true
  })
    .then((response) => {
      setFarmData(response);
      setFarmCollection(response.data.rows[0].collection);
      setFarmSchema(response.data.rows[0].schema);
      setFarmIcon(response.data.rows[0].logo);
      setCreatedBy(response.data.rows[0].creator);
      setFarmRewards(response.data.rows[0].poolsize);
      setFarmContract(response.data.rows[0].contract);
      setFarmExpires(response.data.rows[0].enddate);
      setMinTime(response.data.rows[0].mintime);
      setTotalStaked(response.data.rows[0].totalstaked);
      //setFarmType(response.data.rows[0].farmtype);
      setRewardToken(response.data.rows[0].rewardtoken);

      console.log(response);

      if(response.data.rows[0].farmtype == 'template'){
        setFarmTemplates(response.data.rows[0].templates);
        //console.log('it does');
        setTemplatesDisplay('flex gap-4');

        if(waxUsername){

          //if the farm type is "template", only search for assets with these template IDs
  
  
        axios.get(`https://wax.api.atomicassets.io/atomicassets/v1/assets?collection_name=${response.data.rows[0].collection.trim()}&owner=${waxUsername}&page=1&limit=100&order=desc&sort=asset_id`)
        .then((atomicResponse) => {
          setUnstaked(atomicResponse.data.data);
        }).then(() => {
          axios.post('https://wax.pink.gg/v1/chain/get_table_rows',{
            table:"stakerzz",
            scope:"waxdaofarmer",
            code:"waxdaofarmer",
            key_type: 'name',
            index_position: 2,
            limit:100,
            lower_bound:waxUsername,
            upper_bound:waxUsername,
            json:true
          }).then((farmVectors) => {
            var itr = 0;
  
            while(itr < farmVectors.data.rows.length){
              if(farmVectors.data.rows[itr].farmname == FarmName){
                setValidAssets(farmVectors.data.rows[itr].assets);
  
                axios.get(`https://wax.api.atomicassets.io/atomicassets/v1/assets?ids=${farmVectors.data.rows[itr].assets.join("%2C")}&page=1&limit=100&order=desc&sort=asset_id`)
                .then((validResponse) => {

                  setStakedAssets(validResponse.data.data);
                 
                })
  
                break;
              }
              else{
              itr ++;
              }
            }
            
          
          })
  
        })
  
  
  
        .catch((error) => console.log(error));
  
  
      }


      } //end if farmtype == template




      if(response.data.rows[0].farmtype == 'schema'){

        if(waxUsername){

  
  
        axios.get(`https://wax.api.atomicassets.io/atomicassets/v1/assets?collection_name=${response.data.rows[0].collection.trim()}&schema_name=${response.data.rows[0].schema.trim()}&owner=${waxUsername}&page=1&limit=100&order=desc&sort=asset_id`)
        .then((atomicResponse) => {
          setUnstaked(atomicResponse.data.data);
 
        }).then(() => {
          axios.post('https://wax.pink.gg/v1/chain/get_table_rows',{
            table:"stakerzz",
            scope:"waxdaofarmer",
            code:"waxdaofarmer",
            key_type: 'name',
            index_position: 2,
            limit:100,
            lower_bound:waxUsername,
            upper_bound:waxUsername,
            json:true
          }).then((farmVectors) => {
            var itr = 0;
  
            while(itr < farmVectors.data.rows.length){
              if(farmVectors.data.rows[itr].farmname == FarmName){
                setValidAssets(farmVectors.data.rows[itr].assets);
  
                axios.get(`https://wax.api.atomicassets.io/atomicassets/v1/assets?ids=${farmVectors.data.rows[itr].assets.join("%2C")}&page=1&limit=100&order=desc&sort=asset_id`)
                .then((validResponse) => {

                  setStakedAssets(validResponse.data.data);
                 
                })

                break;
              }
              else{
              itr ++;
              }
            }
            
            
  
          })
  
        })
  
  
  
        .catch((error) => console.log(error));
  
  
      }


      } //end if farmtype == schema
     

    })
    .catch((error) => console.log(error));

}, []);






useEffect(() => {

setClaimable([]);
  const abortCont5 = new AbortController();

  const waxUsername = localStorage.getItem('waxAccount');
  
  if(waxUsername){
    //console.log('You have an active session');
    '';
    //setProfileDisplay('flex');
  }else{
    //console.log('You are not logged in');
    return('');
  }



  axios.post('https://wax.pink.gg/v1/chain/get_table_rows',{
    table:"daos",
    scope:"buildawaxdao",
    code:"buildawaxdao",
    key_type: 'name',
    index_position: '2',
    limit:1000,
    lower_bound:DAOName,
    upper_bound:DAOName,
    json:true,
    signal: abortCont5
  }).then((claimableResponse) => {
 


        setClaimable(claimableResponse.data.rows);

console.log(claimableResponse.data.rows);


  })

.catch((error) => console.log(error));

return() => abortCont5.abort();

}, []);












  return (
    <div id="seo">
    <Helmet>
    <title>Edit DAO {DAOName}</title>
    <meta name="description" content="Edit your custom Wax DAO" />
    <link rel="canonical" href={`https://waxdao.io/edit-dao/${DAOName}`} />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor, wax]} appName={"WaxDAO"}>
    <MainContainer>
    <CustomizeDaoCont>

    <DaoTitle>
        Customize Your DAO
    </DaoTitle>
    <br/>

        <label htmlFor="farmName">DAO Name: <br /></label>
      <TooltipComponent content="12 Characters Max: (a-z, 1-5)"
      position="BottomCenter">
      <ProposalInput type="text" 
      id="farmName" 
      value={farmName}
      onChange={(e) => {
        setFarmName(e.target.value);
        }}
        
      />
      </TooltipComponent>

      <label htmlFor="farmLogo" ><br />DAO Logo (IPFS only):<br /></label>
      <TooltipComponent content="IPFS hash of the logo you want to use"
      position="BottomCenter">
      <ProposalInput type="text" 
      placeholder='Qmabc.....'
      id="farmLogo" 
      value={farmLogo}
      onChange={(e) => {
        setFarmLogo(e.target.value);
      }}
      />
      </TooltipComponent>
<span className="text-sm italic">*100px by 100px</span>


      <br />

        <form>
        <DropDown         
                         onChange={(e) => {if(e.target.value == 'NFT Templates'){setFarmTypeDisplay('block'); setSchemaDisplay('hidden'); setFarmTypeValue('templates'); setTemplateMessage('You can use any template in your collection, even if they are not all in the same schema :)'); setCollectionDisplay(''); setTokenDisplay('hidden'); setWhichPoolDisplay('hidden');} else if(e.target.value == 'NFT Collection'){setFarmTypeDisplay('hidden'); setSchemaDisplay('hidden'); setFarmTypeValue('collection'); setCollectionDisplay(''); setTokenDisplay('hidden'); setWhichPoolDisplay('hidden');} else if(e.target.value == 'Token'){setFarmTypeDisplay('hidden'); setSchemaDisplay('hidden'); setFarmTypeValue('token'); setCollectionDisplay('hidden'); setTokenDisplay(''); setWhichPoolDisplay('hidden'); } else if(e.target.value == 'NFT Schema') {setFarmTypeDisplay('hidden'); setSchemaDisplay(''); setFarmTypeValue('schema'); setCollectionDisplay(''); setTokenDisplay('hidden'); setWhichPoolDisplay('hidden');} else if(e.target.value == 'Link A Staking Pool') {setFarmTypeDisplay('hidden'); setSchemaDisplay('hidden'); setFarmTypeValue('tokenpool'); setCollectionDisplay('hidden'); setTokenDisplay('hidden'); setWhichPoolDisplay(''); setStakingPoolText('Staking Pool');} 
                         else if(e.target.value == 'Link An NFT Farm') {setFarmTypeDisplay('hidden'); setSchemaDisplay('hidden'); setFarmTypeValue('nftfarm'); setCollectionDisplay('hidden'); setTokenDisplay('hidden'); setWhichPoolDisplay(''); setStakingPoolText('NFT Farm'); } 
                         setFarmType(e.target.value)
                       }}
          >
        <option value="" hidden>
          Governance Asset
        </option>
        <option value="Token">Token</option>
        <option value="Link A Staking Pool">Link A Staking Pool</option>
        <option value="Link An NFT Farm">Link An NFT Farm</option>
        <option value="NFT Collection" disabled>NFT Collection (Coming Soon)</option>
        <option value="NFT Schema" disabled>NFT Schema (Coming Soon)</option>
        <option value="NFT Templates" disabled>NFT Templates (Coming Soon)</option>
        </DropDown>
        </form>


      <label htmlFor="whichpool" className={whichPoolDisplay} ><br />Name Of {stakingPoolText}:<br /></label>
      <TooltipComponent content={`Which ${stakingPoolText} on WaxDAO are you linking?`}
      position="BottomCenter">
      <ProposalInput type="text" 
      maxLength={12}
      id="whichpool" 
      placeholder="token.pool"
      className={`${whichPoolDisplay}`}
      value={whichPool}
      onChange={(e) => {
        setWhichPool(e.target.value.toLowerCase());
      }}
      />
      </TooltipComponent>

      <label htmlFor="collection" className={collectionDisplay} ><br />Collection To Stake:<br /></label>
      <TooltipComponent content="Collection name of the NFTs to stake"
      position="BottomCenter">
      <ProposalInput type="text" 
      id="collection" 
      maxLength={12}
      placeholder="hoodpunknfts"
      className={`${collectionDisplay}`}
      value={collection}
      onChange={(e) => {
        setCollection(e.target.value);
      }}
      />
      </TooltipComponent>

      <span className={`${schemaDisplay}`}>
      <label htmlFor="collection" ><br />Schema To Stake:<br /></label>
      <TooltipComponent content="Name of the schema you want people to stake"
      position="BottomCenter">
      <ProposalInput type="text" 
      id="collection" 
      placeholder="waxywojaks"
      value={schema}
      onChange={(e) => {
        setSchema(e.target.value);
      }}
      />
      </TooltipComponent>
      </span>

      <label htmlFor="templates" className={`${farmTypeDisplay}`}><br />Template IDs (10 MAX): <br /></label>
      <UserWhitelist
      placeholder="123456,987654,696969,420420,444444,169169,420069"
      maxLength="200"
      rows={4}
      id="template" 
      className={farmTypeDisplay}
      onChange={(e) => {
        setTemplates(e.target.value.replace(/ /g,''));
      }}
      />
      <span className={`text-sm italic ${farmTypeDisplay}`}>Comma separated list of template IDs</span>
  
<span className={tokenDisplay}>
      <label htmlFor="rewardToken" ><br />Voting Token Symbol:<br /></label>
      <TooltipComponent content="Which token do you want people to vote with?"
      position="BottomCenter">
      <ProposalInput type="text" 
      id="rewardToken" 
      placeholder="WOJAK"
      value={rewardToken}
      onChange={(e) => {
        setRewardToken(e.target.value.toUpperCase());
      }}
      />
      </TooltipComponent>


      <label htmlFor="rewardContract" ><br />Voting Token Contract:<br /></label>
      <TooltipComponent content="Name of the Wax account where your token contract is stored"
      position="BottomCenter">
      <ProposalInput type="text" 
      id="rewardContract" 
      placeholder="eosio.token"
      value={rewardContract}
      onChange={(e) => {
        setRewardContract(e.target.value);
      }}
      />
      </TooltipComponent>


      <label htmlFor="decimals" ><br />Voting Token Decimals:<br /></label>
      <TooltipComponent content="How many decimal places does the voting token have?"
      position="BottomCenter">
      <ProposalInput type="number" 
      placeholder='8'
      id="decimals" 
      value={decimals}
      onChange={(e) => {
        setDecimals(e.target.value);
      }}
      />
      </TooltipComponent>
</span>


      <label htmlFor="stakingPeriod" ><br />Minimum Staking Period (Days):<br /></label>
      <TooltipComponent content="Minimum days you want people to stake for"
      position="BottomCenter">
      <ProposalInput type="number" 
      id="stakingPeriod" 
      placeholder="1"
      value={stakingPeriod}
      onChange={(e) => {
        if(e.target.value < 1){
        setStakingPeriod(1);
        setStakingSeconds(86400);
    
        }
        else{
          setStakingPeriod(Math.round(e.target.value));
          setStakingSeconds(Math.round(e.target.value) * 86400);
        }
      }}
      />
      </TooltipComponent>


        <form>
        <DropDown         
                 onChange={(e) => {if(e.target.value == 'Usernames'){setProposerDisplay('block'); setProposerTypeValue('usernames'); setProposerType(e.target.value); setMinWeightDisplay('hidden'); setMinWeight('1'); } else if(e.target.value == 'Stakeweight'){setProposerDisplay('hidden'); setProposerTypeValue('stakeweight'); setProposerType(e.target.value); setMinWeightDisplay(''); } }}
          >
        <option value="" hidden>
          Proposer Type
        </option>
        <option value="Stakeweight">Stakeweight</option>
        <option value="Usernames">Usernames</option>
        </DropDown>
        </form>


        <label htmlFor="usernames" className={`${proposerDisplay}`}><br />Usernames To Whitelist: <br /></label>
      <TooltipComponent content="Separate each with a comma"
      position="BottomCenter">
      <UserWhitelist
      placeholder="abcde.wam, 12345.wam, waxdao111111"
      rows={4}
      id="usernames" 
      className={proposerDisplay}
      onChange={(e) => {
        setUsernames(e.target.value.replace(/ /g,''));
      }}
      />
      </TooltipComponent>
      <span className={`text-sm italic ${proposerDisplay}`}>{usernamesMessage}</span>




      <label htmlFor="stakingPeriod" ><br />Threshold:<br /></label>
      <TooltipComponent content="% of 'yes' votes for a proposal to pass"
      position="BottomCenter">
      <ProposalInput type="number" 
      id="stakingPeriod" 
      placeholder="1"
      value={threshold}
      onChange={(e) => {

        setThreshold(e.target.value);



      }}
      />
      </TooltipComponent>


      <label htmlFor="stakingPeriod" ><br />Minimum Votes:<br /></label>
      <TooltipComponent content="Minimum amount of tokens/NFTs needed for a proposal to be valid"
      position="BottomCenter">
      <ProposalInput type="number" 
      id="stakingPeriod" 
      placeholder="1"
      value={minimumVotes}
      onChange={(e) => {

        setMinimumVotes(Math.round(e.target.value));



      }}
      />
      </TooltipComponent>

<span className={minWeightDisplay}>
      <label htmlFor="stakingPeriod" ><br />Minimum Weight:<br /></label>
      <TooltipComponent content="Minimum amount of tokens/NFTs required to CREATE a proposal"
      position="BottomCenter">
      <ProposalInput type="number" 
      id="stakingPeriod" 
      placeholder="1"
      value={minWeight}
      onChange={(e) => {

        setMinWeight(Math.round(e.target.value));



      }}
      />
      </TooltipComponent>
</span>

      <label htmlFor="stakingPeriod" ><br />Proposal Length (Minimum 3):<br /></label>
      <TooltipComponent content="Amount of days that a proposal will last"
      position="BottomCenter">
      <ProposalInput type="number" 
      id="stakingPeriod" 
      placeholder="1"
      value={proposalLength}
      onChange={(e) => {

        setProposalLength(Math.round(e.target.value));



      }}
      />
      </TooltipComponent>





      <br />

<Terms>
<p className="font-bold text-xl">WARNING!</p>
<p>These settings can NEVER be changed once you sign this transaction.</p>
<p>Double check, triple check, then quadruple check that EVERYTHING is the way you want it!</p>
<p>WaxDAO can not fix your mistakes, no refunds will be given, no new DAOs will be created for you.</p> 
<p>Don't screw this up!</p>      
</Terms>


<Terms>
<input type="checkbox" id="terms" name="terms" />
<label for="terms"> I understand that this decision is final and WaxDAO can not help me fix my screw-ups</label>
</Terms>


      <LockButton 
          onClick={() => {
            lockSettings(DAOName, farmName, farmTypeValue, decimals, rewardToken, rewardContract, collection, schema, templates, threshold, minimumVotes, minWeight, proposalLength, farmLogo, stakingSeconds, usernames, proposerTypeValue, whichPool);
          }} >
          Lock Settings PERMANENTLY
      </LockButton>


    </CustomizeDaoCont>  
    </MainContainer>
    </UALProvider>
    <br/><br/><br/>
    </div>
  )
}

export default DAOOptions