import React, { useEffect } from 'react';
import { Header } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { Anchor } from 'ual-anchor';
import { User } from "universal-authenticator-library";
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { Api, JsonRpc } from "eosjs";
import {Helmet} from "react-helmet";
import axios from 'axios';

let zero = '0';

const myChain = {
  chainId: 'aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906',
  rpcEndpoints: [{
      protocol: 'https',
      host: 'eos.greymass.com',
      port: '443'
  }]
};

const rpc = new JsonRpc('https://eos.greymass.com');


const anchor = new Anchor([myChain], {

  appName: 'WaxDAO',

});







const issueTokens = async (issueTokenString) => {
  // Retrieve current session from state
  const session = await anchor.login()
  //console.log(session[0].signTransaction);


  try {
    // Reset our response state to clear any previous transaction data
    
    const action = [{
      account: anchor.users[0].accountName,
      name: 'issue',
      authorization: [{
        actor: anchor.users[0].accountName,
        permission: anchor.users[0].requestPermission,
      }],
      data: {
        to: anchor.users[0].accountName,
        quantity: issueTokenString,
        memo: '',
        }
      }]
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction({
      actions: action
    }, {

      broadcast: true,
      blocksBehind: 3,
      expireSeconds: 120,
    })
    // Update application state with the responses of the transaction
    alert('Success!');
  } catch(e) {
    console.log(e)
    alert(e)
    
  }
}




const Issue = () => {

  

  const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode, currentUser, issueTokenName, setIssueTokenName, issueAmount, setIssueAmount, issueDecimals, setIssueDecimals, issueTokenString, setIssueTokenString, tokenList, setTokenList, noTokensDisplay, setNoTokensDisplay  } = useStateContext();

  useEffect(() => {
    const abortCont2 = new AbortController();

    //clear any previous state data
    setIssueTokenName('');
    setIssueAmount('');
    setIssueDecimals('');

    


      }

    
    , []);

  useEffect(() => {
    setTokenList([]);
     const eosUsername = localStorage.getItem('eosAccount');
     const eosWalletProvider = localStorage.getItem('eosWalletProvider');
      
     if(eosUsername){
       //console.log('You have an active session');
       '';
       //setProfileDisplay('flex');
     }else{
       //console.log('You are not logged in');
       return('');
     }
      
      //console.log(waxUsername)
      axios.post('https://eos.api.eosnation.io/v1/chain/get_table_rows',{
        table:"accounts",
        scope:eosUsername,
        code:eosUsername,
        limit:50,
        json:true
      })
        .then((response) => {
          if(response.data.rows[0] != undefined){
            setNoTokensDisplay('hidden');
          }
          setTokenList(response.data.rows);
          //console.log(response.data.rows);
        })
        .catch((error) => console.log(error.response.data));
    
      }
    
    , []);



  return (
    <div id="seo">
    <Helmet>
    <title>Issue Tokens - WaxDAO</title>
    <meta name="description" content="Use our Token Issue tool to issue your custom EOS token to your wallet." />
    <link rel="canonical" href="https://waxdao.io/issue-tokens" />
    </Helmet>
    <div>
    <UALProvider chains={[myChain]} authenticators={[anchor]} appName={"WaxDAO"}>
    <br/><br/><br/>
    <div className="m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl">
    <Header title="Issue Tokens" category="App" />
    <p className="mt-4 text-2xl">Already created your new EOS token?</p>
    <p className="mt-4 text-xl">Now it's time to issue yourself some tokens.</p>


    <div
  className='p-3 m-auto border-4 rounded-lg gap-4 mt-4 text-center'
  style={{ display:'block', borderColor:currentColor }}
  >
    <h2 className="text-2xl font-semibold">My Tokens</h2>
   
   <div className="flex gap-4 text-center m-auto flex-wrap">
   <div id="noTokens" className={`text-center items-center align-center justify-center m-auto ${noTokensDisplay}`}>
    You have not issued any tokens yet.

   </div>
        
    {tokenList.map((item, index) => (


   
        <div key={index}
        
        className='p-3 m-auto border-2 rounded-lg gap-4 mt-4 text-center'
        style={{ borderColor:currentColor }}

        >{item.balance}
        
        </div>


        ))}

</div>    

</div>


    <div id="createForm" className="text-center items-center justify-center border-4 p-6 rounded-xl mt-4"
      style={{borderColor: currentColor}}
    >


      <label htmlFor="tokenName">Token Name: <br /></label>
      <TooltipComponent content="Must be the exact name of the token you created"
      position="BottomCenter">
      <input type="text" 
      id="tokenName" 
      className="border-2 rounded-lg text-xl"
      style={{borderColor: currentColor}}
      value={issueTokenName}
      maxLength={7}
      onChange={(e) => {
        setIssueTokenName(e.target.value.toUpperCase());
        if(issueDecimals == '0'){
          setIssueTokenString(issueAmount + ' ' + e.target.value.toUpperCase());
        }
        
        if(issueDecimals !== '0'){
          setIssueTokenString(issueAmount + '.' + zero.repeat(issueDecimals) + ' ' + e.target.value.toUpperCase());
        }
      
      
      }}
      />
      </TooltipComponent>

      <label htmlFor="issueAmount" ><br />Amount To Issue: <br /></label>
      <TooltipComponent content="Whole numbers only"
      position="BottomCenter">
      <input type="number" 
      id="issueAmount" 
      className="border-2 rounded-lg text-xl"
      style={{borderColor: currentColor}}
      value={issueAmount}
      onChange={(e) => {
        setIssueAmount(e.target.value);
        if(issueDecimals == '0'){
          setIssueTokenString(e.target.value + ' ' + issueTokenName.toUpperCase());
        }
        
        if(issueDecimals !== '0'){
          setIssueTokenString(e.target.value + '.' + zero.repeat(issueDecimals) + ' ' + issueTokenName.toUpperCase());
        }
      
      }}
      />
      </TooltipComponent>

      <label htmlFor="decimals" ><br />Decimal Places: <br /></label>
      <TooltipComponent content="Must be exactly the same as the token you created"
      position="BottomCenter">
      <input type="number" 
      id="decimals" 
      className="border-2 rounded-lg text-xl"
      style={{borderColor: currentColor}}
      value={issueDecimals}
      onChange={(e) => {
        setIssueDecimals(e.target.value);
        if(e.target.value == '0'){
          setIssueTokenString(issueAmount + ' ' + issueTokenName.toUpperCase());
        }
        
        if(e.target.value !== '0'){
          setIssueTokenString(issueAmount + '.' + zero.repeat(e.target.value) + ' ' + issueTokenName.toUpperCase());
        }
        console.log(issueTokenString);
      
      }}
      />
      </TooltipComponent>

      <button 
          type="button"
          className="text-lg p-3
          hover:drop-shadow-xl
          hover:bg-light-gray
          text-white rounded-lg mt-4"
          onClick={() => issueTokens(issueTokenString)}
          style={{ background: currentColor
          }}
        
        >
          Issue Tokens Now
        </button>

    </div>

    </div>
    </UALProvider>
    </div>
    <br/><br/><br/>
    </div>
  )
}

export default Issue