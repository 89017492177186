import React, { useEffect } from 'react';
import { GridComponent, Resize, Sort, ContextMenu, Filter, Page, ExcelExport, PdfExport, Edit, Inject } from '@syncfusion/ej2-react-grids';
import { ordersData, contextMenuItems, ordersGrid, nftFarmData, nftFarmGrid } from '../../data/dummy';
import { Header } from '../../components';
import { ColumnDirective, ColumnsDirective } from '@syncfusion/ej2-react-charts';
import { useParams } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import { Anchor } from 'ual-anchor';
import { Wax } from '@eosdacio/ual-wax';
import { User } from "universal-authenticator-library";
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { Api, JsonRpc } from "eosjs";
import {Helmet} from "react-helmet";
import axios from 'axios';
import { data } from 'autoprefixer';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { BsInfoCircle } from 'react-icons/bs';
import { render } from 'react-dom';
import { MainContainer, FarmMenu, RemoveManyDiv, RemoveManyButton, FarmMenuItem, FarmTopMenu, FarmTopMenuItem, LeftDiv, DaoName, Creator, DaoMenu, DaoMenuItem, RightDiv, AboutDAO, DaoTitle, AboutDaoCont, AboutDaoTitle, AboutDaoBody, SingleTemplate, TemplatesCont, StakingCont, DaoButton, NFT, NFTsCont, NFTLogo, NoDaosMessage, ProposalInput, DaoButtonCont, RamModal, WalletCont, FarmButtonCont, ToggleSwitch, InnerSwitch, ToggleSwitchCont, ToggleMessage, StakeManyButton, StakeManyDiv, LeftProfile, RightProfile, ProfileCont, ProfileImage, LeftProfileImg, SocialButton, FarmsTitle, FarmsPageCont, DropPageCont, MissingInfoMsg, Drop, DropTitle, Modal, ModalContent, Spinner, SpinnerRed, SpinnerBlue, SpinnerGreen, GameButton, DropImage, Remaining, Price, QuantityToBuy, MinMint, AddNFT, DropDescription, DropBody, CreateDaoCont, NewProposal, NewRow, DisabledButton } from '../../components/LeftDiv';
import NumberFormat from 'react-number-format';
var thisCollection;
var list = [];
var list2 = [];
import { Name, raw } from "eos-common";
import ReactMarkdown from 'react-markdown';

function getRndApi() {
  const apiList = ["https://wax.greymass.com", "https://wax.pink.gg", "https://wax.eosrio.io"];
  return apiList[Math.floor(Math.random() * (2 - 0) ) + 0];
}


const apiList = ["https://eos.api.eosnation.io", "https://api.eoseoul.io", "https://eos.eosphere.io"];

const atomicApiList = ["https://eos.api.atomicassets.io"];



let zero = '0';

const myChain = {
  chainId: 'aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906',
  rpcEndpoints: [{
      protocol: 'https',
      host: 'eos.greymass.com',
      port: '443'
  }]
};
const wtf = ['526134'];
const rpc = new JsonRpc('https://eos.greymass.com');


const anchor = new Anchor([myChain], {

  appName: 'WaxDAO',

});

const handleRemoveAsset = (asset_id, assetVector, setAssetVector) => {

    // remove the asset
    const assetList = [...assetVector];
    assetList.splice(assetList.indexOf(asset_id), 1);
    setAssetVector(assetList);
   // console.log('handled removing it');
    


}




const handleAddAsset = (asset_id, assetVector, setAssetVector) => {


        setAssetVector([...assetVector, asset_id])
        //console.log('handled adding it');
        
}





const handleRemovingFromRemoval = (asset_id, removalVector, setRemovalVector) => {

  // remove the asset
  const assetList = [...removalVector];
  assetList.splice(assetList.indexOf(asset_id), 1);
  setRemovalVector(assetList);
  //console.log('handled adding it');
  //console.log(removalVector);
  


}




const handleAddingToRemoval = (asset_id, removalVector, setRemovalVector) => {


      setRemovalVector([...removalVector, asset_id])
      //console.log('handled removing it');
      
}





  const addToPremintPool = async (PoolID, assetVector, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {

    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
    const walletProvider = localStorage.getItem('eosWalletProvider');

    if(walletProvider != 'anchor'){

      setEnterModalText('You are not logged in. Click "Wallet" in the top menu');
  
    }
  
      if(walletProvider == 'anchor'){
  
      // Retrieve current session from state
      const session = await anchor.login()
      //console.log(session[0].signTransaction);
    
  
    
      try {
        // Reset our response state to clear any previous transaction data
        
        const action = 
          [{
            account: 'atomicassets',
            name: 'transfer',
            authorization: [{
              actor: anchor.users[0].accountName,
              permission: anchor.users[0].requestPermission,
          }],
          data: {
            to: 'waxdaomarket',
            from: anchor.users[0].accountName,
            asset_ids: assetVector,
            memo: '|premint_deposit|' + PoolID + '|',
          }
        }]
        // Call transact on the session (compatible with eosjs.transact)
        const response = await session[0].signTransaction({
          actions: action
        }, {
          blocksBehind: 3,
          expireSeconds: 60,
          broadcast: true,
        })
        // Update application state with the responses of the transaction
        setLoadingDisplay('');
        setEnterButtonsDisplay('hidden');
        setEnterModalText('Processing your transaction...')
        const timer = setTimeout(() => {
          
            setEnterModalText('Your assets have been added');
            setLoadingDisplay('none');
            setEnterButtonsDisplay('');
      
        }, 4000);
        return () => clearTimeout(timer);
    
      } catch(e) {
        setEnterModalText(JSON.stringify(e.message));
        console.log(e)
        
      }
    
    
        } //end if wallet = anchor
  
  } //end addToPremintPool





  const removeFromPremintPool = async (PoolID, removalVector, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {

    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
    const walletProvider = localStorage.getItem('eosWalletProvider');

    if(walletProvider != 'anchor'){

      setEnterModalText('You are not logged in. Click "Wallet" in the top menu');
  
    }

  
      if(walletProvider == 'anchor'){
  
      // Retrieve current session from state
      const session = await anchor.login()
      //console.log(session[0].signTransaction);
    
  
    
      try {
        // Reset our response state to clear any previous transaction data
        
        const action = 
          [{
            account: 'waxdaomarket',
            name: 'removeassets',
            authorization: [{
              actor: anchor.users[0].accountName,
              permission: anchor.users[0].requestPermission,
          }],
          data: {
            pool_id: PoolID,
            user: anchor.users[0].accountName,
            assets_to_remove: removalVector,
          }
        }]
        // Call transact on the session (compatible with eosjs.transact)
        const response = await session[0].signTransaction({
          actions: action
        }, {
          blocksBehind: 3,
          expireSeconds: 60,
          broadcast: true,
        })
        // Update application state with the responses of the transaction
        setLoadingDisplay('');
        setEnterButtonsDisplay('hidden');
        setEnterModalText('Processing your transaction...')
        const timer = setTimeout(() => {
          
            setEnterModalText('Your assets have been returned to your wallet');
            setLoadingDisplay('none');
            setEnterButtonsDisplay('');
      
        }, 4000);
        return () => clearTimeout(timer);
    
      } catch(e) {
        setEnterModalText(JSON.stringify(e.message));
        console.log(e)
        
      }
    
    
        } //end if wallet = anchor
  
  } //end removeFromPremintPool



const PremintPage = ({ location }) => {

  const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode, currentUser, 
    setCurrentUser, tokenName, setTokenName, maxSupply, setMaxSupply, decimals, setDecimals, 
    tokenString, setTokenString, farmData, setFarmData, farmCollection, setFarmCollection, 
    farmSchema, setFarmSchema, farmRewards, setFarmRewards, farmExpires, setFarmExpires, 
    farmIcon, setFarmIcon, farmContract, setFarmContract, createdBy, setCreatedBy, unstaked, 
    setUnstaked, stakedOrUnstaked, setStakedOrUnstaked, stakedDisplay, setStakedDisplay, 
    unstakedDisplay, setUnstakedDisplay, validAssets, setValidAssets, commaSeparated, 
    setCommaSeparated, stakedAssets, setStakedAssets, claimable, setClaimable, minTime, 
    setMinTime, depositAmount, setDepositAmount, depositDisplay, setDepositDisplay, totalStaked, 
    setTotalStaked, ramBalance, setRamBalance, ramInfoDisplay, setRamInfoDisplay, farmTemplates, 
    setFarmTemplates, templatesDisplay, setTemplatesDisplay, farmType, setFarmType, 
    nftsCoveredByRam, setNftsCoveredByRam, hourlyPool, setHourlyPool, schemaDisplay, 
    setSchemaDisplay, includeOrExcludeMessage, setIncludeOrExcludeMessage, templateToView, 
    setTemplateToView, refreshAssets, setRefreshAssets, aboutDaoDisplay, setAboutDaoDisplay,
    aboutFarmDisplay, setAboutFarmDisplay, stakeNftsDisplay, setStakeNftsDisplay, 
    unstakeNftsDisplay, setUnstakeNftsDisplay, walletDisplay, setWalletDisplay, assetVector,
    setAssetVector, stakeMultipleDisplay, setStakeMultipleDisplay, highlightedFarm, 
    userCanUnlock,
    setUserCanUnlock,
    setHighlightedFarm,
    profile,
    setProfile,
    rewardType,
    setRewardType,
    rewardAmount,
    setRewardAmount,
    farmPrecision,
    setFarmPrecision,
    dropList,
    setDropList,
    dropPrice,
    setDropPrice,
    whitelistType,
    setWhitelistType,
    quantity,
    setQuantity,
    enterModalText,
    setEnterModalText,
    loadingDisplay, 
    setLoadingDisplay, 
    enterButtonsDisplay,
    setEnterButtonsDisplay,
    enterModalDisplay, 
    setEnterModalDisplay,
    rewardContract,
    setRewardContract,
    pricePerNFT,
    setPricePerNFT,
    dropImage,
    setDropImage,
    totalLeft,
    setTotalLeft,
    totalAvailable,
    setTotalAvailable,
    minimumMint,
    setMinimumMint,
    templateName,
    setTemplateName,
    dropDescription,
    setDropDescription,
    limitPerUser,
    setLimitPerUser,
    cooldownSeconds,
    setCooldownSeconds,
    dropBegins,
    setDropBegins,
    dropEnds,
    setDropEnds,
    displayName,
    setDisplayName,
    collection,
    setCollection,
    addRemoveText,
    setAddRemoveText,
    addDisplay,
    setAddDisplay,
    removeDisplay,
    setRemoveDisplay,
    viewPoolText,
    setViewPoolText,
    removalVector,
    setRemovalVector,
    pageCount,
    setPageCount,
    loadMoreDisplay,
    setLoadMoreDisplay,
    pageSize,
    setPageSize,
    totalAssets,
    setTotalAssets,
    setListOfAssets,
    listOfAssets,



   } = useStateContext();

const { PoolID } = useParams();


//check drops table for drop ID

useEffect(() => {
    setDropList([]);
    setDisplayName('');
    setDropPrice('');
    setWhitelistType('');
    setQuantity(1);
    setRewardContract('');
    setPricePerNFT('');
    setFarmPrecision('');
    setTokenName('');
    setDropImage('');
    setTotalLeft('');
    setTotalAvailable('');
    setMinimumMint('');
    setMaxSupply('');
    setTemplateName('');
    setDropDescription('');
    setFarmCollection('');
    setCreatedBy('');
    setLimitPerUser('');
    setCooldownSeconds('');
    setDropBegins('');
    setDropEnds('');
    setCollection('');
    setStakeMultipleDisplay('');
    setStakedAssets([]);
    setAddRemoveText('Add Assets To Pool');
    setAddDisplay('');
    setRemoveDisplay('hidden');
    setViewPoolText('View Pool');
    setRemovalVector([]);
    setPageCount(1);
    setLoadMoreDisplay('');
    setPageSize(100);
    setTotalAssets(0);
    setListOfAssets([]);
    

    
    axios.post(`${apiList[Math.floor(Math.random() * (2 - 0) ) + 0]}/v1/chain/get_table_rows`,{
        table:"premintpools",
        scope:"waxdaomarket",
        code:"waxdaomarket",
        limit:1,
        lower_bound: PoolID,
        upper_bound: PoolID,
        json:true
        })
        .then((response) => {
    
            setDropList([response.data.rows]);
            setDisplayName(response.data.rows[0].display_name);
            setCollection(response.data.rows[0].collection);
            setCreatedBy(response.data.rows[0].user);
            setTotalAssets(Number(response.data.rows[0].amount_of_assets));
            setListOfAssets(response.data.rows[0].assets);
            


            if( Number(response.data.rows[0].amount_of_assets) >= 1){

              axios.get(`${atomicApiList[Math.floor(Math.random() * (0 - 0) ) + 0]}/atomicassets/v1/assets?ids=${response.data.rows[0].assets.join("%2C")}&page=1&limit=100&order=desc&sort=asset_id`)
              .then((validResponse) => {
  
                setStakedAssets(validResponse.data.data);

                if(response.data.rows[0].amount_of_assets > 100){

                  setPageCount(2);
    
                } else { setLoadMoreDisplay('hidden'); }

                console.log(validResponse);
               
              })

          }





        })
        .catch((error) => console.log(error));  

      }
    
    , []);



    useEffect(() => {
        setAssetVector([]);
        setUnstaked([]);
            const abortCont5 = new AbortController();
        
            const eosUsername = localStorage.getItem('eosAccount');
            if(eosUsername){
            
            //console.log('You have an active session');
            '';
            //setProfileDisplay('flex');
            }else{
            //console.log('You are not logged in');
            return('');
            }
        
            //console.log(nameValue);
            if(collection != ''){
              axios.get(`${atomicApiList[Math.floor(Math.random() * (0 - 0) ) + 0]}/atomicassets/v1/assets?collection_name=${collection}&owner=${eosUsername}&page=1&limit=1000&order=desc&sort=asset_id`)
            .then((atomicResponse) => {
            
              setUnstaked(atomicResponse.data.data);
              //console.log(atomicResponse.data.data);
            
     
            })
            
        
        .catch((error) => console.log(error));
        }
        //return() => abortCont5.abort();
        
        }, [collection]);



        const getNextPage = (pageCount, setPageCount, stakedAssets, setStakedAssets, totalAssets, listOfAssets ) => {

          var lowerBound = pageCount;
      
          axios.get(`${atomicApiList[Math.floor(Math.random() * (0 - 0) ) + 0]}/atomicassets/v1/assets?ids=${listOfAssets.join("%2C")}&page=${pageCount}&limit=100&order=desc&sort=asset_id`)
          .then((validResponse) => {
            var list = [...stakedAssets];

            if(Array.isArray(stakedAssets[0])){
              list = stakedAssets[0];
              //console.log('it is an array');
            }

            list = list.concat(validResponse.data.data);
            //console.log(validResponse.data.data);

            setStakedAssets(list);

            if(pageCount * pageSize < totalAssets){
      
              setPageCount(pageCount + 1);
              //console.log(Number(response.data.next_key))

            } else { setLoadMoreDisplay('hidden'); }
           
          })
          .catch((error) => console.log(error));
            
      }

//if no result, render (this drop doesnt exist)

//if result, render the page

  return (
    <div id="seo">
    <Helmet>
    <title>Pool {PoolID} Details</title>
    <meta name="description" content={`View the assets in pool ${PoolID} on WaxDao`} />
    <link rel="canonical" href={`https://eos.waxdao.io/premint-pool/${PoolID}`} />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor]} appName={"WaxDAO"}>

    <Modal className={enterModalDisplay}>
      <ModalContent>
        {enterModalText}
        
      </ModalContent>

        <Spinner style={{display:loadingDisplay}}>
        <SpinnerRed  />
        <SpinnerBlue  />
        <SpinnerGreen />
        </Spinner>

      <br/>
      <GameButton onClick={() => setEnterModalDisplay('hidden') }>
        Close Window
      </GameButton>
      </Modal>



{/* START OF EDIT MODE */}

{currentUser != null && createdBy != null && currentUser != '' && createdBy != '' && currentUser == createdBy && (


<MainContainer>


<span className={addDisplay}>
<StakeManyDiv className={stakeMultipleDisplay} onClick={() => {addToPremintPool(PoolID, assetVector, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay)}}>
  Add {assetVector.length} NFTs <br/>
  
</StakeManyDiv>
</span>

<span className={removeDisplay}>
<RemoveManyDiv className={stakeMultipleDisplay} onClick={() => {removeFromPremintPool(PoolID, removalVector, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay)}}>
  Remove {removalVector.length} NFTs <br/>
  
</RemoveManyDiv>
</span>





<CreateDaoCont>
<DaoTitle>
{addRemoveText != null && addRemoveText}
&nbsp;&nbsp;


<DaoButton onClick={() => { if(addRemoveText == 'Add Assets To Pool'){setAddRemoveText('Remove Assets From Pool'); setRemoveDisplay(''); setAddDisplay('hidden'); setViewPoolText('Add Assets');} else{setAddRemoveText('Add Assets To Pool'); setRemoveDisplay('hidden'); setAddDisplay(''); setViewPoolText('View Pool');} }}>
{viewPoolText != null && viewPoolText}
</DaoButton>




</DaoTitle>
<NewProposal>



<span className={addDisplay}>


    <NFTsCont>

<MissingInfoMsg>
    Tip: Make sure your collection has RAM or the transaction will fail.
    <br/><br/>
    Tip: We highly, HIGHLY recommend adding small batches at a time if you are going to add a lot of assets.
    <br/><br/>
    Avoid frustration in case something goes wrong and you run into an error!
</MissingInfoMsg>



{ unstaked.length > 0 ? unstaked.map((item, index) => (


<span key={index}>
 

<NFT key={index} style={stakeMultipleDisplay == "hidden" ? {} : stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) > -1 ? {border:'1px solid #fa872d'} : stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) == -1 ? {border:'1px solid #696969'} : ''}>
<p key={index}><span className="font-bold">{item.name != null ? item.name : ''}</span>
      
<br/><span className="font-semibold">Template: {item.template != null ? item.template.template_id : 'None'}</span> 
<br/>Mint {item.template_mint != null ? item.template_mint : ''}
</p>

{item.data.img != null && (
<NFTLogo key={index} 
src={`https://ipfs.io/ipfs/${item.data.img.trim()}`} 
/>

)}

{item.data.img == null && item.data.video != null && (

<video style={{ width: "150px", maxWidth: "150px", height: "150px", maxHeight: "150px", borderRadius:'25%'}} loop>
<source src={`https://ipfs.io/ipfs/${item.data.video.trim()}`} type="video/mp4"/>
</video>



)}

{item.data.img == null && item.data.video == null && item.data.image != null && (
<NFTLogo key={index} 
src={`https://ipfs.io/ipfs/${item.data.image.trim()}`} 
/>

)}

<StakeManyButton key={index}
onClick={() => {
  if(stakeMultipleDisplay == "hidden"){
    stake()
  } else {

    if(assetVector.indexOf(item.asset_id) > -1){
      handleRemoveAsset(item.asset_id, assetVector, setAssetVector)
    } else {
      handleAddAsset(item.asset_id, assetVector, setAssetVector)
    }
  }
  }}
  
  style={stakeMultipleDisplay == "hidden" ? {} : stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) > -1 ? {border:'1px solid #fa872d'} : stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) == -1 ? {border:'1px solid #696969'} : ''}
  className={stakeMultipleDisplay == "hidden" ? "text-orange" : stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) > -1 ? "text-orange" : stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) == -1 ? "text-gray hover:text-black" : ''}
  >
{stakeMultipleDisplay == "hidden" && "Stake Now"}
{stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) > -1 && "Remove"}
{stakeMultipleDisplay == "" && assetVector.indexOf(item.asset_id) == -1 && "Add"}
</StakeManyButton>

</NFT>





  



</span>









)) : (
<NoDaosMessage>
  You don't have any assets in this collection.
</NoDaosMessage>


)}


</NFTsCont>
</span>






<span className={removeDisplay}>


    <NFTsCont>


{ stakedAssets.length > 0 ? stakedAssets.map((item, index) => (


<span key={index}>
 

<NFT key={index} style={stakeMultipleDisplay == "hidden" ? {} : stakeMultipleDisplay == "" && removalVector.indexOf(item.asset_id) > -1 ? {border:'1px solid indianred'} : stakeMultipleDisplay == "" && removalVector.indexOf(item.asset_id) == -1 ? {border:'1px solid #696969'} : ''}>
<p key={index}><span className="font-bold">{item.name != null ? item.name : ''}</span>
      
<br/><span className="font-semibold">Template: {item.template != null ? item.template.template_id : 'None'}</span> 
<br/>Mint {item.template_mint != null ? item.template_mint : ''}
</p>

{item.data.img != null && (
<NFTLogo key={index} 
src={`https://ipfs.io/ipfs/${item.data.img.trim()}`} 
/>

)}

{item.data.img == null && item.data.video != null && (

<video style={{ width: "150px", maxWidth: "150px", height: "150px", maxHeight: "150px", borderRadius:'25%'}} loop>
<source src={`https://ipfs.io/ipfs/${item.data.video.trim()}`} type="video/mp4"/>
</video>



)}

{item.data.img == null && item.data.video == null && item.data.image != null && (
<NFTLogo key={index} 
src={`https://ipfs.io/ipfs/${item.data.image.trim()}`} 
/>

)}

<RemoveManyButton key={index}
onClick={() => {
  if(stakeMultipleDisplay == "hidden"){
    stake()
  } else {

    if(removalVector.indexOf(item.asset_id) > -1){
      handleRemovingFromRemoval(item.asset_id, removalVector, setRemovalVector)
    } else {
      handleAddingToRemoval(item.asset_id, removalVector, setRemovalVector)
    }
  }
  }}
  
  style={stakeMultipleDisplay == "hidden" ? {} : stakeMultipleDisplay == "" && removalVector.indexOf(item.asset_id) > -1 ? {border:'1px solid indianred'} : stakeMultipleDisplay == "" && removalVector.indexOf(item.asset_id) == -1 ? {border:'1px solid #696969'} : ''}
  className={stakeMultipleDisplay == "hidden" ? "text-indian" : stakeMultipleDisplay == "" && removalVector.indexOf(item.asset_id) > -1 ? "text-indian" : stakeMultipleDisplay == "" && removalVector.indexOf(item.asset_id) == -1 ? "text-gray hover:text-black" : ''}
  >
{stakeMultipleDisplay == "hidden" && "Stake Now"}
{stakeMultipleDisplay == "" && removalVector.indexOf(item.asset_id) > -1 && "Remove"}
{stakeMultipleDisplay == "" && removalVector.indexOf(item.asset_id) == -1 && "Add"}
</RemoveManyButton>

</NFT>



  



</span>









)) : (
<NoDaosMessage>
  This pool doesn't have any assets.
</NoDaosMessage>


)}


</NFTsCont>
</span>










</NewProposal>



    
    {/* //end if drop price is not null */}

    

    {(displayName == null || displayName == '') && (


    <DropPageCont>
        <MissingInfoMsg>
        This drop does not exist.<br/><br/>
        If this is an error, we may be having API issues.
        </MissingInfoMsg>
    </DropPageCont>


    )} 

    {/* //end if drop price is not null */}  
    
    </CreateDaoCont>
    
    </MainContainer>



)} 

{/* END OF EDIT MODE */}




{/* START OF NORMAL MODE */}

{currentUser != null && createdBy != null && currentUser != createdBy && (


<MainContainer>



<CreateDaoCont>
<DaoTitle>
Pool Assets
</DaoTitle>
<NewProposal>










    <NFTsCont>


{ stakedAssets.length > 0 ? stakedAssets.map((item, index) => (


<span key={index}>
 

<NFT key={index} style={stakeMultipleDisplay == "hidden" ? {} : stakeMultipleDisplay == "" && removalVector.indexOf(item.asset_id) > -1 ? {border:'1px solid indianred'} : stakeMultipleDisplay == "" && removalVector.indexOf(item.asset_id) == -1 ? {border:'1px solid #696969'} : ''}>
<p key={index}><span className="font-bold">{item.name != null ? item.name : ''}</span>
      
<br/><span className="font-semibold">Template: {item.template != null ? item.template.template_id : 'None'}</span> 
<br/>Mint {item.template_mint != null ? item.template_mint : ''}
</p>

{item.data.img != null && (
<NFTLogo key={index} 
src={`https://ipfs.io/ipfs/${item.data.img.trim()}`} 
/>

)}

{item.data.img == null && item.data.video != null && (

<video style={{ width: "150px", maxWidth: "150px", height: "150px", maxHeight: "150px", borderRadius:'25%'}} loop>
<source src={`https://ipfs.io/ipfs/${item.data.video.trim()}`} type="video/mp4"/>
</video>



)}

{item.data.img == null && item.data.video == null && item.data.image != null && (
<NFTLogo key={index} 
src={`https://ipfs.io/ipfs/${item.data.image.trim()}`} 
/>

)}

</NFT>


</span>









)) : (
<NoDaosMessage>
  This pool doesn't have any assets.
</NoDaosMessage>


)}


</NFTsCont>






<br/>
<NewRow />
<DaoButton className={loadMoreDisplay} onClick={() => { getNextPage(pageCount, setPageCount, stakedAssets, setStakedAssets, totalAssets, listOfAssets) }}>
  Load More
</DaoButton>


<DisabledButton className={loadMoreDisplay != 'hidden' && 'hidden'} >
  No More Results
</DisabledButton>




</NewProposal>



    
    {/* //end if drop price is not null */}

    

    {(displayName == null || displayName == '') && (


    <DropPageCont>
        <MissingInfoMsg>
        This drop does not exist.<br/><br/>
        If this is an error, we may be having API issues.
        </MissingInfoMsg>
    </DropPageCont>


    )} 

    {/* //end if drop price is not null */}  
    
    </CreateDaoCont>
    
    </MainContainer>



)} 


{/* END OF NORMAL MODE */}










    </UALProvider>
    <br/><br/><br/>
    </div>
  )
}

export default PremintPage