import React, { useEffect } from 'react';
import { GridComponent, Resize, Sort, ContextMenu, Filter, Page, ExcelExport, PdfExport, Edit, Inject, Search, Toolbar } from '@syncfusion/ej2-react-grids';
import { ordersData, contextMenuItems, ordersGrid, nftFarmData, nftFarmGrid } from '../data/dummy';
import { Header } from '../components';
import { ColumnDirective, ColumnsDirective } from '@syncfusion/ej2-react-charts';
import { useParams } from 'react-router-dom';
import { useStateContext } from '../contexts/ContextProvider';
import { Anchor } from 'ual-anchor';
import { Wax } from '@eosdacio/ual-wax';
import { User } from "universal-authenticator-library";
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { Api, JsonRpc } from "eosjs";
import {Helmet} from "react-helmet";
import axios from 'axios';
import { data } from 'autoprefixer';
import { AllFarmsCont, PoolAmount, FarmButton, SingleFarmCont, FarmLogo, FarmTitle, FarmsPageCont, FarmsTitle, HighlightedFarm, HighlightedLogo, HighlightDetails, HighlightTitle, FarmEndDate, HighlightMessage, HighlightAmount } from '../components/LeftDiv';
import NumberFormat from 'react-number-format';

let zero = '0';

var thisUsersFarms;

const myChain = {
  chainId: 'aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906',
  rpcEndpoints: [{
      protocol: 'https',
      host: 'eos.greymass.com',
      port: '443'
  }]
};

const rpc = new JsonRpc('https://eos.greymass.com');


const anchor = new Anchor([myChain], {

  appName: 'WaxDAO',

});



const Farms = () => {

  const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode, currentUser, tokenName, 
    setTokenName, maxSupply, setMaxSupply, decimals, setDecimals, tokenString, setTokenString, farmData, 
    setFarmData, farmCollection, setFarmCollection, farmSchema, setFarmSchema, farmRewards, setFarmRewards, 
    farmExpires, setFarmExpires, farmIcon, setFarmIcon, farmContract, setFarmContract, createdBy, setCreatedBy, 
    farmList, setFarmList, stakedOnly, setStakedOnly, farmsUserIsIn, setFarmsUserIsIn, highlightedFarm,
    setHighlightedFarm
  } = useStateContext();

const { FarmName } = useParams();

useEffect(() => {

  const abortCont6 = new AbortController();

  if(stakedOnly != 'yes'){

  axios.post('https://eos.api.eosnation.io/v1/chain/get_table_rows',{
    table:"farms",
    scope:"waxdaofarmer",
    code:"waxdaofarmer",
    //key_type: 'name',
    //indexName: 'farmname',
    limit:1000,
    //lower_bound:FarmName,
    //upper_bound:FarmName,
    json:true,
    signal: abortCont6.signal
  })
    .then((response) => {



      setFarmList([]);
      
      //for each response . data . rows, if the farmname is not in thisUsersFarms, remove it from response.data
  
      setFarmList([response.data.rows]);

    })
    .catch((error) => console.log(error));
  }

  if(stakedOnly == 'yes'){

  const eosUsername = localStorage.getItem('eosAccount');
  
  if(eosUsername){
    //console.log('You have an active session');
    '';
    //setProfileDisplay('flex');
  }else{
    //console.log('You are not logged in');
    return('');
  }

  //first get stakerzz list for their username, then add each farmname to an array

  axios.post('https://eos.api.eosnation.io/v1/chain/get_table_rows',{
    table:"stakerzz",
    scope:"waxdaofarmer",
    code:"waxdaofarmer",
    key_type: 'name',
    index_position: '2',
    limit:1000,
    lower_bound:eosUsername,
    upper_bound:eosUsername,
    json:true
  }).then((userFarmResponse) => {
 


    setFarmsUserIsIn(userFarmResponse);

    //console.log(userFarmResponse);

    let thisUsersFarms = [];

    // display all values
  for (var i = 0; i < userFarmResponse.data.rows.length; i++) {
    //console.log(userFarmResponse.data.rows[i].farmname);
    thisUsersFarms.push(userFarmResponse.data.rows[i].farmname);
  }


    //userFarmResponse.data.rows.forEach(thisUsersFarms.push(rows.farmname));

    //console.log(thisUsersFarms);

    


 //then get the list of farms, and if farm is not in farmnames array, remove it from the new list

 axios.post('https://eos.api.eosnation.io/v1/chain/get_table_rows',{
  table:"farms",
  scope:"waxdaofarmer",
  code:"waxdaofarmer",
  //key_type: 'name',
  //indexName: 'farmname',
  limit:1000,
  //lower_bound:FarmName,
  //upper_bound:FarmName,
  json:true,
  signal: abortCont6.signal
})
  .then((response) => {

    setFarmList([]);
    let testList = [];


        // display all values
  for (var b = 0; b < response.data.rows.length; b++) {
    //console.log(userFarmResponse.data.rows[i].farmname);

    if(thisUsersFarms.includes(response.data.rows[b].farmname)){
    testList.push(response.data.rows[b]);
    setFarmList(farmList => [...farmList, response.data.rows[b]]);
    //console.log(response.data.rows[b].farmname);
    }

  }



    
  })
  .catch((error) => console.log(error));








  })

.catch((error) => console.log(error));


 




  } //end if stakedonly == yes




    return() => abortCont6.abort();

}, [stakedOnly]);








  return (
  <div id="seo">
    <Helmet>
    <title>Farms</title>
    <meta name="description" content="NFT staking pools on WaxDao" />
    <link rel="canonical" href="https://waxdao.io/farms" />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor]} appName={"WaxDAO"}>
    <FarmsPageCont>
      <FarmsTitle>
       NFT Farms &nbsp; &nbsp; 
          <FarmButton onClick={() => {if(stakedOnly == 'yes'){setStakedOnly('no')} 
          else {setStakedOnly('yes')}}}>{stakedOnly == 'yes' ? 'All Farms' : 'My Farms'}
          </FarmButton>
      </FarmsTitle>
   
      <div>

        </div>

<AllFarmsCont>

        {farmList[1] == null && farmList[0] != null && Array.isArray(farmList[0]) ? (farmList[0].map((item, index) => (
            <a href={`https://eos.waxdao.io/farm/${item.farmname}`}>
            <SingleFarmCont>
              {item.logo != null && (
                <FarmLogo src={`https://ipfs.io/ipfs/${item.logo}`} />
              )}
              {item.farmname != null && (
                <FarmTitle>
                {item.farmname}
                </FarmTitle>
              )}
              {item.poolsize != null && (
                <PoolAmount>
                  <NumberFormat displayType='text' thousandSeparator={true} value={Math.round(item.poolsize.substring(0, item.poolsize.indexOf(' ')))} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} />
                 {item.poolsize.substring(item.poolsize.indexOf(' '))}
                </PoolAmount>
              )}
              {item.enddate != null && (
                <FarmEndDate>
                  Ends {new Date(item.enddate * 1000).toLocaleDateString()}
                </FarmEndDate>
              )}

            </SingleFarmCont>
            </a>
            ))) : farmList[1] != null && Array.isArray(farmList) && farmList.map((item, index) => (
              <a href={`https://eos.waxdao.io/farm/${item.farmname}`}>
              <SingleFarmCont>
              {item.logo != null && (
                <FarmLogo src={`https://ipfs.io/ipfs/${item.logo}`} />
              )}
              {item.farmname != null && (
                <FarmTitle>
                {item.farmname}
                </FarmTitle>
              )}
              {item.poolsize != null && (
                <PoolAmount>
                  <NumberFormat displayType='text' thousandSeparator={true} value={Math.round(item.poolsize.substring(0, item.poolsize.indexOf(' ')))} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} />
                 {item.poolsize.substring(item.poolsize.indexOf(' '))}
                </PoolAmount>
              )}
              {item.enddate != null && (
                <FarmEndDate>
                  Ends {new Date(item.enddate * 1000).toLocaleDateString()}
                </FarmEndDate>
              )}

             </SingleFarmCont>
             </a>
              ))}

       

       
       
      
      </AllFarmsCont>
      </FarmsPageCont>
    </UALProvider>
    <br/><br/><br/>
    </div>
  )
}

export default Farms