import React, { useEffect } from 'react';
import { GridComponent, Resize, Sort, ContextMenu, Filter, Page, ExcelExport, PdfExport, Edit, Inject } from '@syncfusion/ej2-react-grids';
import { ordersData, contextMenuItems, ordersGrid, nftFarmData, nftFarmGrid } from '../data/dummy';
import { Header } from '../components';
import { ColumnDirective, ColumnsDirective } from '@syncfusion/ej2-react-charts';
import { useParams } from 'react-router-dom';
import { useStateContext } from '../contexts/ContextProvider';
import { Anchor } from 'ual-anchor';
import { Wax } from '@eosdacio/ual-wax';
import { User } from "universal-authenticator-library";
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { Api, JsonRpc } from "eosjs";
import {Helmet} from "react-helmet";
import axios from 'axios';
import { data } from 'autoprefixer';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';



const myChain = {
  chainId: '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4',
  rpcEndpoints: [{
      protocol: 'https',
      host: 'wax.greymass.com',
      port: '443'
  }]
};

const rpc = new JsonRpc('https://wax.greymass.com');


const anchor = new Anchor([myChain], {

  appName: 'WaxDAO',

});

const wax = new Wax([myChain], {

  appName: 'WaxDAO',

});


const ualLogin = async () => {
 
  //console.log('Loading...');
  await anchor.login();
  getCustomersData();
  //localStorage.setItem('waxAccount', 'anchor.users[0].accountName')
  //console.log(anchor.users[0].accountName);

}

const ualLogout = async () => {
 
  //console.log('Loading...');
  await anchor.logout();
  //console.log(anchor.users[0].accountName);

}



const buyNow = async (quantity) => {
  const walletProvider = localStorage.getItem('walletProvider');

  const amountToSend = (quantity * 109) + '.00000000 WAX';

  if(walletProvider == 'wax'){


    
  


  // Retrieve current session from state
  const session = await wax.login()
  //console.log(session[0].signTransaction);
//console.log(wax.users[0].accountName);


  try {
    // Reset our response state to clear any previous transaction data
    
    const action = [{
          account: 'eosio.token',
          name: 'transfer',
          authorization: [{
            actor: wax.users[0].accountName,
            permission: wax.users[0].requestPermission,
        }],
        data: {
            to: 'waxywojaknft',
            from: wax.users[0].accountName,
            quantity: amountToSend,
            memo: 'purchase',
        }
      }]
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction({
      actions: action
    }, {

      //
      blocksBehind: 3,
      expireSeconds: 60,
      broadcast: true,
    })
    // Update application state with the responses of the transaction
    alert('Success! You can view your packs on the "Unpack" page');
  } catch(e) {
    alert(e)
    console.log(e)
    
  }

    } //end if wallet = wax



    if(walletProvider == 'anchor'){

    // Retrieve current session from state
    const session = await anchor.login()
    //console.log(session[0].signTransaction);
  

  
    try {
      // Reset our response state to clear any previous transaction data
      
      const action = [{
            account: 'eosio.token',
            name: 'transfer',
            authorization: [{
              actor: anchor.users[0].accountName,
              permission: anchor.users[0].requestPermission,
          }],
          data: {
              to: 'waxywojaknft',
              from: anchor.users[0].accountName,
              quantity: amountToSend,
              memo: 'purchase',
          }
        }]
      // Call transact on the session (compatible with eosjs.transact)
      const response = await session[0].signTransaction({
        actions: action
      }, {

        //
        broadcast: true,
      })
      // Update application state with the responses of the transaction
      alert('Success! You can view your packs on the "Unpack" page');
    } catch(e) {
      alert(e)
      console.log(e)
      
    }
  
  
      } //end if wallet = anchor

}







const print = async () => {
  const walletProvider = localStorage.getItem('walletProvider');



  if(walletProvider == 'wax'){


    
  


  // Retrieve current session from state
  const session = await wax.login()
  //console.log(session[0].signTransaction);
//console.log(wax.users[0].accountName);


  try {
    // Reset our response state to clear any previous transaction data
    
    const action = [{
          account: 'waxdaofarmer',
          name: 'roll',
          authorization: [{
            actor: wax.users[0].accountName,
            permission: wax.users[0].requestPermission,
        }],
        data: {
            user: 'waxywojaknft',
        }
      }]
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction({
      actions: action
    }, {

      //
      blocksBehind: 3,
      expireSeconds: 60,
      broadcast: true,
    })
    // Update application state with the responses of the transaction
    alert(JSON.stringify(response));
  } catch(e) {
    alert(e)
    console.log(e)
    
  }

    } //end if wallet = wax



    if(walletProvider == 'anchor'){

    // Retrieve current session from state
    const session = await anchor.login()
    //console.log(session[0].signTransaction);
  

  
    try {
      // Reset our response state to clear any previous transaction data
      
      const action = [{
            account: 'waxdaofarmer',
            name: 'roll',
            authorization: [{
              actor: anchor.users[0].accountName,
              permission: anchor.users[0].requestPermission,
          }],
          data: {
            user: 'waxywojaknft',
          }
        }]
      // Call transact on the session (compatible with eosjs.transact)
      const response = await session[0].signTransaction({
        actions: action
      }, {

        //
        broadcast: true,
      })
      // Update application state with the responses of the transaction
      alert(response);
      console.log(response);
    } catch(e) {
      alert(e)
      console.log(e)
      
    }
  
  
      } //end if wallet = anchor

}





const Drop = ({ location }) => {

  const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode, currentUser, setCurrentUser, tokenName, setTokenName, maxSupply, setMaxSupply, decimals, setDecimals, tokenString, setTokenString, farmData, setFarmData, farmCollection, setFarmCollection, farmSchema, setFarmSchema, farmRewards, setFarmRewards, farmExpires, setFarmExpires, farmIcon, setFarmIcon, farmContract, setFarmContract, createdBy, setCreatedBy, unstaked, setUnstaked, stakedOrUnstaked, setStakedOrUnstaked, stakedDisplay, setStakedDisplay, unstakedDisplay, setUnstakedDisplay, validAssets, setValidAssets, commaSeparated, setCommaSeparated, stakedAssets, setStakedAssets, claimable, setClaimable, burnableDisplay, setBurnableDisplay, burnable, setBurnable, quantity, setQuantity } = useStateContext();

  useEffect(() => {
    const abortCont2 = new AbortController();

    //clear any previous state data
    setQuantity('');

    


      }

    
    , []);



  return (
    <div id="seo">
    <Helmet>
    <title>Buy Wojak NFTs - WaxDAO</title>
    <meta name="description" content="Looking to grab a Wojak NFT? Grab one here." />
    <link rel="canonical" href="https://waxdao.io/wojak/unpack" />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor, wax]} appName={"WaxDAO"}>
    <br/><br/><br/>
    <div className="m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl">
    <Header title="Wojak NFT Drop" category="NFT" />
    <p className="mt-4 text-2xl">Looking to snag a Wojak pack?</p>
    <p className="mt-4 text-xl">Grab one here.</p>  
  
        <div id="burnableNFTs" 
          className={`gap-4 flex-wrap items-center justify-center align-center text-center
          mt-4 border-2 rounded-lg p-4 m-auto `}
          style={{borderColor: currentColor, width: '250px', maxWidth: '250px'}}
          
          >
         
              <p ><span className="font-bold">Wojak Pack</span></p>
              <img 
                src={`https://ipfs.io/ipfs/QmcMHEk3SLzQEoYDykiCy1bJ6DuYy7fwQWsByVhfQuY7pL`} 
                style={{width:'150px', maxWidth:'150px'}} 
                className="hover:drop-shadow-xl m-auto" 
              
              />

<p ><span className="font-bold">Price: 109 Wax</span></p>
<label htmlFor="quantity" ><br />Quantity:<br /></label>
      <TooltipComponent content="How many Wojaks do you want to buy?"
      position="BottomCenter">
      <input type="number" 
      placeholder='10'
      id="quantity" 
      className="border-2 rounded-lg text-xl m-auto"
      style={{borderColor: currentColor, width: '100px'}}
      value={quantity}
      onChange={(e) => {

        if(e.target.value < 1){
          //console.log("false");
          setQuantity(1)
        }

        else{
          setQuantity(Math.round(e.target.value))
        }

      }}
      />
      </TooltipComponent>
      <span className="font-bold">Total: {(quantity * 109)} WAX<br/></span>
              <button
          type="button"
          className="text-lg p-3
          hover:drop-shadow-xl
          hover:bg-light-gray
          text-white rounded-lg mt-4"
          onClick={() => buyNow(quantity)}
          style={{ background: currentColor
          }}
        
        >
          
          Buy Now
        </button>
          



      </div>

   

    </div>
    </UALProvider>
    <br/><br/><br/>
    </div>
  )
}

export default Drop