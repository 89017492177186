import React from 'react';
import { useStateContext } from '../contexts/ContextProvider';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

const MODAL_STYLES = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#FFF',
    padding: '50px',
    zIndex: 1000,
    textAlign: 'center',
    borderRadius: '15px'

}

const OVERLAY_STYLES = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, .7)',
    zIndex: 1000

}

const ListingModal = ({ open, children, onClose, asset }) => {
    const { currentColor, listingIsOpen, setListingIsOpen, assetID, setAssetID } = useStateContext();
    if(!open) return null;
  return (
      <>
      <div style={OVERLAY_STYLES} />
    <div style={MODAL_STYLES}>


      {children}
      <br />
        <button 
          type="button"
          className="text-lg p-3
          hover:drop-shadow-xl
          hover:bg-light-gray
          text-white rounded-lg"
          onClick={() => {
            setListingIsOpen(false);
          
          }}
          style={{ background: currentColor
          }}
        
        >
          Close Window
        </button>

        
    </div>
    </>
  )
}

export default ListingModal