import React, { useEffect } from 'react';
import { GridComponent, Resize, Sort, ContextMenu, Filter, Page, ExcelExport, PdfExport, Edit, Inject } from '@syncfusion/ej2-react-grids';
import { ordersData, contextMenuItems, ordersGrid, nftFarmData, nftFarmGrid } from '../data/dummy';
import { Header } from '../components';
import { ColumnDirective, ColumnsDirective } from '@syncfusion/ej2-react-charts';
import { useParams } from 'react-router-dom';
import { useStateContext } from '../contexts/ContextProvider';
import { Anchor } from 'ual-anchor';
import { Wax } from '@eosdacio/ual-wax';
import { User } from "universal-authenticator-library";
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { Api, JsonRpc } from "eosjs";
import {Helmet} from "react-helmet";
import axios from 'axios';
import { data } from 'autoprefixer';

const myChain = {
  chainId: '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4',
  rpcEndpoints: [{
      protocol: 'https',
      host: 'wax.greymass.com',
      port: '443'
  }]
};

const rpc = new JsonRpc('https://wax.greymass.com');


const anchor = new Anchor([myChain], {

  appName: 'WaxDAO',

});

const wax = new Wax([myChain], {

  appName: 'WaxDAO',

});


const ualLogin = async () => {
 
  //console.log('Loading...');
  await anchor.login();
  getCustomersData();
  //localStorage.setItem('waxAccount', 'anchor.users[0].accountName')
  //console.log(anchor.users[0].accountName);

}

const ualLogout = async () => {
 
  //console.log('Loading...');
  await anchor.logout();
  //console.log(anchor.users[0].accountName);

}



const openPack = async (assetID, refreshAssets, setRefreshAssets) => {
  const walletProvider = localStorage.getItem('walletProvider');

  if(walletProvider == 'wax'){


    
  


  // Retrieve current session from state
  const session = await wax.login()
  //console.log(session[0].signTransaction);
//console.log(wax.users[0].accountName);


  try {
    // Reset our response state to clear any previous transaction data
    
    const action = [{
          account: 'atomicassets',
          name: 'transfer',
          authorization: [{
            actor: wax.users[0].accountName,
            permission: wax.users[0].requestPermission,
        }],
        data: {
            to: 'waxywojaknft',
            from: wax.users[0].accountName,
            asset_ids: [assetID],
            memo: 'pack opening',
        }
      }]
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction({
      actions: action
    }, {
      // Optional: Prevent anchor-link from broadcasting this transaction (default: True)
      //
      //    The wallet/signer connected to this app will NOT broadcast the transaction
      //    as is defined by the anchor-link protocol. Broadcasting is the responsibility
      //    of anchor-link running inside an application (like this demo).
      //
      //    For this demo specifically we do NOT want the transaction to ever be broadcast
      //    to the blockchain, so we're disabling it here.
      //
      //    For all normal applications using anchor-link, you can omit this.
      //
      blocksBehind: 3,
      expireSeconds: 60,
      broadcast: true,
    })
    // Update application state with the responses of the transaction
    alert('Success!');
    setTimeout(() => {
      setRefreshAssets(refreshAssets + 1);
    }, 2000);
    
  } catch(e) {
    alert(e)
    console.log(e)

      setRefreshAssets(refreshAssets + 1);

    
  }

    } //end if wallet = wax



    if(walletProvider == 'anchor'){

    // Retrieve current session from state
    const session = await anchor.login()
    //console.log(session[0].signTransaction);
  

  
    try {
      // Reset our response state to clear any previous transaction data
      
      const action = [{
            account: 'atomicassets',
            name: 'transfer',
            authorization: [{
              actor: anchor.users[0].accountName,
              permission: anchor.users[0].requestPermission,
          }],
          data: {
              to: 'waxywojaknft',
              from: anchor.users[0].accountName,
              asset_ids: [assetID],
              memo: 'pack opening',
          }
        }]
      // Call transact on the session (compatible with eosjs.transact)
      const response = await session[0].signTransaction({
        actions: action
      }, {
        // Optional: Prevent anchor-link from broadcasting this transaction (default: True)
        //
        //    The wallet/signer connected to this app will NOT broadcast the transaction
        //    as is defined by the anchor-link protocol. Broadcasting is the responsibility
        //    of anchor-link running inside an application (like this demo).
        //
        //    For this demo specifically we do NOT want the transaction to ever be broadcast
        //    to the blockchain, so we're disabling it here.
        //
        //    For all normal applications using anchor-link, you can omit this.
        //
        broadcast: true,
      })
      // Update application state with the responses of the transaction
      alert('Success!');

        setRefreshAssets(refreshAssets + 1);

      
    } catch(e) {
      alert(e)
      console.log(e)

        setRefreshAssets(refreshAssets + 1);

      
    }
  
  
      } //end if wallet = anchor

}





const Unpack = ({ location }) => {

  const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode, currentUser, setCurrentUser, tokenName, setTokenName, maxSupply, setMaxSupply, decimals, setDecimals, tokenString, setTokenString, farmData, setFarmData, farmCollection, setFarmCollection, farmSchema, setFarmSchema, farmRewards, setFarmRewards, farmExpires, setFarmExpires, farmIcon, setFarmIcon, farmContract, setFarmContract, createdBy, setCreatedBy, unstaked, setUnstaked, stakedOrUnstaked, setStakedOrUnstaked, stakedDisplay, setStakedDisplay, unstakedDisplay, setUnstakedDisplay, validAssets, setValidAssets, commaSeparated, setCommaSeparated, stakedAssets, setStakedAssets, claimable, setClaimable, burnableDisplay, setBurnableDisplay, burnable, setBurnable, setNoAssetsDisplay, noAssetsDisplay, refreshAssets, setRefreshAssets } = useStateContext();

const { FarmName } = useParams();




useEffect(() => {
  const abortCont2 = new AbortController();
  setBurnable([]);
  setBurnableDisplay('hidden');
  setNoAssetsDisplay('block');
   const waxUsername = localStorage.getItem('waxAccount');
   const walletProvider = localStorage.getItem('walletProvider');
    
   if(waxUsername){
     //console.log('You have an active session');
     '';
     //setProfileDisplay('flex');
   }else{
     //console.log('You are not logged in');
     return('');
   }

        axios.get(`https://wax.api.atomicassets.io/atomicassets/v1/assets?collection_name=hoodpunknfts&schema_name=wojakpacks&owner=${waxUsername}&page=1&limit=50&order=desc&sort=asset_id`, {signal: abortCont2.signal})
          .then((burnResponse) => {

            if(burnResponse.data.data[0] != undefined){
              setBurnableDisplay('flex')
              setNoAssetsDisplay('hidden')

            }
      
            setBurnable(burnResponse.data.data);
            //console.log(burnResponse);
          })


      .catch((error) => console.log(error));
  
      return() => {
      
        abortCont2.abort();
        
      }

    }

  
  , [refreshAssets]);












  return (
    <div id="seo">
    <Helmet>
    <title>Unpack Wojak NFTs - WaxDAO</title>
    <meta name="description" content="Unpack your Wojak NFTs to get discounts and whitelist access to our products. " />
    <link rel="canonical" href="https://waxdao.io/wojak/unpack" />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor, wax]} appName={"WaxDAO"}>
    <br/><br/><br/>
    <div className="m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl">
    <Header title="Unpack Wojaks" category="NFT" />
    <p className="mt-4 text-2xl">Need to unpack your Wojak NFTs?</p>
    <p className="mt-4 text-xl">It's as easy as clicking the "Unpack" button below any of your packs.</p>  
    <p className={`text-xl mt-4 border-2 rounded-lg p-4 ${noAssetsDisplay}`}
      style={{ borderColor: currentColor }}
    >You have no Wojak packs. Did you mean to go to the <a href="https://waxdao.io/wojak/unpack" style={{ color: currentColor }}>opened NFTs page</a>?
    <br />
    You can also grab packs at the <a href="https://waxdao.io/wojak/drop" style={{ color: currentColor }}>drop page</a>.
    
    </p>

        <div id="burnableNFTs" 
          className={`gap-4 flex-wrap items-center justify-center align-center ${burnableDisplay}`}
          
          >

          {burnable.map((item, index) => (

      <div 
        className='p-3 m-3 border-2 rounded-lg gap-4 mt-4 text-center hover:drop-shadow-xl'
        style={{ display:'inline-block', borderColor:currentColor }}
        >
         
              <p key={index}>Mint #<span className="font-bold">{item.template_mint}</span></p>
              <img key={index} 
                src={`https://ipfs.io/ipfs/${item.data.img}`} 
                style={{width:'150px', maxWidth:'150px'}} 
                className="hover:drop-shadow-xl m-auto" 
              
              />
              
              <button key={index}
          type="button"
          className="text-lg p-3
          hover:drop-shadow-xl
          hover:bg-light-gray
          text-white rounded-lg mt-4"
          onClick={() => openPack(item.asset_id, refreshAssets, setRefreshAssets)}
          style={{ background: currentColor
          }}
        
        >
          Unpack
        </button>
          


      </div>

))}




      </div>

   

    </div>
    </UALProvider>
    <br/><br/><br/>
    </div>
  )
}

export default Unpack