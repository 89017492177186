import React, { useEffect } from 'react';
import { Header } from '../../components';
import { useParams } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import { Anchor } from 'ual-anchor';
import { Wax } from '@eosdacio/ual-wax';
import { User } from "universal-authenticator-library";
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { Api, JsonRpc } from "eosjs";
import {Helmet} from "react-helmet";
import axios from 'axios';
import { data } from 'autoprefixer';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { BsInfoCircle } from 'react-icons/bs';
import { AboutDAO, AboutDaoBody, AboutDaoCont, AboutDaoTitle, Creator, DaoButton, DaoButtonCont, DaoName, DaoTitle, FarmButton, FarmButtonCont, FarmMenu, FarmMenuItem, HighlightSlot, HighlightTitle, LeftDiv, MainContainer, PropDescription, ProposalInput, ProposerTypeMessage, RightDiv, StakeLeftRow, StakeRightRow, StakersCont, StakersHeader, StakersLeft, StakersRight, StakersRow, TimeSlotsCont, Wallet, WalletCont, FarmTopMenu, FarmTopMenuItem, DropDown, Modal, ModalContent, Spinner, SpinnerRed, SpinnerBlue, SpinnerGreen, GameButton, UserWhitelist, MissingInfoMsg, DisabledButton, NotLoggedIn } from '../../components/LeftDiv';
import { Name, raw } from "eos-common";


let zero = '0';

const myChain = {
  chainId: 'aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906',
  rpcEndpoints: [{
      protocol: 'https',
      host: 'eos.greymass.com',
      port: '443'
  }]
};

const rpc = new JsonRpc('https://eos.greymass.com');


const anchor = new Anchor([myChain], {

  appName: 'WaxDAO',

});



const newProfile = async (PoolName, description, telegram, discord, twitter, website, nftDrop, image1, image2, image3, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {

  setEnterModalDisplay('');
  setEnterModalText('Awaiting confirmation...');



  const walletProvider = localStorage.getItem('eosWalletProvider');


  if(walletProvider == 'anchor'){

    // Retrieve current session from state
    const session = await anchor.login()
    //console.log(session[0].signTransaction);
  
    try {
      // Reset our response state to clear any previous transaction data
      
      const action = [{
            account: "waxdaofarmer",
            name: 'newprofile',
            authorization: [{
              actor: anchor.users[0].accountName,
              permission: anchor.users[0].requestPermission,
          }],
          data: {
              user: anchor.users[0].accountName,
              farmname: PoolName,
              description: description,
              telegram: telegram,
              discord: discord,
              twitter: twitter,
              website: website,
              latest_drop: nftDrop,
              image1: image1,
              image2: image2,
              image3: image3,
          }
        }]
      // Call transact on the session (compatible with eosjs.transact)
      const response = await session[0].signTransaction({
        actions: action
      }, {

        blocksBehind: 3,
        expireSeconds: 60,
        broadcast: true,
      })
      // Update application state with the responses of the transaction
      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Creating your profile...')
      const timer = setTimeout(() => {
        
          setEnterModalText('Your profile is now created');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');
    
      }, 4000);
      return () => clearTimeout(timer);
  
    } catch(e) {
      setEnterModalText(JSON.stringify(e.message));
      console.log(e)
      
    }
    
    }//end if wallet = anchor

} //end addTemplates














const extendFarm = async (PoolName, daysToExtend, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {
    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
    const walletProvider = localStorage.getItem('eosWalletProvider');
  
  
    if(walletProvider == 'anchor'){
  
      // Retrieve current session from state
      const session = await anchor.login()
      //console.log(session[0].signTransaction);
    
      try {
        // Reset our response state to clear any previous transaction data
        
        const action = [{
              account: 'eosio.token',
              name: 'transfer',
              authorization: [{
                actor: anchor.users[0].accountName,
                permission: anchor.users[0].requestPermission,
            }],
            data: {
                to: 'waxdaofarmer',
                from: anchor.users[0].accountName,
                quantity: '1.0000 EOS',
                memo: '|extend_pool|' + PoolName + '|' + (daysToExtend * 86400) + '|',
            }
          }]
        // Call transact on the session (compatible with eosjs.transact)
        const response = await session[0].signTransaction({
          actions: action
        }, {

          blocksBehind: 3,
          expireSeconds: 60,
          broadcast: true,
        })
        // Update application state with the responses of the transaction
        setLoadingDisplay('');
        setEnterButtonsDisplay('hidden');
        setEnterModalText('Entending your pool...')
        const timer = setTimeout(() => {
          
            setEnterModalText('Your pool has been extended by ' + daysToExtend + ' days');
            setLoadingDisplay('none');
            setEnterButtonsDisplay('');
      
        }, 4000);
        return () => clearTimeout(timer);
    
      } catch(e) {
        setEnterModalText(JSON.stringify(e.message));
        console.log(e)
        
      }
      
      }//end if wallet = anchor
  
  
  
  }



  const depositTokens = async (farmName, amountToAdd, contract, rewardtoken, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {
    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
    const walletProvider = localStorage.getItem('eosWalletProvider');
  
    if(walletProvider == 'anchor'){
  
    // Retrieve current session from state
    const session = await anchor.login()
  
    try {
      // Reset our response state to clear any previous transaction data
      
      const action = [{
            account: contract,
            name: 'transfer',
            authorization: [{
              actor: anchor.users[0].accountName,
              permission: anchor.users[0].requestPermission,
          }],
          data: {
              to: 'waxdaofarmer',
              from: anchor.users[0].accountName,
              quantity: amountToAdd + ' ' + rewardtoken,
              memo: '|pool_deposit|' + farmName + '|',
          }
        }]
      // Call transact on the session (compatible with eosjs.transact)
      const response = await session[0].signTransaction({
        actions: action
      }, {
        blocksBehind: 3,
        expireSeconds: 60,
        broadcast: true,
      })
      // Update application state with the responses of the transaction
      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Adding your funds...')
      const timer = setTimeout(() => {
        
          setEnterModalText('Your tokens have been added to the reward pool');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');
    
      }, 4000);
      return () => clearTimeout(timer);
  
    } catch(e) {
      setEnterModalText(JSON.stringify(e.message));
      console.log(e)
      
    }
  
  } //end if wallet = ANCHOR
  
  };





const PoolOptions = ({ location }) => {

  const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode, currentUser, 
    setCurrentUser, tokenName, setTokenName, maxSupply, setMaxSupply, decimals, setDecimals, 
    tokenString, setTokenString, farmData, setFarmData, farmCollection, setFarmCollection, farmSchema, 
    setFarmSchema, farmRewards, setFarmRewards, farmExpires, setFarmExpires, farmIcon, setFarmIcon, 
    farmContract, setFarmContract, createdBy, setCreatedBy, unstaked, setUnstaked, stakedOrUnstaked, 
    setStakedOrUnstaked, stakedDisplay, setStakedDisplay, unstakedDisplay, setUnstakedDisplay, validAssets, 
    setValidAssets, commaSeparated, setCommaSeparated, stakedAssets, setStakedAssets, claimable, setClaimable, 
    minTime, setMinTime, depositAmount, setDepositAmount, depositDisplay, setDepositDisplay, totalStaked, 
    setTotalStaked, ramBalance, setRamBalance, ramInfoDisplay, setRamInfoDisplay, farmTemplates, setFarmTemplates, 
    templatesDisplay, setTemplatesDisplay, farmType, setFarmType, nftsCoveredByRam, setNftsCoveredByRam, extendDisplay, 
    setExtendDisplay, daysToExtend, setDaysToExtend, optionsAmountToAdd, setOptionsAmountToAdd, rewardToken, 
    setRewardToken, tokenPrecision, setTokenPrecision, logoHash, setLogoHash, logoDisplay, setLogoDisplay, 
    walletDisplay, setWalletDisplay, aboutFarmDisplay, setAboutFarmDisplay, stakeNftsDisplay, setStakeNftsDisplay,
    setUnstakeNftsDisplay, unstakeNftsDisplay, proposalTitle, setProposalTitle, subtitle, setSubtitle,
    highlightDescription, setHighlightDescription, setHighlightIpfs, highlightIpfs, highlightedFarm, 
    setHighlightedFarm, selectedSlot, setSelectedSlot, setHighlightHexColor, highlightHexColor, highlightTitle,
    setHighlightTitle, manageTemplatesDisplay, setManageTemplatesDisplay, setActionToPerform, actionToPerform,
    increaseLimitDisplay,
    setIncreaseLimitDisplay,
    addTemplatesDisplay,
    setAddTemplatesDisplay,
    removeTemplatesDisplay,
    setRemoveTemplatesDisplay,
    actionTypeValue,
    setActionTypeValue,
    maxTemplates,
    setMaxTemplates,
    amountOfTemplatesToAdd,
    setAmountOfTemplatesToAdd,
    paymentMethod,
    setPaymentMethod,
    enterModalText,
    setEnterModalText,
    loadingDisplay, 
    setLoadingDisplay, 
    enterButtonsDisplay,
    setEnterButtonsDisplay,
    enterModalDisplay, 
    setEnterModalDisplay,
    templates,
    setTemplates,
    tempToRemove,
    setTempToRemove,
    setManageProfileDisplay,
    manageProfileDisplay,
    description,
    setDescription,
    telegram,
    setTelegram,
    discord,
    setDiscord,
    twitter,
    setTwitter,
    website,
    setWebsite,
    nftDrop,
    setNftDrop,
    image1,
    setImage1,
    image2,
    setImage2,
    image3,
    setImage3,
    profile,
    setProfile,



  } = useStateContext();

const { PoolName } = useParams();

useEffect(() => {

  const abortCont = new AbortController();
  const abortCont2 = new AbortController();
  const abortCont3 = new AbortController();
  const abortCont4 = new AbortController();


  setStakedAssets([]);
  setUnstaked([]);
  setValidAssets([]);
  setCommaSeparated('');
  setMinTime('');
  setFarmTemplates([]);
  setTemplatesDisplay('hidden');
  setSelectedSlot('');
  setStakeNftsDisplay('hidden');
  setAboutFarmDisplay('');
  setManageTemplatesDisplay('hidden');
  setActionToPerform('');
  setIncreaseLimitDisplay('hidden');
  setAddTemplatesDisplay('hidden');
  setRemoveTemplatesDisplay('hidden');
  setActionTypeValue('');
  setMaxTemplates('');
  setAmountOfTemplatesToAdd('');
  setPaymentMethod('');
  setTempToRemove('');
  setManageProfileDisplay('hidden');


  const eosUsername = localStorage.getItem('eosAccount');
  
  if(eosUsername){
    //console.log('You have an active session');
    '';
    //setProfileDisplay('flex');
  }else{
    //console.log('You are not logged in');
    '';     //return('');
  }



  axios.post('https://eos.api.eosnation.io/v1/chain/get_table_rows',{
    table:"pools",
    scope:"waxdaofarmer",
    code:"waxdaofarmer",
    key_type: 'name',
    indexName: 'poolname',
    limit:1,
    lower_bound:PoolName,
    upper_bound:PoolName,
    json:true
  })
    .then((response) => {
      setFarmData(response);
      setFarmCollection(response.data.rows[0].collection);
      setFarmSchema(response.data.rows[0].schema);
      setFarmIcon(response.data.rows[0].logo);
      setCreatedBy(response.data.rows[0].creator);
      setFarmRewards(response.data.rows[0].poolsize);
      setFarmContract(response.data.rows[0].contract);
      setFarmExpires(response.data.rows[0].enddate);
      setMinTime(response.data.rows[0].mintime);
      setTotalStaked(response.data.rows[0].totalstaked);
      setFarmType(response.data.rows[0].farmtype);
      setRewardToken(response.data.rows[0].rwdtoken.substring( response.data.rows[0].rwdtoken.indexOf(',') + 1 ));

     

    })
    .catch((error) => console.log(error));

}, []);





useEffect(() => {

  setDescription('');
  setTelegram('');
  setDiscord('');
  setTwitter('');
  setWebsite('');
  setNftDrop('');
  setImage1('');
  setImage2('');
  setImage3('');

  setProfile([]);
    const abortCont5 = new AbortController();
  
    const eosUsername = localStorage.getItem('eosAccount');
    const nameValue = new Name(PoolName).raw().toString();
    if(eosUsername){
      
      //console.log('You have an active session');
      '';
      //setProfileDisplay('flex');
    }else{
      //console.log('You are not logged in');
      return('');
    }
  
    //console.log(nameValue);
  
    axios.post('https://eos.api.eosnation.io/v1/chain/get_table_rows',{
      table:"poolprofiles",
      scope:"waxdaofarmer",
      code:"waxdaofarmer",
      limit:1,
      lower_bound:nameValue,
      upper_bound:nameValue,
      json:true
    }).then((profileResponse) => {
 
          setProfile(profileResponse.data.rows[0]);
          if(profileResponse.data.rows[0] != null){

            setDescription(profileResponse.data.rows[0].description);
            setTelegram(profileResponse.data.rows[0].telegram);
            setDiscord(profileResponse.data.rows[0].discord);
            setTwitter(profileResponse.data.rows[0].twitter);
            setWebsite(profileResponse.data.rows[0].website);
            setNftDrop(profileResponse.data.rows[0].latest_drop);
            setImage1(profileResponse.data.rows[0].image1);
            setImage2(profileResponse.data.rows[0].image2);
            setImage3(profileResponse.data.rows[0].image3);



          } else{



          }
          console.log(profileResponse.data.rows[0]);
          

    })
  
  .catch((error) => console.log(error));
  
  //return() => abortCont5.abort();
  
  }, []);






const changeLogo = async (farmName, logoHash, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {
    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
    const walletProvider = localStorage.getItem('eosWalletProvider');
 
  
    if(walletProvider == 'anchor'){
  
  
    // Retrieve current session from state
    const session = await anchor.login()
    //console.log(session[0].signTransaction);
  
    try {
      // Reset our response state to clear any previous transaction data
      
      const action = [{
            account: 'eosio.token',
            name: 'transfer',
            authorization: [{
              actor: anchor.users[0].accountName,
              permission: anchor.users[0].requestPermission,
          }],
          data: {
              to: 'waxdaofarmer',
              from: anchor.users[0].accountName,
              quantity: "0.1000 EOS",
              memo: '|pool_logo_change|' + farmName + '|' + logoHash + '|',
          }
        }]
      // Call transact on the session (compatible with eosjs.transact)
      const response = await session[0].signTransaction({
        actions: action
      }, {
  
        blocksBehind: 3,
        expireSeconds: 60,
        broadcast: true,
      })
      // Update application state with the responses of the transaction
      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Entending your pool...')
      const timer = setTimeout(() => {
        
          setEnterModalText('Your logo has been updated. It may take a minute to reflect the change');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');
    
      }, 4000);
      return () => clearTimeout(timer);
  
    } catch(e) {
      setEnterModalText(JSON.stringify(e.message));
      console.log(e)
      
    }
  
  } //end if wallet = ANCHOR
  
  }; //end change logo 


useEffect(() => {

setClaimable([]);
  const abortCont5 = new AbortController();

  const eosUsername = localStorage.getItem('eosAccount');
  
  if(eosUsername){
    //console.log('You have an active session');
    '';
    //setProfileDisplay('flex');
  }else{
    //console.log('You are not logged in');
    return('');
  }



  axios.post('https://eos.api.eosnation.io/v1/chain/get_table_rows',{
    table:"tokens",
    scope:"waxdaofarmer",
    code:"waxdaofarmer",
    key_type: 'name',
    index_position: '3',
    limit:1000,
    lower_bound:PoolName,
    upper_bound:PoolName,
    json:true,
    signal: abortCont5
  }).then((claimableResponse) => {
        setClaimable(claimableResponse.data.rows);
  })

.catch((error) => console.log(error));

return() => abortCont5.abort();

}, []);











  return (
    <div id="seo">
    <Helmet>
    <title>{PoolName} Details</title>
    <meta name="description" content={`Stake tokens in the ${PoolName} pool on WaxDao`} />
    <link rel="canonical" href={`https://eos.waxdao.io/pool/${PoolName}`} />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor]} appName={"WaxDAO"}>

    {createdBy != null && createdBy != localStorage.getItem('eosAccount') && (<span>

      <NotLoggedIn>
        You must be the pool creator in order to edit the pool. Are you logged in?
      </NotLoggedIn>

    </span>)}

    {createdBy != null && createdBy == localStorage.getItem('eosAccount') && (<span>

    <FarmTopMenu>

    <FarmTopMenuItem onClick={() => {setAboutFarmDisplay(''); setStakeNftsDisplay('hidden'); setUnstakeNftsDisplay('hidden'); setWalletDisplay('hidden');} }>
            Pool<br/>Details
        </FarmTopMenuItem>

        <FarmTopMenuItem onClick={() => {setAboutFarmDisplay('hidden'); setStakeNftsDisplay('hidden'); setUnstakeNftsDisplay('hidden'); setWalletDisplay('');} }>
            Manage<br/>Pool
        </FarmTopMenuItem>

        <FarmTopMenuItem onClick={() => {setAboutFarmDisplay('hidden'); setStakeNftsDisplay(''); setUnstakeNftsDisplay('hidden'); setWalletDisplay('hidden'); } }>
            Stakers<br/>List
        </FarmTopMenuItem>

        


    </FarmTopMenu>




    <MainContainer>

    <Modal className={enterModalDisplay}>
      <ModalContent>
        {enterModalText}
        
      </ModalContent>

        <Spinner style={{display:loadingDisplay}}>
        <SpinnerRed  />
        <SpinnerBlue  />
        <SpinnerGreen />
        </Spinner>

      <br/>
      <GameButton onClick={() => setEnterModalDisplay('hidden') }>
        Close Window
      </GameButton>
    </Modal>


    <LeftDiv>

    <img src={`https://ipfs.io/ipfs/${farmIcon}`} 
        alt={`${PoolName} Logo`} 
        style={{ width:'150px', 
          height:'150px',
          maxWidth:'150px',
          maxHeight:'150px',
          marginLeft:'auto',
          marginRight:'auto',
          marginTop:'15px'
        }}
        className="rounded-xl hover:drop-shadow-xl"      
      />

<DaoName>
    {PoolName != null && PoolName}
    </DaoName>

    <Creator>
    {createdBy != null && (

        <span>By {createdBy}</span> 

    )} 
    </Creator>


    <FarmMenu>

        <FarmMenuItem onClick={() => {setAboutFarmDisplay(''); setStakeNftsDisplay('hidden'); setUnstakeNftsDisplay('hidden'); setWalletDisplay('hidden');} }>
            Pool Details
        </FarmMenuItem>

        <FarmMenuItem onClick={() => {setAboutFarmDisplay('hidden'); setStakeNftsDisplay('hidden'); setUnstakeNftsDisplay('hidden'); setWalletDisplay('');} }>
            Manage Pool
        </FarmMenuItem>

        <FarmMenuItem onClick={() => {setAboutFarmDisplay('hidden'); setStakeNftsDisplay(''); setUnstakeNftsDisplay('hidden'); setWalletDisplay('hidden'); } }>
            Stakers List
        </FarmMenuItem>




    </FarmMenu>
    
    </LeftDiv>

    <RightDiv>

    <Wallet className={walletDisplay}>
      <DaoTitle>
        Pool Actions
      </DaoTitle>
      <WalletCont>

{/* Farm Actions */}

<FarmButtonCont>
      <DaoButton 
          onClick={() => {
              
            setLogoDisplay('');
            setExtendDisplay('hidden');
            setDepositDisplay('hidden');
            setManageTemplatesDisplay('hidden');
            setManageProfileDisplay('hidden');
        
        }}
        >
          Change Logo
        </DaoButton>


        <DaoButton 
          onClick={() => {
              setDepositDisplay('');
              setLogoDisplay('hidden');
              setExtendDisplay('hidden');
              setManageTemplatesDisplay('hidden');
              setManageProfileDisplay('hidden');
            }}
        >
          Deposit Rewards
        </DaoButton>

        <DaoButton 

          onClick={() => {
            setExtendDisplay('');
            setDepositDisplay('hidden');
            setLogoDisplay('hidden');
            setManageTemplatesDisplay('hidden');
            setManageProfileDisplay('hidden');
      }}        
        >
          Extend Pool
        </DaoButton>

        <DisabledButton disabled

          onClick={() => {
            setExtendDisplay('hidden');
            setDepositDisplay('hidden');
            setLogoDisplay('hidden');
            setManageTemplatesDisplay('hidden');
            setManageProfileDisplay('');
      
      }}        
        >
          Edit Profile (Soon)
        </DisabledButton>

        </FarmButtonCont>

{/* Divs to show based on what is selected */}

<label htmlFor="depositInput" className={`${depositDisplay}`}><br/>Enter Amount: <br /></label>

    <ProposalInput 
      id="depositInput"
      className={depositDisplay}
      type="number"
      placeholder='69'
      value={optionsAmountToAdd}
      onChange={(e) => {
          
        setOptionsAmountToAdd(Math.round(e.target.value));
        
        if(tokenPrecision == 0){
            setTokenString(e.target.value);
        }

        else {
            setTokenString(e.target.value + '.' + zero.repeat(tokenPrecision));
        }
    }}
      />




      <label htmlFor="precision" className={`${depositDisplay}`}><br/>Token Precision: <br /></label>

    <ProposalInput 
      id="precision"
      className={depositDisplay}
      type="number"
      value={tokenPrecision}
      onChange={(e) => {

        if(Math.round(e.target.value) == 0){
            setTokenPrecision(Math.round(e.target.value));
            setTokenString(optionsAmountToAdd);

        }
        else {
            setTokenString(optionsAmountToAdd + '.' + zero.repeat(e.target.value));
            setTokenPrecision(Math.round(e.target.value));
        }  
    }}    
      />

<br/>
  <DaoButton
  className={depositDisplay}
  onClick={() => {
    depositTokens(PoolName, tokenString, farmContract, rewardToken, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay)
  }}
>
    Add {tokenString + ' ' + rewardToken}
    </DaoButton>   


{/* Extend Display */}

<div className={` ${extendDisplay}`}>

<label htmlFor="extend" ><br />Days To Add:<br /></label>

<ProposalInput type="number" 
placeholder='69420'
id="extend" 
value={daysToExtend}
onChange={(e) => {
  if(e.target.value < 1){
    setDaysToExtend(1);
  
  }
  else{
    setDaysToExtend(Math.round(e.target.value));
  }
}}
/>


<br/>
<DaoButton 
    onClick={() => extendFarm(PoolName, daysToExtend, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay)}
  >
    Submit Transaction
  </DaoButton>

  </div>





{/* Logo Change Display */}

{/* Manage Profile */}

<span className={manageProfileDisplay}>

    <MissingInfoMsg>
      Note: You can not create a profile unless one of the following is true:
      <br/><br/>
        You are authorized by the account where the token contract is deployed
      <br></br><br/>
      The pool creator is the same account where the token contract is deployed
      <br/><br/>
    </MissingInfoMsg>

    <label htmlFor="templates"><br />Bio/Description:<br /></label>
      <UserWhitelist
      placeholder="500 characters max"
      maxLength="500"
      rows={4}
      value={description}
      id="template" 
      onChange={(e) => {
        setDescription(e.target.value);
      }}
      />

<br/><br/>
<MissingInfoMsg>
      Tip: Don't forget to include the https in your links.<br/><br/>
      Example: https://t.me/hoodpunks instead of just t.me/hoodpunks
      <br></br>
      
    </MissingInfoMsg>


<label htmlFor="templates" className={`${manageProfileDisplay}`}><br />Telegram:<br /></label>

      <ProposalInput
      placeholder="t.me/yourtelegram"
      maxLength="100"
      type="text"
      id="template" 
      value={telegram}
      className={manageProfileDisplay}
      onChange={(e) => {
        setTelegram(e.target.value);
      }}
      />


<label htmlFor="templates" className={`${manageProfileDisplay}`}><br />Discord:<br /></label>

      <ProposalInput
      placeholder="discord.gg/your_discord"
      maxLength="100"
      value={discord}
      type="text"
      id="template" 
      className={manageProfileDisplay}
      onChange={(e) => {
        setDiscord(e.target.value);
      }}
      />

<label htmlFor="templates" className={`${manageProfileDisplay}`}><br />Twitter:<br /></label>

      <ProposalInput
      placeholder="twitter.com/some_dude"
      maxLength="100"
      value={twitter}
      type="text"
      id="template" 
      className={manageProfileDisplay}
      onChange={(e) => {
        setTwitter(e.target.value);
      }}
      />

<label htmlFor="templates" className={`${manageProfileDisplay}`}><br />Website:<br /></label>

      <ProposalInput
      placeholder="your_website.com"
      maxLength="100"
      value={website}
      type="text"
      id="template" 
      className={manageProfileDisplay}
      onChange={(e) => {
        setWebsite(e.target.value);
      }}
      />



<label htmlFor="templates" className={`${manageProfileDisplay}`}><br />NFT Drop:<br /></label>

      <ProposalInput
      placeholder="neftyblocks.com/my_drop_fam"
      maxLength="100"
      value={nftDrop}
      type="text"
      id="template" 
      className={manageProfileDisplay}
      onChange={(e) => {
        setNftDrop(e.target.value);
      }}
      />



    <MissingInfoMsg>
    Tip: All 3 images should be square, roughly 500x500. Enter the IPFS hash only. <br/><br/>
    You can always edit the images later, so feel free to play with the sizing and let us know
    what works best for you!
    </MissingInfoMsg>



<label htmlFor="templates" className={`${manageProfileDisplay}`}><br />Image 1: <br /></label>

      <ProposalInput
      placeholder="Qmabc.."
      value={image1}
      maxLength="60"
      type="text"
      id="template" 
      className={manageProfileDisplay}
      onChange={(e) => {
        setImage1(e.target.value);
      }}
      />



<label htmlFor="templates" className={`${manageProfileDisplay}`}><br />Image 2: <br /></label>

      <ProposalInput
      placeholder="Qm123.."
      value={image2}
      maxLength="60"
      type="text"
      id="template" 
      className={manageProfileDisplay}
      onChange={(e) => {
        setImage2(e.target.value);
      }}
      />


<label htmlFor="templates" className={`${manageProfileDisplay}`}><br />Image 3:<br /></label>

      <ProposalInput
      placeholder="Qm69420.."
      value={image3}
      maxLength="60"
      type="text"
      id="template" 
      className={manageProfileDisplay}
      onChange={(e) => {
        setImage3(e.target.value);
      }}
      />




      
  <br/><br/>

{description != '' && telegram != '' && discord != '' && twitter != '' && website != '' && nftDrop != '' && image1 != '' && image2 != '' && image3 != '' ? (

<DaoButton 
onClick={() => newProfile(PoolName, description, telegram, discord, twitter, website, nftDrop, image1, image2, image3, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay)}
>
  Submit Transaction
</DaoButton>


) : (
  <span>
  <MissingInfoMsg>
  All fields need to have something in them before you can submit the transaction.
  </MissingInfoMsg>

<DisabledButton 
disabled
>
  Submit Transaction
</DisabledButton>
</span>
  )}









      </span>






  <label htmlFor="ipfsHash" className={`${logoDisplay}`}><br/>IPFS Hash: <br /></label>

    <ProposalInput 
      id="ipfsHash"
      type="text"
      className={logoDisplay}
      placeholder='Qm69420abcde'
      value={logoHash}
      onChange={(e) => {setLogoHash(e.target.value)}}
      
      />

   <br/> 
  <DaoButton

  className={`${logoDisplay}`}
  onClick={() => {
    changeLogo(PoolName, logoHash, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay)
  }}
>
    
    Change Logo (0.1 EOS)
    </DaoButton>   

      </WalletCont>


    </Wallet>

    <AboutDAO className={aboutFarmDisplay}>
      <DaoTitle>
      Pool Details
      </DaoTitle>
      <AboutDaoCont>
        <AboutDaoTitle>
          Total Tokens Staked
        </AboutDaoTitle>
        <AboutDaoBody>
        {totalStaked != null && totalStaked}
        </AboutDaoBody>

        <AboutDaoTitle>
          Expires
        </AboutDaoTitle>
        <AboutDaoBody>
          {farmExpires != null && new Date(farmExpires * 1000).toLocaleDateString()}
        </AboutDaoBody>

        <AboutDaoTitle>
          Reward Pool
        </AboutDaoTitle>
        <AboutDaoBody>
          {farmRewards != null && farmRewards}
        </AboutDaoBody>

        <AboutDaoTitle>
          Minimum Staking Period
        </AboutDaoTitle>
        <AboutDaoBody>
          {minTime != null && minTime / 86400} Days
        </AboutDaoBody>



      </AboutDaoCont>
    </AboutDAO>

    <AboutDAO className={stakeNftsDisplay}>
      <DaoTitle>
        Stakers List
      </DaoTitle>
      <StakersCont>
        <AboutDaoBody>
          <StakersHeader>
            <StakersLeft>
              Address
            </StakersLeft>
            <StakersRight>
              Quantity
            </StakersRight>
          </StakersHeader>
        {claimable.length > 0 && claimable.map((item, index) => (
          <StakersRow key={index} className={item.amountstaked == 0 && "hidden"}>
           
             
                <StakeLeftRow>
                  {item.user}
                </StakeLeftRow>
                <StakeRightRow>
                  {item.amountstaked}
                </StakeRightRow>
             
              
           
          </StakersRow>
        )) }



        </AboutDaoBody>

        <AboutDaoBody>

        </AboutDaoBody>
      </StakersCont>
    </AboutDAO>




    




 







    </RightDiv>
    </MainContainer>

    </span>)}  
    </UALProvider>
    <br/><br/><br/>
    </div>
  )
}

export default PoolOptions