import React, { useEffect } from 'react';
import { Header } from '../../components';
import { useStateContext } from '../../contexts/ContextProvider';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { Anchor } from 'ual-anchor';
import { Wax } from '@eosdacio/ual-wax';
import { User } from "universal-authenticator-library";
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { Api, JsonRpc } from "eosjs";
import {Helmet} from "react-helmet";
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import Select from 'react-select';
import axios from 'axios';
import { BurnableCont, BuyWojak, CreateDaoCont, CreateFarmMsg, DaoButton, DaoTitle, DropDown, GameButton, MainContainer, MissingInfoMsg, Modal, ModalContent, NewProposal, NewRow, NFT, ProposalInput, SingleDropCont, Spinner, SpinnerBlue, SpinnerGreen, SpinnerRed } from '../../components/LeftDiv';


let zero = '0';

const myChain = {
  chainId: 'aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906',
  rpcEndpoints: [{
      protocol: 'https',
      host: 'eos.greymass.com',
      port: '443'
  }]
};

const rpc = new JsonRpc('https://eos.greymass.com');


const anchor = new Anchor([myChain], {

  appName: 'WaxDAO',

});




const createPool = async (farmName, farmLogo, tokenToStake, decimals, tokenContract, rewardToken, rewardDecimals, rewardContract, stakingSeconds, timestamp, stakingAmount, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {
  setEnterModalDisplay('');
  setEnterModalText('Awaiting confirmation...');
  
  const walletProvider = localStorage.getItem('eosWalletProvider');


    if(walletProvider == 'anchor'){

    // Retrieve current session from state
    const session = await anchor.login()
    //console.log(session[0].signTransaction);
  
  

  
    try {
      // Reset our response state to clear any previous transaction data
      
      const action = [{
            account: 'eosio.token',
            name: 'transfer',
            authorization: [{
              actor: anchor.users[0].accountName,
              permission: anchor.users[0].requestPermission,
          }],
          data: {
              to: 'waxdaofarmer',
              from: anchor.users[0].accountName,
              quantity: '25.0000 EOS',
              memo: '|createpool|' + farmName + '|' + rewardContract + '|' + farmLogo + '|' + timestamp + '|' + stakingAmount + '|' + stakingSeconds + '|' + tokenToStake + '|'  + decimals + '|' + tokenContract + '|' + rewardDecimals + ',' + rewardToken + '|',
          }
        }]
      // Call transact on the session (compatible with eosjs.transact)
      const response = await session[0].signTransaction({
        actions: action
      }, {
   
        blocksBehind: 3,
        expireSeconds: 60,
        broadcast: true,
      })
      // Update application state with the responses of the transaction
      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Creating your pool...')
      const timer = setTimeout(() => {
        
          setEnterModalText('Your pool has been created. You can deposit rewards on the "Manage Pools" page');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');
    
      }, 4000);
      return () => clearTimeout(timer);
  
    } catch(e) {
      setEnterModalText(JSON.stringify(e.message));
      console.log(e)
      
    }
  

  

  
      } //end if wallet = anchor

}











const CreatePool = () => {
  
  const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode, currentUser, 
    tokenName, setTokenName, maxSupply, setMaxSupply, decimals, setDecimals, tokenString, 
    setTokenString, memo, setMemo, farmName, setFarmName, rewardToken, setRewardToken, rewardContract, 
    setRewardContract, poolSize, setPoolSize, collection, setCollection, schema, setSchema, farmLogo, 
    setFarmLogo, paymentMethod, setPaymentMethod, timestamp, setTimestamp, setEpochTime, assetDisplay, 
    setAssetDisplay, assetToBurn, setAssetToBurn, burnable, setBurnable, burnableDisplay, setBurnableDisplay, 
    tokenToStake, setTokenToStake, rewardDecimals, setRewardDecimals, tokenContract, setTokenContract, 
    stakingPeriod, setStakingPeriod, stakingAmount, setStakingAmount, stakingSeconds, setStakingSeconds, 
    quantity, setQuantity,    
    enterModalText,
    setEnterModalText,
    loadingDisplay, 
    setLoadingDisplay, 
    enterButtonsDisplay,
    setEnterButtonsDisplay,
    enterModalDisplay, 
    setEnterModalDisplay,
  
  
  } = useStateContext();
  useEffect(() => {
    const abortCont2 = new AbortController();
    setBurnable([]);
    setBurnableDisplay('hidden');

// reset the state of form fields so they dont remain filled after coming back from a different page

setDecimals('');
setFarmName('');
setRewardToken('');
setRewardContract('');
setFarmLogo('');
setPaymentMethod('Payment Method');
setTokenToStake('');
setRewardDecimals('');
setStakingAmount('');
setTokenContract('');
setStakingPeriod('');
setQuantity('');



      }

    
    , []);

  return (
    <div id="seo">
    <Helmet>
    <title>Create Staking Pool - WaxDAO</title>
    <meta name="description" content="Create your own staking pool on EOS blockchain, using any EOS token." />
    <link rel="canonical" href="https://waxdao.io/create-pool" />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor]} appName={"WaxDAO"}>
    <MainContainer>

    <Modal className={enterModalDisplay}>
      <ModalContent>
        {enterModalText}
        
      </ModalContent>

        <Spinner style={{display:loadingDisplay}}>
        <SpinnerRed  />
        <SpinnerBlue  />
        <SpinnerGreen />
        </Spinner>

      <br/>
      <GameButton onClick={() => setEnterModalDisplay('hidden') }>
        Close Window
      </GameButton>
    </Modal>

    <CreateDaoCont>

      <DaoTitle>
        Want to reward your token holders?
      </DaoTitle>

        <CreateFarmMsg>
        You can create a staking pool that gives hourly rewards to your holders. <br/><br/>
        It's a great way to incentivize people to add liquidity, or to hold your native token.
        </CreateFarmMsg>


        <br/><br/>
        <NewProposal>


      <label htmlFor="farmName">Pool Name: <br /></label>
      <TooltipComponent content="12 Characters Max: (a-z, 1-5)"
      position="BottomCenter">
      <ProposalInput type="text" 
      id="farmName" 
      maxLength={12}
      placeholder="mytokenpool"
      value={farmName}
      onChange={(e) => {
        setFarmName(e.target.value.toLowerCase());
        }}
        
      />
      </TooltipComponent>

      <label htmlFor="farmLogo" ><br />Pool Logo (IPFS only):<br /></label>
      <TooltipComponent content="IPFS hash of the logo you want to use"
      position="BottomCenter">
      <ProposalInput type="text" 
      placeholder='Qmabc.....'
      maxLength={60}
      id="farmLogo" 
      value={farmLogo}
      onChange={(e) => {
        setFarmLogo(e.target.value);
      }}
      />
      </TooltipComponent>

      <MissingInfoMsg>
<span className="font-bold">Warning:</span> Entering incorrect token details will result in a pool that doesn't work, and WaxDAO will not be able 
to help you. <br/>
Make 100% sure that you have the correct symbol, precision and contract for both the staking token, and the reward token.
<br/>
WaxDAO will not be held responsible for user errors.
</MissingInfoMsg>


<SingleDropCont>
<h2 className="font-bold text-xl">Token To Stake:</h2>
      <label htmlFor="collection" ><br />Symbol:<br /></label>
      <TooltipComponent content="Name of the token you want people to stake"
      position="BottomCenter">
      <ProposalInput type="text" 
      id="collection" 
      maxLength={7}
      value={tokenToStake}
      onChange={(e) => {
        setTokenToStake(e.target.value.toUpperCase());
      }}
      />
      </TooltipComponent>

      <label htmlFor="decimals" ><br />Precision:<br /></label>
      <TooltipComponent content="Decimal places of the token to stake"
      position="BottomCenter">
      <ProposalInput type="number" 
      id="decimals" 
      value={decimals}
      onChange={(e) => {

        if(e.target.value < 0){
          //console.log("false");
          setDecimals(0)
        }

        else{
          setDecimals(Math.round(e.target.value))
        }

      }}
      />
      </TooltipComponent>

      <label htmlFor="tokenContract" ><br />Contract:<br /></label>
      <TooltipComponent content="Name of the EOS account where your token contract is stored"
      position="BottomCenter">
      <ProposalInput type="text" 
      maxLength={12}
      id="tokenContract" 
      value={tokenContract}
      onChange={(e) => {
        setTokenContract(e.target.value.toLowerCase());
      }}
      />
      </TooltipComponent>

      </SingleDropCont>


      <SingleDropCont>
<h2 className="font-bold text-xl">Reward Token:</h2>

      <label htmlFor="rewardToken" ><br />Symbol:<br /></label>
      <TooltipComponent content="Which token do you want to give to stakers?"
      position="BottomCenter">
      <ProposalInput type="text" 
      maxLength={7}
      id="rewardToken" 
      value={rewardToken}
      onChange={(e) => {
        setRewardToken(e.target.value.toUpperCase());
      }}
      />
      </TooltipComponent>


      <label htmlFor="decimals" ><br />Precision:<br /></label>
      <TooltipComponent content="How many decimal places does the reward token have?"
      position="BottomCenter">
      <ProposalInput type="number" 
      id="decimals" 
      value={rewardDecimals}
      onChange={(e) => {
        if(e.target.value < 0){
          //console.log("false");
          setRewardDecimals(0)
        }

        else{
          setRewardDecimals(Math.round(e.target.value))
        }


      }}
      />
      </TooltipComponent>


      <label htmlFor="rewardContract" ><br />Contract:<br /></label>
      <TooltipComponent content="Name of the EOS account where your token contract is stored"
      position="BottomCenter">
      <ProposalInput type="text" 
      maxLength={12}
      id="rewardContract" 
      value={rewardContract}
      onChange={(e) => {
        setRewardContract(e.target.value.toLowerCase());
      }}
      />
      </TooltipComponent>

      </SingleDropCont>

<br/><br/>

<label htmlFor="stakingPeriod" ><br />Minimum Staking Period (Days):<br /></label>
      <TooltipComponent content="Minimum days you want people to stake for"
      position="BottomCenter">
      <ProposalInput type="number" 
      id="stakingPeriod" 
      value={stakingPeriod}
      onChange={(e) => {
        if(e.target.value < 1){
        setStakingPeriod(1);
        setStakingSeconds(86400);
    
        }
        else{
          setStakingPeriod(Math.round(e.target.value));
          setStakingSeconds(Math.round(e.target.value) * 86400);
        }
      }}
      />
      </TooltipComponent>


      <label htmlFor="stkAmount" ><br />Minimum Staking Amount:<br /></label>
      <TooltipComponent content="Minimum amount of tokens required for staking"
      position="BottomCenter">
      <ProposalInput type="number" 
      id="stkAmount" 
      value={stakingAmount}
      onChange={(e) => {
        if(e.target.value < 1){
        setStakingAmount(1);
        
        }
        else{
          setStakingAmount(Math.round(e.target.value));
        }
      }}
      />
      </TooltipComponent>

      <br />




      <label htmlFor="stakingPeriod" ><br />Pool Expiration Date:<br /></label>
      <TooltipComponent content="How long do you want the pool to give rewards for?"
      position="BottomCenter">
        <div className='daterangepicker-control-section'>
      <DatePickerComponent
        id="datepicker"
         //style={{fontSize:'18px', color:'white'}}
         cssClass='customClass'
         
         onChange={(e) => {
             setEpochTime(e)
        }}
         
         >
      </DatePickerComponent>
      </div>
      </TooltipComponent>

      <br />




<DaoButton 
          onClick={() => {
            createPool(farmName, farmLogo, tokenToStake, decimals, tokenContract, rewardToken, rewardDecimals, rewardContract, stakingSeconds, timestamp, stakingAmount, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay)
          }}
        
        >
          Create Pool (25 EOS)
        </DaoButton>

    
      </NewProposal>
      </CreateDaoCont>

    </MainContainer>
    </UALProvider>
    <br/><br/><br/>
    </div>
  )
}

export default CreatePool