import React, { useEffect } from 'react';
import { GridComponent, Resize, Sort, ContextMenu, Filter, Page, ExcelExport, PdfExport, Edit, Inject, Search, Toolbar } from '@syncfusion/ej2-react-grids';
import { ColumnDirective, ColumnsDirective } from '@syncfusion/ej2-react-charts';
import { useParams } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import { Anchor } from 'ual-anchor';
import { Wax } from '@eosdacio/ual-wax';
import { User } from "universal-authenticator-library";
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { Api, JsonRpc } from "eosjs";
import {Helmet} from "react-helmet";
import axios from 'axios';
import { data } from 'autoprefixer';
import { AllFarmsCont, PoolAmount, FarmButton, SingleFarmCont, FarmLogo, FarmTitle, FarmsPageCont, FarmsTitle, HighlightedFarm, HighlightedLogo, HighlightDetails, HighlightTitle, FarmEndDate, HighlightMessage, HighlightAmount, Modal, ModalContent, Spinner, SpinnerRed, SpinnerBlue, SpinnerGreen, GameButton, UserWhitelist, DaoButton, SuggestionsCont, Suggestion, SuggestionDesc, SuggestionDeets, SuggestionUser, SuggestionBtn, MissingInfoMsg, ProposalInput, DisabledButton, FinalizeButton, DropDown } from '../../components/LeftDiv';
import NumberFormat from 'react-number-format';
import { DatePickerComponent, DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

let zero = '0';

var thisUsersFarms;

function getRndInteger(min, max) {
    return Math.floor(Math.random() * (max - min) ) + min;
  }

  const myChain = {
    chainId: 'aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906',
    rpcEndpoints: [{
        protocol: 'https',
        host: 'eos.greymass.com',
        port: '443'
    }]
  };
  
  const rpc = new JsonRpc('https://eos.greymass.com');


const anchor = new Anchor([myChain], {

  appName: 'WaxDAO',

});



const ualLogin = async () => {
 
 // console.log('Loading...');
  await anchor.login();
  getCustomersData();
  //localStorage.setItem('waxAccount', 'anchor.users[0].accountName')
  //console.log(anchor.users[0].accountName);

}

const ualLogout = async () => {
 
 // console.log('Loading...');
  await anchor.logout();
  //console.log(anchor.users[0].accountName);

}


const createDrip = async (receivingAccount, depositAmount, timestamp, whitelistType, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {

    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
    var amountToSend = Number(depositAmount).toFixed(4);
    var uniqueID = getRndInteger(100000000, 200000000)

    const walletProvider = localStorage.getItem('eosWalletProvider');
  

      if(walletProvider == 'anchor'){
  
      // Retrieve current session from state
      const session = await anchor.login()
      //console.log(session[0].signTransaction);
    
  
    
      try {
        // Reset our response state to clear any previous transaction data
        
        const action = [{
            account: 'waxdaoescrow',
            name: 'createdrip',
            authorization: [{
              actor: anchor.users[0].accountName,
              permission: anchor.users[0].requestPermission,
            }],
            data: {
                buyer: anchor.users[0].accountName,
                seller: receivingAccount,
                end_time: timestamp,
                unique_id: uniqueID,
                lock_type: whitelistType,
            }
            },{
                account: 'eosio.token',
                name: 'transfer',
                authorization: [{
                  actor: anchor.users[0].accountName,
                  permission: anchor.users[0].requestPermission,
              }],
              data: {
                  from: anchor.users[0].accountName,
                  to: 'waxdaoescrow',
                  quantity: amountToSend + ' EOS',
                  memo: '|drip_deposit|' + uniqueID + '|',
              }
            }]
        // Call transact on the session (compatible with eosjs.transact)
        const response = await session[0].signTransaction({
          actions: action
        }, {
          blocksBehind: 3,
          expireSeconds: 60,
          broadcast: true,
        })
        // Update application state with the responses of the transaction
        setLoadingDisplay('');
        setEnterButtonsDisplay('hidden');
        setEnterModalText('Processing your transaction...')
        const timer = setTimeout(() => {
          
            setEnterModalText('Your slow drip has been created');
            setLoadingDisplay('none');
            setEnterButtonsDisplay('');
      
        }, 4000);
        return () => clearTimeout(timer);
    
      } catch(e) {
  
  
              setEnterModalText(JSON.stringify(e.message));
  
        console.log(e)
        
      }
    
    
        } //end if wallet = anchor
  
  } //end addSuggest



  const finalizeDrip = async (ID, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {

    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
    const walletProvider = localStorage.getItem('eosWalletProvider');
 

  
      if(walletProvider == 'anchor'){
  
      // Retrieve current session from state
      const session = await anchor.login()
      //console.log(session[0].signTransaction);
    
  
    
      try {
        // Reset our response state to clear any previous transaction data
        
        const action = 
          [{
            account: 'waxdaoescrow',
            name: 'finalizedrip',
            authorization: [{
              actor: anchor.users[0].accountName,
              permission: anchor.users[0].requestPermission,
          }],
          data: {
              ID: ID,
              buyer: anchor.users[0].accountName,
          }
        }]
        // Call transact on the session (compatible with eosjs.transact)
        const response = await session[0].signTransaction({
          actions: action
        }, {
          blocksBehind: 3,
          expireSeconds: 60,
          broadcast: true,
        })
        // Update application state with the responses of the transaction
        setLoadingDisplay('');
        setEnterButtonsDisplay('hidden');
        setEnterModalText('Processing your transaction...')
        const timer = setTimeout(() => {
          
            setEnterModalText('Your drip has been finalized');
            setLoadingDisplay('none');
            setEnterButtonsDisplay('');
      
        }, 4000);
        return () => clearTimeout(timer);
    
      } catch(e) {
        setEnterModalText(JSON.stringify(e.message));
        console.log(e)
        
      }
    
    
        } //end if wallet = anchor
  
  } //end finalizeDrip




  const cancelDrip = async (ID, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {

    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
    const walletProvider = localStorage.getItem('eosWalletProvider');
  
      if(walletProvider == 'anchor'){
  
      // Retrieve current session from state
      const session = await anchor.login()
      //console.log(session[0].signTransaction);
    
  
    
      try {
        // Reset our response state to clear any previous transaction data
        
        const action = 
          [{
            account: 'waxdaoescrow',
            name: 'canceldrip',
            authorization: [{
              actor: anchor.users[0].accountName,
              permission: anchor.users[0].requestPermission,
          }],
          data: {
              ID: ID,
              buyer: anchor.users[0].accountName,
          }
        }]
        // Call transact on the session (compatible with eosjs.transact)
        const response = await session[0].signTransaction({
          actions: action
        }, {
          blocksBehind: 3,
          expireSeconds: 60,
          broadcast: true,
        })
        // Update application state with the responses of the transaction
        setLoadingDisplay('');
        setEnterButtonsDisplay('hidden');
        setEnterModalText('Processing your transaction...')
        const timer = setTimeout(() => {
          
            setEnterModalText('Your drip has been cancelled');
            setLoadingDisplay('none');
            setEnterButtonsDisplay('');
      
        }, 4000);
        return () => clearTimeout(timer);
    
      } catch(e) {
        setEnterModalText(JSON.stringify(e.message));
        console.log(e)
        
      }
    
    
        } //end if wallet = anchor
  
  } //end cancelDrip



  const claimDrip = async (ID, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {

    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
    const walletProvider = localStorage.getItem('eosWalletProvider');
  
      if(walletProvider == 'anchor'){
  
      // Retrieve current session from state
      const session = await anchor.login()
      //console.log(session[0].signTransaction);
    
  
    
      try {
        // Reset our response state to clear any previous transaction data
        
        const action = 
          [{
            account: 'waxdaoescrow',
            name: 'claimdrip',
            authorization: [{
              actor: anchor.users[0].accountName,
              permission: anchor.users[0].requestPermission,
          }],
          data: {
              ID: ID,
              seller: anchor.users[0].accountName,
          }
        }]
        // Call transact on the session (compatible with eosjs.transact)
        const response = await session[0].signTransaction({
          actions: action
        }, {
          blocksBehind: 3,
          expireSeconds: 60,
          broadcast: true,
        })
        // Update application state with the responses of the transaction
        setLoadingDisplay('');
        setEnterButtonsDisplay('hidden');
        setEnterModalText('Processing your transaction...')
        const timer = setTimeout(() => {
          
            setEnterModalText('Your claim was successful');
            setLoadingDisplay('none');
            setEnterButtonsDisplay('');
      
        }, 4000);
        return () => clearTimeout(timer);
    
      } catch(e) {
        setEnterModalText(JSON.stringify(e.message));
        console.log(e)
        
      }
    
    
        } //end if wallet = anchor
  
  } //end claimDrip





const Escrow = () => {

  const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode, currentUser, tokenName, 
    setTokenName, maxSupply, setMaxSupply, decimals, setDecimals, tokenString, setTokenString, farmData, 
    setFarmData, farmCollection, setFarmCollection, farmSchema, setFarmSchema, farmRewards, setFarmRewards, 
    farmExpires, setFarmExpires, farmIcon, setFarmIcon, farmContract, setFarmContract, createdBy, setCreatedBy, 
    farmList, setFarmList, stakedOnly, setStakedOnly, farmsUserIsIn, setFarmsUserIsIn, highlightedFarm,
    setHighlightedFarm,
    suggestions,
    setSuggestions,
    enterModalText,
    setEnterModalText,
    loadingDisplay, 
    setLoadingDisplay, 
    enterButtonsDisplay,
    setEnterButtonsDisplay,
    enterModalDisplay, 
    setEnterModalDisplay,
    description,
    setDescription,
    receivingAccount,
    setReceivingAccount,
    epochTime,
    setEpochTime,
    timestamp,
    setTimestamp,
    depositAmount,
    setDepositAmount,
    sellerDrips,
    setSellerDrips,
    whitelistType,
    setWhitelistType,
  } = useStateContext();

useEffect(() => {

    const eosUsername = localStorage.getItem('eosAccount');

    if(eosUsername){
        ''
    } else {
        return;
    }

  // Get highlighted farm info

  setSuggestions([]);
  setReceivingAccount('');
  setDepositAmount('');
  setTimestamp('');
    const abortCont5 = new AbortController();
  
    axios.post('https://eos.api.eosnation.io/v1/chain/get_table_rows',{
      table:"drips",
      scope:"waxdaoescrow",
      code:"waxdaoescrow",
      limit:1000,
      key_type: 'name',
      index_position: 2,
      limit:1000,
      lower_bound:eosUsername,
      upper_bound:eosUsername,
      json:true
    }).then((suggestionsResponse) => {

        setSuggestions(suggestionsResponse.data.rows);
          console.log(suggestionsResponse.data.rows);
  

  
  
    })
  
  .catch((error) => console.log(error));
  

  
  }, []);




  useEffect(() => {

    const eosUsername = localStorage.getItem('eosAccount');

    if(eosUsername){
        ''
    } else {
        return;
    }

  // Get highlighted farm info


  setSellerDrips([]);

    const abortCont5 = new AbortController();
  
    axios.post('https://eos.api.eosnation.io/v1/chain/get_table_rows',{
      table:"drips",
      scope:"waxdaoescrow",
      code:"waxdaoescrow",
      limit:1000,
      key_type: 'name',
      index_position: 3,
      limit:1000,
      lower_bound:eosUsername,
      upper_bound:eosUsername,
      json:true
    }).then((sellerResponse) => {

        setSellerDrips(sellerResponse.data.rows);
    
  
    })
  
  .catch((error) => console.log(error));
  

  
  }, []);




  return (
  <div id="seo">
    <Helmet>
    <title>Escrow</title>
    <meta name="description" content="Use WaxDAO's slow drip contract to create an escrow agreement" />
    <link rel="canonical" href="https://waxdao.io/escrow" />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor]} appName={"WaxDAO"}>
    
    <Modal className={enterModalDisplay}>
      <ModalContent>
        {enterModalText}
        
      </ModalContent>

        <Spinner style={{display:loadingDisplay}}>
        <SpinnerRed  />
        <SpinnerBlue  />
        <SpinnerGreen />
        </Spinner>

      <br/>
      <GameButton onClick={() => setEnterModalDisplay('hidden') }>
        Close Window
      </GameButton>
      </Modal>

<FarmsPageCont>
<FarmsTitle>
    Create Slow Drip
</FarmsTitle>
<SuggestionsCont>

    <MissingInfoMsg>
        The contract will slowly drip a portion of the funds to the receiver every hour until expiration.<br/><br/>
        The funds need to be claimed manually by the seller/team.<br/><br/> 
        P2P drips can be cancelled at any time. Team vesting drips are irreversible.<br/><br/>
        Service fee is 2% (even if cancelled before expiration)
    </MissingInfoMsg>


    <form>
        <DropDown         
          onChange={(e) => { setWhitelistType(e.target.value)}}
          >
        <option value="" hidden>
          Transaction Type
        </option>
        <option value="p2p">Peer To Peer</option>
        <option value="vesting">Team Vesting</option>
        </DropDown>
        </form>


    <label htmlFor="templates" className="text-white"><br />Receiving Account:<br /></label>
    <ProposalInput
    maxLength={12}
    value={receivingAccount}
    id="template" 
    onChange={(e) => {
        setReceivingAccount(e.target.value.toLowerCase());
    }}
    />

<label htmlFor="templates" className="text-white"><br />Amount Of EOS:<br /></label>
    <ProposalInput
    maxLength={12}
    value={depositAmount}
    id="template" 
    onChange={(e) => {
        setDepositAmount(e.target.value);
    }}
    />
    


    <label htmlFor="templates" className="text-white"><br />Drip Completion Date:<br /></label>
      <TooltipComponent content="Expiration date of the slow drip"
      position="BottomCenter">
        <div className='daterangepicker-control-section'>
      <DateTimePickerComponent
        id="datepicker"
         //style={{fontSize:'18px', color:'white'}}
         cssClass='customClass'
         
         onChange={(e) => {
             setEpochTime(e)
        }}
         
         >
      </DateTimePickerComponent>
      </div>
      </TooltipComponent>

{timestamp != null && receivingAccount != null && depositAmount != null && whitelistType != null && whitelistType != 'none' && timestamp > 0 && receivingAccount.length > 0 && depositAmount.length > 0 && (
<span>
<br/>
<DaoButton onClick={() => { createDrip(receivingAccount, depositAmount, timestamp, whitelistType, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) }}>
    Create Drip
</DaoButton>
</span>
)}


{(timestamp == null || receivingAccount == null || depositAmount == null || timestamp == '' || receivingAccount.length == 0 || whitelistType == 'none' || depositAmount.length == 0) && (
<span>
    <br/>
<MissingInfoMsg>
You need to fill out all fields before you can submit your transaction (the date must be "selected" and not typed)
</MissingInfoMsg>

<DisabledButton>
    Create Drip
</DisabledButton>
</span>
)}





</SuggestionsCont>
</FarmsPageCont>





    </UALProvider>
    <br/><br/><br/>
    </div>
  )
}

export default Escrow