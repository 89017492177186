import React, { useEffect } from 'react';
import { GridComponent, Resize, Sort, ContextMenu, Filter, Page, ExcelExport, PdfExport, Edit, Inject, Search, Toolbar } from '@syncfusion/ej2-react-grids';
import { ColumnDirective, ColumnsDirective } from '@syncfusion/ej2-react-charts';
import { useParams } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import { Anchor } from 'ual-anchor';
import { Wax } from '@eosdacio/ual-wax';
import { User } from "universal-authenticator-library";
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { Api, JsonRpc } from "eosjs";
import {Helmet} from "react-helmet";
import axios from 'axios';
import { data } from 'autoprefixer';
import { AllFarmsCont, PoolAmount, FarmButton, SingleFarmCont, FarmLogo, FarmTitle, FarmsPageCont, FarmsTitle, HighlightedFarm, HighlightedLogo, HighlightDetails, HighlightTitle, FarmEndDate, HighlightMessage, HighlightAmount, Modal, ModalContent, Spinner, SpinnerRed, SpinnerBlue, SpinnerGreen, GameButton, UserWhitelist, DaoButton, SuggestionsCont, Suggestion, SuggestionDesc, SuggestionDeets, SuggestionUser, SuggestionBtn, MissingInfoMsg, ProposalInput, DisabledButton, FinalizeButton, VestingButton } from '../../components/LeftDiv';
import NumberFormat from 'react-number-format';
import { DatePickerComponent, DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

let zero = '0';

var thisUsersFarms;

function getRndInteger(min, max) {
    return Math.floor(Math.random() * (max - min) ) + min;
  }

  const myChain = {
    chainId: 'aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906',
    rpcEndpoints: [{
        protocol: 'https',
        host: 'eos.greymass.com',
        port: '443'
    }]
  };
  
  const rpc = new JsonRpc('https://eos.greymass.com');


const anchor = new Anchor([myChain], {

  appName: 'WaxDAO',

});


const ualLogin = async () => {
 
 // console.log('Loading...');
  await anchor.login();
  getCustomersData();
  //localStorage.setItem('waxAccount', 'anchor.users[0].accountName')
  //console.log(anchor.users[0].accountName);

}

const ualLogout = async () => {
 
 // console.log('Loading...');
  await anchor.logout();
  //console.log(anchor.users[0].accountName);

}


  const finalizeDrip = async (ID, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {

    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
    const walletProvider = localStorage.getItem('eosWalletProvider');
  
  
      if(walletProvider == 'anchor'){
  
      // Retrieve current session from state
      const session = await anchor.login()
      //console.log(session[0].signTransaction);
    
  
    
      try {
        // Reset our response state to clear any previous transaction data
        
        const action = 
          [{
            account: 'waxdaoescrow',
            name: 'finalizedrip',
            authorization: [{
              actor: anchor.users[0].accountName,
              permission: anchor.users[0].requestPermission,
          }],
          data: {
              ID: ID,
              buyer: anchor.users[0].accountName,
          }
        }]
        // Call transact on the session (compatible with eosjs.transact)
        const response = await session[0].signTransaction({
          actions: action
        }, {
          blocksBehind: 3,
          expireSeconds: 60,
          broadcast: true,
        })
        // Update application state with the responses of the transaction
        setLoadingDisplay('');
        setEnterButtonsDisplay('hidden');
        setEnterModalText('Processing your transaction...')
        const timer = setTimeout(() => {
          
            setEnterModalText('Your drip has been finalized');
            setLoadingDisplay('none');
            setEnterButtonsDisplay('');
      
        }, 4000);
        return () => clearTimeout(timer);
    
      } catch(e) {
        setEnterModalText(JSON.stringify(e.message));
        console.log(e)
        
      }
    
    
        } //end if wallet = anchor
  
  } //end finalizeDrip




  const cancelDrip = async (ID, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {

    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
    const walletProvider = localStorage.getItem('eosWalletProvider');
  
  
      if(walletProvider == 'anchor'){
  
      // Retrieve current session from state
      const session = await anchor.login()
      //console.log(session[0].signTransaction);
    
  
    
      try {
        // Reset our response state to clear any previous transaction data
        
        const action = 
          [{
            account: 'waxdaoescrow',
            name: 'canceldrip',
            authorization: [{
              actor: anchor.users[0].accountName,
              permission: anchor.users[0].requestPermission,
          }],
          data: {
              ID: ID,
              buyer: anchor.users[0].accountName,
          }
        }]
        // Call transact on the session (compatible with eosjs.transact)
        const response = await session[0].signTransaction({
          actions: action
        }, {
          blocksBehind: 3,
          expireSeconds: 60,
          broadcast: true,
        })
        // Update application state with the responses of the transaction
        setLoadingDisplay('');
        setEnterButtonsDisplay('hidden');
        setEnterModalText('Processing your transaction...')
        const timer = setTimeout(() => {
          
            setEnterModalText('Your drip has been cancelled');
            setLoadingDisplay('none');
            setEnterButtonsDisplay('');
      
        }, 4000);
        return () => clearTimeout(timer);
    
      } catch(e) {
        setEnterModalText(JSON.stringify(e.message));
        console.log(e)
        
      }
    
    
        } //end if wallet = anchor
  
  } //end cancelDrip



  const claimDrip = async (ID, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {

    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
    const walletProvider = localStorage.getItem('eosWalletProvider');
  
  
      if(walletProvider == 'anchor'){
  
      // Retrieve current session from state
      const session = await anchor.login()
      //console.log(session[0].signTransaction);
    
  
    
      try {
        // Reset our response state to clear any previous transaction data
        
        const action = 
          [{
            account: 'waxdaoescrow',
            name: 'claimdrip',
            authorization: [{
              actor: anchor.users[0].accountName,
              permission: anchor.users[0].requestPermission,
          }],
          data: {
              ID: ID,
              seller: anchor.users[0].accountName,
          }
        }]
        // Call transact on the session (compatible with eosjs.transact)
        const response = await session[0].signTransaction({
          actions: action
        }, {
          blocksBehind: 3,
          expireSeconds: 60,
          broadcast: true,
        })
        // Update application state with the responses of the transaction
        setLoadingDisplay('');
        setEnterButtonsDisplay('hidden');
        setEnterModalText('Processing your transaction...')
        const timer = setTimeout(() => {
          
            setEnterModalText('Your claim was successful');
            setLoadingDisplay('none');
            setEnterButtonsDisplay('');
      
        }, 4000);
        return () => clearTimeout(timer);
    
      } catch(e) {
        setEnterModalText(JSON.stringify(e.message));
        console.log(e)
        
      }
    
    
        } //end if wallet = anchor
  
  } //end claimDrip





const MyEscrow = () => {

  const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode, currentUser, tokenName, 
    setTokenName, maxSupply, setMaxSupply, decimals, setDecimals, tokenString, setTokenString, farmData, 
    setFarmData, farmCollection, setFarmCollection, farmSchema, setFarmSchema, farmRewards, setFarmRewards, 
    farmExpires, setFarmExpires, farmIcon, setFarmIcon, farmContract, setFarmContract, createdBy, setCreatedBy, 
    farmList, setFarmList, stakedOnly, setStakedOnly, farmsUserIsIn, setFarmsUserIsIn, highlightedFarm,
    setHighlightedFarm,
    suggestions,
    setSuggestions,
    enterModalText,
    setEnterModalText,
    loadingDisplay, 
    setLoadingDisplay, 
    enterButtonsDisplay,
    setEnterButtonsDisplay,
    enterModalDisplay, 
    setEnterModalDisplay,
    description,
    setDescription,
    receivingAccount,
    setReceivingAccount,
    epochTime,
    setEpochTime,
    timestamp,
    setTimestamp,
    depositAmount,
    setDepositAmount,
    sellerDrips,
    setSellerDrips,
    buyerOrSeller,
    setBuyerOrSeller,
    buyerDisplay,
    setBuyerDisplay,
    sellerDisplay,
    setSellerDisplay,
  } = useStateContext();

useEffect(() => {

    setBuyerOrSeller('Buyer');
    setBuyerDisplay('');
    setSellerDisplay('hidden');

    const eosUsername = localStorage.getItem('eosAccount');

    if(eosUsername){
        ''
    } else {
        return;
    }

  // Get highlighted farm info

  setSuggestions([]);
  setReceivingAccount('');
  setDepositAmount('');
  setTimestamp('');
    const abortCont5 = new AbortController();
  
    axios.post('https://eos.api.eosnation.io/v1/chain/get_table_rows',{
      table:"drips",
      scope:"waxdaoescrow",
      code:"waxdaoescrow",
      limit:1000,
      key_type: 'name',
      index_position: 2,
      limit:1000,
      lower_bound:eosUsername,
      upper_bound:eosUsername,
      json:true
    }).then((suggestionsResponse) => {

        setSuggestions(suggestionsResponse.data.rows);
          console.log(suggestionsResponse.data.rows);
  

  
  
    })
  
  .catch((error) => console.log(error));
  

  
  }, []);




  useEffect(() => {

    const eosUsername = localStorage.getItem('eosAccount');

    if(eosUsername){
        ''
    } else {
        return;
    }

  // Get highlighted farm info


  setSellerDrips([]);

    const abortCont5 = new AbortController();
  
    axios.post('https://eos.api.eosnation.io/v1/chain/get_table_rows',{
      table:"drips",
      scope:"waxdaoescrow",
      code:"waxdaoescrow",
      limit:1000,
      key_type: 'name',
      index_position: 3,
      limit:1000,
      lower_bound:eosUsername,
      upper_bound:eosUsername,
      json:true
    }).then((sellerResponse) => {

        setSellerDrips(sellerResponse.data.rows);
          //console.log(sellerResponse.data.rows);
  

  
  
    })
  
  .catch((error) => console.log(error));
  

  
  }, []);




  return (
  <div id="seo">
    <Helmet>
    <title>Manage Escrow</title>
    <meta name="description" content="View and manage your escrow agreements. Finalize, cancel and claim." />
    <link rel="canonical" href="https://waxdao.io/manage-escrow" />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor]} appName={"WaxDAO"}>
    
    <Modal className={enterModalDisplay}>
      <ModalContent>
        {enterModalText}
        
      </ModalContent>

        <Spinner style={{display:loadingDisplay}}>
        <SpinnerRed  />
        <SpinnerBlue  />
        <SpinnerGreen />
        </Spinner>

      <br/>
      <GameButton onClick={() => setEnterModalDisplay('hidden') }>
        Close Window
      </GameButton>
      </Modal>

<FarmsPageCont>
<FarmsTitle>
    Manage Escrow &nbsp;&nbsp;

    <FinalizeButton onClick={() => { if(buyerOrSeller == 'Buyer'){ setBuyerOrSeller('Seller'); setBuyerDisplay('hidden'); setSellerDisplay(''); } else{ setBuyerOrSeller('Buyer');  setBuyerDisplay(''); setSellerDisplay('hidden');} }}>
        {buyerOrSeller != null && buyerOrSeller}
    </FinalizeButton>


</FarmsTitle>
<SuggestionsCont>


{suggestions != null && suggestions.length < 1 && buyerOrSeller == 'Buyer' && (

<MissingInfoMsg>
You do not have any open escrow deals. You can create one on the main escrow page.
</MissingInfoMsg>

)}

{sellerDrips != null && sellerDrips.length < 1 && buyerOrSeller == 'Seller' && (

<MissingInfoMsg>
You do not have any open escrow deals. You can create one on the main escrow page.
</MissingInfoMsg>

)}


<span className={buyerDisplay}>

    {suggestions != null && Array.isArray(suggestions) && suggestions.map((item, index) => (
        <Suggestion key={index}>
        <SuggestionDesc>
        Drip {item.amount != null && item.amount} to {item.seller != null && item.seller}
        </SuggestionDesc>
        <SuggestionDeets>
        Ends {item.end_time != null && new Date(item.end_time * 1000).toLocaleDateString()}
        </SuggestionDeets>
        <SuggestionDeets>

            <SuggestionUser>
                {item.lock_type != null && item.lock_type == "p2p" && (
                  <FinalizeButton onClick={() => { finalizeDrip(item.ID, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) }}>
                  Finalize
                  </FinalizeButton>
                )}


{item.lock_type != null && item.lock_type == "vesting" && (
                  <VestingButton>
                  vesting
                  </VestingButton>
                )}


            </SuggestionUser>

            <SuggestionUser>

            </SuggestionUser>


            <SuggestionUser>




                  {item.lock_type != null && item.lock_type == "p2p" && (
                  <SuggestionBtn onClick={() => { cancelDrip(item.ID, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) }}>
                  Cancel
                 </SuggestionBtn>
                )}

            </SuggestionUser>
         
        </SuggestionDeets>
        </Suggestion>
    ))}

</span>

<span className={sellerDisplay}>


    {sellerDrips != null && Array.isArray(sellerDrips) && sellerDrips.map((item, index) => (
        <Suggestion key={index}>
        <SuggestionDesc>
        Receiving {item.amount != null && item.amount} from {item.buyer != null && item.buyer}
        </SuggestionDesc>
        <SuggestionDeets>
        Ends {item.end_time != null && new Date(item.end_time * 1000).toLocaleDateString()}
        </SuggestionDeets>
        <SuggestionDeets>

<SuggestionUser>

</SuggestionUser>

<SuggestionUser>

</SuggestionUser>

            <SuggestionUser>

                  <FinalizeButton onClick={() => { claimDrip(item.ID, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) }}>
                      Claim
                  </FinalizeButton>

            </SuggestionUser>




         
        </SuggestionDeets>
        </Suggestion>
    ))}


</span>


</SuggestionsCont>
</FarmsPageCont>





    </UALProvider>
    <br/><br/><br/>
    </div>
  )
}

export default MyEscrow