import React, { useEffect } from 'react';
import { GridComponent, Resize, Sort, ContextMenu, Filter, Page, ExcelExport, PdfExport, Edit, Inject } from '@syncfusion/ej2-react-grids';
import { ordersData, contextMenuItems, ordersGrid, nftFarmData, nftFarmGrid } from '../../data/dummy';
import { Header } from '../../components';
import { ColumnDirective, ColumnsDirective } from '@syncfusion/ej2-react-charts';
import { useParams } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import { Anchor } from 'ual-anchor';
import { Wax } from '@eosdacio/ual-wax';
import { User } from "universal-authenticator-library";
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { Api, JsonRpc } from "eosjs";
import {Helmet} from "react-helmet";
import axios from 'axios';
import { data } from 'autoprefixer';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { BsInfoCircle } from 'react-icons/bs';
import { render } from 'react-dom';
import { MainContainer, FarmMenu, FarmMenuItem, FarmTopMenu, FarmTopMenuItem, LeftDiv, DaoName, Creator, DaoMenu, DaoMenuItem, RightDiv, AboutDAO, DaoTitle, AboutDaoCont, AboutDaoTitle, AboutDaoBody, SingleTemplate, TemplatesCont, StakingCont, DaoButton, NFT, NFTsCont, NFTLogo, NoDaosMessage, ProposalInput, DaoButtonCont, RamModal, WalletCont, FarmButtonCont, ToggleSwitch, InnerSwitch, ToggleSwitchCont, ToggleMessage, StakeManyButton, StakeManyDiv, LeftProfile, RightProfile, ProfileCont, ProfileImage, LeftProfileImg, SocialButton, FarmsTitle, FarmsPageCont, DropPageCont, MissingInfoMsg, Drop, DropTitle, Modal, ModalContent, Spinner, SpinnerRed, SpinnerBlue, SpinnerGreen, GameButton, DropImage, Remaining, Price, QuantityToBuy, MinMint, AddNFT, DropDescription, DropBody, PoolAssetsTitle, UnboxTemplate } from '../../components/LeftDiv';
import NumberFormat from 'react-number-format';
var thisCollection;
var list = [];
var list2 = [];
import { Name, raw } from "eos-common";
import ReactMarkdown from 'react-markdown';

function getRndApi() {
  const apiList = ["https://wax.greymass.com", "https://wax.pink.gg", "https://wax.eosrio.io"];
  return apiList[Math.floor(Math.random() * (2 - 0) ) + 0];
}

const apiList = ["https://eos.api.eosnation.io", "https://api.eoseoul.io", "https://eos.eosphere.io"];

const atomicApiList = ["https://eos.api.atomicassets.io"];



let zero = '0';

const myChain = {
  chainId: 'aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906',
  rpcEndpoints: [{
      protocol: 'https',
      host: 'eos.greymass.com',
      port: '443'
  }]
};
const wtf = ['526134'];
const rpc = new JsonRpc('https://eos.greymass.com');


const anchor = new Anchor([myChain], {

  appName: 'WaxDAO',

});

function getRndInteger(min, max) {
  return Math.floor(Math.random() * (max - min) ) + min;
}


const purchaseDrop = async (DropID, quantity, dropType, rewardContract, pricePerNFT, farmPrecision, tokenName, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {

    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');

    var uniqueID = getRndInteger(100000000, 200000000);

    var apiWorked = "no";

    const walletProvider = localStorage.getItem('eosWalletProvider');

    if(walletProvider != 'anchor'){

      setEnterModalText('You are not logged in. Click "Wallet" in the top menu');
  
    }

    var amountToSend = (pricePerNFT * quantity).toFixed(farmPrecision);

  
      if(walletProvider == 'anchor'){
  
      // Retrieve current session from state
      const session = await anchor.login()
      //console.log(session[0].signTransaction);
    
  
    
      try {
        // Reset our response state to clear any previous transaction data
        
        const action = 
          [{
            account: 'waxdaomarket',
            name: 'assertdrop',
            authorization: [{
              actor: anchor.users[0].accountName,
              permission: anchor.users[0].requestPermission,
          }],
          data: {
              user: anchor.users[0].accountName,
              drop_ID: DropID,
              quantity_to_assert: quantity,
          }
        },
        
        {
            account: rewardContract,
            name: 'transfer',
            authorization: [{
              actor: anchor.users[0].accountName,
              permission: anchor.users[0].requestPermission,
          }],
          data: {
            to: 'waxdaomarket',
            from: anchor.users[0].accountName,
            quantity: amountToSend + ' ' + tokenName,
            memo: '|purchase_drop|' + DropID + '|' + uniqueID + '|',
          }
        }]
        // Call transact on the session (compatible with eosjs.transact)
        const response = await session[0].signTransaction({
          actions: action
        }, {
          blocksBehind: 3,
          expireSeconds: 60,
          broadcast: true,
        })
        // Update application state with the responses of the transaction
        setLoadingDisplay('');
        setEnterButtonsDisplay('hidden');
  
  
        if(dropType == "premint.pool"){
  
  
        setEnterModalText('Fetching preminted NFTs...')
        const timer = setTimeout(() => {
    
          try {
          //get result from API
          axios.post(`${apiList[Math.floor(Math.random() * (2 - 0) ) + 0]}/v1/chain/get_table_rows`,{
            table:"results",
            scope:"waxdaomarket",
            code:"waxdaomarket",
            limit:1,
            lower_bound:uniqueID,
            upper_bound:uniqueID,
            json:true
          }).then((resultResponse) => {
            
            if(resultResponse.data.rows[0].outcome != ''){
    
              apiWorked = "yes";

              axios.get(`${atomicApiList[Math.floor(Math.random() * (0 - 0) ) + 0]}/atomicassets/v1/assets?ids=${resultResponse.data.rows[0].outcome.join("%2C")}&page=1&limit=100&order=desc&sort=asset_id`)
              .then((validResponse) => {
                //console.log(validResponse.data);
                setEnterModalText(
                  <span className="text-center align-center justify-center">
                    Here are your new NFTs!<br/>
                  { validResponse != null && validResponse.data.data.length > 0 ? validResponse.data.data.map((item, index) => (
    
                  <NFT key={index} >
                  <p key={index}><span className="font-bold">{item.name != null ? item.name : ''}</span>
                  <br/>Mint {item.template_mint != null ? item.template_mint : ''}
                  </p>
    
                  {item.data.img != null && (
                  <NFTLogo
                  src={`https://ipfs.io/ipfs/${item.data.img.trim()}`} 
                  />
    
                  )}
    
                  {item.data.img == null && item.data.video != null && (
    
                  <video style={{ width: "150px", maxWidth: "150px", height: "150px", maxHeight: "150px", borderRadius:'25%'}} loop>
                  <source src={`https://ipfs.io/ipfs/${item.data.video.trim()}`} type="video/mp4"/>
                  </video>
    
    
    
                  )}
    
                  {item.data.img == null && item.data.video == null && item.data.image != null && (
                  <NFTLogo
                  src={`https://ipfs.io/ipfs/${item.data.image.trim()}`} 
                  />
    
                  )}
    
                  </NFT>
    
    
    
                  )) : (
    <span></span>
    
                  )}
                  </span>);
                
                 
                })
    
              } else {
              setEnterModalText('Could not retrieve result. Check your wallet to see what you got.');
            }
            setLoadingDisplay('none');
            setEnterButtonsDisplay('');
        
          })
    
    
      }//end try timer
    
      catch(e) {
        setEnterModalText('Could not retrieve result. Check your wallet.');
        console.log(e)
        
      }//end catch timer
    
        }, 4000);
        const timer2 = setTimeout(() => {
  
          if(apiWorked == "no"){
            setEnterModalText('Could not retrieve result. Check your wallet.');
            setEnterButtonsDisplay('');
            setLoadingDisplay('none');
          }
    
        }, 5000);
  
  
        return () => {clearTimeout(timer); clearTimeout(timer2);}
  
  
  
        }//end if drop type is premint pool
  
        else{
  
          setLoadingDisplay('');
          setEnterButtonsDisplay('hidden');
          setEnterModalText('Processing your transaction...')
          const timer = setTimeout(() => {
            
              setEnterModalText('Your purchase was successful');
              setLoadingDisplay('none');
              setEnterButtonsDisplay('');
        
          }, 4000);
          return () => clearTimeout(timer);
  
        }
  
  
  
  
  
    
      } catch(e) {
        setEnterModalText(JSON.stringify(e.message));
        console.log(e)
        
      }
    
    
        } //end if wallet = anchor
  
  } //end purchaseDrop
  



  const claimDrop = async (DropID, quantity, dropType, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {

    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');

    var uniqueID = getRndInteger(100000000, 200000000);

    var apiWorked = "no";

    const walletProvider = localStorage.getItem('eosWalletProvider');

    if(walletProvider != 'anchor'){

      setEnterModalText('You are not logged in. Click "Wallet" in the top menu');
  
    }

  
  
      if(walletProvider == 'anchor'){
  
      // Retrieve current session from state
      const session = await anchor.login()
      //console.log(session[0].signTransaction);
    
  
    
      try {
        // Reset our response state to clear any previous transaction data
        
        const action = 
          [{
            account: 'waxdaomarket',
            name: 'claimdrop',
            authorization: [{
              actor: anchor.users[0].accountName,
              permission: anchor.users[0].requestPermission,
          }],
          data: {
              user: anchor.users[0].accountName,
              drop_ID: DropID,
              quantity_to_mint: quantity,
              unique_id: uniqueID,
          }
        }]
        // Call transact on the session (compatible with eosjs.transact)
        const response = await session[0].signTransaction({
          actions: action
        }, {
          blocksBehind: 3,
          expireSeconds: 60,
          broadcast: true,
        })
        // Update application state with the responses of the transaction
        setLoadingDisplay('');
        setEnterButtonsDisplay('hidden');
  
  
        if(dropType == "premint.pool"){
  
  
        setEnterModalText('Fetching preminted NFT...')
        const timer = setTimeout(() => {
    
          try {
          //get result from API
          axios.post(`${apiList[Math.floor(Math.random() * (2 - 0) ) + 0]}/v1/chain/get_table_rows`,{
            table:"results",
            scope:"waxdaomarket",
            code:"waxdaomarket",
            limit:1,
            lower_bound:uniqueID,
            upper_bound:uniqueID,
            json:true
          }).then((resultResponse) => {
            
            if(resultResponse.data.rows[0].outcome != ''){
    
              apiWorked = "yes";
  
              axios.get(`${atomicApiList[Math.floor(Math.random() * (0 - 0) ) + 0]}/atomicassets/v1/assets?ids=${resultResponse.data.rows[0].outcome}&page=1&limit=1&order=desc&sort=asset_id`)
              .then((validResponse) => {
                //console.log(validResponse.data);
                setEnterModalText(
                <span className="text-center align-center justify-center">
                  Here's your new NFT!<br/>
                  {validResponse != null && (
                    <span>{validResponse.data.data[0].data.name}<br/>
                    <img src={`https://ipfs.io/ipfs/${validResponse.data.data[0].data.img}`} style={{width: '150px', maxWidth: '150px', height: 'auto', marginLeft: 'auto', marginRight: 'auto', alignSelf:'center'}} />
                    </span>
                  )}
                  
                </span>);
              
               
              })
  
            } else {
              setEnterModalText('Could not retrieve result. Check your wallet to see what you got.');
            }
            setLoadingDisplay('none');
            setEnterButtonsDisplay('');
        
          })
    
    
      }//end try timer
    
      catch(e) {
        setEnterModalText('Could not retrieve result. Check your wallet.');
        console.log(e)
        
      }//end catch timer
    
        }, 4000);
        const timer2 = setTimeout(() => {
  
          if(apiWorked == "no"){
            setEnterModalText('Could not retrieve result. Check your wallet.');
            setEnterButtonsDisplay('');
            setLoadingDisplay('none');
          }
    
        }, 5000);
  
  
        return () => {clearTimeout(timer); clearTimeout(timer2);}
  
  
  
        }//end if drop type is premint pool
  
        else{
  
          setLoadingDisplay('');
          setEnterButtonsDisplay('hidden');
          setEnterModalText('Processing your transaction...')
          const timer = setTimeout(() => {
            
              setEnterModalText('Your purchase was successful');
              setLoadingDisplay('none');
              setEnterButtonsDisplay('');
        
          }, 4000);
          return () => clearTimeout(timer);
  
        }
  
  
  
  
  
    
      } catch(e) {
        setEnterModalText(JSON.stringify(e.message));
        console.log(e)
        
      }
    
    
        } //end if wallet = anchor
  
  } //end claimDrop

const DropPage = ({ location }) => {

  const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode, currentUser, 
    setCurrentUser, tokenName, setTokenName, maxSupply, setMaxSupply, decimals, setDecimals, 
    tokenString, setTokenString, farmData, setFarmData, farmCollection, setFarmCollection, 
    farmSchema, setFarmSchema, farmRewards, setFarmRewards, farmExpires, setFarmExpires, 
    farmIcon, setFarmIcon, farmContract, setFarmContract, createdBy, setCreatedBy, unstaked, 
    setUnstaked, stakedOrUnstaked, setStakedOrUnstaked, stakedDisplay, setStakedDisplay, 
    unstakedDisplay, setUnstakedDisplay, validAssets, setValidAssets, commaSeparated, 
    setCommaSeparated, stakedAssets, setStakedAssets, claimable, setClaimable, minTime, 
    setMinTime, depositAmount, setDepositAmount, depositDisplay, setDepositDisplay, totalStaked, 
    setTotalStaked, ramBalance, setRamBalance, ramInfoDisplay, setRamInfoDisplay, farmTemplates, 
    setFarmTemplates, templatesDisplay, setTemplatesDisplay, farmType, setFarmType, 
    nftsCoveredByRam, setNftsCoveredByRam, hourlyPool, setHourlyPool, schemaDisplay, 
    setSchemaDisplay, includeOrExcludeMessage, setIncludeOrExcludeMessage, templateToView, 
    setTemplateToView, refreshAssets, setRefreshAssets, aboutDaoDisplay, setAboutDaoDisplay,
    aboutFarmDisplay, setAboutFarmDisplay, stakeNftsDisplay, setStakeNftsDisplay, 
    unstakeNftsDisplay, setUnstakeNftsDisplay, walletDisplay, setWalletDisplay, assetVector,
    setAssetVector, stakeMultipleDisplay, setStakeMultipleDisplay, highlightedFarm, 
    userCanUnlock,
    setUserCanUnlock,
    setHighlightedFarm,
    profile,
    setProfile,
    rewardType,
    setRewardType,
    rewardAmount,
    setRewardAmount,
    farmPrecision,
    setFarmPrecision,
    dropList,
    setDropList,
    dropPrice,
    setDropPrice,
    whitelistType,
    setWhitelistType,
    quantity,
    setQuantity,
    enterModalText,
    setEnterModalText,
    loadingDisplay, 
    setLoadingDisplay, 
    enterButtonsDisplay,
    setEnterButtonsDisplay,
    enterModalDisplay, 
    setEnterModalDisplay,
    rewardContract,
    setRewardContract,
    pricePerNFT,
    setPricePerNFT,
    dropImage,
    setDropImage,
    totalLeft,
    setTotalLeft,
    totalAvailable,
    setTotalAvailable,
    minimumMint,
    setMinimumMint,
    templateName,
    setTemplateName,
    dropDescription,
    setDropDescription,
    limitPerUser,
    setLimitPerUser,
    cooldownSeconds,
    setCooldownSeconds,
    dropBegins,
    setDropBegins,
    dropEnds,
    setDropEnds,
    dropType,
    setDropType,
    poolIDs,
    setPoolIDs,




   } = useStateContext();

const { DropID } = useParams();


//check drops table for drop ID

useEffect(() => {
    setDropList([]);
    setDropPrice('');
    setWhitelistType('');
    setQuantity(1);
    setRewardContract('');
    setPricePerNFT('');
    setFarmPrecision('');
    setTokenName('');
    setDropImage('');
    setTotalLeft('');
    setTotalAvailable('');
    setMinimumMint('');
    setMaxSupply('');
    setTemplateName('');
    setDropDescription('');
    setFarmCollection('');
    setCreatedBy('');
    setLimitPerUser('');
    setCooldownSeconds('');
    setDropBegins('');
    setDropEnds('');
    setDropType('standard');
    setStakedAssets([]);
    setPoolIDs([]);

    
    axios.post(`${apiList[Math.floor(Math.random() * (2 - 0) ) + 0]}/v1/chain/get_table_rows`,{
        table:"drops",
        scope:"waxdaomarket",
        code:"waxdaomarket",
        limit:1,
        lower_bound: DropID,
        upper_bound: DropID,
        json:true
        })
        .then((response) => {
    
            setDropList([response.data.rows]);
            setDropPrice(response.data.rows[0].price);
            setWhitelistType(response.data.rows[0].whitelist_type);
            setRewardContract(response.data.rows[0].contract);    
            setPricePerNFT(response.data.rows[0].price.substr(0, response.data.rows[0].price.indexOf(' '))); 
            setTokenName(response.data.rows[0].price.substr(response.data.rows[0].price.indexOf(' ') + 1));
            setTotalLeft(response.data.rows[0].total_left);
            setTotalAvailable(response.data.rows[0].total_available);
            setDropDescription(response.data.rows[0].drop_description);
            setFarmCollection(response.data.rows[0].collection);
            setCreatedBy(response.data.rows[0].user);
            setLimitPerUser(response.data.rows[0].limit_per_user);
            setCooldownSeconds(response.data.rows[0].cooldown);
            setDropBegins(response.data.rows[0].start_time);
            setDropEnds(response.data.rows[0].end_time);
            setDropType(response.data.rows[0].drop_type);
            setDropImage(response.data.rows[0].drop_logo);
            setPoolIDs(response.data.rows[0].other);

            var pool_id = response.data.rows[0].other[0];
            //console.log(pool_id)

            if(response.data.rows[0].drop_type == "premint.pack"){ return; }


            if(response.data.rows[0].price.indexOf('.')){

                var decimalString = response.data.rows[0].price.substr(0, response.data.rows[0].price.indexOf(' '));
    
                setFarmPrecision(decimalString.substr(decimalString.indexOf('.') + 1).length);
              }
        
              else{

                setFarmPrecision('0');
              }

              if(response.data.rows[0].drop_type == "standard"){

                axios.get(`${atomicApiList[Math.floor(Math.random() * (0 - 0) ) + 0]}/atomicassets/v1/templates/${response.data.rows[0].collection}/${response.data.rows[0].template_id}`)
                .then((atomicResponse) => {
  
                    //console.log(atomicResponse.data.data)
                    setDropImage(atomicResponse.data.data.immutable_data.img);
                    setMinimumMint(Number(atomicResponse.data.data.issued_supply) + 1);
                    setMaxSupply(atomicResponse.data.data.max_supply);
                    setTemplateName(atomicResponse.data.data.name);
                 
                }) //end then atomicresponse


              }//end if drop type is standard


              if(response.data.rows[0].drop_type == "premint.pool"){

                setQuantity('1');

                //get the pool details from our table
                axios.post(`${apiList[Math.floor(Math.random() * (2 - 0) ) + 0]}/v1/chain/get_table_rows`,{
                  table:"premintpools",
                  scope:"waxdaomarket",
                  code:"waxdaomarket",
                  limit:1,
                  lower_bound: pool_id,
                  upper_bound: pool_id,
                  json:true
                  })
                  .then((poolResponse) => {


                //then, get the NFTs from atomicassets 

                //get all the assets in the pool so we can display them
                    //console.log(poolResponse)
                if( Number(poolResponse.data.rows[0].amount_of_assets) >= 1){

                  axios.get(`${atomicApiList[Math.floor(Math.random() * (0 - 0) ) + 0]}/atomicassets/v1/assets?ids=${poolResponse.data.rows[0].assets.join("%2C")}&page=1&limit=1000&order=desc&sort=asset_id`)
                  .then((validResponse) => {
      
                    setStakedAssets(validResponse.data.data);
                    //console.log(validResponse.data.data);
                   
                  })
    
                }

              

              })
              .catch((error) => console.log(error)); 

              }//end if drop type is premint.pool

        })
        .catch((error) => console.log(error));  

      }
    
    , []);

//if no result, render (this drop doesnt exist)

//if result, render the page

  return (
    <div id="seo">
    <Helmet>
    <title>Drop {DropID} Details</title>
    <meta name="description" content={`Buy NFTs from drop ${DropID} on WaxDao`} />
    <link rel="canonical" href={`https://eos.waxdao.io/drops/${DropID}`} />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor]} appName={"WaxDAO"}>

    <Modal className={enterModalDisplay}>
      <ModalContent>
        {enterModalText}
        
      </ModalContent>

        <Spinner style={{display:loadingDisplay}}>
        <SpinnerRed  />
        <SpinnerBlue  />
        <SpinnerGreen />
        </Spinner>

      <br/>
      <GameButton onClick={() => setEnterModalDisplay('hidden') }>
        Close Window
      </GameButton>
      </Modal>


    <MainContainer>

    {dropPrice != null && dropPrice != '' && (


        <DropPageCont>
        <Drop>
{/* 
            <DropTitle>
            Drop Type
            </DropTitle>
            {whitelistType == "none" && ("Public Drop")}
            {whitelistType == "farm" && ("WaxDAO Farm")}
            {whitelistType == "usernames" && ("Specific Users")}


            <DropTitle>
            Drop {DropID}
            </DropTitle>

 */}

{whitelistType != null && whitelistType != "none" && (
<span>
<MissingInfoMsg>
This is a whitelisted drop. If you are not whitelisted by the creator, you won't be able to participate.
<br/><br/>
Whitelist type: {whitelistType}

</MissingInfoMsg>
<br/>
</span>

)}


            <Remaining>
            {totalLeft != null && totalLeft}/{totalAvailable != null && totalAvailable} left
            </Remaining>
{dropType != null && dropType == "standard" && (
            <span>
            <MinMint>
            Min Mint: {minimumMint != null && minimumMint}/{maxSupply != null && maxSupply != '0' && maxSupply}{maxSupply != null && maxSupply == '0' && (<span>&#8734;</span>)}
            </MinMint>

            <DropImage src={dropImage != null && `https://ipfs.io/ipfs/${dropImage}`} />
            </span>
 )} 


{dropType != null && dropType == "premint.pool" && (
            <span>
            <DropImage src={dropImage != null && `https://ipfs.io/ipfs/${dropImage}`} />
            </span>
)}



            {templateName != null && templateName}


            {dropPrice != null && dropPrice != "0 FREE" && (
            <Price>
            {pricePerNFT != null && quantity != null && farmPrecision != null && (pricePerNFT * quantity).toFixed(farmPrecision)} {tokenName != null && tokenName}
            </Price>

            )}


            {dropPrice != null && dropPrice == "0 FREE" && (
            <Price>
            FREE
            </Price>

            )}


{dropType != null && dropType == "standard" && (
<span>
<label htmlFor="dropPrice"><br/>Quantity To {dropPrice != null && dropPrice != "0 FREE" && "Buy"}{dropPrice != null && dropPrice == "0 FREE" && "Claim"}: <br /></label>
            <AddNFT onClick={() => { 
                if(quantity <= 1){
                    setQuantity(1);
                } else {
                    setQuantity(quantity - 1)
                }
                 }}>&nbsp;-&nbsp;</AddNFT>
            <QuantityToBuy 
            type="text" 
            maxLength={4}
            id="dropPrice" 
            placeholder='e.g. 3'
            value={quantity}
            onChange={(e) => {
                setQuantity(Math.round(e.target.value));
                }}
                
            />
            <AddNFT onClick={() => { setQuantity(quantity + 1) }}>&nbsp;+&nbsp;</AddNFT>
<br/>

</span>
)}

{dropType != null && dropType == "premint.pool" && (
<span>
<label htmlFor="dropPrice"><br/>Quantity To {dropPrice != null && dropPrice != "0 FREE" && "Buy"}{dropPrice != null && dropPrice == "0 FREE" && "Claim"}: <br /></label>

            <QuantityToBuy 
            type="text" 
            maxLength={1}
            id="dropPrice" 
            value={1}                
            />
<br/>

</span>
)}


{dropPrice != null && dropPrice != "0 FREE" && (

<DaoButton onClick={() => { purchaseDrop(DropID, quantity, dropType, rewardContract, pricePerNFT, farmPrecision, tokenName, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) }}>
Buy {quantity} NFTs
</DaoButton>

)}


{dropPrice != null && dropPrice == "0 FREE" && (

<DaoButton onClick={() => { claimDrop(DropID, quantity, dropType, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) }}>
Claim {quantity} NFTs
</DaoButton>

)}



        <DropDescription>
        <DropTitle>
            Collection: 
        </DropTitle>
        <DropBody>
            {farmCollection != null && farmCollection}
        </DropBody>

        <DropTitle>
            Drop Creator: 
        </DropTitle>
        <DropBody>
            {createdBy != null && createdBy}
        </DropBody>

        <DropTitle>
            Drop Type: 
        </DropTitle>
        <DropBody>
            {dropType != null && dropType == "standard" && "Mint On Demand"}
            {dropType != null && dropType == "premint.pool" && "Preminted Pool"}
        </DropBody>

        {dropType != null && dropType == "premint.pool" && (
          <span>
        <DropTitle>
            NFTs Per Order:
        </DropTitle>
        <DropBody>
            {poolIDs != null && poolIDs.length > 0 && (<span>{poolIDs.length}</span>)}
        </DropBody>


        <DropTitle>
            Drop Draws From These Pools:
        </DropTitle>
        <DropBody>
           
        

        { poolIDs.length > 0 ? poolIDs.map((item, index) => (


          //render all this if the farm type is template and the template matches

          <a key={index} href={`https://eos.waxdao.io/premint-pool/${item}`} target="none"><UnboxTemplate>{item}</UnboxTemplate></a>


          )) : (
          <NoDaosMessage>
            No pools to draw from.
          </NoDaosMessage>


          )}

          </DropBody>


















        </span>
      )}

        <DropTitle>
            Limit Per User: 
        </DropTitle>
        <DropBody>
            {limitPerUser != null && limitPerUser}
        </DropBody>

        <DropTitle>
            Cooldown: 
        </DropTitle>
        <DropBody>
            {cooldownSeconds != null && cooldownSeconds != '0' && (<span>{cooldownSeconds} seconds</span>)}
            {cooldownSeconds != null && cooldownSeconds == '0' && (<span>No cooldown</span>)}
        </DropBody>

        <DropTitle>
            Start Time: 
        </DropTitle>
        <DropBody>
            {dropBegins != null && dropBegins != '0' && (<span>{new Date(dropBegins * 1000).toLocaleString()}</span>)}
            {dropBegins != null && dropBegins == '0' && (<span>In progress</span>)}
        </DropBody>

        <DropTitle>
            End Time: 
        </DropTitle>
        <DropBody>
           {dropEnds != null && dropEnds != '0' && (<span>{new Date(dropEnds * 1000).toLocaleString()}</span>)}
        </DropBody>

                <br/><br/>
                {dropDescription != null && dropDescription}
        </DropDescription>


{/* 
<span className={dropType != null && dropType != "premint.pool" && "hidden"}>


        <NFTsCont>

        <PoolAssetsTitle>
            Assets In Pool
          </PoolAssetsTitle>


{ stakedAssets.length > 0 ? stakedAssets.map((item, index) => (


<span key={index}>
 

<NFT key={index} style={stakeMultipleDisplay == "hidden" ? {} : stakeMultipleDisplay == "" && removalVector.indexOf(item.asset_id) > -1 ? {border:'1px solid indianred'} : stakeMultipleDisplay == "" && removalVector.indexOf(item.asset_id) == -1 ? {border:'1px solid #696969'} : ''}>
<p key={index}><span className="font-bold">{item.name != null ? item.name : ''}</span>
      
<br/><span className="font-semibold">Template: {item.template != null ? item.template.template_id : 'None'}</span> 
<br/>Mint {item.template_mint != null ? item.template_mint : ''}
</p>

{item.data.img != null && (
<NFTLogo key={index} 
src={`https://ipfs.io/ipfs/${item.data.img.trim()}`} 
/>

)}

{item.data.img == null && item.data.video != null && (

<video style={{ width: "150px", maxWidth: "150px", height: "150px", maxHeight: "150px", borderRadius:'25%'}} loop>
<source src={`https://ipfs.io/ipfs/${item.data.video.trim()}`} type="video/mp4"/>
</video>



)}

{item.data.img == null && item.data.video == null && item.data.image != null && (
<NFTLogo key={index} 
src={`https://ipfs.io/ipfs/${item.data.image.trim()}`} 
/>

)}

</NFT>



  



</span>









)) : (
<NoDaosMessage>
  This pool doesn't have any assets.
</NoDaosMessage>


)}


</NFTsCont>
</span>

 */}

        </Drop>

        </DropPageCont>


    )} 
    
    {/* //end if drop price is not null */}

    

    {(dropPrice == null || dropPrice == '') && (


    <DropPageCont>
        <MissingInfoMsg>
        This drop does not exist.<br/><br/>
        If this is an error, we may be having API issues.
        </MissingInfoMsg>
    </DropPageCont>


    )} 

    {/* //end if drop price is not null */}  

    </MainContainer>
    </UALProvider>
    <br/><br/><br/>
    </div>
  )
}

export default DropPage