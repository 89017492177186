import React, { useEffect } from 'react';
import { GridComponent, Resize, Sort, ContextMenu, Filter, Page, ExcelExport, PdfExport, Edit, Inject } from '@syncfusion/ej2-react-grids';
import { ordersData, contextMenuItems, ordersGrid, nftFarmData, nftFarmGrid } from '../../data/dummy';
import { Header } from '../../components';
import { ColumnDirective, ColumnsDirective } from '@syncfusion/ej2-react-charts';
import { useParams } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import { Anchor } from 'ual-anchor';
import { Wax } from '@eosdacio/ual-wax';
import { User } from "universal-authenticator-library";
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { Api, JsonRpc } from "eosjs";
import {Helmet} from "react-helmet";
import axios from 'axios';
import { data } from 'autoprefixer';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { BsInfoCircle } from 'react-icons/bs';
import { render } from 'react-dom';
import { MainContainer, FarmMenu, FarmMenuItem, FarmTopMenu, FarmTopMenuItem, LeftDiv, DaoName, Creator, DaoMenu, DaoMenuItem, RightDiv, AboutDAO, DaoTitle, AboutDaoCont, AboutDaoTitle, AboutDaoBody, SingleTemplate, TemplatesCont, StakingCont, DaoButton, NFT, NFTsCont, NFTLogo, NoDaosMessage, ProposalInput, DaoButtonCont, RamModal, WalletCont, FarmButtonCont, ToggleSwitch, InnerSwitch, ToggleSwitchCont, ToggleMessage, StakeManyButton, StakeManyDiv, LeftProfile, RightProfile, ProfileCont, ProfileImage, LeftProfileImg, SocialButton, FarmsTitle, FarmsPageCont, DropPageCont, MissingInfoMsg, Drop, DropTitle, Modal, ModalContent, Spinner, SpinnerRed, SpinnerBlue, SpinnerGreen, GameButton, DropImage, Remaining, Price, QuantityToBuy, MinMint, AddNFT, DropDescription, DropBody, QuantityToDeposit } from '../../components/LeftDiv';
import NumberFormat from 'react-number-format';
var thisCollection;
var list = [];
var list2 = [];
import { Name, raw } from "eos-common";
import ReactMarkdown from 'react-markdown';

function getRndApi() {
  const apiList = ["https://wax.greymass.com", "https://wax.pink.gg", "https://wax.eosrio.io"];
  return apiList[Math.floor(Math.random() * (2 - 0) ) + 0];
}


const apiList = ["https://eos.api.eosnation.io", "https://api.eoseoul.io", "https://eos.eosphere.io"];

const atomicApiList = ["https://eos.api.atomicassets.io"];



let zero = '0';

const myChain = {
  chainId: 'aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906',
  rpcEndpoints: [{
      protocol: 'https',
      host: 'eos.greymass.com',
      port: '443'
  }]
};
const wtf = ['526134'];
const rpc = new JsonRpc('https://eos.greymass.com');


const anchor = new Anchor([myChain], {

  appName: 'WaxDAO',

});





const depositRam = async (CollectionName, quantity, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {

    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
    const walletProvider = localStorage.getItem('eosWalletProvider');

    if(walletProvider != 'anchor'){

      setEnterModalText('You are not logged in. Click "Wallet" in the top menu');
  
    }

    var amountToSend = Number(quantity).toFixed(4);
  

      if(walletProvider == 'anchor'){
  
      // Retrieve current session from state
      const session = await anchor.login()
      //console.log(session[0].signTransaction);
    
  
    
      try {
        // Reset our response state to clear any previous transaction data
        
        const action = 
          [{
            account: 'eosio.token',
            name: 'transfer',
            authorization: [{
              actor: anchor.users[0].accountName,
              permission: anchor.users[0].requestPermission,
          }],
          data: {
            to: 'waxdaomarket',
            from: anchor.users[0].accountName,
            quantity: amountToSend + ' EOS',
            memo: '|deposit_collection_ram|' + CollectionName + '|',
          }
        }]
        // Call transact on the session (compatible with eosjs.transact)
        const response = await session[0].signTransaction({
          actions: action
        }, {
          blocksBehind: 3,
          expireSeconds: 60,
          broadcast: true,
        })
        // Update application state with the responses of the transaction
        setLoadingDisplay('');
        setEnterButtonsDisplay('hidden');
        setEnterModalText('Processing your transaction...')
        const timer = setTimeout(() => {
          
            setEnterModalText('Your deposit was successful');
            setLoadingDisplay('none');
            setEnterButtonsDisplay('');
      
        }, 4000);
        return () => clearTimeout(timer);
    
      } catch(e) {
        setEnterModalText(JSON.stringify(e.message));
        console.log(e)
        
      }
    
    
        } //end if wallet = anchor
  
  } //end depositRam





  const withdrawRam = async (CollectionName, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {

    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
    const walletProvider = localStorage.getItem('eosWalletProvider');

    if(walletProvider != 'anchor'){

      setEnterModalText('You are not logged in. Click "Wallet" in the top menu');
  
    }


      if(walletProvider == 'anchor'){
  
      // Retrieve current session from state
      const session = await anchor.login()
      //console.log(session[0].signTransaction);
    
  
    
      try {
        // Reset our response state to clear any previous transaction data
        
        const action = 
          [{
            account: 'waxdaomarket',
            name: 'withdrawram',
            authorization: [{
              actor: anchor.users[0].accountName,
              permission: anchor.users[0].requestPermission,
          }],
          data: {
            user: anchor.users[0].accountName,
            collection: CollectionName,
          }
        }]
        // Call transact on the session (compatible with eosjs.transact)
        const response = await session[0].signTransaction({
          actions: action
        }, {
          blocksBehind: 3,
          expireSeconds: 60,
          broadcast: true,
        })
        // Update application state with the responses of the transaction
        setLoadingDisplay('');
        setEnterButtonsDisplay('hidden');
        setEnterModalText('Processing your transaction...')
        const timer = setTimeout(() => {
          
            setEnterModalText('Your withdrawal was successful');
            setLoadingDisplay('none');
            setEnterButtonsDisplay('');
      
        }, 4000);
        return () => clearTimeout(timer);
    
      } catch(e) {
        setEnterModalText(JSON.stringify(e.message));
        console.log(e)
        
      }
    
    
        } //end if wallet = anchor
  
  } //end withdrawRam
  




const CollectionPage = ({ location }) => {

  const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode, currentUser, 
    setCurrentUser, tokenName, setTokenName, maxSupply, setMaxSupply, decimals, setDecimals, 
    tokenString, setTokenString, farmData, setFarmData, farmCollection, setFarmCollection, 
    farmSchema, setFarmSchema, farmRewards, setFarmRewards, farmExpires, setFarmExpires, 
    farmIcon, setFarmIcon, farmContract, setFarmContract, createdBy, setCreatedBy, unstaked, 
    setUnstaked, stakedOrUnstaked, setStakedOrUnstaked, stakedDisplay, setStakedDisplay, 
    unstakedDisplay, setUnstakedDisplay, validAssets, setValidAssets, commaSeparated, 
    setCommaSeparated, stakedAssets, setStakedAssets, claimable, setClaimable, minTime, 
    setMinTime, depositAmount, setDepositAmount, depositDisplay, setDepositDisplay, totalStaked, 
    setTotalStaked, ramBalance, setRamBalance, ramInfoDisplay, setRamInfoDisplay, farmTemplates, 
    setFarmTemplates, templatesDisplay, setTemplatesDisplay, farmType, setFarmType, 
    nftsCoveredByRam, setNftsCoveredByRam, hourlyPool, setHourlyPool, schemaDisplay, 
    setSchemaDisplay, includeOrExcludeMessage, setIncludeOrExcludeMessage, templateToView, 
    setTemplateToView, refreshAssets, setRefreshAssets, aboutDaoDisplay, setAboutDaoDisplay,
    aboutFarmDisplay, setAboutFarmDisplay, stakeNftsDisplay, setStakeNftsDisplay, 
    unstakeNftsDisplay, setUnstakeNftsDisplay, walletDisplay, setWalletDisplay, assetVector,
    setAssetVector, stakeMultipleDisplay, setStakeMultipleDisplay, highlightedFarm, 
    userCanUnlock,
    setUserCanUnlock,
    setHighlightedFarm,
    profile,
    setProfile,
    rewardType,
    setRewardType,
    rewardAmount,
    setRewardAmount,
    farmPrecision,
    setFarmPrecision,
    dropList,
    setDropList,
    dropPrice,
    setDropPrice,
    whitelistType,
    setWhitelistType,
    quantity,
    setQuantity,
    enterModalText,
    setEnterModalText,
    loadingDisplay, 
    setLoadingDisplay, 
    enterButtonsDisplay,
    setEnterButtonsDisplay,
    enterModalDisplay, 
    setEnterModalDisplay,
    rewardContract,
    setRewardContract,
    pricePerNFT,
    setPricePerNFT,
    dropImage,
    setDropImage,
    totalLeft,
    setTotalLeft,
    totalAvailable,
    setTotalAvailable,
    minimumMint,
    setMinimumMint,
    templateName,
    setTemplateName,
    dropDescription,
    setDropDescription,
    limitPerUser,
    setLimitPerUser,
    cooldownSeconds,
    setCooldownSeconds,
    collectionInfo,
    setCollectionInfo,
    waxBalance,
    setWaxBalance,




   } = useStateContext();

const { CollectionName } = useParams();


//check drops table for drop ID
useEffect(() => {
    setCollectionInfo([]);
    
    
    
     const eosUsername = localStorage.getItem('eosAccount');
     const walletProvider = localStorage.getItem('eosWalletProvider');
      
     if(eosUsername){
       //console.log('You have an active session');
       '';
       //setProfileDisplay('flex');
     }else{
       //console.log('You are not logged in');
       return('');
     }
    
     const nameValue = new Name(eosUsername).raw().toString();
    

    
     axios.get(`${atomicApiList[Math.floor(Math.random() * (0 - 0) ) + 0]}/atomicassets/v1/collections/${CollectionName}`)
                .then((response) => {
            
                    setCollectionInfo([response.data.data]);
                    //console.log(response.data.data)
                  
                  
                })
                .catch((error) => console.log(error));

      }
    
    , []);




    useEffect(() => {

        setRamBalance('');
        
         const eosUsername = localStorage.getItem('eosAccount');
         const walletProvider = localStorage.getItem('eosWalletProvider');
          
         if(eosUsername){
           //console.log('You have an active session');
           '';
           //setProfileDisplay('flex');
         }else{
           //console.log('You are not logged in');
           return('');
         }
        
         const nameValue = new Name(CollectionName).raw().toString();
        
         axios.post(`${apiList[Math.floor(Math.random() * (2 - 0) ) + 0]}/v1/chain/get_table_rows`,{
            table:"rambalances",
            scope:"waxdaomarket",
            code:"waxdaomarket",
            limit:1,
            lower_bound: nameValue,
            upper_bound: nameValue,
            json:true
          }).then((ramResponse) => {
        
            console.log(ramResponse);
            setRamBalance(ramResponse.data.rows[0].balance);
            
        
        
          })//end .then ramResponse
          .catch((error) => console.log(error));
          
          }
        
        , []);






        useEffect(() => {
          setWaxBalance([]);
           const eosUsername = localStorage.getItem('eosAccount');
           const walletProvider = localStorage.getItem('eosWalletProvider');
            
           if(eosUsername){
             //console.log('You have an active session');
             '';
             //setProfileDisplay('flex');
           }else{
             //console.log('You are not logged in');
             return('');
           }
            
            axios.post(`${apiList[Math.floor(Math.random() * (2 - 0) ) + 0]}/v1/chain/get_table_rows`,{
              table:"accounts",
              scope:eosUsername,
              code:"eosio.token",
              limit:50,
              json:true
            })
              .then((response) => {
      
                setWaxBalance(response.data.rows);
                console.log(response);

              })
              .catch((error) => console.log(error.response.data));
          
            }
          
          , []);

//if no result, render (this drop doesnt exist)

//if result, render the page

  return (
    <div id="seo">
    <Helmet>
    <title>{CollectionName} Collection</title>
    <meta name="description" content={`Edit and create drops for ${CollectionName} NFT collection`} />
    <link rel="canonical" href={`https://eos.waxdao.io/collection/${CollectionName}`} />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor]} appName={"WaxDAO"}>

    <Modal className={enterModalDisplay}>
      <ModalContent>
        {enterModalText}
        
      </ModalContent>

        <Spinner style={{display:loadingDisplay}}>
        <SpinnerRed  />
        <SpinnerBlue  />
        <SpinnerGreen />
        </Spinner>

      <br/>
      <GameButton onClick={() => setEnterModalDisplay('hidden') }>
        Close Window
      </GameButton>
      </Modal>


    <MainContainer>

 {collectionInfo != null && collectionInfo != '' && currentUser != null && collectionInfo[0].authorized_accounts != null && collectionInfo[0].authorized_accounts.indexOf(currentUser) > -1 && ( 

        <DropPageCont>
            <DaoTitle>
                Collection Info
            </DaoTitle>
        <Drop>







            <Remaining>
            {collectionInfo[0].collection_name != null && collectionInfo[0].collection_name}
            </Remaining>

            <DropImage src={collectionInfo[0].img != null && `https://ipfs.io/ipfs/${collectionInfo[0].img}`} />
            {collectionInfo[0].author != null && (<span>by {collectionInfo[0].author}</span>)}



            <Price>
                Ram Balance:<br/>
                {ramBalance != null && ramBalance}
            </Price>

            <Price>
                My EOS Balance:<br/>
                {waxBalance != null && waxBalance != '' && (

                <span>
                {waxBalance.map((item, index) => (

                  <span key={index} className={item.balance != null && item.balance.indexOf("EOS") == -1 && "hidden"}>

                    {item.balance != null && item.balance.indexOf("EOS") > -1 && item.balance}

                  </span>

                ))}
                </span>
                

                )}
            </Price>

            <label htmlFor="dropPrice"><br/>EOS To Deposit: <br /></label>

            <QuantityToDeposit 
            type="text" 
            maxLength={6}
            id="dropPrice" 
            placeholder='e.g. 3'
            value={quantity}
            onChange={(e) => {
                setQuantity(e.target.value);
                }}
                
            />
            
<br/>

        <DaoButton onClick={() => { depositRam(CollectionName, quantity, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) }}>
            Deposit {Number(quantity).toFixed(4)} EOS
        </DaoButton>

        <br/><br/>
        <DaoButton onClick={() => { withdrawRam(CollectionName, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) }}>
            Withdraw Ram
        </DaoButton>



        </Drop>
        </DropPageCont>


    )} 




{collectionInfo != null && collectionInfo != '' && currentUser != null && collectionInfo[0].authorized_accounts != null && collectionInfo[0].authorized_accounts.indexOf(currentUser) == -1 && (


<DropPageCont>
 
<MissingInfoMsg>
    You are not authorized to manage this collection
</MissingInfoMsg>

</DropPageCont>


)} 

{/* End if you shouldnt be on this page */}



    
    {/* //end if collection is not null */}

    

    {(collectionInfo == null || collectionInfo == '') && (


    <DropPageCont>
        <MissingInfoMsg>
        This collection does not exist.<br/><br/>
        If this is an error, we may be having API issues.
        </MissingInfoMsg>
    </DropPageCont>


    )} 

    {/* //end if collection is null */}  

    </MainContainer>
    </UALProvider>
    <br/><br/><br/>
    </div>
  )
}

export default CollectionPage