import React, { useEffect } from 'react';
import { GridComponent, Resize, Sort, ContextMenu, Filter, Page, ExcelExport, PdfExport, Edit, Inject, Search, Toolbar } from '@syncfusion/ej2-react-grids';
import { ordersData, contextMenuItems, ordersGrid, nftFarmData, nftFarmGrid, myFarmsGrid, depositTokens } from '../data/dummy';
import { Header } from '../components';
import { ColumnDirective, ColumnsDirective } from '@syncfusion/ej2-react-charts';
import { useParams } from 'react-router-dom';
import { useStateContext } from '../contexts/ContextProvider';
import { Anchor } from 'ual-anchor';
import { Wax } from '@eosdacio/ual-wax';
import { User } from "universal-authenticator-library";
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { Api, JsonRpc } from "eosjs";
import {Helmet} from "react-helmet";
import axios from 'axios';
import { data } from 'autoprefixer';
import { Name, raw } from "eos-common";
import vote from '../data/vote.png';
import { MainContainer, LeftDiv, RightDiv, DaoName, Creator, Proposals, NewProposal, AboutDAO, DaoMenu, DaoMenuItem, Wallet, DaoTitle,
  SingleProposal, ProposalButton, ProposalButtonContainer, ProposalTitle, ProposalAuthor, ProposalDescription, ProposerTypeMessage,
  ProposerList, ProposalInput, PropDescription, ProposalChoices, RemoveButton, AddButton, SubmitButton, DropDown, AboutDaoTitle,
  AboutDaoBody, AboutDaoCont, DaoButton, DaoButtonCont, StakedAmount, CreateDaoCont, CreateDaoInfo, DaoLink, BurnableCont, NewRow,
  NFT, BuyWojak, DAO, DaosCont, MyDaoTitle, NoDaosMessage
  } from '../components/LeftDiv';

let zero = '0';

const myChain = {
  chainId: '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4',
  rpcEndpoints: [{
      protocol: 'https',
      host: 'wax.greymass.com',
      port: '443'
  }]
};

const rpc = new JsonRpc('https://wax.greymass.com');


const anchor = new Anchor([myChain], {

  appName: 'WaxDAO',

});

const wax = new Wax([myChain], {

  appName: 'WaxDAO',

});


const ualLogin = async () => {
 
  console.log('Loading...');
  await anchor.login();
  getCustomersData();
  //localStorage.setItem('waxAccount', 'anchor.users[0].accountName')
  //console.log(anchor.users[0].accountName);

}

const ualLogout = async () => {
 
  console.log('Loading...');
  await anchor.logout();
  //console.log(anchor.users[0].accountName);

}





const MyDAOs = () => {

  const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode, currentUser, tokenName, setTokenName, maxSupply, setMaxSupply, decimals, setDecimals, tokenString, setTokenString, farmData, setFarmData, farmCollection, setFarmCollection, farmSchema, setFarmSchema, farmRewards, setFarmRewards, farmExpires, setFarmExpires, farmIcon, setFarmIcon, farmContract, setFarmContract, createdBy, setCreatedBy, farmList, setFarmList, ramAmount, setRamAmount } = useStateContext();

const { FarmName } = useParams();

useEffect(() => {
setFarmList([]);
  //(async () => {

 // await anchor.login();

 const waxUsername = localStorage.getItem('waxAccount');
 const walletProvider = localStorage.getItem('walletProvider');
  
 if(waxUsername){
   //console.log('You have an active session');
   '';
   //setProfileDisplay('flex');
 }else{
   //console.log('You are not logged in');
   return('');
 }
  
  
  axios.post('https://wax.pink.gg/v1/chain/get_table_rows',{
    table:"daos",
    scope:"buildawaxdao",
    code:"buildawaxdao",
    key_type: 'name',
    index_position: 3,
    limit:50,
    lower_bound: waxUsername,
    upper_bound: waxUsername,
    json:true
  })
    .then((response) => {

      setFarmList(response.data.rows);
      console.log(response.data.rows);
    })
    .catch((error) => console.log(error));

  }

, []);

  return (
  <div id="seo">
    <Helmet>
    <title>My DAOs</title>
    <meta name="description" content="View a list of DAOs that you have created on WaxDAO" />
    <link rel="canonical" href="https://waxdao.io/my-daos" />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor, wax]} appName={"WaxDAO"}>
    <MainContainer>

    <CreateDaoCont>


    <DaoTitle>
        My DAOs
        &nbsp;&nbsp;

      <a href="https://waxdao.io/create-dao">
      <DaoButton>
      + New DAO
      </DaoButton>
      </a>
    </DaoTitle>

  


<DaosCont>

      {farmList.length > 0 ? farmList.map((item, index) => (

<span key={index}>
   

{item.status == 'unlocked' ? (


<DAO>

<MyDaoTitle>
  DAO {item.ID}
</MyDaoTitle>


<img 

        style={{ width:'150px', 
          maxWidth:'150px',
          height:'150px',
          maxHeight:'150px'
          }}
        className="rounded-xl hover:drop-shadow-xl"  
          src={vote}
        />


<a href={`https://waxdao.io/edit-dao/${item.ID}`}>
<DaoButton>
  Edit Now
</DaoButton>
</a>
</DAO>



) : (

<DAO>

<MyDaoTitle>
  {item.daoname}
</MyDaoTitle>


<img 
        alt={`${item.daoname} Logo`} 
        style={{ width:'150px', 
          maxWidth:'150px',
          height:'150px',
          maxHeight:'150px'
          }}
        className="rounded-xl hover:drop-shadow-xl"  
          src={`https://ipfs.io/ipfs/${item.logo}`}
        />



<a href={`https://waxdao.io/daos/${item.daoname}`}>
<DaoButton>
  View DAO
</DaoButton>
</a>
</DAO>






)}


    


</span>

)) : (
  <NoDaosMessage>
  You have not created any DAOs yet
  </NoDaosMessage>
  )}


</DaosCont>





</CreateDaoCont>
</MainContainer>


    </UALProvider>
    <br/><br/><br/>
    </div>
  )
}

export default MyDAOs