import React, { useEffect } from 'react';
import { GridComponent, Resize, Sort, ContextMenu, Filter, Page, ExcelExport, PdfExport, Edit, Inject, Search, Toolbar } from '@syncfusion/ej2-react-grids';
import { ColumnDirective, ColumnsDirective } from '@syncfusion/ej2-react-charts';
import { useParams } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import { Anchor } from 'ual-anchor';
import { Wax } from '@eosdacio/ual-wax';
import { User } from "universal-authenticator-library";
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { Api, JsonRpc } from "eosjs";
import {Helmet} from "react-helmet";
import axios from 'axios';
import { data } from 'autoprefixer';
import { AllFarmsCont, PoolAmount, FarmButton, SingleFarmCont, FarmLogo, FarmTitle, FarmsPageCont, FarmsTitle, HighlightedFarm, HighlightedLogo, HighlightDetails, HighlightTitle, FarmEndDate, HighlightMessage, HighlightAmount, Modal, ModalContent, Spinner, SpinnerRed, SpinnerBlue, SpinnerGreen, GameButton, UserWhitelist, DaoButton, SuggestionsCont, Suggestion, SuggestionDesc, SuggestionDeets, SuggestionUser, SuggestionBtn, MissingInfoMsg, ProposalInput, DisabledButton, FinalizeButton, DropDown } from '../../components/LeftDiv';
import NumberFormat from 'react-number-format';
import { DatePickerComponent, DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

let zero = '0';

var thisUsersFarms;

function getRndInteger(min, max) {
    return Math.floor(Math.random() * (max - min) ) + min;
  }

  const myChain = {
    chainId: 'aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906',
    rpcEndpoints: [{
        protocol: 'https',
        host: 'eos.greymass.com',
        port: '443'
    }]
  };
  
  const rpc = new JsonRpc('https://eos.greymass.com');


const anchor = new Anchor([myChain], {

  appName: 'WaxDAO',

});



const ualLogin = async () => {
 
 // console.log('Loading...');
  await anchor.login();
  getCustomersData();
  //localStorage.setItem('waxAccount', 'anchor.users[0].accountName')
  //console.log(anchor.users[0].accountName);

}

const ualLogout = async () => {
 
 // console.log('Loading...');
  await anchor.logout();
  //console.log(anchor.users[0].accountName);

}






const EscrowFAQ = () => {

  const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode, currentUser, tokenName, 
    setTokenName, maxSupply, setMaxSupply, decimals, setDecimals, tokenString, setTokenString, farmData, 
    setFarmData, farmCollection, setFarmCollection, farmSchema, setFarmSchema, farmRewards, setFarmRewards, 
    farmExpires, setFarmExpires, farmIcon, setFarmIcon, farmContract, setFarmContract, createdBy, setCreatedBy, 
    farmList, setFarmList, stakedOnly, setStakedOnly, farmsUserIsIn, setFarmsUserIsIn, highlightedFarm,
    setHighlightedFarm,
    suggestions,
    setSuggestions,
    enterModalText,
    setEnterModalText,
    loadingDisplay, 
    setLoadingDisplay, 
    enterButtonsDisplay,
    setEnterButtonsDisplay,
    enterModalDisplay, 
    setEnterModalDisplay,
    description,
    setDescription,
    receivingAccount,
    setReceivingAccount,
    epochTime,
    setEpochTime,
    timestamp,
    setTimestamp,
    depositAmount,
    setDepositAmount,
    sellerDrips,
    setSellerDrips,
    whitelistType,
    setWhitelistType,
  } = useStateContext();






  return (
  <div id="seo">
    <Helmet>
    <title>Escrow FAQ</title>
    <meta name="description" content="Answers to frequently asked questions about WaxDAO escrow" />
    <link rel="canonical" href="https://waxdao.io/escrow-faq" />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor]} appName={"WaxDAO"}>
    
    <Modal className={enterModalDisplay}>
      <ModalContent>
        {enterModalText}
        
      </ModalContent>

        <Spinner style={{display:loadingDisplay}}>
        <SpinnerRed  />
        <SpinnerBlue  />
        <SpinnerGreen />
        </Spinner>

      <br/>
      <GameButton onClick={() => setEnterModalDisplay('hidden') }>
        Close Window
      </GameButton>
      </Modal>

<FarmsPageCont>
<FarmsTitle>
    Escrow FAQ
</FarmsTitle>
<SuggestionsCont>

<Suggestion>
    <SuggestionDesc>
        What is a slow drip?
    </SuggestionDesc>
    <SuggestionDeets>
        A slow drip is when you distribute tokens to someone over a long period of time.
        <br/><br/>
        Instead of sending them 1 lumpsum payment, you can slowly give them access to their tokens (as they deliver work to you, for example)
    </SuggestionDeets>
</Suggestion>
 
<Suggestion>
    <SuggestionDesc>
        How do I use the escrow feature?
    </SuggestionDesc>
    <SuggestionDeets>
        All you need to do is fill out the form on the <a href="https://eos.waxdao.io/escrow">escrow page</a>, and submit your transaction.
        <br/><br/>
        The slow drip will begin immediately upon receiving your deposit.
    </SuggestionDeets>
</Suggestion>

<Suggestion>
    <SuggestionDesc>
        Do the funds get sent to the receiver automatically?
    </SuggestionDesc>
    <SuggestionDeets>
        No, the receiver needs to claim their funds in order to receive them.<br/><br/>
        There is a claim button on the <a href="https://eos.waxdao.io/manage-escrow">manage escrow page</a>.
    </SuggestionDeets>
</Suggestion>

<Suggestion>
    <SuggestionDesc>
        What is the difference between Peer To Peer and Team Vesting?
    </SuggestionDesc>
    <SuggestionDeets>
        The peer to peer option is geared toward consumers who are doing business with each other.<br/><br/>
        For example, a buyer hires someone to do work. The funds get slowly dripped to the "seller", but the buyer can cancel the drip at any time.<br/><br/>
        Similarly, the buyer can also "finalize" the deal at any time- releasing 100% of the funds to the seller.<br/><br/>
        Team Vesting is geared more towards project owners who want to lock their tokens for a period of time.<br/><br/>
        There are some key differences here- team vesting is not cancellable, and cannot be finalized early.<br/><br/>
        Once the funds are deposited, you are locked into a slow drip and there is no way out. A great way to promote trust within your community.
    </SuggestionDeets>
</Suggestion>


<Suggestion>
    <SuggestionDesc>
        How much does this service cost?
    </SuggestionDesc>
    <SuggestionDeets>
        WaxDAO takes a 2% fee on all deposits, everything after the initial deposit is free. 
    </SuggestionDeets>
</Suggestion>


<Suggestion>
    <SuggestionDesc>
        Is there a minimum deposit?
    </SuggestionDesc>
    <SuggestionDeets>
        Yes, there is a minimum deposit of 10 EOS for all slow drip agreements.
    </SuggestionDeets>
</Suggestion>

<Suggestion>
    <SuggestionDesc>
        Can I add more to the contract after I make my deposit?
    </SuggestionDesc>
    <SuggestionDeets>
        You can not add more funds to an existing agreement, but you can create a 2nd transaction if you need to increase the funding.
    </SuggestionDeets>
</Suggestion>


<Suggestion>
    <SuggestionDesc>
        Can I add multiple users to 1 slow-drip?
    </SuggestionDesc>
    <SuggestionDeets>
        We decided this wouldn't be necessary, as you can just create a separate transaction for each wallet that you want to drip funds to.
    </SuggestionDeets>
</Suggestion>


<Suggestion>
    <SuggestionDesc>
        What tokens are supported?
    </SuggestionDesc>
    <SuggestionDeets>
        At this time, only EOS is accepted for slow drips (and WAX on the <a href="https://waxdao.io/escrow">WAX version of our site</a>. We do plan to add more tokens in the near future.
    </SuggestionDeets>
</Suggestion>


<Suggestion>
    <SuggestionDesc>
        What happens if I cancel my drip?
    </SuggestionDesc>
    <SuggestionDeets>
        You can only cancel a peer to peer drip, not a team vest.<br/><br/>
        If you cancel, you will get everything back, except for: WaxDAO's 2% fee, and any drips that have taken place since your initial deposit.
    </SuggestionDeets>
</Suggestion>


<Suggestion>
    <SuggestionDesc>
        What is the finalize button for?
    </SuggestionDesc>
    <SuggestionDeets>
        Finalize is also only available on P2P transactions.<br/><br/>
        If the seller completes their work early, or you are just feeling generous, you can finalize the transaction early and release the full amount to them.
    </SuggestionDeets>
</Suggestion>


<Suggestion>
    <SuggestionDesc>
        What should I put for the completion date?
    </SuggestionDesc>
    <SuggestionDeets>
        That's up to you! How long do you want the payments to take before they stop dripping to the seller?<br/><br/>
        If it is a team vesting transaction, how long until the team should be able to unlock all of their tokens?<br/><br/>
        This is entirely up to you. Please think carefully because there is no way out once you start vesting.
    </SuggestionDeets>
</Suggestion>


<Suggestion>
    <SuggestionDesc>
        Does the receiver need to claim every hour?
    </SuggestionDesc>
    <SuggestionDeets>
        No. They CAN, but they don't NEED to. Their funds will be there waiting for them, even if they don't click a button every hour.
    </SuggestionDeets>
</Suggestion>


<Suggestion>
    <SuggestionDesc>
        I tried to finalize/claim, but I got an error about "not having an open row". What is this?
    </SuggestionDesc>
    <SuggestionDeets>
        This just means that the person receiving tokens has never held that token before. You can use the "open" action on that token contract 
        to open a balance for them. For example, if the token is EOS.. you can do that <a href="https://bloks.io/account/eosio.token?loadContract=true&tab=Actions&account=eosio.token&scope=eosio.token&limit=100&action=open" target="none">here</a>.
    </SuggestionDeets>
</Suggestion>


<Suggestion>
    <SuggestionDesc>
        Who can I ask 9000 more questions to because I don't feel like reading?
    </SuggestionDesc>
    <SuggestionDeets>
        Please join our <a href="https://t.me/hoodpunks" target="none">Telegram</a> if you have any other questions.
    </SuggestionDeets>
</Suggestion>



</SuggestionsCont>
</FarmsPageCont>





    </UALProvider>
    <br/><br/><br/>
    </div>
  )
}

export default EscrowFAQ