import React, { useEffect } from 'react';
import { Header } from '../../components';
import { useStateContext } from '../../contexts/ContextProvider';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { Anchor } from 'ual-anchor';
import { Wax } from '@eosdacio/ual-wax';
import { User } from "universal-authenticator-library";
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { Api, JsonRpc } from "eosjs";
import {Helmet} from "react-helmet";
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import Select from 'react-select';
import axios from 'axios';

import { NavPageCont, DaoTitle, NavPageItem, NavPageInnerCont
} from '../.././components/LeftDiv';

const myChain = {
  chainId: '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4',
  rpcEndpoints: [{
      protocol: 'https',
      host: 'wax.greymass.com',
      port: '443'
  }]
};

const rpc = new JsonRpc('https://wax.greymass.com');


const anchor = new Anchor([myChain], {

  appName: 'WaxDAO',

});

const wax = new Wax([myChain], {

  appName: 'WaxDAO',

});



const StakingNav = () => {
  
  const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode, currentUser, tokenName, setTokenName, maxSupply, setMaxSupply, decimals, setDecimals, tokenString, setTokenString, memo, setMemo, farmName, setFarmName, rewardToken, setRewardToken, rewardContract, setRewardContract, poolSize, setPoolSize, collection, setCollection, schema, setSchema, farmLogo, setFarmLogo, paymentMethod, setPaymentMethod, timestamp, setTimestamp, setEpochTime, assetDisplay, setAssetDisplay, assetToBurn, setAssetToBurn, burnable, setBurnable, burnableDisplay, setBurnableDisplay, tokenToStake, setTokenToStake, rewardDecimals, setRewardDecimals, tokenContract, setTokenContract, stakingPeriod, setStakingPeriod, stakingAmount, setStakingAmount, stakingSeconds, setStakingSeconds, quantity, setQuantity } = useStateContext();


  return (
    <div id="seo">
    <Helmet>
    <title>Staking Pools Homepage - WaxDAO</title>
    <meta name="description" content="Stake WAX Tokens and earn APY on WaxDAO" />
    <link rel="canonical" href="https://waxdao.io/staking-pools" />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor, wax]} appName={"WaxDAO"}>
    <NavPageCont>
        <DaoTitle>
        Staking Pools
        </DaoTitle>   
        <NavPageInnerCont>
            <NavPageItem href="https://waxdao.io/pools">
                All Pools
            </NavPageItem>
            <NavPageItem href="https://waxdao.io/my-pools">
                My Pools
            </NavPageItem>
            <NavPageItem href="https://waxdao.io/create-pool">
                Create Pool
            </NavPageItem>
            <NavPageItem href="https://waxdao.io/pool-faq">
                Pool FAQ
            </NavPageItem>
        </NavPageInnerCont>
    </NavPageCont>   
    </UALProvider>
    </div>
  )
}

export default StakingNav