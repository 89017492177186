import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { FiSettings } from 'react-icons/fi';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';



import { Navbar, Footer, Sidebar, ThemeSettings } from './components';
import { TokenGen, HomePage, Whitelisting, Deploy, Create, Issue, Transfer, Burn, Listing, DAOs, DAOPage, Proposals, Votes, CreateDAO, MyDAOs, DAOOptions, Farms, CreateFarm, FarmPage, FarmFAQ, MyFarms, Drop, Unpack, Staking, Token, Whitepaper, Search, Pools, MyPools, CreatePool, PoolFAQ, PoolPage, Profile, FarmOptions, Swap, FarmingNav, WojakNav, StakingNav, TokenNav, DaoNav, MyDrops, CreateDrop, DropPage, Drops, CollectionPage, Suggestions, PoolOptions, Escrow, MyEscrow, EscrowFAQ } from './pages';

import { NewNavbar, NewNavLink
    } from './components/LeftDiv';




import { useStateContext } from './contexts/ContextProvider';
import './App.css';
import Unavailable from './components/Unavailable';
import CreatePack from './pages/drops/CreatePack';
import CreatePremint from './pages/drops/CreatePremint';
import CreatePremintedDrop from './pages/drops/CreatePremintedDrop';
import PremintPage from './pages/drops/PremintPage';
import UnboxList from './pages/drops/UnboxList';
import UnboxPage from './pages/drops/UnboxPage';
import PackTutorial from './pages/PackTutorial';


const colorMode = localStorage.getItem('colorMode');
const themeMode = localStorage.getItem('themeMode');




const App = () => {



    const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode, currentUser } = useStateContext();
  
    return (
    <div className={currentMode === 'Dark' ? 'dark' : ''}>


        <BrowserRouter>
            <div className="flex relative dark:bg-main-dark-bg">

            <div className={
                `dark:bg-main-dark-bg bg-main-bg min-h-screen w-full 
                ${activeMenu ?
                'md:ml-72' 
                : 'flex-2'}`
            }>

                    <Navbar />
              
            <div>
                {themeSettings && <ThemeSettings />}

                <Routes>

                    {/* Home */}

                    <Route path="/" element={<HomePage />} />
                    <Route path="/suggestions" element={<Unavailable />} />

                    {/* TokenGen */}
                
                    <Route path="/tutorial" element={<TokenGen />} />
                    <Route path="/whitelisting" element={<Whitelisting />} />
                    <Route path="/deploy-contract" element={<Deploy />} />
                    <Route path="/create-token" element={<Create />} />
                    <Route path="/issue-tokens" element={<Issue />} />
                    <Route path="/transfer-tokens" element={<Transfer />} />
                    <Route path="/burn-tokens" element={<Burn />} />
                    <Route path="/listing-request" element={<Listing />} />

                    {/* Farms */}

                    <Route path="/farms" element={<Farms />} />
                    <Route path="/my-farms" element={<MyFarms />} />
                    <Route path="/create-farm" element={<CreateFarm />} />
                    <Route path="/farm/:FarmName" element={<FarmPage />} />
                    <Route path="/edit-farm/:FarmName" element={<FarmOptions />} />
                    <Route path="/farm-faq" element={<FarmFAQ />} />

                    {/* Wojak NFTs */}
                    <Route path="/wojak" element={<Unavailable />} />
                    <Route path="/wojak/drop" element={<Unavailable />} />
                    <Route path="/wojak/swap" element={<Unavailable />} />
                    <Route path="/wojak/unpack" element={<Unavailable />} />
                    <Route path="/wojak/staking" element={<Unavailable />} />
                    <Route path="/wojak/token" element={<Unavailable />} />
                    <Route path="/wojak/whitepaper" element={<Unavailable />} />
                    <Route path="/wojak/search" element={<Unavailable />} />

                    {/* Pools */}

                    <Route path="/pools" element={<Pools />} />
                    <Route path="/my-pools" element={<MyPools />} />
                    <Route path="/create-pool" element={<CreatePool />} />
                    <Route path="/pool/:PoolName" element={<PoolPage />} />
                    <Route path="/pool-faq" element={<PoolFAQ />} />
                    <Route path="/edit-pool/:PoolName" element={<PoolOptions />} />

                    {/* Drops */}

                    <Route path="/my-drops" element={<MyDrops />} />
                    <Route path="/create-drop" element={<CreateDrop />} />
                    <Route path="/drops/:DropID" element={<DropPage />} />
                    <Route path="/collection/:CollectionName" element={<CollectionPage />} />
                    <Route path="/drops" element={<Drops />} />
                    <Route path="/create-pack/:PoolID" element={<CreatePack />} />
                    <Route path="/create-premint" element={<CreatePremint />} />
                    <Route path="/create-preminted-drop/:PoolID" element={<CreatePremintedDrop />} />
                    <Route path="/premint-pool/:PoolID" element={<PremintPage />} />
                    <Route path="/unbox" element={<UnboxList />} />
                    <Route path="/unbox/:DropID" element={<UnboxPage />}/>
                    <Route path="/pack-tutorial" element={<PackTutorial />} />

                    {/* Escrow */}

                    <Route path="/escrow" element={<Escrow />} />
                    <Route path="/manage-escrow" element={<MyEscrow />} />
                    <Route path="/escrow-faq" element={<EscrowFAQ />} />

                    {/* Governance */}
                    
                    <Route path="/daos" element={<Unavailable />} />
                    <Route path="/proposals/:ProposalID" element={<Unavailable />} />
                    <Route path="/votes" element={<Unavailable />} />
                    <Route path="/create-dao" element={<Unavailable />} />
                    <Route path="/my-daos" element={<Unavailable />} />
                    <Route path="/edit-dao/:DAOName" element={<Unavailable />} />
                    <Route path="/daos/:DAOName" element={<Unavailable />} />

                    {/* Profile */}
                    <Route path="/user/:UserName" element={<Unavailable />} />

                    {/* NewNavbar */}

                    <Route path="/nft-farming" element={<Unavailable />} />
                    <Route path="/wojak-nfts" element={<Unavailable />} />
                    <Route path="/staking-pools" element={<Unavailable />} />
                    <Route path="/token-creator" element={<Unavailable />} />
                    <Route path="/dao-menu" element={<Unavailable />} />

                </Routes>
            </div>
            </div>

            </div>       
        </BrowserRouter>
    </div>
  )
}

export default App