import React, { createContext, useContext, useState} from 'react';

const StateContext = createContext();
var initialColorMode = localStorage.getItem('colorMode');
var initialThemeMode = localStorage.getItem('themeMode');
var initialUser = localStorage.getItem('themeMode');

if(initialColorMode){
  '';  //console.log('');
} else {
    initialColorMode = '#1E4DB7';
}

if(initialThemeMode){
   ''; //console.log('');
} else {
    initialThemeMode = 'Dark';
}


const initialState = {
    chat: false,
    cart: false,
    userProfile: false,
    notification: false,
}

export const ContextProvider = ({ children }) => {
    const [activeMenu, setActiveMenu] = useState(false);
    const [isClicked, setIsClicked] = useState(initialState);
    const [screenSize, setScreenSize] = useState(undefined);
    const [currentColor, setCurrentColor] = useState(initialColorMode);
    const [currentMode, setCurrentMode] = useState(initialThemeMode);
    const [themeSettings, setThemeSettings] = useState(false);
    const [currentUser, setCurrentUser] = useState('');
    const [cloudLoginDisplay, setCloudLoginDisplay] = useState('block');
    const [cloudLogoutDisplay, setCloudLogoutDisplay] = useState('none');
    const [loginDisplay, setLoginDisplay] = useState('block');
    const [logoutDisplay, setLogoutDisplay] = useState('none');
    const [profileDisplay, setProfileDisplay] = useState('none');
    const [tokenName, setTokenName] = useState('');
    const [maxSupply, setMaxSupply] = useState('');
    const [decimals, setDecimals] = useState('');
    const [tokenString, setTokenString] = useState('');
    const [transferTokenName, setTransferTokenName] = useState('');
    const [transferAmount, setTransferAmount] = useState('');
    const [transferDecimals, setTransferDecimals] = useState('');
    const [transferTokenString, setTransferTokenString] = useState('');
    const [issueTokenName, setIssueTokenName] = useState('');
    const [issueAmount, setIssueAmount] = useState('');
    const [issueDecimals, setIssueDecimals] = useState('');
    const [issueTokenString, setIssueTokenString] = useState('');
    const [receiver, setReceiver] = useState('');
    const [burnTokenName, setBurnTokenName] = useState('');
    const [burnAmount, setBurnAmount] = useState('');
    const [burnDecimals, setBurnDecimals] = useState('');
    const [burnTokenString, setBurnTokenString] = useState('');
    const [whitelistStatus, setWhitelistStatus] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [listingIsOpen, setListingIsOpen] = useState(true);
    const [assetID, setAssetID] = useState('');
    const [assetVector, setAssetVector] = useState([]);
    const [memo, setMemo] = useState('');
    const [farmName, setFarmName] = useState('');
    const [rewardToken, setRewardToken] = useState('');
    const [rewardContract, setRewardContract] = useState('');
    const [poolSize, setPoolSize] = useState('');
    const [collection, setCollection] = useState('');
    const [schema, setSchema] = useState('');
    const [farmLogo, setFarmLogo] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('Payment Method');
    const [timestamp, setTimestamp] = useState('');
    const [assetToBurn, setAssetToBurn] = useState('');
    const [assetDisplay, setAssetDisplay] = useState('none');
    const [farmData, setFarmData] = useState('');
    const [farmCollection, setFarmCollection] = useState('');
    const [createdBy, setCreatedBy] = useState('');
    const [farmSchema, setFarmSchema] = useState('');
    const [farmSchemaArray, setFarmSchemaArray] = useState([]);
    const [farmRewards, setFarmRewards] = useState('');
    const [farmExpires, setFarmExpires] = useState('');
    const [farmIcon, setFarmIcon] = useState('');
    const [farmContract, setFarmContract] = useState('');
    const [farmList, setFarmList] = useState([]);
    const [amountToAdd, setAmountToAdd] = useState([]);
    const [logoHash, setLogoHash] = useState([]);
    const [unstaked, setUnstaked] = useState([]);
    const [stakedOrUnstaked, setStakedOrUnstaked] = useState('Staked');
    const [stakedDisplay, setStakedDisplay] = useState('hidden');
    const [unstakedDisplay, setUnstakedDisplay] = useState('flex');
    const [validAssets, setValidAssets] = useState([]);
    const [commaSeparated, setCommaSeparated] = useState('');
    const [stakedAssets, setStakedAssets] = useState([]);
    const [claimable, setClaimable] = useState('');
    const [ramAmount, setRamAmount] = useState('');
    const [ramString, setRamString] = useState('');
    const [tokenList, setTokenList] = useState([]);
    const [burnable, setBurnable] = useState([]);
    const [burnableDisplay, setBurnableDisplay] = useState('hidden');
    const [noTokensDisplay, setNoTokensDisplay] = useState('');
    const [noAssetsDisplay, setNoAssetsDisplay] = useState('block');
    const [tokenToStake, setTokenToStake] = useState('');
    const [rewardDecimals, setRewardDecimals] = useState('');
    const [tokenContract, setTokenContract] = useState('');
    const [stakingPeriod, setStakingPeriod] = useState('');
    const [stakingAmount, setStakingAmount] = useState('');
    const [stakingSeconds, setStakingSeconds] = useState('');
    const [minTime, setMinTime] = useState('');
    const [poolList, setPoolList] = useState([]);
    const [tokenPrecision, setTokenPrecision] = useState('');
    const [minAmount, setMinAmount] = useState('');
    const [newAmountToAdd, setNewAmountToAdd] = useState([]);
    const [stakedBalance, setStakedBalance] = useState('0');
    const [hourlyPool, setHourlyPool] = useState('0');
    const [totalStaked, setTotalStaked] = useState('0');
    const [depositAmount, setDepositAmount] = useState('');
    const [depositDisplay, setDepositDisplay] = useState('hidden');
    const [quantity, setQuantity] = useState('');
    const [farmType, setFarmType] = useState('');
    const [farmTypeDisplay, setFarmTypeDisplay] = useState('hidden');
    const [farmTypeValue, setFarmTypeValue] = useState('');
    const [templates, setTemplates] = useState('');
    const [ramBalance, setRamBalance] = useState('');
    const [ramInfoDisplay, setRamInfoDisplay] = useState('hidden');
    const [farmTemplates, setFarmTemplates] = useState([]);
    const [templatesDisplay, setTemplatesDisplay] = useState('hidden');
    const [nftsCoveredByRam, setNftsCoveredByRam] = useState('0');
    const [schemaDisplay, setSchemaDisplay] = useState('hidden');
    const [extendDisplay, setExtendDisplay] = useState('hidden');
    const [daysToExtend, setDaysToExtend] = useState('');
    const [templateMessage, setTemplateMessage] = useState('You can use any template in your collection, even if they are not all in the same schema :)');
    const [includeOrExcludeMessage, setIncludeOrExcludeMessage] = useState('Stakeable');
    const [optionsAmountToAdd, setOptionsAmountToAdd] = useState('');
    const [logoDisplay, setLogoDisplay] = useState('hidden');
    const [templateToView, setTemplateToView] = useState('any');
    const [stakedOnly, setStakedOnly] = useState('no');
    const [farmsUserIsIn, setFarmsUserIsIn] = useState([]);
    const [refreshAssets, setRefreshAssets] = useState(0);
    const [swapInAmount, setSwapInAmount] = useState('0');
    const [swapOutAmount, setSwapOutAmount] = useState('0');
    const [swapInToken, setSwapInToken] = useState('WAXDAO');
    const [swapOutToken, setSwapOutToken] = useState('WOJAK');
    const [swapInDisplay, setSwapInDisplay] = useState('');
    const [swapOutDisplay, setSwapOutDisplay] = useState('');
    const [rwdToken, setRwdToken] = useState('');
    const [collectionDisplay, setCollectionDisplay] = useState('');
    const [tokenDisplay, setTokenDisplay] = useState('hidden');
    const [threshold, setThreshold] = useState('');
    const [minimumVotes, setMinimumVotes] = useState('');
    const [minWeight, setMinWeight] = useState('');
    const [proposalLength, setProposalLength] = useState('');
    const [nftDaoDisplay, setNftDaoDisplay] = useState('');
    const [stakeTokensDisplay, setStakeTokensDisplay] = useState('hidden');
    const [unstakeTokensDisplay, setUnstakeTokensDisplay] = useState('hidden');
    const [proposerDisplay, setProposerDisplay] = useState('hidden');
    const [proposerType, setProposerType] = useState('');
    const [proposerTypeValue, setProposerTypeValue] = useState('');
    const [minWeightDisplay, setMinWeightDisplay] = useState('');
    const [usernames, setUsernames] = useState('');
    const [usernamesMessage, setUsernamesMessage] = useState('Which usernames should be able to create a proposal? Separate each with a comma.');
    const [proposalList, setProposalList] = useState([]);
    const [newProposalDisplay, setNewProposalDisplay] = useState('hidden');
    const [newProposalText, setNewProposalText] = useState('+ Create New');
    const [proposalType, setProposalType] = useState('Proposal Type');
    const [proposalTypeValue, setProposalTypeValue] = useState('');
    const [choicesList, setChoicesList] = useState([{ choice: ''}]);
    const [description, setDescription] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const [proposalInfo, setProposalInfo] = useState([]);
    const [daoName, setDaoName] = useState('');
    const [daoInfo, setDaoInfo] = useState([]);
    const [stakeWeight, setStakeWeight] = useState([]);
    const [proposalTitle, setProposalTitle] = useState('');
    const [voteList, setVoteList] = useState([]);
    const [totalVoteWeight, setTotalVoteWeight] = useState('0');
    const [totalVotesPerChoice, setTotalVotesPerChoice] = useState([]);
    const [percentPerChoice, setPercentPerChoice] = useState([]);
    const [twoAssetsToBurn, setTwoAssetsToBurn] = useState(Array(2));
    const [highlightedFarm, setHighlightedFarm] = useState([]);
    const [subtitle, setSubtitle] = useState('');
    const [highlightDescription, setHighlightDescription] = useState('');
    const [highlightIpfs, setHighlightIpfs] = useState('');
    const [selectedSlot, setSelectedSlot] = useState('');
    const [highlightHexColor, setHighlightHexColor] = useState('');
    const [highlightTitle, setHighlightTitle] = useState('');
    const [farmPrecision, setFarmPrecision] = useState('');

    // DAO Tabs Display

    const [aboutDaoDisplay, setAboutDaoDisplay] = useState('hidden');
    const [proposalsDisplay, setProposalsDisplay] = useState('');
    const [newProposalContainerDisplay, setNewProposalContainerDisplay] = useState('hidden');
    const [walletDisplay, setWalletDisplay] = useState('hidden');
    const [choicesDisplay, setChoicesDisplay] = useState('');
    const [receiverDisplay, setReceiverDisplay] = useState('hidden');
    const [receivingAccount, setReceivingAccount] = useState('');
    const [proposalSymbol, setProposalSymbol] = useState('');
    const [proposalAmount, setProposalAmount] = useState('');
    const [proposalDecimals, setProposalDecimals] = useState('');
    const [proposalContract, setProposalContract] = useState('');
    const [treasuryAssets, setTreasuryAssets] = useState([]);
    const [treasuryDepositAmount, setTreasuryDepositAmount] = useState('');
    const [treasuryDepositDisplay, setTreasuryDepositDisplay] = useState('hidden');
    const [whichPoolDisplay, setWhichPoolDisplay] = useState('hidden');
    const [whichPool, setWhichPool] = useState('');
    const [voteDuration, setVoteDuration] = useState('');
    const [stakingPoolText, setStakingPoolText] = useState('Staking Pool');
    const [poolName, setPoolName] = useState('');
    const [userCanUnlock, setUserCanUnlock] = useState('');

    //Farm Tabs Display
    const [aboutFarmDisplay, setAboutFarmDisplay] = useState('hidden');
    const [stakeNftsDisplay, setStakeNftsDisplay] = useState('');
    const [unstakeNftsDisplay, setUnstakeNftsDisplay] = useState('hidden');

    const [proposers, setProposers] = useState([]);
    const [stakeMultipleDisplay, setStakeMultipleDisplay] = useState('hidden');

    const [totalBurned, setTotalBurned] = useState('75');
    const [totalFarms, setTotalFarms] = useState('50+');
    const [totalNftsStaked, setTotalNftsStaked] = useState('10,000+');
    const [contractResponse, setContractResponse] = useState('');
    const [modalDisplay, setModalDisplay] = useState('hidden');
    const [modalMessage, setModalMessage] = useState('');
    const [loadingDisplay, setLoadingDisplay] = useState('none');

    const [rewardType, setRewardType] = useState('');
    const [rewardTypeDisplay, setRewardTypeDisplay] = useState('hidden');
    const [rewardTypeValue, setRewardTypeValue] = useState('sharedpool');
    const [rewardAmount, setRewardAmount] = useState('0');
    const [manageTemplatesDisplay, setManageTemplatesDisplay] = useState('hidden');
    const [actionToPerform, setActionToPerform] = useState('');
    const [increaseLimitDisplay, setIncreaseLimitDisplay] = useState('hidden');
    const [addTemplatesDisplay, setAddTemplatesDisplay] = useState('hidden');
    const [removeTemplatesDisplay, setRemoveTemplatesDisplay] = useState('hidden');
    const [actionTypeValue, setActionTypeValue] = useState('');
    const [maxTemplates, setMaxTemplates] = useState('');
    const [amountOfTemplatesToAdd, setAmountOfTemplatesToAdd] = useState('');
    const [price, setPrice] = useState('');
    const [enterModalDisplay, setEnterModalDisplay] = useState('hidden');
    const [enterButtonsDisplay, setEnterButtonsDisplay] = useState('');
    const [enterModalText, setEnterModalText] = useState('Awaiting signature...');
    const [tempToRemove, setTempToRemove] = useState('');
    const [manageProfileDisplay, setManageProfileDisplay] = useState('hidden');
    const [telegram, setTelegram] = useState('');
    const [discord, setDiscord] = useState('');
    const [twitter, setTwitter] = useState('');
    const [website, setWebsite] = useState('');
    const [nftDrop, setNftDrop] = useState('');
    const [image1, setImage1] = useState('');
    const [image2, setImage2] = useState('');
    const [image3, setImage3] = useState('');
    const [profile, setProfile] = useState([]);

    // DROPS

    const [dropList, setDropList] = useState([]);
    const [isWhitelistedForDrops, setIsWhitelistedForDrops] = useState('no');
    const [usernamesDisplay, setUsernamesDisplay] = useState('hidden');
    const [farmnameDisplay, setFarmnameDisplay] = useState('hidden');
    const [whitelistType, setWhitelistType] = useState('none');
    const [startTimestamp, setStartTimestamp] = useState('');
    const [endTimestamp, setEndTimestamp] = useState('');
    const [dropPrice, setDropPrice] = useState('');
    const [dropSymbol, setDropSymbol] = useState('');
    const [dropPrecision, setDropPrecision] = useState('');
    const [templateID, setTemplateID] = useState('');
    const [totalAvailable, setTotalAvailable] = useState('');
    const [limitPerUser, setLimitPerUser] = useState('');
    const [cooldownSeconds, setCooldownSeconds] = useState('');
    const [minimumNftsStaked, setMinimumNftsStaked] = useState('');
    const [pricePerNFT, setPricePerNFT] = useState('');
    const [dropImage, setDropImage] = useState('');
    const [totalLeft, setTotalLeft] = useState('');
    const [minimumMint, setMinimumMint] = useState('');
    const [templateName, setTemplateName] = useState('');
    const [dropDescription, setDropDescription] = useState('');
    const [revenueOption, setRevenueOption] = useState('no');
    const [revenueDisplay, setRevenueDisplay] = useState('hidden');
    const [percentToPool, setPercentToPool] = useState('0');
    
    // Navbar Display

    const [dropsDisplay, setDropsDisplay] = useState('hidden');
    const [stakingDisplay, setStakingDisplay] = useState('hidden');
    const [tokensDisplay, setTokensDisplay] = useState('hidden');
    const [tokenCreatorDisplay, setTokenCreatorDisplay] = useState('hidden');
    const [daosDisplay, setDaosDisplay] = useState('hidden');
    const [supportDisplay, setSupportDisplay] = useState('hidden');
    const [navWalletDisplay, setNavWalletDisplay] = useState('hidden');
    const [mobileNavDisplay, setMobileNavDisplay] = useState('hidden');
    const [myCollections, setMyCollections] = useState([]);
    const [collectionInfo, setCollectionInfo] = useState([]);
    const [wojakNftsDisplay, setWojakNftsDisplay] = useState('hidden');
    const [dropBegins, setDropBegins] = useState('');
    const [dropEnds, setDropEnds] = useState('');
    const [waxBalance, setWaxBalance] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [sellerDrips, setSellerDrips] = useState([]);
    const [buyerOrSeller, setBuyerOrSeller] = useState('Buyer');
    const [buyerDisplay, setBuyerDisplay] = useState('');
    const [sellerDisplay, setSellerDisplay] = useState('hidden');
    const [escrowDisplay, setEscrowDisplay] = useState('hidden');
    const [dropSortMode, setDropSortMode] = useState('ending_soonest');
    const [myPremintedPools, setMyPremintedPools] = useState([]);
    const [displayName, setDisplayName] = useState('');
    const [poolStatus, setPoolStatus] = useState('');
    const [addRemoveText, setAddRemoveText] = useState('Add Assets To Pool');
    const [addDisplay, setAddDisplay] = useState('');
    const [removeDisplay, setRemoveDisplay] = useState('hidden');
    const [viewPoolText, setViewPoolText] = useState('View Pool');
    const [removalVector, setRemovalVector] = useState([]);
    const [dropType, setDropType] = useState('standard');
    const [poolIDs, setPoolIDs] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [loadMoreDisplay, setLoadMoreDisplay] = useState('');
    const [loading, setLoading] = useState(true);
    const [farmLoading, setFarmLoading] = useState(Array(100), true);
    const [farmLoadingDisplay, setFarmLoadingDisplay] = useState(Array(100), '');
    const [pageSize, setPageSize] = useState(100);
    const [totalAssets, setTotalAssets] = useState(0);
    const [listOfAssets, setListOfAssets] = useState([]);



    const setMode = (e) => {
        setCurrentMode(e.target.value);

        localStorage.setItem('themeMode', e.target.value);

        setThemeSettings(false);
    }


    const setEpochTime = (e) => {
        let date      = new Date(e.target.value);
        var epochTime = date.getTime() / 1000;
        setTimestamp(epochTime);

    }

    const setStartTime = (e) => {
        let date      = new Date(e.target.value);
        var epochTime = date.getTime() / 1000;
        setStartTimestamp(epochTime);

    }

    const setEndTime = (e) => {
        let date      = new Date(e.target.value);
        var epochTime = date.getTime() / 1000;
        setEndTimestamp(epochTime);

    }

    const setColor = (color) => {
        setCurrentColor(color);

        localStorage.setItem('colorMode', color);

        setThemeSettings(false);
    }



    const handleClick = (clicked) => {setIsClicked({...initialState, [clicked]:
        true });
    }
    return (
        <StateContext.Provider
            value={{ 
                activeMenu,
                setActiveMenu,
                isClicked,
                setIsClicked,
                handleClick,
                screenSize,
                setScreenSize,
                currentColor,
                currentMode,
                themeSettings,
                setThemeSettings,
                setMode,
                setColor,
                currentUser,
                setCurrentUser,
                loginDisplay,
                setLoginDisplay,
                profileDisplay,
                setProfileDisplay,
                tokenName,
                setTokenName,
                maxSupply,
                setMaxSupply,
                decimals,
                setDecimals,
                tokenString,
                setTokenString,
                issueTokenName,
                setIssueTokenName,
                issueAmount,
                setIssueAmount,
                issueDecimals,
                setIssueDecimals,
                issueTokenString,
                setIssueTokenString,
                transferTokenName,
                setTransferTokenName,
                transferAmount,
                setTransferAmount,
                transferDecimals,
                setTransferDecimals,
                transferTokenString,
                setTransferTokenString,
                receiver,
                setReceiver,
                burnTokenName,
                setBurnTokenName,
                burnAmount,
                setBurnAmount,
                burnDecimals,
                setBurnDecimals,
                burnTokenString,
                setBurnTokenString,
                whitelistStatus,
                setWhitelistStatus,
                isOpen,
                setIsOpen,
                assetID,
                setAssetID,
                logoutDisplay,
                setLogoutDisplay,
                listingIsOpen,
                setListingIsOpen,
                memo,
                setMemo,
                farmName,
                setFarmName,
                rewardToken,
                setRewardToken,
                rewardContract,
                setRewardContract,
                poolSize,
                setPoolSize,
                collection,
                setCollection,
                farmLogo,
                setFarmLogo,
                paymentMethod,
                setPaymentMethod,
                timestamp,
                setTimestamp,
                setEpochTime,
                assetDisplay,
                setAssetDisplay,
                assetToBurn,
                setAssetToBurn,
                schema,
                setSchema,
                farmData,
                setFarmData,
                farmCollection,
                setFarmCollection,
                farmSchema,
                setFarmSchema,
                farmRewards,
                setFarmRewards,
                farmExpires,
                setFarmExpires,
                farmIcon,
                setFarmIcon,
                farmContract,
                setFarmContract,
                createdBy,
                setCreatedBy,
                farmList,
                setFarmList,
                amountToAdd,
                setAmountToAdd,
                logoHash,
                setLogoHash,
                unstaked,
                setUnstaked,
                stakedOrUnstaked,
                setStakedOrUnstaked,
                stakedDisplay,
                setStakedDisplay,
                unstakedDisplay,
                setUnstakedDisplay,
                validAssets,
                setValidAssets,
                commaSeparated,
                setCommaSeparated,
                stakedAssets,
                setStakedAssets,
                claimable,
                setClaimable,
                ramAmount,
                setRamAmount,
                ramString,
                setRamString,
                cloudLoginDisplay,
                setCloudLoginDisplay,
                cloudLogoutDisplay,
                setCloudLogoutDisplay,
                tokenList,
                setTokenList,
                burnable,
                setBurnable,
                burnableDisplay,
                setBurnableDisplay,
                noTokensDisplay,
                setNoTokensDisplay,
                noAssetsDisplay,
                setNoAssetsDisplay,
                tokenToStake,
                setTokenToStake,
                rewardDecimals,
                setRewardDecimals,
                tokenContract,
                setTokenContract,
                stakingPeriod,
                setStakingPeriod,
                stakingAmount,
                setStakingAmount,
                stakingSeconds,
                setStakingSeconds,
                minTime,
                setMinTime,
                poolList,
                setPoolList,
                tokenPrecision,
                setTokenPrecision,
                minAmount,
                setMinAmount,
                newAmountToAdd,
                setNewAmountToAdd,
                stakedBalance,
                setStakedBalance,
                hourlyPool,
                setHourlyPool,
                totalStaked,
                setTotalStaked,
                depositAmount,
                setDepositAmount,
                depositDisplay,
                setDepositDisplay,
                quantity,
                setQuantity,
                farmType,
                setFarmType,
                farmTypeDisplay,
                setFarmTypeDisplay,
                farmTypeValue,
                setFarmTypeValue,
                templates,
                setTemplates,
                ramBalance,
                setRamBalance,
                ramInfoDisplay,
                setRamInfoDisplay,
                farmTemplates,
                setFarmTemplates,
                templatesDisplay,
                setTemplatesDisplay,
                nftsCoveredByRam,
                setNftsCoveredByRam,
                schemaDisplay,
                setSchemaDisplay,
                extendDisplay,
                setExtendDisplay,
                daysToExtend,
                setDaysToExtend,
                templateMessage,
                setTemplateMessage,
                includeOrExcludeMessage,
                setIncludeOrExcludeMessage,
                optionsAmountToAdd,
                setOptionsAmountToAdd,
                logoDisplay,
                setLogoDisplay,
                templateToView,
                setTemplateToView,
                stakedOnly,
                setStakedOnly,
                farmsUserIsIn,
                setFarmsUserIsIn,
                refreshAssets,
                setRefreshAssets,
                swapInAmount,
                setSwapInAmount,
                swapOutAmount,
                setSwapOutAmount,
                swapInToken,
                setSwapInToken,
                swapOutToken,
                setSwapOutToken,
                swapInDisplay,
                setSwapInDisplay,
                swapOutDisplay,
                setSwapOutDisplay,
                rwdToken,
                setRwdToken,
                collectionDisplay,
                setCollectionDisplay,
                tokenDisplay,
                setTokenDisplay,
                threshold,
                setThreshold,
                minimumVotes,
                setMinimumVotes,
                minWeight,
                setMinWeight,
                proposalLength,
                setProposalLength,
                nftDaoDisplay,
                setNftDaoDisplay,
                stakeTokensDisplay,
                setStakeTokensDisplay,
                unstakeTokensDisplay,
                setUnstakeTokensDisplay,
                proposerDisplay,
                setProposerDisplay,
                proposerType,
                setProposerType,
                proposerTypeValue,
                setProposerTypeValue,
                minWeightDisplay,
                setMinWeightDisplay,
                usernames,
                setUsernames,
                usernamesMessage,
                setUsernamesMessage,
                proposalList,
                setProposalList,
                newProposalDisplay,
                setNewProposalDisplay,
                newProposalText,
                setNewProposalText,
                proposalType,
                setProposalType,
                proposalTypeValue,
                setProposalTypeValue,
                choicesList,
                setChoicesList,
                description,
                setDescription,
                selectedOption,
                setSelectedOption,
                proposalInfo,
                setProposalInfo,
                daoName,
                setDaoName,
                daoInfo,
                setDaoInfo,
                stakeWeight,
                setStakeWeight,
                proposalTitle,
                setProposalTitle,
                voteList,
                setVoteList,
                totalVoteWeight,
                setTotalVoteWeight,
                totalVotesPerChoice,
                setTotalVotesPerChoice,
                percentPerChoice,
                setPercentPerChoice,
                aboutDaoDisplay,
                setAboutDaoDisplay,
                proposalsDisplay,
                setProposalsDisplay,
                newProposalContainerDisplay,
                setNewProposalContainerDisplay,
                walletDisplay,
                setWalletDisplay,
                proposers,
                setProposers,
                twoAssetsToBurn,
                setTwoAssetsToBurn,
                aboutFarmDisplay,
                setAboutFarmDisplay,
                stakeNftsDisplay,
                setStakeNftsDisplay,
                unstakeNftsDisplay,
                setUnstakeNftsDisplay,
                assetVector,
                setAssetVector,
                stakeMultipleDisplay,
                setStakeMultipleDisplay,
                highlightedFarm,
                setHighlightedFarm,
                subtitle,
                setSubtitle,
                highlightDescription,
                setHighlightDescription,
                highlightIpfs,
                setHighlightIpfs,
                selectedSlot,
                setSelectedSlot,
                highlightHexColor,
                setHighlightHexColor,
                highlightTitle,
                setHighlightTitle,
                choicesDisplay,
                setChoicesDisplay,
                receiverDisplay,
                setReceiverDisplay,
                receivingAccount,
                setReceivingAccount,
                proposalSymbol,
                proposalAmount,
                proposalDecimals,
                proposalContract,
                setProposalSymbol,
                setProposalAmount,
                setProposalDecimals,
                setProposalContract,
                treasuryAssets,
                setTreasuryAssets,
                treasuryDepositAmount,
                setTreasuryDepositAmount,
                treasuryDepositDisplay,
                setTreasuryDepositDisplay,
                whichPoolDisplay,
                setWhichPoolDisplay,
                whichPool,
                setWhichPool,
                voteDuration,
                setVoteDuration,
                stakingPoolText,
                setStakingPoolText,
                totalBurned,
                setTotalBurned,
                contractResponse,
                setContractResponse,
                modalDisplay,
                setModalDisplay,
                modalMessage,
                setModalMessage,
                loadingDisplay,
                setLoadingDisplay,
                totalFarms,
                setTotalFarms,
                totalNftsStaked,
                setTotalNftsStaked,
                poolName,
                setPoolName,
                userCanUnlock,
                setUserCanUnlock,
                rewardType,
                setRewardType,
                rewardTypeDisplay,
                setRewardTypeDisplay,
                rewardTypeValue,
                setRewardTypeValue,
                rewardAmount,
                setRewardAmount,
                manageTemplatesDisplay,
                setManageTemplatesDisplay,
                actionToPerform,
                setActionToPerform,
                increaseLimitDisplay,
                setIncreaseLimitDisplay,
                addTemplatesDisplay,
                setAddTemplatesDisplay,
                removeTemplatesDisplay,
                setRemoveTemplatesDisplay,
                actionTypeValue,
                setActionTypeValue,
                maxTemplates,
                setMaxTemplates,
                amountOfTemplatesToAdd,
                setAmountOfTemplatesToAdd,
                price,
                setPrice,
                enterModalDisplay,
                setEnterModalDisplay,
                enterButtonsDisplay,
                setEnterButtonsDisplay,
                enterModalText,
                setEnterModalText,
                tempToRemove,
                setTempToRemove,
                manageProfileDisplay,
                setManageProfileDisplay,
                telegram,
                setTelegram,
                discord,
                setDiscord,
                twitter,
                setTwitter,
                website,
                setWebsite,
                nftDrop,
                setNftDrop,
                image1,
                setImage1,
                image2,
                setImage2,
                image3,
                setImage3,
                profile,
                setProfile,
                farmPrecision,
                setFarmPrecision,
                dropList,
                setDropList,
                isWhitelistedForDrops,
                setIsWhitelistedForDrops,
                usernamesDisplay,
                setUsernamesDisplay,
                farmnameDisplay,
                setFarmnameDisplay,
                whitelistType,
                setWhitelistType,
                setStartTime,
                setEndTime,
                startTimestamp,
                setStartTimestamp,
                endTimestamp,
                setEndTimestamp,
                dropPrice,
                setDropPrice,
                dropSymbol,
                setDropSymbol,
                dropPrecision,
                setDropPrecision,
                templateID,
                setTemplateID,
                totalAvailable,
                setTotalAvailable,
                limitPerUser,
                setLimitPerUser,
                cooldownSeconds,
                setCooldownSeconds,
                minimumNftsStaked,
                setMinimumNftsStaked,
                pricePerNFT,
                setPricePerNFT,
                dropImage,
                setDropImage,
                totalLeft,
                setTotalLeft,
                minimumMint,
                setMinimumMint,
                templateName,
                setTemplateName,
                dropDescription,
                setDropDescription,
                revenueOption,
                setRevenueOption,
                revenueDisplay,
                setRevenueDisplay,
                percentToPool,
                setPercentToPool,
                dropsDisplay,
                setDropsDisplay,
                stakingDisplay,
                setStakingDisplay,
                tokensDisplay,
                setTokensDisplay,
                tokenCreatorDisplay,
                setTokenCreatorDisplay,
                daosDisplay,
                setDaosDisplay,
                supportDisplay,
                setSupportDisplay,
                navWalletDisplay,
                setNavWalletDisplay,
                mobileNavDisplay,
                setMobileNavDisplay,
                myCollections,
                setMyCollections,
                collectionInfo,
                setCollectionInfo,
                wojakNftsDisplay,
                setWojakNftsDisplay,
                dropBegins,
                setDropBegins,
                dropEnds,
                setDropEnds,
                waxBalance,
                setWaxBalance,
                suggestions,
                setSuggestions,
                sellerDrips,
                setSellerDrips,
                buyerOrSeller,
                setBuyerOrSeller,
                buyerDisplay,
                setBuyerDisplay,
                sellerDisplay,
                setSellerDisplay,
                escrowDisplay,
                setEscrowDisplay,
                farmSchemaArray,
                setFarmSchemaArray,
                dropSortMode,
                setDropSortMode,
                myPremintedPools,
                setMyPremintedPools,
                displayName,
                setDisplayName,
                poolStatus,
                setPoolStatus,
                addRemoveText,
                setAddRemoveText,
                addDisplay,
                setAddDisplay,
                removeDisplay,
                setRemoveDisplay,
                viewPoolText,
                setViewPoolText,
                removalVector,
                setRemovalVector,
                dropType,
                setDropType,
                poolIDs,
                setPoolIDs,
                pageCount,
                setPageCount,
                loadMoreDisplay,
                setLoadMoreDisplay,
                loading,
                setLoading,
                farmLoading,
                setFarmLoading,
                farmLoadingDisplay,
                setFarmLoadingDisplay,
                pageSize,
                setPageSize,
                totalAssets,
                setTotalAssets,
                setListOfAssets,
                listOfAssets,


                
                


             }}
        >
            {children}
        </StateContext.Provider>
    )
}

export const useStateContext = () => useContext(StateContext);