import React, { useEffect } from 'react';
import { AiOutlineMenu } from 'react-icons/ai';
import { FiShoppingCart } from 'react-icons/fi';
import { BsChat, BsChatLeft } from 'react-icons/bs';
import { RiNotification3Line } from 'react-icons/ri';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import avatar from '../data/avatar.jpg';
import neon_logo from '../data/neon_logo.jpg';
import { Cart, Chat, Notification, UserProfile } from '.';
import { useStateContext } from '../contexts/ContextProvider';
import { Anchor } from 'ual-anchor';
import { User } from "universal-authenticator-library";
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { Api, JsonRpc } from "eosjs";
import axios from 'axios';
import { Name, raw } from "eos-common";
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import { AnchorButton, CloudButton, DaoButton, ExpandedNavBody, ExpandedNavButton, ExpandedNavHeader, ExpandedNavLink, ExpandLargeNav, ExpandMobileNav, GameButton, MobileNavCont, MobileNavLine, Modal, ModalContent, NavChain, NavLines, NavLogo, NavWallet, NewNavbar, NewNavLink, RightNav, Spinner, SpinnerBlue, SpinnerGreen, SpinnerRed } from './LeftDiv';

const myChain = {
  chainId: 'aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906',
  rpcEndpoints: [{
      protocol: 'https',
      host: 'eos.greymass.com',
      port: '443'
  }]
};



const rpc = new JsonRpc('https://eos.greymass.com');


const anchor = new Anchor([myChain], {

  appName: 'WaxDAO',

});



const Navbar = () => {
  const { activeMenu, setActiveMenu, isClicked, setIsClicked, handleClick, screenSize, setScreenSize, 
    currentColor, currentUser, setCurrentUser, loginDisplay, setLoginDisplay, profileDisplay, 
    setProfileDisplay, whitelistStatus, setWhitelistStatus, logoutDisplay, setLogoutDisplay, cloudLoginDisplay, 
    setCloudLoginDisplay, cloudLogoutDisplay, setCloudLogoutDisplay, dropsDisplay, setDropsDisplay, 
    stakingDisplay,
    setStakingDisplay,
    tokensDisplay,
    setTokensDisplay,
    tokenCreatorDisplay,
    setTokenCreatorDisplay,
    daosDisplay,
    setDaosDisplay,
    supportDisplay,
    setSupportDisplay,
    navWalletDisplay,
    setNavWalletDisplay,
    enterModalText,
    setEnterModalText,
    loadingDisplay, 
    setLoadingDisplay, 
    enterButtonsDisplay,
    setEnterButtonsDisplay,
    enterModalDisplay, 
    setEnterModalDisplay,
    mobileNavDisplay,
    setMobileNavDisplay,
    wojakNftsDisplay,
    setWojakNftsDisplay,
    escrowDisplay,
    setEscrowDisplay,


  } = useStateContext();

  useEffect(() => {

    setDropsDisplay('hidden');
    setStakingDisplay('hidden');
    setTokensDisplay('hidden');
    setTokenCreatorDisplay('hidden');
    setDaosDisplay('hidden');
    setSupportDisplay('hidden');
    setNavWalletDisplay('hidden');
    setMobileNavDisplay('hidden');
    setWojakNftsDisplay('hidden');
    setEscrowDisplay('hidden');
    
    
    const eosUsername = localStorage.getItem('eosAccount');
    const eosWalletProvider = localStorage.getItem('eosWalletProvider');

   
  
    if(eosUsername){

      //console.log('theres a username, ' + eosUsername);

      if(eosWalletProvider == 'anchor'){
       
        setCurrentUser(eosUsername);
        setLoginDisplay('none');
        setLogoutDisplay('block');
        setProfileDisplay('flex');
        const nameValue = new Name(eosUsername).raw().toString();
        //console.log('provider is anchor, for value ' + nameValue)
        getCustomersData(nameValue, setWhitelistStatus);


        }
      


    }else{
      console.log('You are not logged in');
    }
  
    }, []);





  const ualLogin = async () => {
 
    console.log('Loading...');
    await anchor.login();

    setCurrentUser(anchor.users[0].accountName);
    setProfileDisplay('flex');
    setLoginDisplay('none');
    setLogoutDisplay('block');
    setCloudLoginDisplay('none');
    setCloudLogoutDisplay('none');
    localStorage.setItem('eosAccount', anchor.users[0].accountName);
    localStorage.setItem('eosWalletProvider','anchor');
    const eosName = anchor.users[0].accountName;
    //console.log("hello");
    const nameValue = new Name(eosName).raw().toString();
    //console.log(new Name('theonlineinn').raw().toString());
    getCustomersData(nameValue, setWhitelistStatus);
    location.reload();
  
  }
  
  const ualLogout = async () => {

    await anchor.logout();
    setCurrentUser('');
    setLogoutDisplay('none');
    setLoginDisplay('block');
    setCloudLogoutDisplay('none');
    setCloudLoginDisplay('block');
    localStorage.removeItem('eosAccount');
    localStorage.removeItem('eoswlstatus');
    localStorage.removeItem('eosWalletProvider');
  
  }



  const getCustomersData = async (nameValue, setWhitelistStatus) => {

  
  
    try {
  
      const wlistResponse = await (
  
      axios.post('https://eos.api.eosnation.io/v1/chain/get_table_rows',{
      table:"whitelist",
      scope:"createatoken",
      code:"createatoken",
      limit:1,
      lower_bound:nameValue,
      upper_bound:nameValue,
      json:true
  
      }))

      console.log(wlistResponse)
      
          
          if(wlistResponse.data.rows[0].username !== undefined){
  
            setWhitelistStatus(true);

            localStorage.setItem('eoswlstatus', "true")
  
          }
          
   }
    
    catch(e) {

      
    }
  
  }



  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);








  return (
    <div className="">
      <UALProvider chains={[myChain]} authenticators={[anchor]} appName={"WaxDAO"}>

      <Modal className={enterModalDisplay}>
      <ModalContent>
        {enterModalText}
        
      </ModalContent>

        <Spinner style={{display:loadingDisplay}}>
        <SpinnerRed  />
        <SpinnerBlue  />
        <SpinnerGreen />
        </Spinner>

      <br/>
      <GameButton onClick={() => setEnterModalDisplay('hidden') }>
        Close Window
      </GameButton>
      </Modal>


      <NewNavbar>
          <a href="https://eos.waxdao.io" style={{display: 'flex', width: '200px'}}>
          <NavLogo src={neon_logo} />
          </a>

                <NewNavLink onClick={() => {
                  if(dropsDisplay == "hidden"){
                    setDropsDisplay('');
                  } else {
                    setDropsDisplay('hidden')
                  }
                  setStakingDisplay('hidden');
                  setTokensDisplay('hidden');
                  setDaosDisplay('hidden');
                  setSupportDisplay('hidden');
                  setNavWalletDisplay('hidden');
                  setEscrowDisplay('hidden');
                }}>
                  NFTs
                </NewNavLink>

                <NewNavLink onClick={() => {
                  if(stakingDisplay == "hidden"){
                    setStakingDisplay('');
                  } else {
                    setStakingDisplay('hidden')
                  }
                  setDropsDisplay('hidden');
                  setTokensDisplay('hidden');
                  setDaosDisplay('hidden');
                  setSupportDisplay('hidden');
                  setNavWalletDisplay('hidden');
                  setEscrowDisplay('hidden');
                }}>
                  Staking
                </NewNavLink>
                

                <NewNavLink onClick={() => {
                  if(escrowDisplay == "hidden"){
                    setEscrowDisplay('');
                  } else {
                    setEscrowDisplay('hidden');
                  }
                  setDropsDisplay('hidden');
                  setTokensDisplay('hidden');
                  setDaosDisplay('hidden');
                  setSupportDisplay('hidden');
                  setNavWalletDisplay('hidden');
                  setStakingDisplay('hidden');
                }}>
                  Escrow
                </NewNavLink>



                <NewNavLink onClick={() => {
                  if(tokensDisplay == "hidden"){
                    setTokensDisplay('');
                  } else {
                    setTokensDisplay('hidden')
                  }
                  setStakingDisplay('hidden');
                  setDropsDisplay('hidden');
                  setDaosDisplay('hidden');
                  setSupportDisplay('hidden');
                  setNavWalletDisplay('hidden');
                  setEscrowDisplay('hidden');
                }}>
                  Tokens
                </NewNavLink>

                <NewNavLink onClick={() => {
                  if(daosDisplay == "hidden"){
                    setDaosDisplay('');
                  } else {
                    setDaosDisplay('hidden')
                  }
                  setStakingDisplay('hidden');
                  setTokensDisplay('hidden');
                  setDropsDisplay('hidden');
                  setSupportDisplay('hidden');
                  setNavWalletDisplay('hidden');
                  setEscrowDisplay('hidden');
                }}>
                  DAOs
                </NewNavLink>

                <NewNavLink onClick={() => {
                  if(supportDisplay == "hidden"){
                    setSupportDisplay('');
                  } else {
                    setSupportDisplay('hidden')
                  }
                  setStakingDisplay('hidden');
                  setTokensDisplay('hidden');
                  setDaosDisplay('hidden');
                  setDropsDisplay('hidden');
                  setNavWalletDisplay('hidden');
                  setEscrowDisplay('hidden');
                }}>
                  Support
                </NewNavLink>

                
                <NavChain href="https://waxdao.io">
                    WAX
                </NavChain>


                
                <NavWallet onClick={() => {
                  if(navWalletDisplay == "hidden"){
                      setNavWalletDisplay('');
                    } else {
                      setNavWalletDisplay('hidden')
                    }
                    setStakingDisplay('hidden');
                    setTokensDisplay('hidden');
                    setDaosDisplay('hidden');
                    setSupportDisplay('hidden');
                    setDropsDisplay('hidden');
                    setEscrowDisplay('hidden');
                  }}>
                    Wallet
                </NavWallet>

                {/* MOBILE */}

                <MobileNavCont onClick={() => { if(mobileNavDisplay == "hidden"){setMobileNavDisplay('')} 
                              else{setMobileNavDisplay('hidden');
                                setEscrowDisplay('hidden');
                                setDropsDisplay('hidden');
                                setStakingDisplay('hidden');
                                setTokensDisplay('hidden');
                                setTokenCreatorDisplay('hidden');
                                setDaosDisplay('hidden');
                                setSupportDisplay('hidden');
                                setWojakNftsDisplay('hidden');
                                setNavWalletDisplay('hidden');} }}>
                  <MobileNavLine /><MobileNavLine /><MobileNavLine />
                </MobileNavCont>





            </NewNavbar>

<ExpandLargeNav className={dropsDisplay}>

    <ExpandedNavLink href="https://eos.waxdao.io/drops">
      Drops
    </ExpandedNavLink>
    <ExpandedNavLink href="https://eos.waxdao.io/my-drops">
      Manage Drops
    </ExpandedNavLink>
    <ExpandedNavLink href="https://eos.waxdao.io/unbox">
      Unbox
    </ExpandedNavLink>
    <ExpandedNavLink href="https://eos.waxdao.io/pack-tutorial">
    Pack Tutorial
    </ExpandedNavLink>

    <ExpandedNavHeader onClick={() => { if(wojakNftsDisplay == "hidden"){setWojakNftsDisplay('block')} else{setWojakNftsDisplay('hidden')} }}>
      Wojak NFTs
    </ExpandedNavHeader>
    <ExpandedNavBody className={wojakNftsDisplay} href="https://eos.waxdao.io/wojak/drop">
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Buy
    </ExpandedNavBody>
    <ExpandedNavBody className={wojakNftsDisplay} href="https://eos.waxdao.io/wojak/unpack">
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Unbox
    </ExpandedNavBody>
    <ExpandedNavBody className={wojakNftsDisplay} href="https://eos.waxdao.io/farm/waxdao.1week">
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Stake
    </ExpandedNavBody>
    <ExpandedNavBody className={wojakNftsDisplay} href="https://eos.waxdao.io/wojak/staking">
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;My Wojaks
    </ExpandedNavBody>

</ExpandLargeNav>


<ExpandLargeNav className={stakingDisplay}>

    <ExpandedNavLink href="https://eos.waxdao.io/pools">
      Token Pools
    </ExpandedNavLink>
    <ExpandedNavLink href="https://eos.waxdao.io/farms">
      NFT Farms
    </ExpandedNavLink>
    <ExpandedNavLink href="https://eos.waxdao.io/my-pools">
      Manage Pools
    </ExpandedNavLink>
    <ExpandedNavLink href="https://eos.waxdao.io/my-farms">
      Manage Farms
    </ExpandedNavLink>

</ExpandLargeNav>


<ExpandLargeNav className={escrowDisplay}>

    <ExpandedNavLink href="https://eos.waxdao.io/escrow">
      Create Slow Drip
    </ExpandedNavLink>
    <ExpandedNavLink href="https://eos.waxdao.io/manage-escrow">
      Manage Escrow
    </ExpandedNavLink>

</ExpandLargeNav>


<ExpandLargeNav className={tokensDisplay}>

    <ExpandedNavLink href="https://eos.waxdao.io/wojak/swap">
      Swap
    </ExpandedNavLink>
    <ExpandedNavHeader onClick={() => { if(tokenCreatorDisplay == "hidden"){setTokenCreatorDisplay('block')} else{setTokenCreatorDisplay('hidden')} }}>
      Token Creator
    </ExpandedNavHeader>
    <ExpandedNavBody className={tokenCreatorDisplay} href="https://eos.waxdao.io/tutorial">
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Step 1: Tutorial
    </ExpandedNavBody>
    <ExpandedNavBody className={tokenCreatorDisplay} href="https://eos.waxdao.io/whitelisting">
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Step 2: Whitelisting
    </ExpandedNavBody>
    <ExpandedNavBody className={tokenCreatorDisplay} href="https://eos.waxdao.io/deploy-contract">
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Step 3: Deploy Contract
    </ExpandedNavBody>
    <ExpandedNavBody className={tokenCreatorDisplay} href="https://eos.waxdao.io/create-token">
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Step 4: Create Token
    </ExpandedNavBody>
    <ExpandedNavBody className={tokenCreatorDisplay} href="https://eos.waxdao.io/issue-tokens">
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Step 5: Issue Tokens
    </ExpandedNavBody>
    <ExpandedNavBody className={tokenCreatorDisplay} href="https://eos.waxdao.io/burn-tokens">
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Burn
    </ExpandedNavBody>
    <ExpandedNavBody className={tokenCreatorDisplay} href="https://eos.waxdao.io/transfer-tokens">
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Transfer
    </ExpandedNavBody>

</ExpandLargeNav>


<ExpandLargeNav className={daosDisplay}>

    <ExpandedNavLink href="https://eos.waxdao.io/daos/waxdao">
      Our DAO
    </ExpandedNavLink>
    <ExpandedNavLink href="https://eos.waxdao.io/daos">
      All DAOs
    </ExpandedNavLink>
    <ExpandedNavLink href="https://eos.waxdao.io/my-daos">
      Manage DAOs
    </ExpandedNavLink>


</ExpandLargeNav>


<ExpandLargeNav className={supportDisplay}>

    <ExpandedNavLink href="https://eos.waxdao.io/suggestions">
      Feature Request
    </ExpandedNavLink>
    <ExpandedNavLink href="https://eos.waxdao.io/farm-faq">
      Farm FAQ
    </ExpandedNavLink>
    <ExpandedNavLink href="https://eos.waxdao.io/pool-faq">
      Pool FAQ
    </ExpandedNavLink>
    <ExpandedNavLink href="https://eos.waxdao.io/tutorial">
      Token Creator FAQ
    </ExpandedNavLink>
    <ExpandedNavLink href="https://eos.waxdao.io/escrow-faq">
    Escrow FAQ
    </ExpandedNavLink>
    <ExpandedNavLink href="https://t.me/hoodpunks">
      WaxDAO Telegram
    </ExpandedNavLink>


</ExpandLargeNav>


<ExpandLargeNav className={navWalletDisplay}>

    {loginDisplay == "none" && currentUser != null && (

      <ExpandedNavLink href={`https://bloks.io/account/${currentUser}`} target="none">
      {currentUser}
      </ExpandedNavLink>

    )}

    <ExpandedNavButton onClick={() => { ualLogout() }} style={{display: logoutDisplay}} >
      Logout
    </ExpandedNavButton>


    {loginDisplay != "none" && (

    <ExpandedNavButton onClick={() => {
      setEnterModalDisplay('');
      setEnterModalText(
      <span>
        Which Wallet?<br/><br/>

        <AnchorButton onClick={() => ualLogin()}>
          Anchor
        </AnchorButton>

      </span>);
    }}>
    Login
    </ExpandedNavButton>

    )}


</ExpandLargeNav>






{/* MOBILE EXTENDED */}


<ExpandMobileNav className={mobileNavDisplay}>

    <ExpandedNavHeader onClick={() => {
                  if(dropsDisplay == "hidden"){
                    setDropsDisplay('');
                  } else {
                    setDropsDisplay('hidden')
                  }
                  setStakingDisplay('hidden');
                  setTokensDisplay('hidden');
                  setDaosDisplay('hidden');
                  setSupportDisplay('hidden');
                  setNavWalletDisplay('hidden');
                  setEscrowDisplay('hidden');
                }}>
      NFTs
    </ExpandedNavHeader>
        <span className={dropsDisplay}>
        <ExpandedNavLink href="https://eos.waxdao.io/drops">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Drops
        </ExpandedNavLink>
        <ExpandedNavLink href="https://eos.waxdao.io/my-drops">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Manage Drops
        </ExpandedNavLink>

        <ExpandedNavLink href="https://eos.waxdao.io/unbox">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Unbox
        </ExpandedNavLink>
        <ExpandedNavLink href="https://eos.waxdao.io/pack-tutorial">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pack Tutorial
        </ExpandedNavLink>

        <ExpandedNavHeader onClick={() => { if(wojakNftsDisplay == "hidden"){setWojakNftsDisplay('block')} else{setWojakNftsDisplay('hidden')} }}>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Wojak NFTs
        </ExpandedNavHeader>
        <ExpandedNavBody className={wojakNftsDisplay} href="https://eos.waxdao.io/wojak/drop">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Buy
        </ExpandedNavBody>
        <ExpandedNavBody className={wojakNftsDisplay} href="https://eos.waxdao.io/wojak/unpack">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Unbox
        </ExpandedNavBody>
        <ExpandedNavBody className={wojakNftsDisplay} href="https://eos.waxdao.io/farm/waxdao.1week">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Stake
        </ExpandedNavBody>
        <ExpandedNavBody className={wojakNftsDisplay} href="https://eos.waxdao.io/wojak/staking">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;My Wojaks
        </ExpandedNavBody>
        </span>

    <ExpandedNavHeader onClick={() => {
                  if(stakingDisplay == "hidden"){
                    setStakingDisplay('');
                  } else {
                    setStakingDisplay('hidden')
                  }
                  setDropsDisplay('hidden');
                  setTokensDisplay('hidden');
                  setDaosDisplay('hidden');
                  setSupportDisplay('hidden');
                  setNavWalletDisplay('hidden');
                  setEscrowDisplay('hidden');
                }}>
      Staking
    </ExpandedNavHeader>

        <span className={stakingDisplay}>
        <ExpandedNavLink href="https://eos.waxdao.io/pools">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Token Pools
        </ExpandedNavLink>
        <ExpandedNavLink href="https://eos.waxdao.io/farms">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;NFT Farms
        </ExpandedNavLink>
        <ExpandedNavLink href="https://eos.waxdao.io/my-pools">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Manage Pools
        </ExpandedNavLink>
        <ExpandedNavLink href="https://eos.waxdao.io/my-farms">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Manage Farms
        </ExpandedNavLink>
        </span>

        <ExpandedNavHeader onClick={() => {
                  if(escrowDisplay == "hidden"){
                    setEscrowDisplay('');
                  } else {
                    setEscrowDisplay('hidden');
                  }
                  setDropsDisplay('hidden');
                  setTokensDisplay('hidden');
                  setDaosDisplay('hidden');
                  setSupportDisplay('hidden');
                  setNavWalletDisplay('hidden');
                  setStakingDisplay('hidden');
                }}>
      Escrow
    </ExpandedNavHeader>

        <span className={escrowDisplay}>
        <ExpandedNavLink href="https://eos.waxdao.io/escrow">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Create Slow Drip
        </ExpandedNavLink>
        <ExpandedNavLink href="https://eos.waxdao.io/manage-escrow">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Manage Escrow
        </ExpandedNavLink>

        </span>


    <ExpandedNavHeader onClick={() => {
                  if(tokensDisplay == "hidden"){
                    setTokensDisplay('');
                  } else {
                    setTokensDisplay('hidden')
                  }
                  setStakingDisplay('hidden');
                  setDropsDisplay('hidden');
                  setDaosDisplay('hidden');
                  setSupportDisplay('hidden');
                  setNavWalletDisplay('hidden');
                  setEscrowDisplay('hidden');
                }}>
      Tokens
    </ExpandedNavHeader>

       <span className={tokensDisplay}>
       <ExpandedNavLink href="https://eos.waxdao.io/wojak/swap">
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Swap
        </ExpandedNavLink>
        <ExpandedNavHeader onClick={() => { if(tokenCreatorDisplay == "hidden"){setTokenCreatorDisplay('block')} else{setTokenCreatorDisplay('hidden')} }}>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Token Creator
        </ExpandedNavHeader>
        <ExpandedNavBody className={tokenCreatorDisplay} href="https://eos.waxdao.io/tutorial">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Step 1: Tutorial
        </ExpandedNavBody>
        <ExpandedNavBody className={tokenCreatorDisplay} href="https://eos.waxdao.io/whitelisting">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Step 2: Whitelisting
        </ExpandedNavBody>
        <ExpandedNavBody className={tokenCreatorDisplay} href="https://eos.waxdao.io/deploy-contract">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Step 3: Deploy Contract
        </ExpandedNavBody>
        <ExpandedNavBody className={tokenCreatorDisplay} href="https://eos.waxdao.io/create-token">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Step 4: Create Token
        </ExpandedNavBody>
        <ExpandedNavBody className={tokenCreatorDisplay} href="https://eos.waxdao.io/issue-tokens">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Step 5: Issue Tokens
        </ExpandedNavBody>
        <ExpandedNavBody className={tokenCreatorDisplay} href="https://eos.waxdao.io/burn-tokens">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Burn
        </ExpandedNavBody>
        <ExpandedNavBody className={tokenCreatorDisplay} href="https://eos.waxdao.io/transfer-tokens">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Transfer
        </ExpandedNavBody>
        </span>


        <ExpandedNavHeader onClick={() => {
                  if(daosDisplay == "hidden"){
                    setDaosDisplay('');
                  } else {
                    setDaosDisplay('hidden')
                  }
                  setStakingDisplay('hidden');
                  setTokensDisplay('hidden');
                  setDropsDisplay('hidden');
                  setSupportDisplay('hidden');
                  setNavWalletDisplay('hidden');
                  setEscrowDisplay('hidden');
                }}>
      DAOs
    </ExpandedNavHeader>

      <span className={daosDisplay}>
        <ExpandedNavLink href="https://eos.waxdao.io/daos/waxdao">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Our DAO
        </ExpandedNavLink>
        <ExpandedNavLink href="https://eos.waxdao.io/daos">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;All DAOs
        </ExpandedNavLink>
        <ExpandedNavLink href="https://eos.waxdao.io/my-daos">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Manage DAOs
        </ExpandedNavLink>
      </span>

      <ExpandedNavHeader onClick={() => {
                  if(supportDisplay == "hidden"){
                    setSupportDisplay('');
                  } else {
                    setSupportDisplay('hidden')
                  }
                  setStakingDisplay('hidden');
                  setTokensDisplay('hidden');
                  setDaosDisplay('hidden');
                  setDropsDisplay('hidden');
                  setNavWalletDisplay('hidden');
                  setEscrowDisplay('hidden');
                }}>
      Support
    </ExpandedNavHeader>

    <span className={supportDisplay}>

    <ExpandedNavLink href="https://eos.waxdao.io/suggestions">
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Feature Request
    </ExpandedNavLink>
    <ExpandedNavLink href="https://eos.waxdao.io/farm-faq">
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Farm FAQ
    </ExpandedNavLink>
    <ExpandedNavLink href="https://eos.waxdao.io/pool-faq">
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pool FAQ
    </ExpandedNavLink>
    <ExpandedNavLink href="https://eos.waxdao.io/tutorial">
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Token Creator FAQ
    </ExpandedNavLink>
    <ExpandedNavLink href="https://eos.waxdao.io/escrow-faq">
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Escrow FAQ
    </ExpandedNavLink>
    <ExpandedNavLink href="https://t.me/hoodpunks">
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;WaxDAO Telegram
    </ExpandedNavLink>

    </span>


    <ExpandedNavHeader onClick={() => {
                  if(navWalletDisplay == "hidden"){
                      setNavWalletDisplay('');
                    } else {
                      setNavWalletDisplay('hidden')
                    }
                    setStakingDisplay('hidden');
                    setTokensDisplay('hidden');
                    setDaosDisplay('hidden');
                    setSupportDisplay('hidden');
                    setDropsDisplay('hidden');
                    setEscrowDisplay('hidden');
                  }}>
      Wallet
    </ExpandedNavHeader>

    <span className={navWalletDisplay}>

        {loginDisplay == "none" && currentUser != null && (

        <ExpandedNavLink href={`https://bloks.io/account/${currentUser}`} target="none">
        {currentUser}
        </ExpandedNavLink>

        )}

        <ExpandedNavButton onClick={() => { ualLogout() }} style={{display: logoutDisplay}} >
        Logout
        </ExpandedNavButton>

        {loginDisplay != "none" && (

        <ExpandedNavButton onClick={() => {
        setEnterModalDisplay('');
        setEnterModalText(
        <span>
          Which Wallet?<br/><br/>

          <AnchorButton onClick={() => ualLogin()}>
            Anchor
          </AnchorButton>

        </span>);
        }}>
        Login
        </ExpandedNavButton>

        )}

    </span>







</ExpandMobileNav>


      </UALProvider>
    </div>
  )
}

export default Navbar