import React, { useEffect } from 'react';
import { GridComponent, Resize, Sort, ContextMenu, Filter, Page, ExcelExport, PdfExport, Edit, Inject, Search, Toolbar } from '@syncfusion/ej2-react-grids';
import { ordersData, contextMenuItems, ordersGrid, nftFarmData, nftFarmGrid, myFarmsGrid, depositTokens, myPoolsGrid } from '../../data/dummy';
import { Header } from '../../components';
import { ColumnDirective, ColumnsDirective } from '@syncfusion/ej2-react-charts';
import { useParams } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import { Anchor } from 'ual-anchor';
import { Wax } from '@eosdacio/ual-wax';
import { User } from "universal-authenticator-library";
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { Api, JsonRpc } from "eosjs";
import {Helmet} from "react-helmet";
import axios from 'axios';
import { data } from 'autoprefixer';
import { Name, raw } from "eos-common";
import { AllFarmsCont, DaoButton, FarmEndDate, FarmLogo, FarmsPageCont, FarmsTitle, FarmTitle, PoolAmount, ProposerTypeMessage, SingleFarmCont } from '../../components/LeftDiv';
import NumberFormat from 'react-number-format';

let zero = '0';

const myChain = {
  chainId: 'aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906',
  rpcEndpoints: [{
      protocol: 'https',
      host: 'eos.greymass.com',
      port: '443'
  }]
};

const rpc = new JsonRpc('https://eos.greymass.com');


const anchor = new Anchor([myChain], {

  appName: 'WaxDAO',

});





const MyPools = () => {

  const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode, currentUser, tokenName, setTokenName, maxSupply, setMaxSupply, decimals, setDecimals, tokenString, setTokenString, farmData, setFarmData, farmCollection, setFarmCollection, farmSchema, setFarmSchema, farmRewards, setFarmRewards, farmExpires, setFarmExpires, farmIcon, setFarmIcon, farmContract, setFarmContract, createdBy, setCreatedBy, farmList, setFarmList, ramAmount, setRamAmount, poolList, setPoolList } = useStateContext();

const { FarmName } = useParams();

useEffect(() => {
setPoolList([]);
  //(async () => {

 // await anchor.login();

 const eosUsername = localStorage.getItem('eosAccount');
 const walletProvider = localStorage.getItem('eosWalletProvider');
  
 if(eosUsername){
   //console.log('You have an active session');
   '';
   //setProfileDisplay('flex');
 }else{
   //console.log('You are not logged in');
   return('');
 }
  
  
  axios.post('https://eos.api.eosnation.io/v1/chain/get_table_rows',{
    table:"pools",
    scope:"waxdaofarmer",
    code:"waxdaofarmer",
    key_type: 'name',
    index_position: 3,
    limit:50,
    lower_bound: eosUsername,
    upper_bound: eosUsername,
    json:true
  })
    .then((response) => {

      setPoolList([response.data.rows]);
      //console.log(response);
    })
    .catch((error) => console.log(error));

  }

, []);

  return (
  <div id="seo">
    <Helmet>
    <title>My Staking Pools</title>
    <meta name="description" content="Track your EOS staking pools on Waxdao" />
    <link rel="canonical" href="https://waxdao.io/my-pools" />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor]} appName={"WaxDAO"}>
    <FarmsPageCont>
      <FarmsTitle>
        Manage Pools
        &nbsp;&nbsp;

      <a href="https://eos.waxdao.io/create-pool">
      <DaoButton>
      + New Pool
      </DaoButton>
      </a>
      </FarmsTitle>

      <AllFarmsCont>


{poolList == null && (

<ProposerTypeMessage>
  You have not created any pools yet.<br/><br/>
  To create your own staking pool, go to the <a href="https://eos.waxdao.io/create-pool" style={{color:"#fa872d"}} >Create Pool</a> page.
</ProposerTypeMessage>
)}

{poolList != null && poolList[0] != null && poolList[0].length == 0 && (

  <ProposerTypeMessage>
    You have not created any pools yet.<br/><br/>
    To create your own staking pool, go to the <a href="https://eos.waxdao.io/create-pool" style={{color:"#fa872d"}} >Create Pool</a> page.
  </ProposerTypeMessage>
)}

{poolList[1] == null && poolList[0] != null && Array.isArray(poolList[0]) ? (poolList[0].map((item, index) => (
    <a href={`https://eos.waxdao.io/edit-pool/${item.poolname}`}>
    <SingleFarmCont>
      {item.logo != null && (
        <FarmLogo src={`https://ipfs.io/ipfs/${item.logo}`} />
      )}
      {item.poolname != null && (
        <FarmTitle>
        {item.poolname}
        </FarmTitle>
      )}

      {item.poolsize != null && (
        <PoolAmount>
          <NumberFormat displayType='text' thousandSeparator={true} value={Math.round(item.poolsize.substring(0, item.poolsize.indexOf(' ')))} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} />
         {item.poolsize.substring(item.poolsize.indexOf(' '))}
        </PoolAmount>
      )}

      {item.enddate != null && (
        <FarmEndDate>
          Ends {new Date(item.enddate * 1000).toLocaleDateString()}
        </FarmEndDate>
      )}

    </SingleFarmCont>
    </a>
    ))) : poolList[1] != null && Array.isArray(poolList) && poolList.map((item, index) => (
      <a href={`https://eos.waxdao.io/edit-pool/${item.poolname}`}>
      <SingleFarmCont>
      {item.logo != null && (
        <FarmLogo src={`https://ipfs.io/ipfs/${item.logo}`} />
      )}
      {item.poolname != null && (
        <FarmTitle>
        {item.poolname}
        </FarmTitle>
      )}
      {item.poolsize != null && (
        <PoolAmount>
          <NumberFormat displayType='text' thousandSeparator={true} value={Math.round(item.poolsize.substring(0, item.poolsize.indexOf(' ')))} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} />
         {item.poolsize.substring(item.poolsize.indexOf(' '))}
        </PoolAmount>
      )}
      {item.enddate != null && (
        <FarmEndDate>
          Ends {new Date(item.enddate * 1000).toLocaleDateString()}
        </FarmEndDate>
      )}

     </SingleFarmCont>
     </a>
      ))}






</AllFarmsCont>

      </FarmsPageCont>
    </UALProvider>
    </div>
  )
}

export default MyPools