import React from 'react';
import { Header } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { ListingModal } from '../components';
import {Helmet} from "react-helmet";

const Listing = () => {
  const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode, currentUser, isOpen, setIsOpen, listingIsOpen, setListingIsOpen } = useStateContext();

  return (
    <div id="seo">
    <Helmet>
    <title>Listing Form - WaxDAO</title>
    <meta name="description" content="Get listed on our Wax Token price tracker, which monitors the trading volume and market cap of Wax tokens." />
    <link rel="canonical" href="https://waxdao.io/listing-request" />
    </Helmet>
  
    <div className="m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl">
    <Header title="Listing Request Form" category="App" />
    <p className="mt-4 text-2xl">Want your Wax token listed on our <a href="https://mikedcrypto.com/prices/wax-tokens" style={{color: currentColor}}>price tracker</a>?</p>
    <p className="mt-4 text-xl">Fill out the form below.</p>

    <div className="text-white text-xl text-center p-3 mt-3 rounded-lg items-center justify-center"
    style={{ backgroundColor: currentColor}}
    >
    <span className="font-semibold">Note: </span>
    Your token must be trading on <a href="https://wax.alcor.exchange">Alcor Exchange</a> in order to get listed.
    </div>

    <div id="createForm" className="text-center items-center justify-center border-4 p-6 rounded-3xl mt-4"
      style={{borderColor: currentColor}}
    >
      <form>
      <label htmlFor="tokenName">Token Name: <br /></label>
      <TooltipComponent content="Must be the exact name of the token you created"
      position="BottomCenter">
      <input type="text" 
      required
      id="tokenName" 
      className="border-2 rounded-lg text-xl"
      style={{borderColor: currentColor}}
      />
      </TooltipComponent>

      <label htmlFor="contract"><br />Contract Address: <br /></label>
      <TooltipComponent content="The Wax account where your token contract is deployed"
      position="BottomCenter">
      <input type="text" 
      required
      id="contract" 
      className="border-2 rounded-lg text-xl"
      style={{borderColor: currentColor}}
      />
      </TooltipComponent>

      <label htmlFor="website" ><br />Website: <br /></label>
      <TooltipComponent content="The website of your project"
      position="BottomCenter">
      <input type="text" 
      required
      id="website" 
      className="border-2 rounded-lg text-xl"
      style={{borderColor: currentColor}}
      />
      </TooltipComponent>

      <label htmlFor="logo" ><br />Logo (300 x 300): <br /></label>
      <TooltipComponent content="IPFS hash only."
      position="BottomCenter">
      <input type="text" 
      required
      id="logo"
      placeholder="Qmksajdfgdkjgfsdkj" 
      className="border-2 rounded-lg text-xl"
      style={{borderColor: currentColor}}
      />
      </TooltipComponent>

      <label htmlFor="telegram" ><br />Telegram: <br /></label>
      <TooltipComponent content="Your project's Telegram"
      position="BottomCenter">
      <input type="text" 
      id="telegram" 
      className="border-2 rounded-lg text-xl"
      style={{borderColor: currentColor}}
      />
      </TooltipComponent>

      <label htmlFor="discord" ><br />Discord: <br /></label>
      <TooltipComponent content="Your project's Discord"
      position="BottomCenter">
      <input type="text" 
      id="discord" 
      className="border-2 rounded-lg text-xl"
      style={{borderColor: currentColor}}
      />
      </TooltipComponent>


      <label htmlFor="twitter" ><br />Twitter: <br /></label>
      <TooltipComponent content="Your project's Twitter"
      position="BottomCenter">
      <input type="text" 
      id="twitter" 
      className="border-2 rounded-lg text-xl"
      style={{borderColor: currentColor}}
      />
      </TooltipComponent>

      <label htmlFor="description" ><br />Description: <br /></label>
      <TooltipComponent content="A brief description of your project"
      position="BottomCenter">
      <textarea
      required
      placeholder="Please provide a brief description of your project. 1000 characters max."
      maxLength="1000"
      id="description" 
      className="border-2 rounded-lg text-xl"
      style={{borderColor: currentColor}}
      />
      </TooltipComponent>

      <button 
          type="submit"
          className="text-lg p-3
          hover:drop-shadow-xl
          hover:bg-light-gray
          text-white rounded-lg mt-4"
          // onClick={() => listingRequest()}
          style={{ background: currentColor
          }}
        
        >
          Submit Request
        </button>
</form>
    </div>
    <ListingModal open={listingIsOpen}>
        <div className="text-center items-center justify-center">
      <label htmlFor="asset_ID" className="text-xl">We are not accepting new applications at this time.<br />
      Check back soon.</label><br />
     
  

      
        <br />
        </div>
      </ListingModal>

    </div>
    </div>
  )
}

export default Listing