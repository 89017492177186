import React, { useEffect } from 'react';
import { Header } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { Anchor } from 'ual-anchor';
import { Wax } from '@eosdacio/ual-wax';
import { User } from "universal-authenticator-library";
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { Api, JsonRpc } from "eosjs";
import {Helmet} from "react-helmet";
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import Select from 'react-select';
import axios from 'axios';
import { CreateDaoCont, BurnableCont, BuyWojak, NewRow, NFT, DropDown, CreateFarmMsg, DaoButton, DaoTitle, MainContainer, NewProposal, ProposalInput, ProposerTypeMessage, SingleProposal, UserWhitelist, FarmsPageCont, MissingInfoMsg } from '../components/LeftDiv';
import './datepicker.css';


let zero = '0';

const myChain = {
  chainId: 'aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906',
  rpcEndpoints: [{
      protocol: 'https',
      host: 'eos.greymass.com',
      port: '443'
  }]
};

const rpc = new JsonRpc('https://eos.greymass.com');


const anchor = new Anchor([myChain], {

  appName: 'WaxDAO',

});


const createFarm = async (farmName, farmLogo, collection, schema, rewardToken, rewardContract, decimals, timestamp, stakingSeconds, farmTypeValue, templates, rewardTypeValue, rewardAmount) => {
  const walletProvider = localStorage.getItem('eosWalletProvider');


    if(walletProvider == 'anchor'){

    // Retrieve current session from state
    const session = await anchor.login()
    //console.log(session[0].signTransaction);
  
    try {
      // Reset our response state to clear any previous transaction data
      
      const action = [{
            account: 'eosio.token',
            name: 'transfer',
            authorization: [{
              actor: anchor.users[0].accountName,
              permission: anchor.users[0].requestPermission,
          }],
          data: {
              to: 'waxdaofarmer',
              from: anchor.users[0].accountName,
              quantity: '25.0000 EOS',
              memo: '|createfarm|' + farmName + '|' + rewardToken + '|' + decimals + '|' + rewardContract + '|' + collection + '|' + schema + '|' + farmLogo + '|' + timestamp + '|' + stakingSeconds + '|' + farmTypeValue + '|' + templates + '|' + rewardTypeValue + '|' + rewardAmount + '|',
          }
        }]
      // Call transact on the session (compatible with eosjs.transact)
      const response = await session[0].signTransaction({
        actions: action
      }, {

        broadcast: true,
      })
      // Update application state with the responses of the transaction
      alert('Success! You can add funds to your reward pool on the "My Farms" page');
    } catch(e) {
      alert(e)
      console.log(e)
      
    }
  
  

  
      } //end if wallet = anchor

}



const CreateFarm = () => {
  
  const {  decimals, setDecimals, farmName, setFarmName, rewardToken, setRewardToken, rewardContract, setRewardContract, 
   collection, setCollection, schema, setSchema, farmLogo, setFarmLogo, paymentMethod, setPaymentMethod, timestamp, 
   setTimestamp, setEpochTime, assetDisplay, setAssetDisplay, assetToBurn, setAssetToBurn, burnable, setBurnable, 
   burnableDisplay, setBurnableDisplay, stakingPeriod, setStakingSeconds, stakingSeconds, setStakingPeriod, quantity, 
   setQuantity, farmType, setFarmType, farmTypeDisplay, setFarmTypeDisplay, farmTypeValue, setFarmTypeValue, templates, 
   setTemplates, schemaDisplay, setSchemaDisplay, templateMessage, setTemplateMessage,                 
   rewardType,
   setRewardType,
   rewardTypeDisplay,
   setRewardTypeDisplay,
   rewardTypeValue,
   setRewardTypeValue,
   rewardAmount,
   setRewardAmount,
  
  } = useStateContext();
  useEffect(() => {
    const abortCont2 = new AbortController();

    //clear any previous state data

    setBurnable([]);
    setBurnableDisplay('hidden');
    setDecimals('');
    setFarmName('');
    setRewardToken('');
    setRewardContract('');
    setFarmLogo('');
    setPaymentMethod('Payment Method');
    setStakingPeriod('');
    setCollection('');
    setSchema('');
    setQuantity('');
    setFarmType('Select Farm Type');
    

      }

    
    , []);


  return (
    <div id="seo">
    <Helmet>
    <title>Create NFT Farm - WaxDAO</title>
    <meta name="description" content="Launch your own NFT farm on EOS. Your community will be able to stake your NFTs and earn passive income. " />
    <link rel="canonical" href="https://waxdao.io/create-farm" />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor]} appName={"WaxDAO"}>
    <MainContainer>
      <CreateDaoCont>
        <DaoTitle>
        Ready to launch your NFT farm?
        </DaoTitle>
        <CreateFarmMsg>
        Just fill out the form and submit your payment, and your farm will be live in minutes.<br/><br/> 
        <span className="font-bold">Confused? </span>Watch this tutorial. (note: on EOS, farms can only be paid for with EOS)<br/><br/>
        <iframe width="300" maxWidth="80%" height="170" src="https://www.youtube.com/embed/zD99wgKlAAk" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </CreateFarmMsg>



       <br/><br/>
        <NewProposal>

      <label htmlFor="farmName">Farm Name: <br /></label>
      <TooltipComponent content="12 Characters Max: (a-z, 1-5)"
      position="BottomCenter">
      <ProposalInput type="text" 
      maxLength={12}
      id="farmName" 
      placeholder="mynewnftfarm"
      value={farmName}
      onChange={(e) => {
        setFarmName(e.target.value.toLowerCase());
        }}
        
      />
      </TooltipComponent>

      <label htmlFor="farmLogo" ><br />Farm Logo (IPFS only):<br /></label>
      <TooltipComponent content="IPFS hash of the logo you want to use"
      position="BottomCenter">
      <ProposalInput type="text" 
      placeholder='Qmabc.....'
      id="farmLogo" 
      value={farmLogo}
      onChange={(e) => {
        setFarmLogo(e.target.value);
      }}
      />
      </TooltipComponent>
<span className="text-sm italic">*100px by 100px</span>


      <br /><br />




        <form>
        <DropDown         
                 onChange={(e) => {if(e.target.value == 'Specific Templates'){setFarmTypeDisplay('block'); setSchemaDisplay('hidden'); setFarmTypeValue('template'); setTemplateMessage('You can use any template in your collection, even if they are not all in the same schema :)')} else if(e.target.value == 'Entire Collection'){setFarmTypeDisplay('hidden'); setSchemaDisplay('hidden'); setFarmTypeValue('collection');} else if(e.target.value == 'Schema w/ Exclusions'){setFarmTypeDisplay('block'); setSchemaDisplay(''); setFarmTypeValue('s.exclude'); setTemplateMessage('You can exclude up to 10 templates from this schema')} else if(e.target.value == 'Entire Schema') {setFarmTypeDisplay('hidden'); setSchemaDisplay(''); setFarmTypeValue('schema');}
                 setFarmType(e.target.value)
               }}
          >
        <option value="" hidden>
          Farm Type
        </option>
        <option value="Entire Collection">Entire Collection</option>
        <option value="Entire Schema">Entire Schema</option>
        <option value="Schema w/ Exclusions">Schema w/ Exclusions</option>
        <option value="Specific Templates">Specific Templates</option>
        </DropDown>
        </form>


      <label htmlFor="collection" ><br />Collection To Stake:<br /></label>
      <TooltipComponent content="Collection name of the NFTs to stake"
      position="BottomCenter">
      <ProposalInput type="text" 
      id="collection" 
      maxLength={12}
      placeholder="hoodpunknfts"
      value={collection}
      onChange={(e) => {
        setCollection(e.target.value.toLowerCase());
      }}
      />
      </TooltipComponent>

      <span className={`${schemaDisplay}`}>
      <label htmlFor="collection" ><br />Schemas (10 Max):<br /></label>

      <TooltipComponent content="Separate each with a comma"
      position="BottomCenter">
      <UserWhitelist
      placeholder="schema1,schema2,schema3..."
      maxLength="200"
      rows={4}
      id="template" 
      className={schemaDisplay}
      value={schema}
      onChange={(e) => {
        setSchema(e.target.value.toLowerCase());
      }}
      />
      </TooltipComponent>
      </span>

      <label htmlFor="templates" className={`${farmTypeDisplay}`}><br />Template IDs (10 MAX): <br /></label>
      <TooltipComponent content="Separate each with a comma"
      position="BottomCenter">
      <UserWhitelist
      placeholder="123456,987654,696969,420420,444444,169169,420069"
      maxLength="200"
      rows={4}
      id="template" 
      className={farmTypeDisplay}
      onChange={(e) => {
        setTemplates(e.target.value);
      }}
      />
      </TooltipComponent>
      <span className={`text-sm italic ${farmTypeDisplay}`}>{templateMessage}</span>

      <br/>
      <ProposerTypeMessage>
      <span className="font-bold">Warning:</span> Entering incorrect token details will result in a pool that doesn't work, and WaxDAO will not be able 
      to help you. <br/>
      Make 100% sure that you have the correct symbol, precision and contract for the reward token.
      <br/>
      WaxDAO will not be held responsible for user errors.
      </ProposerTypeMessage>

      <label htmlFor="rewardToken" ><br />Reward Token Symbol:<br /></label>
      <TooltipComponent content="Which token do you want to give to farmers?"
      position="BottomCenter">
      <ProposalInput type="text" 
      id="rewardToken" 
      placeholder="WOJAK"
      maxLength={7}
      value={rewardToken}
      onChange={(e) => {
        setRewardToken(e.target.value.toUpperCase());
      }}
      />
      </TooltipComponent>


      <label htmlFor="rewardContract" ><br />Reward Token Contract:<br /></label>
      <TooltipComponent content="Name of the EOS account where your token contract is stored"
      position="BottomCenter">
      <ProposalInput type="text" 
      id="rewardContract" 
      maxLength={12}
      placeholder="eosio.token"
      value={rewardContract}
      onChange={(e) => {
        setRewardContract(e.target.value.toLowerCase());
      }}
      />
      </TooltipComponent>


      <label htmlFor="decimals" ><br />Reward Token Decimals:<br /></label>
      <TooltipComponent content="How many decimal places does the reward token have?"
      position="BottomCenter">
      <ProposalInput type="number" 
      id="decimals" 
      value={decimals}
      onChange={(e) => {
        setDecimals(e.target.value);
      }}
      />
      </TooltipComponent>

      <form>
        <DropDown         
                 onChange={(e) => {if(e.target.value == 'Shared Pool'){setRewardTypeDisplay('hidden'); setRewardTypeValue('sharedpool'); } else if(e.target.value == 'Exact Amount Per NFT'){setRewardTypeDisplay(''); setRewardTypeValue('static');} 
                 setRewardType(e.target.value)
               }}
          >
        <option value="" hidden>
          Reward Type
        </option>
        <option value="Shared Pool">Shared Pool</option>
        <option value="Exact Amount Per NFT">Exact Amount Per NFT</option>
        </DropDown>
        </form>


<label htmlFor="rewardAmountContent" className={rewardTypeDisplay} ><br />Hourly Reward Per NFT:<br /></label>
      <TooltipComponent content="What is the hourly reward you want to give for each NFT"
      position="BottomCenter"
      >
      <ProposalInput type="number" 
      id="rewardAmountContent" 
      placeholder="numbers only"
      value={rewardAmount}
      className={rewardTypeDisplay}
      onChange={(e) => {
        setRewardAmount(e.target.value);
      }}
      />
      </TooltipComponent>


      <label htmlFor="stakingPeriod" ><br />Minimum Staking Period (Days):<br /></label>
      <TooltipComponent content="Minimum days you want people to stake for"
      position="BottomCenter">
      <ProposalInput type="number" 
      id="stakingPeriod" 
      placeholder="1"
      value={stakingPeriod}
      onChange={(e) => {
        if(e.target.value < 1){
        setStakingPeriod(1);
        setStakingSeconds(86400);
    
        }
        else{
          setStakingPeriod(Math.round(e.target.value));
          setStakingSeconds(Math.round(e.target.value) * 86400);
        }
      }}
      />
      </TooltipComponent>


     
      {/* <div style={{width:'220px', borderColor:'#fa872d', color:'white'}} className="m-auto border-2 rounded-lg text-white font-white"> */}
      <label htmlFor="stakingPeriod" ><br />Pool Expiration Date:<br /></label>
      <TooltipComponent content="How long do you want the pool to give rewards for?"
      position="BottomCenter">
        <div className='daterangepicker-control-section'>
      <DatePickerComponent
        id="datepicker"
         //style={{fontSize:'18px', color:'white'}}
         cssClass='customClass'
         
         onChange={(e) => {
             setEpochTime(e)
        }}
         
         >
      </DatePickerComponent>
      </div>
      </TooltipComponent>
  
      {/* </div> */}
        <br />




      <DaoButton 

          onClick={() => {
            
            createFarm(farmName, farmLogo, collection, schema, rewardToken, rewardContract, decimals, timestamp, stakingSeconds, farmTypeValue, templates, rewardTypeValue, rewardAmount)
          
          }}
        
        >
          Create Farm (25 EOS) {schema}
        </DaoButton>


    
    </NewProposal>
    </CreateDaoCont>
    </MainContainer>
    </UALProvider>
    <br/><br/><br/>
    </div>
  )



}

export default CreateFarm