import React, { useEffect } from 'react';
import { Header } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { Anchor } from 'ual-anchor';
import { User } from "universal-authenticator-library";
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { Api, JsonRpc } from "eosjs";
import {Helmet} from "react-helmet";
import axios from 'axios';
import { FarmsPageCont, MissingInfoMsg } from '../components/LeftDiv';

let zero = '0';

const myChain = {
  chainId: 'aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906',
  rpcEndpoints: [{
      protocol: 'https',
      host: 'eos.greymass.com',
      port: '443'
  }]
};

const rpc = new JsonRpc('https://eos.greymass.com');


const anchor = new Anchor([myChain], {

  appName: 'WaxDAO',

});



const burnTokens = async (burnTokenString) => {
  // Retrieve current session from state
  const session = await anchor.login()
  //console.log(session[0].signTransaction);


  try {
    // Reset our response state to clear any previous transaction data
    
    const action = [{
      account: anchor.users[0].accountName,
      name: 'burn',
      authorization: [{
      actor: anchor.users[0].accountName,
      permission: anchor.users[0].requestPermission,
      }],
      data: {
        quantity: burnTokenString,
        memo: '',
        }
      }]
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction({
      actions: action
    }, {
      broadcast: true,
    })
    // Update application state with the responses of the transaction
    alert('Success!');
  } catch(e) {
    console.log(e)
    
  }
}


const Burn = () => {

  const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode, currentUser, burnTokenName, setBurnTokenName, burnAmount, setBurnAmount, burnDecimals, setBurnDecimals, burnTokenString, setBurnTokenString, tokenList, setTokenList, burnable, setBurnable, noTokensDisplay, setNoTokensDisplay } = useStateContext();

  useEffect(() => {
 

    //clear any previous state data
    setBurnTokenName('');
    setBurnAmount('');
    setBurnDecimals('');

    


      }

    
    , []);



  useEffect(() => {
    const abortCont2 = new AbortController();
    setTokenList([]);
     const waxUsername = localStorage.getItem('waxAccount');
     const walletProvider = localStorage.getItem('walletProvider');
      
     if(waxUsername){
       //console.log('You have an active session');
       '';
       //setProfileDisplay('flex');
     }else{
       //console.log('You are not logged in');
       return('');
     }
      
      //console.log(waxUsername)
      axios.post('https://wax.pink.gg/v1/chain/get_table_rows',{
        table:"accounts",
        scope:waxUsername,
        code:waxUsername,
        limit:50,
        json:true,
        signal: abortCont2.signal
      })
        .then((response) => {

          if(response.data.rows[0] != undefined){
            setNoTokensDisplay('hidden');
          }
    
          setTokenList(response.data.rows);
          //console.log(response.data.rows);
        })
        
        .catch((error) => console.log(error.response.data));
    
        return() => {
        
          abortCont2.abort();
          
        }

      }

    
    , []);




  return (
    <div id="seo">
    <Helmet>
    <title>Burn Tokens - WaxDAO</title>
    <meta name="description" content="Burn EOS tokens using the WaxDAO token burner. Only works with tokens using our custom EOS token contract." />
    <link rel="canonical" href="https://waxdao.io/burn-tokens" />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor]} appName={"WaxDAO"}>
    <br/><br/><br/>
    <div className="m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl">
    <Header title="Burn Tokens" category="App" />
    <p className="mt-4 text-2xl">Want to reduce the max supply of your EOS token?</p>
    <p className="mt-4 text-xl">Your community will love that. Just fill out the form below and your tokens will be burned.</p>

    <div
  className='p-3 m-auto border-4 rounded-lg gap-4 mt-4 text-center'
  style={{ display:'block', borderColor:currentColor }}
  >
    <h2 className="text-2xl font-semibold">My Tokens</h2>
   
   <div className="flex gap-4 text-center m-auto flex-wrap">

   <div id="noTokens" className={`text-center items-center align-center justify-center m-auto ${noTokensDisplay}`}>
    You have no tokens from your contract on this account.

   </div>


    {tokenList.map((item, index) => (


   
        <div key={index}
        
        className='p-3 m-auto border-2 rounded-lg gap-4 mt-4 text-center'
        style={{ borderColor:currentColor }}

        >{item.balance}</div>


        ))}

</div>    

</div>



    <div id="createForm" className="text-center items-center justify-center border-4 p-6 rounded-full mt-4"
      style={{borderColor: currentColor}}
    >
      <label htmlFor="tokenName">Token Name: <br /></label>
      <TooltipComponent content="Must be the exact name of the token you created"
      position="BottomCenter">
      <input type="text" 
      id="tokenName" 
      className="border-2 rounded-lg text-xl"
      style={{borderColor: currentColor}}
      maxLength={7}
      value={burnTokenName}
      onChange={(e) => {
        setBurnTokenName(e.target.value.toUpperCase());
        if(burnDecimals == '0'){
          setBurnTokenString(burnAmount + ' ' + e.target.value.toUpperCase());
        }
        
        if(burnDecimals !== '0'){
          setBurnTokenString(burnAmount + '.' + zero.repeat(burnDecimals) + ' ' + e.target.value.toUpperCase());
        }
      
      
      }}
      />
      </TooltipComponent>

      <label htmlFor="issueAmount" ><br />Amount To Burn: <br /></label>
      <TooltipComponent content="Whole numbers only"
      position="BottomCenter">
      <input type="number" 
      id="issueAmount" 
      className="border-2 rounded-lg text-xl"
      style={{borderColor: currentColor}}
      value={burnAmount}
      onChange={(e) => {
        setBurnAmount(e.target.value);
        if(burnDecimals == '0'){
          setBurnTokenString(e.target.value + ' ' + burnTokenName.toUpperCase());
        }
        
        if(burnDecimals !== '0'){
          setBurnTokenString(e.target.value + '.' + zero.repeat(burnDecimals) + ' ' + burnTokenName.toUpperCase());
        }
      
      }}

      />
      </TooltipComponent>

      <label htmlFor="decimals" ><br />Decimal Places: <br /></label>
      <TooltipComponent content="Must be exactly the same as the token you created"
      position="BottomCenter">
      <input type="number" 
      id="decimals" 
      className="border-2 rounded-lg text-xl"
      style={{borderColor: currentColor}}
      value={burnDecimals}
      onChange={(e) => {
        setBurnDecimals(e.target.value);
        if(e.target.value == '0'){
          setBurnTokenString(burnAmount + ' ' + burnTokenName.toUpperCase());
        }
        
        if(e.target.value !== '0'){
          setBurnTokenString(burnAmount + '.' + zero.repeat(e.target.value) + ' ' + burnTokenName.toUpperCase());
        }
        //console.log(burnTokenString);
      
      }}
      />
      </TooltipComponent>

      <button 
          type="button"
          className="text-lg p-3
          hover:drop-shadow-xl
          hover:bg-light-gray
          text-white rounded-lg mt-4"
          onClick={() => burnTokens(burnTokenString)}
          style={{ background: currentColor
          }}
        
        >
          Burn Tokens
        </button>

    </div>

    </div>
    </UALProvider>
    </div>
  )
















}

export default Burn