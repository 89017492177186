import React, { useEffect } from 'react';
import { Header } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { Anchor } from 'ual-anchor';
import { User } from "universal-authenticator-library";
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { Api, JsonRpc } from "eosjs";
import {Helmet} from "react-helmet";
import axios from 'axios';
import { CustomizeDaoCont, DaoButton, DaoTitle, FarmsPageCont, MainContainer, MissingInfoMsg, Modal, ModalContent, ModalLink, ModalOverlay, ProposalInput, ProposerTypeMessage, Spinner, SpinnerBlue, SpinnerGreen, SpinnerRed } from '../components/LeftDiv';

let zero = '0';

const myChain = {
  chainId: 'aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906',
  rpcEndpoints: [{
      protocol: 'https',
      host: 'eos.greymass.com',
      port: '443'
  }]
};

const rpc = new JsonRpc('https://eos.greymass.com');


const anchor = new Anchor([myChain], {

  appName: 'WaxDAO',

});



const checkForContract = async (tokenString, setModalMessage, setModalDisplay, setLoadingDisplay) => {

  const session = await anchor.login()

  try {

    //SET THE MODAL MESSAGE TO 'THERE IS NO CONTRACT...'
    setModalMessage('Checking for token contract...');

    //SET THE MODAL DISPLAY TO '' (NEED TO CREATE MODAL CSS)
    setModalDisplay('');

    setLoadingDisplay('');

    const timer = setTimeout(() => {

    axios.post('https://eos.api.eosnation.io/v1/chain/get_code',{
      account_name: anchor.users[0].accountName,
      code_as_wasm: 1,
      json:true
    }).then((codeResponse) => {

    setLoadingDisplay('none');

    if(codeResponse.data.wasm == ""){

    console.log("There is no contract on this account");

    //SET THE MODAL MESSAGE TO 'THERE IS NO CONTRACT...'
    //setModalMessage('There doesn\'t appear to be a contract on this account. You need to \<a href="https://waxdao.io/deploy-contract"\>deploy the token contract\</a\> before you can create a token.');
    setModalMessage(<span>There doesn't appear to be a contract on this account. You need to <ModalLink href="https://eos.waxdao.io/deploy-contract">deploy the contract</ModalLink> before you can create a token</span>);
    //MODAL NEEDS A 'CLOSE' BUTTON THAT SETS MODAL DISPLAY TO NONE/HIDDEN

    return;

  }

  setModalDisplay('hidden');
  setLoadingDisplay('none');
  createToken(tokenString, setModalMessage, setModalDisplay, setLoadingDisplay);

  console.log(codeResponse.data)
})}, 4000); //end .then after checking for contract
return () => clearTimeout(timer);
  }

    //catch error for initial request to proposals table
    catch(e) {
      console.log(e)
    }
  
  }






const createToken = async (tokenString, setModalMessage, setModalDisplay, setLoadingDisplay) => {
    //SET THE MODAL MESSAGE TO 'THERE IS NO CONTRACT...'
    setModalMessage('Creating your token...');

    //SET THE MODAL DISPLAY TO '' (NEED TO CREATE MODAL CSS)
    setModalDisplay('');

    setLoadingDisplay('');
  // Retrieve current session from state
  const session = await anchor.login()
  //console.log(session[0].signTransaction);


    try {
      // Reset our response state to clear any previous transaction data
      
      const action = [{
            account: anchor.users[0].accountName,
            name: 'create',
            authorization: [{
              actor: anchor.users[0].accountName,
              permission: anchor.users[0].requestPermission,
          }],
          data: {
            issuer: anchor.users[0].accountName,
            maximum_supply: tokenString,
          }
        }]
      // Call transact on the session (compatible with eosjs.transact)
      const response = await session[0].signTransaction({
        actions: action
      }, {

        broadcast: true,
        blocksBehind: 3,
        expireSeconds: 120,
      })
      // Update application state with the responses of the transaction
      setModalMessage('Your token has been created')
      setLoadingDisplay('none');
    } catch(e) {
      setModalMessage(JSON.stringify(e.message))
      setLoadingDisplay('none');
      console.log(e)
      
    }





  
}






const Create = () => {
  
  const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode, currentUser, tokenName, 
    setTokenName, maxSupply, setMaxSupply, decimals, setDecimals, tokenString, setTokenString,
    contractResponse, setContractResponse, modalDisplay, setModalDisplay, modalMessage,
    setModalMessage, loadingDisplay, setLoadingDisplay,
   } = useStateContext();

  useEffect(() => {
    const abortCont2 = new AbortController();

    //clear any previous state data
    setModalMessage('');
    setTokenName('');
    setMaxSupply('');
    setDecimals('');
    setModalDisplay('hidden');
    setLoadingDisplay('none');

    


      }

    
    , []);


 

  return (
    <div id="seo">
    <Helmet>
    <title>Create EOS Tokens - WaxDAO</title>
    <meta name="description" content="Create your own EOS token in 5 minutes or less, using our EOS token generator." />
    <link rel="canonical" href="https://waxdao.io/create-token" />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor]} appName={"WaxDAO"}>
    <MainContainer>
      <CustomizeDaoCont>
   <DaoTitle>
    EOS Token Creator
   </DaoTitle>

   <ProposerTypeMessage>
   Have you deployed your token contract already?
   <br/><br/>
   Awesome! You can now use this page to create as many EOS tokens as you like!
   </ProposerTypeMessage>
  

<ModalOverlay className={modalDisplay} />

<Modal className={modalDisplay}>
  <ModalContent>
    {modalMessage}
  </ModalContent>

  <Spinner style={{display:loadingDisplay}}>
    <SpinnerRed  />
    <SpinnerBlue  />
    <SpinnerGreen />
  </Spinner>

<br/>
<DaoButton onClick={() => setModalDisplay('hidden') }>
  Close Window
</DaoButton>

</Modal>




      <label htmlFor="tokenName"><br/>Token Name: <br /></label>
      <TooltipComponent content="Maximum 7 characters"
      position="BottomCenter">
      <ProposalInput type="text" 
      id="tokenName" 
      value={tokenName}
      onChange={(e) => {
        setTokenName(e.target.value.toUpperCase());
        if(decimals == '0'){
          setTokenString(maxSupply + ' ' + e.target.value.toUpperCase());
        }
        
        if(decimals !== '0'){
          setTokenString(maxSupply + '.' + zero.repeat(decimals) + ' ' + e.target.value.toUpperCase());
        }
      
      
      }}
      />
      </TooltipComponent>

      <label htmlFor="maxSupply" ><br />Maximum Supply: <br /></label>
      <TooltipComponent content="Whole numbers only"
      position="BottomCenter">
      <ProposalInput type="number" 
      id="maxSupply" 
      value={maxSupply}
      onChange={(e) => {
        setMaxSupply(e.target.value);
        if(decimals == '0'){
          setTokenString(e.target.value + ' ' + tokenName);
        }
        
        if(decimals !== '0'){
          setTokenString(e.target.value + '.' + zero.repeat(decimals) + ' ' + tokenName);
        }
      
      }}
      />
      </TooltipComponent>

      <label htmlFor="decimals" ><br />Decimal Places: <br /></label>
      <TooltipComponent content="Whole numbers only"
      position="BottomCenter">
      <ProposalInput type="number" 
      id="decimals" 
      value={decimals}
      onChange={(e) => {
        setDecimals(e.target.value);
        if(e.target.value == '0'){
          setTokenString(maxSupply + ' ' + tokenName);
        }
        
        if(e.target.value !== '0'){
          setTokenString(maxSupply + '.' + zero.repeat(e.target.value) + ' ' + tokenName);
        }
        console.log(tokenString);
      
      }}
      />
      </TooltipComponent>

      <DaoButton 
          onClick={() => checkForContract(tokenString, setModalMessage, setModalDisplay, setLoadingDisplay)}
        >
          Create Token Now
        </DaoButton>

    </CustomizeDaoCont>
    </MainContainer>
    </UALProvider>

    </div>
  )




  








}

export default Create