import React, { useEffect } from 'react';
import { GridComponent, Resize, Sort, ContextMenu, Filter, Page, ExcelExport, PdfExport, Edit, Inject, Search, Toolbar } from '@syncfusion/ej2-react-grids';
import { ordersData, contextMenuItems, ordersGrid, nftFarmData, nftFarmGrid } from '../data/dummy';
import { Header } from '../components';
import { ColumnDirective, ColumnsDirective } from '@syncfusion/ej2-react-charts';
import { useParams } from 'react-router-dom';
import { useStateContext } from '../contexts/ContextProvider';
import { Anchor } from 'ual-anchor';
import { Wax } from '@eosdacio/ual-wax';
import { User } from "universal-authenticator-library";
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { Api, JsonRpc } from "eosjs";
import {Helmet} from "react-helmet";
import axios from 'axios';
import { data } from 'autoprefixer';
import { AllFarmsCont, PoolAmount, FarmButton, SingleFarmCont, FarmLogo, FarmTitle, FarmsPageCont, FarmsTitle, HighlightedFarm, HighlightedLogo, HighlightDetails, HighlightTitle, FarmEndDate, HighlightMessage, HighlightAmount, HomePageCont, HomeTitle, SingleProposal, HomeDescription, HomePageCont2, HomeTitle2, TrustedByDiv, TrustedByCont, TrustedByTitle, HomePageStars, TrustedByLink } from '../components/LeftDiv';
import NumberFormat from 'react-number-format';
import waxdao_logo from '../data/waxdao_logo_v2.png';
import logo9001 from './logo9001.mp4'



let zero = '0';

var thisUsersFarms;

const myChain = {
  chainId: '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4',
  rpcEndpoints: [{
      protocol: 'https',
      host: 'wax.greymass.com',
      port: '443'
  }]
};

const rpc = new JsonRpc('https://wax.greymass.com');


const anchor = new Anchor([myChain], {

  appName: 'WaxDAO',

});

const wax = new Wax([myChain], {

  appName: 'WaxDAO',

});


const ualLogin = async () => {
 
 // console.log('Loading...');
  await anchor.login();
  getCustomersData();
  //localStorage.setItem('waxAccount', 'anchor.users[0].accountName')
  //console.log(anchor.users[0].accountName);

}

const ualLogout = async () => {
 
 // console.log('Loading...');
  await anchor.logout();
  //console.log(anchor.users[0].accountName);

}


const sumArray = (array, setTotalBurned) => {

  var totalWojaksBurned = Number(0);

  for(var i = 0; i < array.length; i++){

    totalWojaksBurned += Number(array[i].assets);

  }

  setTotalBurned(totalWojaksBurned);

}


const sumNfts = (array, setTotalNftsStaked) => {

  var totalStakedOnWaxdao = Number(0);
  
  for(var i = 0; i < array.length; i++){

    totalStakedOnWaxdao += Number(array[i].totalstaked);

  }

  setTotalNftsStaked(totalStakedOnWaxdao);

}


const HomePage = () => {

  const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode, currentUser, tokenName, 
    setTokenName, maxSupply, setMaxSupply, decimals, setDecimals, tokenString, setTokenString, farmData, 
    setFarmData, farmCollection, setFarmCollection, farmSchema, setFarmSchema, farmRewards, setFarmRewards, 
    farmExpires, setFarmExpires, farmIcon, setFarmIcon, farmContract, setFarmContract, createdBy, setCreatedBy, 
    farmList, setFarmList, stakedOnly, setStakedOnly, farmsUserIsIn, setFarmsUserIsIn, highlightedFarm,
    setHighlightedFarm, totalBurned, setTotalBurned, totalFarms, setTotalFarms, totalNftsStaked,
    setTotalNftsStaked
  } = useStateContext();

 

  useEffect(() => {
  
    const abortCont = new AbortController();

    setTotalBurned('75');

    axios.get(`https://wax.api.atomicassets.io/atomicassets/v1/burns?collection_name=hoodpunknfts&schema_name=waxywojaks&page=1&limit=100&order=desc`)
    .then((burnResponse) => {
      sumArray(burnResponse.data.data, setTotalBurned);
      //console.log(burnResponse.data.data)
    })
    .catch((error) => console.log(error));

    }, [])



  useEffect(() => {

    const abortCont5 = new AbortController();

    setTotalFarms('50+');
    setTotalNftsStaked('10,000+');

    axios.post('https://wax.pink.gg/v1/chain/get_table_rows',{
      table:"farms",
      scope:"waxdaofarmer",
      code:"waxdaofarmer",
      limit:1000,
      json:true,
      signal: abortCont5
    }).then((farmResponse) => {
      sumNfts(farmResponse.data.rows, setTotalNftsStaked);
      console.log(farmResponse.data.rows)
      setTotalFarms(farmResponse.data.rows.length);
    })
    .catch((error) => console.log(error));

    }, [])




  return (
  <div id="seo">
    <Helmet>
    <title>Home - WaxDAO</title>
    <meta name="description" content="WaxDAO builds tools for creators on Wax blockchain" />
    <link rel="canonical" href="https://waxdao.io/farms" />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor, wax]} appName={"WaxDAO"}>
    <HomePageCont>
    {/* <HomePageStars src={stars} /> */}
      <HomeTitle>
        {/* 
      <video style={{margin:'auto'}} width="100%" height="auto">
            <source src={logo9001} type="video/mp4"/>
          </video>
 */}

          <img src={waxdao_logo} style={{margin:'auto'}} width="100%" height="auto" />


      </HomeTitle>

      <HomeDescription>
          Building tools for creators on the Wax Blockchain
      </HomeDescription>

      </HomePageCont>

    <HomePageCont2>
    <HomeTitle2>
       Trusted By Over 1,000 People
      </HomeTitle2>
      <TrustedByCont>
        <TrustedByDiv>
            <TrustedByTitle>
                Wallets
            </TrustedByTitle>
            There are over 1000 users who are using our platform
        </TrustedByDiv>
        <TrustedByDiv>
            <TrustedByTitle>
                NFTs Staked
            </TrustedByTitle>
            {totalNftsStaked != null && <NumberFormat displayType='text' thousandSeparator={true} value={totalNftsStaked} />} NFTs are staked on WaxDAO. Join the revolution!
        </TrustedByDiv>
        <TrustedByDiv>
            <TrustedByTitle>
                Total Farms
            </TrustedByTitle>
            There are currently {totalFarms != null && totalFarms} NFT farms on WaxDAO, and counting
        </TrustedByDiv>
        <TrustedByDiv>
            <TrustedByTitle>
                NFTs Burned
            </TrustedByTitle>
            {totalBurned != null && totalBurned} of 10,000 Wojak NFTs have already been removed from the supply. Users receive discounts on our services by burning our NFTs
        </TrustedByDiv>
      </TrustedByCont>
    </HomePageCont2>


    <HomePageCont2>
    <HomeTitle2>
       Our Services
      </HomeTitle2>
      <TrustedByCont>
        
        <TrustedByLink href="https://waxdao.io/token-creator">
            <TrustedByTitle>
                Token Creator
            </TrustedByTitle>
            Anyone on WAX Blockchain can create their own token in 5 minutes or less by using our tool
        </TrustedByLink>
        
        <TrustedByLink href="https://waxdao.io/nft-farming">
            <TrustedByTitle>
                NFT Farms
            </TrustedByTitle>
            Launch your own NFT farm and let users earn staking rewards for your collection
        </TrustedByLink>
        <TrustedByLink href="https://waxdao.io/staking-pools">
            <TrustedByTitle>
                LP Farming
            </TrustedByTitle>
            Create a reward pool for staking your LP token, and incentivize people to add liquidity. Or, do this with a regular Wax token instead
        </TrustedByLink >
        <TrustedByLink href="https://waxdao.io/dao-menu">
            <TrustedByTitle>
                DAO Creator
            </TrustedByTitle>
            Grant voting priveleges to your holders by creating your own DAO. Let your community help shape the future of your project
        </TrustedByLink>
      </TrustedByCont>
    </HomePageCont2>


    </UALProvider>
    <br/><br/><br/>
    </div>
  )
}

export default HomePage