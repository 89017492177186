import React, { useEffect } from 'react';
import { GridComponent, Resize, Sort, ContextMenu, Filter, Page, ExcelExport, PdfExport, Edit, Inject, Search, Toolbar } from '@syncfusion/ej2-react-grids';
import { ordersData, contextMenuItems, ordersGrid, nftFarmData, nftFarmGrid, nftPoolGrid, nftPoolData } from '../../data/dummy';
import { Header } from '../../components';
import { ColumnDirective, ColumnsDirective } from '@syncfusion/ej2-react-charts';
import { useParams } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import { Anchor } from 'ual-anchor';
import { Wax } from '@eosdacio/ual-wax';
import { User } from "universal-authenticator-library";
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { Api, JsonRpc } from "eosjs";
import {Helmet} from "react-helmet";
import axios from 'axios';
import { data } from 'autoprefixer';
import { AllFarmsCont, FarmsPageCont, MainContainer, NFT, PoolAmount, SingleFarmCont, FarmLogo, FarmTitle, FarmEndDate, FarmsTitle, SingleProposal, StakingPoolMsg } from '../../components/LeftDiv';
import NumberFormat from 'react-number-format';

let zero = '0';

const myChain = {
  chainId: 'aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906',
  rpcEndpoints: [{
      protocol: 'https',
      host: 'eos.greymass.com',
      port: '443'
  }]
};

const rpc = new JsonRpc('https://eos.greymass.com');


const anchor = new Anchor([myChain], {

  appName: 'WaxDAO',

});


const Pools = () => {

  const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode, currentUser, tokenName, setTokenName, maxSupply, setMaxSupply, decimals, setDecimals, tokenString, setTokenString, farmData, setFarmData, farmCollection, setFarmCollection, farmSchema, setFarmSchema, farmRewards, setFarmRewards, farmExpires, setFarmExpires, farmIcon, setFarmIcon, farmContract, setFarmContract, createdBy, setCreatedBy, farmList, setFarmList, poolList, setPoolList } = useStateContext();

const { FarmName } = useParams();

useEffect(() => {

  const abortCont6 = new AbortController();


  axios.post('https://eos.api.eosnation.io/v1/chain/get_table_rows',{
    table:"pools",
    scope:"waxdaofarmer",
    code:"waxdaofarmer",
    //key_type: 'name',
    //indexName: 'farmname',
    limit:100,
    //lower_bound:FarmName,
    //upper_bound:FarmName,
    json:true,
    signal: abortCont6.signal
  })
    .then((response) => {

      setPoolList([response.data.rows]);
      //console.log(response);
    })
    .catch((error) => console.log(error));

    return() => abortCont6.abort();

}, []);

  return (
  <div id="seo">
    <Helmet>
    <title>Staking Pools</title>
    <meta name="description" content="Wax token staking pools on Waxdao" />
    <link rel="canonical" href="https://waxdao.io/pools" />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor]} appName={"WaxDAO"}>
      <MainContainer>
        <FarmsPageCont>
          <FarmsTitle>
        Token Staking Pools
        </FarmsTitle>

        <StakingPoolMsg>
          Staking pools allow you to earn passive income for staking a token.
          <br/><br/>
          If you have a project, you can create a reward pool for your project's LP token- incentivizing 
          people to add liquidity.
          <br/><br/>
          Simply have your community stake the LP token, and reward them with any token you like. This isn't 
          just limited to LP tokens, you can reward them for staking any token on EOS.
        </StakingPoolMsg>
          <AllFarmsCont>



          {poolList[1] == null && poolList[0] != null && Array.isArray(poolList[0]) ? (poolList[0].map((item, index) => (
            <a href={`https://eos.waxdao.io/pool/${item.poolname}`}>
            <SingleFarmCont>
              {item.logo != null && (
                <FarmLogo src={`https://ipfs.io/ipfs/${item.logo}`} />
              )}
              {item.poolname != null && (
                <FarmTitle>
                {item.poolname}
                </FarmTitle>
              )}
              {item.poolsize != null && (
                <PoolAmount>
                  <NumberFormat displayType='text' thousandSeparator={true} value={Math.round(item.poolsize.substring(0, item.poolsize.indexOf(' ')))} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} />
                 {item.poolsize.substring(item.poolsize.indexOf(' '))}
                </PoolAmount>
              )}
              {item.enddate != null && (
                <FarmEndDate>
                  Ends {new Date(item.enddate * 1000).toLocaleDateString()}
                </FarmEndDate>
              )}

            </SingleFarmCont>
            </a>
            ))) : poolList[1] != null && Array.isArray(poolList) && poolList.map((item, index) => (
              <a href={`https://eos.waxdao.io/pool/${item.poolname}`}>
              <SingleFarmCont>
              {item.logo != null && (
                <FarmLogo src={`https://ipfs.io/ipfs/${item.logo}`} />
              )}
              {item.poolname != null && (
                <FarmTitle>
                {item.poolname}
                </FarmTitle>
              )}
              {item.poolsize != null && (
                <PoolAmount>
                  <NumberFormat displayType='text' thousandSeparator={true} value={Math.round(item.poolsize.substring(0, item.poolsize.indexOf(' ')))} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} />
                 {item.poolsize.substring(item.poolsize.indexOf(' '))}
                </PoolAmount>
              )}
              {item.enddate != null && (
                <FarmEndDate>
                  Ends {new Date(item.enddate * 1000).toLocaleDateString()}
                </FarmEndDate>
              )}

             </SingleFarmCont>
             </a>
              ))}
    </AllFarmsCont>
    </FarmsPageCont>
    </MainContainer>
    </UALProvider>
    <br/><br/><br/>
    </div>
  )
}

export default Pools