import React, { useEffect } from 'react';
import { GridComponent, Resize, Sort, ContextMenu, Filter, Page, ExcelExport, PdfExport, Edit, Inject } from '@syncfusion/ej2-react-grids';
import { ordersData, contextMenuItems, ordersGrid, nftFarmData, nftFarmGrid } from '../data/dummy';
import { Header } from '../components';
import { ColumnDirective, ColumnsDirective } from '@syncfusion/ej2-react-charts';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useParams } from 'react-router-dom';
import { useStateContext } from '../contexts/ContextProvider';
import { Anchor } from 'ual-anchor';
import { Wax } from '@eosdacio/ual-wax';
import { User } from "universal-authenticator-library";
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { Api, JsonRpc } from "eosjs";
import {Helmet} from "react-helmet";
import axios from 'axios';
import { data } from 'autoprefixer';

let zero = '0';

const myChain = {
  chainId: '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4',
  rpcEndpoints: [{
      protocol: 'https',
      host: 'wax.greymass.com',
      port: '443'
  }]
};

const rpc = new JsonRpc('https://wax.greymass.com');


const anchor = new Anchor([myChain], {

  appName: 'WaxDAO',

});

const wax = new Wax([myChain], {

  appName: 'WaxDAO',

});


const ualLogin = async () => {
 
  console.log('Loading...');
  await anchor.login();
  getCustomersData();
  //localStorage.setItem('waxAccount', 'anchor.users[0].accountName')
  //console.log(anchor.users[0].accountName);

}

const ualLogout = async () => {
 
  console.log('Loading...');
  await anchor.logout();
  //console.log(anchor.users[0].accountName);

}


const stakeTokens = async (stakingAmount, tokenContract, tokenToStake, UserName) => {

  const walletProvider = localStorage.getItem('walletProvider');

  if(walletProvider == 'wax'){

  // Retrieve current session from state
  const session = await wax.login()
  //console.log(session[0].signTransaction);

  try {
    // Reset our response state to clear any previous transaction data
    
    const action = [{
          account: tokenContract,
          name: 'transfer',
          authorization: [{
            actor: wax.users[0].accountName,
            permission: wax.users[0].requestPermission,
        }],
        data: {
            to: 'waxdaofarmer',
            from: wax.users[0].accountName,
            quantity: stakingAmount + ' ' + tokenToStake,
            memo: '|stake_tokens|' + UserName + '|',
        }
      }]
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction({
      actions: action
    }, {

      blocksBehind: 3,
      expireSeconds: 60,
      broadcast: true,
    })
    // Update application state with the responses of the transaction
    alert('Success! Your tokens are now staked');
  } catch(e) {
    alert(e)
    console.log(e)
    
  }
  
  }//end if wallet = wax


  if(walletProvider == 'anchor'){

    // Retrieve current session from state
    const session = await anchor.login()
    //console.log(session[0].signTransaction);
  
    try {
      // Reset our response state to clear any previous transaction data
      
      const action = [{
            account: tokenContract,
            name: 'transfer',
            authorization: [{
              actor: anchor.users[0].accountName,
              permission: anchor.users[0].requestPermission,
          }],
          data: {
              to: 'waxdaofarmer',
              from: anchor.users[0].accountName,
              quantity: stakingAmount + ' ' + tokenToStake,
              memo: '|stake_tokens|' + UserName + '|',
          }
        }]
      // Call transact on the session (compatible with eosjs.transact)
      const response = await session[0].signTransaction({
        actions: action
      }, {
        // Optional: Prevent anchor-link from broadcasting this transaction (default: True)
        //
        //    The wallet/signer connected to this app will NOT broadcast the transaction
        //    as is defined by the anchor-link protocol. Broadcasting is the responsibility
        //    of anchor-link running inside an application (like this demo).
        //
        //    For this demo specifically we do NOT want the transaction to ever be broadcast
        //    to the blockchain, so we're disabling it here.
        //
        //    For all normal applications using anchor-link, you can omit this.
        //
        blocksBehind: 3,
        expireSeconds: 60,
        broadcast: true,
      })
      // Update application state with the responses of the transaction
      alert('Success! Your tokens are now staked');
    } catch(e) {
      alert(e)
      console.log(e)
      
    }
    
    }//end if wallet = anchor



}




const depositRam = async (UserName) => {

  const walletProvider = localStorage.getItem('walletProvider');

  if(walletProvider == 'wax'){

  // Retrieve current session from state
  const session = await wax.login()
  //console.log(session[0].signTransaction);

  try {
    // Reset our response state to clear any previous transaction data
    
    const action = [{
          account: 'eosio.token',
          name: 'transfer',
          authorization: [{
            actor: wax.users[0].accountName,
            permission: wax.users[0].requestPermission,
        }],
        data: {
            to: 'waxdaofarmer',
            from: wax.users[0].accountName,
            quantity: '1.00000000 WAX',
            memo: '|deposit_user_ram|' + UserName + '|',
        }
      }]
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction({
      actions: action
    }, {

      blocksBehind: 3,
      expireSeconds: 60,
      broadcast: true,
    })
    // Update application state with the responses of the transaction
    alert('Success! Your tokens are now staked');
  } catch(e) {
    alert(e)
    console.log(e)
    
  }
  
  }//end if wallet = wax


  if(walletProvider == 'anchor'){

    // Retrieve current session from state
    const session = await anchor.login()
    //console.log(session[0].signTransaction);
  
    try {
      // Reset our response state to clear any previous transaction data
      
      const action = [{
            account: 'eosio.token',
            name: 'transfer',
            authorization: [{
              actor: anchor.users[0].accountName,
              permission: anchor.users[0].requestPermission,
          }],
          data: {
              to: 'waxdaofarmer',
              from: anchor.users[0].accountName,
              quantity: '1.00000000 WAX',
              memo: '|deposit_user_ram|' + UserName + '|',
          }
        }]
      // Call transact on the session (compatible with eosjs.transact)
      const response = await session[0].signTransaction({
        actions: action
      }, {
        // Optional: Prevent anchor-link from broadcasting this transaction (default: True)
        //
        //    The wallet/signer connected to this app will NOT broadcast the transaction
        //    as is defined by the anchor-link protocol. Broadcasting is the responsibility
        //    of anchor-link running inside an application (like this demo).
        //
        //    For this demo specifically we do NOT want the transaction to ever be broadcast
        //    to the blockchain, so we're disabling it here.
        //
        //    For all normal applications using anchor-link, you can omit this.
        //
        blocksBehind: 3,
        expireSeconds: 60,
        broadcast: true,
      })
      // Update application state with the responses of the transaction
      alert('Success! Your tokens are now staked');
    } catch(e) {
      alert(e)
      console.log(e)
      
    }
    
    }//end if wallet = anchor



}






const unstakeTokens = async (stakingAmount, tokenContract, tokenToStake, UserName) => {

  const walletProvider = localStorage.getItem('walletProvider');

  const stakingString = stakingAmount.toString().replace('.', '');

  if(walletProvider == 'wax'){

  // Retrieve current session from state
  const session = await wax.login()
  //console.log(session[0].signTransaction);

  try {
    // Reset our response state to clear any previous transaction data
    
    const action = [{
          account: 'waxdaofarmer',
          name: 'unstaketoken',
          authorization: [{
            actor: wax.users[0].accountName,
            permission: wax.users[0].requestPermission,
        }],
        data: {
            user: wax.users[0].accountName,
            amount: stakingString,
            UserName: UserName,
        }
      }]
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction({
      actions: action
    }, {
      // Optional: Prevent anchor-link from broadcasting this transaction (default: True)
      //
      //    The wallet/signer connected to this app will NOT broadcast the transaction
      //    as is defined by the anchor-link protocol. Broadcasting is the responsibility
      //    of anchor-link running inside an application (like this demo).
      //
      //    For this demo specifically we do NOT want the transaction to ever be broadcast
      //    to the blockchain, so we're disabling it here.
      //
      //    For all normal applications using anchor-link, you can omit this.
      //
      blocksBehind: 3,
      expireSeconds: 60,
      broadcast: true,
    })
    // Update application state with the responses of the transaction
    alert('Success! Your NFT has been unstaked');
  } catch(e) {
    alert(e)
    console.log(e)
    
  }

} // end if wallet = wax


if(walletProvider == 'anchor'){

  // Retrieve current session from state
  const session = await anchor.login()
  //console.log(session[0].signTransaction);

  try {
    // Reset our response state to clear any previous transaction data
    
    const action = [{
          account: 'waxdaofarmer',
          name: 'unstaketoken',
          authorization: [{
            actor: anchor.users[0].accountName,
            permission: anchor.users[0].requestPermission,
        }],
        data: {
          user: anchor.users[0].accountName,
          amount: stakingString,
          UserName: UserName,
        }
      }]
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction({
      actions: action
    }, {
      // Optional: Prevent anchor-link from broadcasting this transaction (default: True)
      //
      //    The wallet/signer connected to this app will NOT broadcast the transaction
      //    as is defined by the anchor-link protocol. Broadcasting is the responsibility
      //    of anchor-link running inside an application (like this demo).
      //
      //    For this demo specifically we do NOT want the transaction to ever be broadcast
      //    to the blockchain, so we're disabling it here.
      //
      //    For all normal applications using anchor-link, you can omit this.
      //
      blocksBehind: 3,
      expireSeconds: 60,
      broadcast: true,
    })
    // Update application state with the responses of the transaction
    alert('Success! Your NFT has been unstaked');
  } catch(e) {
    alert(e)
    console.log(e)
    
  }

} // end if wallet = anchor

}



const claimRewards = async (UserName) => {

  const walletProvider = localStorage.getItem('walletProvider');

  if(walletProvider == 'wax'){

  // Retrieve current session from state
  const session = await wax.login()
  //console.log(session[0].signTransaction);

  try {
    // Reset our response state to clear any previous transaction data
    
    const action = [{
          account: 'waxdaofarmer',
          name: 'claimpoolrwd',
          authorization: [{
            actor: wax.users[0].accountName,
            permission: wax.users[0].requestPermission,
        }],
        data: {
            user: wax.users[0].accountName,
            pool: UserName,
        }
      }]
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction({
      actions: action
    }, {
      // Optional: Prevent anchor-link from broadcasting this transaction (default: True)
      //
      //    The wallet/signer connected to this app will NOT broadcast the transaction
      //    as is defined by the anchor-link protocol. Broadcasting is the responsibility
      //    of anchor-link running inside an application (like this demo).
      //
      //    For this demo specifically we do NOT want the transaction to ever be broadcast
      //    to the blockchain, so we're disabling it here.
      //
      //    For all normal applications using anchor-link, you can omit this.
      //
      blocksBehind: 3,
      expireSeconds: 60,
      broadcast: true,
    })
    // Update application state with the responses of the transaction
    alert('Success! Your rewards have been sent.');
  } catch(e) {
    alert(e)
    console.log(e)
    
  }

}// end if wallet = wax


if(walletProvider == 'anchor'){

  // Retrieve current session from state
  const session = await anchor.login()
  //console.log(session[0].signTransaction);

  try {
    // Reset our response state to clear any previous transaction data
    
    const action = [{
          account: 'waxdaofarmer',
          name: 'claimpoolrwd',
          authorization: [{
            actor: anchor.users[0].accountName,
            permission: anchor.users[0].requestPermission,
        }],
        data: {
            user: anchor.users[0].accountName,
            pool: UserName,
        }
      }]
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction({
      actions: action
    }, {
      // Optional: Prevent anchor-link from broadcasting this transaction (default: True)
      //
      //    The wallet/signer connected to this app will NOT broadcast the transaction
      //    as is defined by the anchor-link protocol. Broadcasting is the responsibility
      //    of anchor-link running inside an application (like this demo).
      //
      //    For this demo specifically we do NOT want the transaction to ever be broadcast
      //    to the blockchain, so we're disabling it here.
      //
      //    For all normal applications using anchor-link, you can omit this.
      //
      blocksBehind: 3,
      expireSeconds: 60,
      broadcast: true,
    })
    // Update application state with the responses of the transaction
    alert('Success! Your rewards have been sent.');
  } catch(e) {
    alert(e)
    console.log(e)
    
  }

}// end if wallet = anchor

}




const Profile = ({ location }) => {

  const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode, currentUser, setCurrentUser, tokenName, setTokenName, maxSupply, setMaxSupply, decimals, setDecimals, tokenString, setTokenString, farmData, setFarmData, farmCollection, setFarmCollection, farmSchema, setFarmSchema, farmRewards, setFarmRewards, farmExpires, setFarmExpires, farmIcon, setFarmIcon, farmContract, setFarmContract, createdBy, setCreatedBy, unstaked, setUnstaked, stakedOrUnstaked, setStakedOrUnstaked, stakedDisplay, setStakedDisplay, unstakedDisplay, setUnstakedDisplay, validAssets, setValidAssets, commaSeparated, setCommaSeparated, stakedAssets, setStakedAssets, claimable, setClaimable, minTime, setMinTime, tokenToStake, setTokenToStake, tokenContract, setTokenContract, tokenList, setTokenList, noTokensDisplay, setNoTokensDisplay, stakingAmount, setStakingAmount, tokenPrecision, setTokenPrecision, minAmount, setMinAmount, stakedBalance, setStakedBalance, hourlyPool, setHourlyPool, totalStaked, setTotalStaked } = useStateContext();

const { UserName } = useParams();

useEffect(() => {

  const abortCont = new AbortController();
  const abortCont2 = new AbortController();
  const abortCont3 = new AbortController();
  const abortCont4 = new AbortController();

  setStakingAmount('');

  setStakedAssets([]);
  setUnstaked([]);
  setValidAssets([]);
  setCommaSeparated('');
  setTokenToStake('');
  setTokenContract('');
  setTokenList([]);
  setMinAmount('');
  setHourlyPool('0');
  setTotalStaked('0');


  const waxUsername = localStorage.getItem('waxAccount');
  
  if(waxUsername){
    //console.log('You have an active session');
    '';
    //setProfileDisplay('flex');
  }else{
    //console.log('You are not logged in');
    '';     //return('');
  }



  axios.post('https://wax.pink.gg/v1/chain/get_table_rows',{
    table:"pools",
    scope:"waxdaofarmer",
    code:"waxdaofarmer",
    key_type: 'name',
    indexName: 'UserName',
    limit:1,
    lower_bound:UserName,
    upper_bound:UserName,
    json:true,
    signal: abortCont.signal
  })
    .then((response) => {
      setFarmData(response);
      setFarmCollection(response.data.rows[0].collection);
      setFarmSchema(response.data.rows[0].schema);
      setFarmIcon(response.data.rows[0].logo);
      setCreatedBy(response.data.rows[0].creator);
      setFarmRewards(response.data.rows[0].poolsize);
      setFarmContract(response.data.rows[0].contract);
      setFarmExpires(response.data.rows[0].enddate);
      setMinTime(response.data.rows[0].mintime / 86400);
      setMinAmount(response.data.rows[0].minamount);
      setTokenPrecision(response.data.rows[0].tokentostake.substring(0, response.data.rows[0].tokentostake.indexOf(",")));
      setTokenToStake(response.data.rows[0].tokentostake.substring(response.data.rows[0].tokentostake.indexOf(",") + 1));
      setTokenContract(response.data.rows[0].stkcontract);
      setHourlyPool(response.data.rows[0].hourlyreward);
      setTotalStaked(response.data.rows[0].totalstaked);

      //console.log(response.data.rows[0].collection);
      if(waxUsername){

        axios.post('https://wax.pink.gg/v1/chain/get_table_rows',{
          table:"accounts",
          scope:waxUsername,
          code:response.data.rows[0].stkcontract,
          limit:50,
          json:true,
          signal: abortCont3.signal
        }).then((poolResponse) => {

          if(poolResponse.data.rows[0] != undefined){
            setNoTokensDisplay('hidden');
          }
    
          setTokenList(poolResponse.data.rows);
          //console.log(response.data.rows);
        })



      .catch((error) => console.log(error));


    }



    })
    .catch((error) => console.log(error));

    return() => {
      abortCont.abort();
      abortCont2.abort();
      abortCont3.abort();
      abortCont4.abort();
      
    }

}, []);









useEffect(() => {


  const abortCont5 = new AbortController();

  const waxUsername = localStorage.getItem('waxAccount');
  
  if(waxUsername){
    //console.log('You have an active session');
    '';
    //setProfileDisplay('flex');
  }else{
    //console.log('You are not logged in');
    return('');
  }

  setStakedBalance('0');

  axios.post('https://wax.eosdac.io/v1/chain/get_table_rows',{
    table:"tokens",
    scope:"waxdaofarmer",
    code:"waxdaofarmer",
    key_type: 'name',
    index_position: 2,
    limit:100,
    lower_bound:waxUsername,
    upper_bound:waxUsername,
    json:true,
    signal: abortCont5.signal
  }).then((claimableResponse) => {
    var claimitr = 0;

    while(claimitr < claimableResponse.data.rows.length){
      if(claimableResponse.data.rows[claimitr].UserName == UserName){
        setClaimable(claimableResponse.data.rows[claimitr].claimable);
        setStakedBalance(claimableResponse.data.rows[claimitr].amountstaked);

          //console.log(claimableResponse);

        break;
      }
      else{
        claimitr ++;
      }
    }


  })

.catch((error) => console.log(error));

return() => abortCont5.abort();

}, []);












  return (
    <div id="seo">
    <Helmet>
    <title>{UserName} Profile</title>
    <meta name="description" content={`${UserName} profile on WaxDao`} />
    <link rel="canonical" href={`https://waxdao.io/user/${UserName}`} />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor, wax]} appName={"WaxDAO"}>
    <div className="m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl">
    <Header title={`${UserName}`} category="Profile" />

    

    <div id="createForm" className="text-center items-center justify-center border-4 p-6 rounded-lg mt-4 text-2xl"
      style={{borderColor: currentColor}}
    >
      <div className='p-3 m-auto'>
      <span className="font-semibold">User profile pages coming soon to WaxDAO!</span>
      
      </div>
<div>

  Follow your favorite creators, drop them a message and more :)
</div>






    </div>








    </div>
    </UALProvider>
    </div>
  )
}

export default Profile