import React, { useEffect, useRef } from 'react';
import { GridComponent, Resize, Sort, ContextMenu, Filter, Page, ExcelExport, PdfExport, Edit, Inject } from '@syncfusion/ej2-react-grids';
import { ordersData, contextMenuItems, ordersGrid, nftFarmData, nftFarmGrid } from '../data/dummy';
import { Header } from '../components';
import { ColumnDirective, ColumnsDirective, NiceInterval } from '@syncfusion/ej2-react-charts';
import { useParams } from 'react-router-dom';
import { useStateContext } from '../contexts/ContextProvider';
import { Anchor } from 'ual-anchor';
import { Wax } from '@eosdacio/ual-wax';
import { User } from "universal-authenticator-library";
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { Api, JsonRpc } from "eosjs";
import {Helmet} from "react-helmet";
import axios from 'axios';
import { data } from 'autoprefixer';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { BsInfoCircle } from 'react-icons/bs';
import { render } from 'react-dom';
import Select from 'react-select';
import { GiConsoleController } from 'react-icons/gi';
import NumberFormat from 'react-number-format';

var totalWeight;



var choicesArray = Array(10).fill(0);

var percentPerVote;

var percentagesArray = Array(10).fill(0);

const handleChoiceSelection = (e, index, selectedOption, setSelectedOption) => {

    setSelectedOption(index);
    

}


const handleChoiceAdd = (setChoicesList, choicesList) => {

    setChoicesList([...choicesList, { choice: ''}])

}

const handleChoiceRemove = (setChoicesList, choicesList, index) => {
    const list = [...choicesList];
    list.splice(index, 1);
    setChoicesList(list);

}

const handleChoiceChange = (e, index, choicesList, setChoicesList) => {
    const {name, value} = e.target;
    const list = [...choicesList];
    list[index][name] = value;
    setChoicesList(list);

}

const sumArray = (array, totalVoteWeight, setTotalVoteWeight, percentPerVote, choicesArray, totalVotesPerChoice, 
  setTotalVotesPerChoice, percentPerChoice, setPercentPerChoice, percentagesArray) => {
  const arrayToSum = [...array];
  var totalWeight = Number(0);




  for(var i = 0; i < array.length; i++){

    totalWeight += Number(array[i].voteweight);

    choicesArray[array[i].choice] += Number(array[i].voteweight);

  }

  setTotalVoteWeight(totalWeight);

  setTotalVotesPerChoice(choicesArray);

  percentPerVote = 100 / totalWeight;


  for(var z = 0; z < choicesArray.length; z++){

    percentagesArray[z] = choicesArray[z] * percentPerVote;

  }

  setPercentPerChoice(percentagesArray);
  
  

}


const submitVote = async (daoName, ProposalID, selectedOption) => {


  if(selectedOption === ''){
      alert('You need to select an option first');
      return;
  }

  const walletProvider = localStorage.getItem('walletProvider');

  if(walletProvider == 'wax'){

  // Retrieve current session from state
  const session = await wax.login()
  //console.log(session[0].signTransaction);

  try {
    // Reset our response state to clear any previous transaction data
    
    const action = [{
          account: 'buildawaxdao',
          name: 'vote',
          authorization: [{
            actor: wax.users[0].accountName,
            permission: wax.users[0].requestPermission,
        }],
        data: {
            user: wax.users[0].accountName,
            daoname: daoName,
            proposal_id: ProposalID,
            choice: selectedOption,
        }
      }]
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction({
      actions: action
    }, {

      blocksBehind: 3,
      expireSeconds: 60,
      broadcast: true,
    })
    // Update application state with the responses of the transaction
    alert('Success! Your vote has been submitted');
  } catch(e) {
    alert(e)
    console.log(e)
    
  }

} // end if wallet = wax


if(walletProvider == 'anchor'){

  // Retrieve current session from state
  const session = await anchor.login()
  //console.log(session[0].signTransaction);

  try {
    // Reset our response state to clear any previous transaction data
    
    const action = [{
          account: 'buildawaxdao',
          name: 'vote',
          authorization: [{
            actor: anchor.users[0].accountName,
            permission: anchor.users[0].requestPermission,
        }],
        data: {
            user: anchor.users[0].accountName,
            daoname: daoName,
            proposal_id: ProposalID,
            choice: selectedOption,
        }
      }]
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction({
      actions: action
    }, {

      blocksBehind: 3,
      expireSeconds: 60,
      broadcast: true,
    })
    // Update application state with the responses of the transaction
    alert('Success! Your vote has been submitted');

  } catch(e) {
    alert(e)
    console.log(e)
    
  }

} // end if wallet = anchor





}



const finalize = async (ProposalID) => {


  const walletProvider = localStorage.getItem('walletProvider');

  if(walletProvider == 'wax'){

  // Retrieve current session from state
  const session = await wax.login()
  //console.log(session[0].signTransaction);

  try {
    // Reset our response state to clear any previous transaction data
    
    const action = [{
          account: 'buildawaxdao',
          name: 'finalize',
          authorization: [{
            actor: wax.users[0].accountName,
            permission: wax.users[0].requestPermission,
        }],
        data: {
            user: wax.users[0].accountName,
            proposal_id: ProposalID,

        }
      }]
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction({
      actions: action
    }, {

      blocksBehind: 3,
      expireSeconds: 60,
      broadcast: true,
    })
    // Update application state with the responses of the transaction
    alert('Success! The proposal has been finalized');
  } catch(e) {
    alert(e)
    console.log(e)
    
  }

} // end if wallet = wax


if(walletProvider == 'anchor'){

  // Retrieve current session from state
  const session = await anchor.login()
  //console.log(session[0].signTransaction);

  try {
    // Reset our response state to clear any previous transaction data
    
    const action = [{
          account: 'buildawaxdao',
          name: 'finalize',
          authorization: [{
            actor: anchor.users[0].accountName,
            permission: anchor.users[0].requestPermission,
        }],
        data: {
            user: anchor.users[0].accountName,
            proposal_id: ProposalID,

        }
      }]
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction({
      actions: action
    }, {

      blocksBehind: 3,
      expireSeconds: 60,
      broadcast: true,
    })
    // Update application state with the responses of the transaction
    alert('Success! The proposal has been finalized');

  } catch(e) {
    alert(e)
    console.log(e)
    
  }

} // end if wallet = anchor

} // end finalize tx



let zero = '0';

const myChain = {
  chainId: '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4',
  rpcEndpoints: [{
      protocol: 'https',
      host: 'wax.greymass.com',
      port: '443'
  }]
};
const wtf = ['526134'];
const rpc = new JsonRpc('https://wax.greymass.com');


const anchor = new Anchor([myChain], {

  appName: 'WaxDAO',

});

const wax = new Wax([myChain], {

  appName: 'WaxDAO',

});






const Proposals = ({ location }) => {

  const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode, currentUser, 
    setCurrentUser, tokenName, setTokenName, maxSupply, setMaxSupply, decimals, setDecimals, 
    tokenString, setTokenString, farmData, setFarmData, farmCollection, setFarmCollection, farmSchema, 
    setFarmSchema, farmRewards, setFarmRewards, farmExpires, setFarmExpires, farmIcon, setFarmIcon, 
    farmContract, setFarmContract, createdBy, setCreatedBy, unstaked, setUnstaked, stakedOrUnstaked, 
    setStakedOrUnstaked, stakedDisplay, setStakedDisplay, unstakedDisplay, setUnstakedDisplay, validAssets, 
    setValidAssets, commaSeparated, setCommaSeparated, stakedAssets, setStakedAssets, claimable, 
    setClaimable, minTime, setMinTime, depositAmount, setDepositAmount, depositDisplay, setDepositDisplay, 
    totalStaked, setTotalStaked, ramBalance, setRamBalance, ramInfoDisplay, setRamInfoDisplay, farmTemplates, 
    setFarmTemplates, templatesDisplay, setTemplatesDisplay, farmType, setFarmType, nftsCoveredByRam, 
    setNftsCoveredByRam, hourlyPool, setHourlyPool, schemaDisplay, setSchemaDisplay, includeOrExcludeMessage, 
    setIncludeOrExcludeMessage, templateToView, setTemplateToView, refreshAssets, setRefreshAssets, 
    nftDaoDisplay, setNftDaoDisplay, stakeTokensDisplay, setStakeTokensDisplay, unstakeTokensDisplay, 
    setUnstakeTokensDisplay, proposalList, setProposalList, newProposalDisplay, setNewProposalDisplay, 
    newProposalText, setNewProposalText, proposalType, setProposalType, proposalTypeValue, setProposalTypeValue,
    choicesList, setChoicesList, description, setDescription, selectedOption, setSelectedOption, proposalInfo,
    setProposalInfo, daoName, setDaoName, daoInfo, setDaoInfo, stakeWeight, setStakeWeight, voteList, setVoteList,
    totalVoteWeight, setTotalVoteWeight, totalVotesPerChoice, setTotalVotesPerChoice, percentPerChoice,
    setPercentPerChoice
    } = useStateContext();


const { ProposalID } = useParams();

useEffect(() => {

  const abortCont = new AbortController();

  //Clear any previous data from state variables

  setProposalInfo([]);
  setDaoName('');
  setDaoInfo([]);
  setStakeWeight([]);

  //get their WAX account from localstorage

  const waxUsername = localStorage.getItem('waxAccount');

  //get the proposal info from the table
 
  axios.post('https://wax.pink.gg/v1/chain/get_table_rows',{
    table:"proposals",
    scope:"buildawaxdao",
    code:"buildawaxdao",
    limit:1,
    lower_bound:ProposalID,
    upper_bound:ProposalID,
    json:true
  })
    .then((response) => {

      //store the proposal info in state variable

      setProposalInfo(response.data.rows[0]);
      setDaoName(response.data.rows[0].daoname);

      //if the daoname (and the row) wax found, get info for this dao

      if(response.data.rows[0].daoname != null){

        axios.post('https://wax.pink.gg/v1/chain/get_table_rows',{
          table:"daos",
          scope:"buildawaxdao",
          code:"buildawaxdao",
          limit:1,
          key_type: 'name',
          index_position: 2,
          lower_bound:response.data.rows[0].daoname,
          upper_bound:response.data.rows[0].daoname,
          json:true
        }).then((daoResponse) => {

          //handle the response from the daos table

          setDaoInfo(daoResponse.data.rows[0]);
          setDecimals(daoResponse.data.rows[0].tokensymbol.substring(0, daoResponse.data.rows[0].tokensymbol.indexOf(',')));

          //if dao type is token and user is logged in, get their stakeweight from the tokenpower table

          if(daoResponse.data.rows[0].staketype == 'token' && waxUsername != null){

            axios.post('https://wax.pink.gg/v1/chain/get_table_rows',{
              table:"tokenpower",
              scope:"buildawaxdao",
              code:"buildawaxdao",
              limit:100,
              key_type: 'name',
              index_position: 2,
              lower_bound:waxUsername,
              upper_bound:waxUsername,
              json:true
            }).then((tokenPowerResponse) => {

              //store the response in state, there may be multiple rows so we need to only show the one for this dao

              setStakeWeight(tokenPowerResponse.data.rows);

            }) //end.then for staketype = token response
            //catch error for staketype = token response
            .catch((error) => console.log(error));

          } // end if staketype = token


          //if dao type is != token and user is logged in and staketype != null, get their stakeweight from the nftpower table

          if(daoResponse.data.rows[0].staketype != 'token' && daoResponse.data.rows[0].staketype != null && waxUsername != null){

            axios.post('https://wax.pink.gg/v1/chain/get_table_rows',{
              table:"nftpower",
              scope:"buildawaxdao",
              code:"buildawaxdao",
              limit:100,
              key_type: 'name',
              index_position: 2,
              lower_bound:waxUsername,
              upper_bound:waxUsername,
              json:true
            }).then((nftPowerResponse) => {

              //store the response in state, there may be multiple rows so we need to only show the one for this dao

              setStakeWeight(nftPowerResponse.data.rows);

            }) //end.then for staketype != token response
            //catch error for staketype != token response
            .catch((error) => console.log(error));

          } // end if dao type is != token and user is logged in and staketype != null, get their stakeweight from the nftpower table



        }) // end .then for dao table response
        //catch error for dao table response
        .catch((error) => console.log(error));


      } //end if daoname != null




    }) //end .then for initial request to proposals table

    //catch error for initial request to proposals table
    .catch((error) => console.log(error));

}, []);






// Fetch all votes from the votes table where proposal ID is this one

useEffect(() => {

  const abortCont = new AbortController();

  //Clear any previous vote data from state variables

  setVoteList([]);
  setTotalVotesPerChoice([]);
  setPercentPerChoice([]);

  //get their WAX account from localstorage

  const waxUsername = localStorage.getItem('waxAccount');

  //get the proposal info from the table
 
  axios.post('https://wax.pink.gg/v1/chain/get_table_rows',{
    table:"votes",
    scope:"buildawaxdao",
    code:"buildawaxdao",
    limit:1000,
    key_type: 'i64',
    index_position: 2,
    lower_bound:ProposalID,
    upper_bound:ProposalID,
    json:true
  }).then((voteResponse) => {

    setVoteList(voteResponse.data.rows);


    //console.log(voteResponse.data.rows)

    sumArray(voteResponse.data.rows, totalVoteWeight, setTotalVoteWeight, percentPerVote, choicesArray, totalVotesPerChoice, 
        setTotalVotesPerChoice, percentPerChoice, setPercentPerChoice, percentagesArray)

  })

    //catch error for initial request to proposals table
    .catch((error) => console.log(error));

}, []);






  return (





    <div id="seo">
    <Helmet>
    <title>Proposal {ProposalID} - WaxDAO</title>
    <meta name="description" content={`Vote on proposal ${ProposalID} on WaxDao`} />
    <link rel="canonical" href={`https://waxdao.io/proposals/${ProposalID}`} />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor, wax]} appName={"WaxDAO"}>

  <br/><br/><br/>






{proposalInfo != null && (
  
<span>


  <div className="m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl">
  {/* <Header title={`${ProposalID}`} category="" /> */}


  <h2 className="text-lg">{proposalInfo.title != null && proposalInfo.title} {daoInfo.daoname != null && (
    
   <span className="p-1 pl-2 pr-2 text-sm border-1 rounded-2xl font-semibold"
      style={{background: '#F8F8FF', color:'#696969'}}
   ><a href={`https://waxdao.io/daos/${daoInfo.daoname}`}>in {daoInfo.daoname}</a></span> 
  
  )}</h2>
  
<div className="flex flex-wrap gap-2 mt-2" style={{justifyContent:'right', marginBottom:'10px'}}>

{proposalInfo.status != null && (
  <div className="inline-block p-1 pl-2 pr-2 text-sm border-1 rounded-2xl">
  {proposalInfo.status[0].toUpperCase() + proposalInfo.status.substring(1) }
  </div>
)}

{proposalInfo.outcome != null && (
  <div className="inline-block p-1 pl-2 pr-2 text-sm border-1 rounded-2xl">
  {proposalInfo.outcome[0].toUpperCase() + proposalInfo.outcome.substring(1)}
  </div>
)}

{proposalInfo.ID != null && (
  <div className="inline-block p-1 pl-2 pr-2 text-sm border-1 rounded-2xl">
  ID #{proposalInfo.ID}
  </div>
)}

{proposalInfo.end_time != null && (
  <div className="inline-block p-1 pl-2 pr-2 text-sm border-1 rounded-2xl">

  {new Date(proposalInfo.end_time) > 
  Math.round(Date.now() / 1000) && (

    <span>Ends {new Date(proposalInfo.end_time * 1000).toLocaleDateString()}</span>

  )}

  {new Date(proposalInfo.end_time) <= 
  Math.round(Date.now() / 1000) && (

    <span>Ended {new Date(proposalInfo.end_time * 1000).toLocaleDateString()}</span>

  )}  


  </div>
)}




</div>

<div className="p-2 text-sm rounded-sm"
style={{backgroundColor:'#F8F8FF'}}
>

<div className="p-2 text-md inline-block">

  <span className="font-semibold" style={{color:'#696969'}}>TOTAL VOTERS:</span> <br/>
  <span className="font-bold">{voteList.length}</span>

</div>

<div className="p-2 text-md inline-block ml-10">

<span className="font-semibold" style={{color:'#696969'}}>TOTAL STAKED:</span> <br/>
<span className="font-bold">
  { totalVoteWeight != null && decimals != null && daoInfo != null && daoInfo.staketype != "nftfarm" && <NumberFormat displayType='text' thousandSeparator={true} value={totalVoteWeight / Math.pow(10, decimals) }  />}
  { totalVoteWeight != null && daoInfo != null && daoInfo.staketype == "nftfarm" && <NumberFormat displayType='text' thousandSeparator={true} value={totalVoteWeight}  />}
</span>
  

</div>

</div>


{proposalInfo.proposal_type == "tkn.transfer" && (
<p className="text-2xl font-bold mt-3">Transfer {proposalInfo.amount} from treasury to {proposalInfo.receivers[0]}</p>
)}

<div className="description mt-2">
<p className="font-semibold">Description:</p>
{proposalInfo.description != null && proposalInfo.description}


</div>




</div>

<div className="m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl">

<h2 className="text-lg mt-2">Choices</h2>
<br/>

<div className="items-center text-center">

<form>
{proposalInfo.proposal_type == "standard" && proposalInfo.choices.length > 0 ?  proposalInfo.choices.map((item, index) => (

<div className="inline-block" style={{width:'40%', margin:'5%', backgroundColor:'#F8F8FF', verticalAlign:'top', paddingBottom:'10px', boxSizing:'border-box'}}>

<div className="font-semibold p-3" style={{textAlign:'left', boxSizing:'border-box', width:'100%'}}>
<input type="radio" value={index} name="radioName" onChange={(e) => {handleChoiceSelection(e, index, selectedOption, setSelectedOption)}} /> {item}
</div> 

{/* SHOW THE TOTAL VOTES FOR THIS CHOICE */}

<div style={{width:'90%', marginLeft:'auto', marginRight:'auto', color:'#696969', height:'20px', borderBottom:'1px solid #696969', paddingBottom:'30px', boxSizing:'border-box'}}>
   <div className="text-md inline-block font-semibold" style={{width:'50%', textAlign:'left'}}>&nbsp;&nbsp;
   {totalVotesPerChoice != null && daoInfo != null && daoInfo.staketype != "nftfarm" && <NumberFormat displayType='text' thousandSeparator={true} value={totalVotesPerChoice[index] / Math.pow(10, decimals) }  />}
   {totalVotesPerChoice != null && daoInfo != null && daoInfo.staketype == "nftfarm" &&<NumberFormat displayType='text' thousandSeparator={true} value={totalVotesPerChoice[index]}  />}
   
   </div>
    
   <div className="text-md inline-block font-semibold" style={{width:'50%', textAlign:'right'}}>{percentPerChoice != null && voteList.length > 0 && Math.round(percentPerChoice[index], 2)}{percentPerChoice != null && voteList.length == 0 && 0}%&nbsp;&nbsp;</div>

    
</div>


{/* ADDRESS / VOTE TITLES */}

<div style={{width:'90%', marginLeft:'auto', marginRight:'auto', color:'#696969', height:'20px', borderBottom:'1px solid #696969', paddingBottom:'30px', paddingTop:'10px', boxSizing:'border-box'}}>
   
   <div className="text-sm inline-block font-semibold" style={{width:'50%', textAlign:'left'}}>&nbsp;&nbsp;ADDRESS</div>
    
   <div className="text-sm inline-block font-semibold" style={{width:'50%', textAlign:'right'}}>VOTE&nbsp;&nbsp;</div>

    
</div>

{/* ADDRESS / VOTE DETAILS */}

{voteList != null ? voteList.sort((a, b) => a.voteweight > b.voteweight ? 1:+1).map((vote, vote_index) => ( 
   <>
   
   
   {vote.choice == index && (
<div style={{width:'90%', marginLeft:'auto', marginRight:'auto', color:'#696969', height:'20px', borderBottom:'1px solid #696969', paddingBottom:'30px', boxSizing:'border-box'}}>
   <div className="text-sm inline-block font-semibold" style={{width:'50%', textAlign:'left'}}>&nbsp;&nbsp;{vote.voter}</div>
    
   <div className="text-sm inline-block font-semibold" style={{width:'50%', textAlign:'right'}}>
     {vote.voteweight != null && daoInfo != null && daoInfo.staketype != "nftfarm" && <NumberFormat displayType='text' thousandSeparator={true} value={vote.voteweight / Math.pow(10, decimals) }  />}
     {vote.voteweight != null && daoInfo != null && daoInfo.staketype == "nftfarm" && <NumberFormat displayType='text' thousandSeparator={true} value={vote.voteweight}  />}
   
   &nbsp;&nbsp;</div>

    
</div>
   )} 
   </>
)) : ''}


</div>



)
) : ''}
</form>




<form>
{proposalInfo.proposal_type == "tkn.transfer" && proposalInfo.choices.length > 0 ?  proposalInfo.choices.map((item, index) => (

<div className="inline-block" style={{width:'40%', margin:'5%', backgroundColor:'#F8F8FF', verticalAlign:'top', paddingBottom:'10px', boxSizing:'border-box'}}>

<div className="font-semibold p-3" style={{textAlign:'left', boxSizing:'border-box', width:'100%'}}>
<input type="radio" value={index} name="radioName" onChange={(e) => {handleChoiceSelection(e, index, selectedOption, setSelectedOption)}} /> {item}
</div> 

{/* SHOW THE TOTAL VOTES FOR THIS CHOICE */}

<div style={{width:'90%', marginLeft:'auto', marginRight:'auto', color:'#696969', height:'20px', borderBottom:'1px solid #696969', paddingBottom:'30px', boxSizing:'border-box'}}>
   <div className="text-md inline-block font-semibold" style={{width:'50%', textAlign:'left'}}>&nbsp;&nbsp;
   
   {totalVotesPerChoice != null && daoInfo != null && daoInfo.staketype != "nftfarm" && <NumberFormat displayType='text' thousandSeparator={true} value={totalVotesPerChoice[index] / Math.pow(10, decimals) }  />}
   {totalVotesPerChoice != null && daoInfo != null && daoInfo.staketype == "nftfarm" && <NumberFormat displayType='text' thousandSeparator={true} value={totalVotesPerChoice[index]}  />}
   
   </div>
    
   <div className="text-md inline-block font-semibold" style={{width:'50%', textAlign:'right'}}>{percentPerChoice != null && voteList.length > 0 && Math.round(percentPerChoice[index], 2)}{percentPerChoice != null && voteList.length == 0 && 0}%&nbsp;&nbsp;</div>

    
</div>


{/* ADDRESS / VOTE TITLES */}

<div style={{width:'90%', marginLeft:'auto', marginRight:'auto', color:'#696969', height:'20px', borderBottom:'1px solid #696969', paddingBottom:'30px', paddingTop:'10px', boxSizing:'border-box'}}>
   
   <div className="text-sm inline-block font-semibold" style={{width:'50%', textAlign:'left'}}>&nbsp;&nbsp;ADDRESS</div>
    
   <div className="text-sm inline-block font-semibold" style={{width:'50%', textAlign:'right'}}>VOTE&nbsp;&nbsp;</div>

    
</div>

{/* ADDRESS / VOTE DETAILS */}

{voteList != null ? voteList.sort((a, b) => a.voteweight > b.voteweight ? 1:+1).map((vote, vote_index) => ( 
   <>
   
   
   {vote.choice == index && (
<div style={{width:'90%', marginLeft:'auto', marginRight:'auto', color:'#696969', height:'20px', borderBottom:'1px solid #696969', paddingBottom:'30px', boxSizing:'border-box'}}>
   <div className="text-sm inline-block font-semibold" style={{width:'50%', textAlign:'left'}}>&nbsp;&nbsp;{vote.voter}</div>
    
   <div className="text-sm inline-block font-semibold" style={{width:'50%', textAlign:'right'}}>
     {vote.voteweight != null && daoInfo != null && daoInfo.staketype != "nftfarm" && <NumberFormat displayType='text' thousandSeparator={true} value={vote.voteweight / Math.pow(10, decimals) }  />}
     {vote.voteweight != null && daoInfo != null && daoInfo.staketype == "nftfarm" &&  <NumberFormat displayType='text' thousandSeparator={true} value={vote.voteweight}  />}
     
     &nbsp;&nbsp;</div>

    
</div>
   )} 
   </>
)) : ''}


</div>



)
) : ''}
</form>


<button 
        type="button"
        className="text-lg p-3
        hover:drop-shadow-xl
        hover:bg-light-gray
        text-white rounded-lg mt-4"
      onClick={() => {
          submitVote(daoName, ProposalID, selectedOption);
      }}
        style={{ background: currentColor, width:'60%'
        }}
      
      >
        Submit Vote
      </button>

{proposalInfo.end_time != null && 
  proposalInfo.end_time > 
  Math.round(Date.now() / 1000) && (

    <button 
    type="button"
    className="text-lg p-3
    hover:drop-shadow-xl
    hover:bg-light-gray
    text-white rounded-lg mt-4"
    disabled
  onClick={() => {
      finalize(ProposalID);
  }}
    style={{ background: '#DCDCDC', width:'60%', color: '#FFFAFA'
    }}
  
  >
    Finalize Proposal
  </button>

  )}


{proposalInfo.end_time != null && 
  proposalInfo.end_time <= 
  Math.round(Date.now() / 1000) && 
  proposalInfo.status == "finalized" &&
  
  (

    <button 
    type="button"
    className="text-lg p-3
    hover:drop-shadow-xl
    hover:bg-light-gray
    text-white rounded-lg mt-4"
    disabled
  onClick={() => {
      finalize(ProposalID);
  }}
    style={{ background: '#DCDCDC', width:'60%', color: '#FFFAFA'
    }}
  
  >
    Finalized
  </button>

  )}


{proposalInfo.end_time != null && 
  proposalInfo.end_time <= 
  Math.round(Date.now() / 1000) &&
  proposalInfo.status != "finalized" &&
  (

    <button 
    type="button"
    className="text-lg p-3
    hover:drop-shadow-xl
    hover:bg-light-gray
    text-white rounded-lg mt-4"
    onClick={() => {
      finalize(ProposalID);
  }}
    style={{ background: currentColor, width:'60%'
    }}
  
  >
    Finalize Proposal
  </button>

  )}
  <br/>
      </div>
</div>



</span>

)} 

{/* END IF PROPOSAL ID EXISTS */}

{proposalInfo == null && (

<span>


<div className="m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl">

<p>This proposal ID does not exist</p>

</div>
</span>

)}





    </UALProvider>
    <br/><br/><br/>
    </div>
  )
}

export default Proposals