import React, { useEffect } from 'react';
import { GridComponent, Resize, Sort, ContextMenu, Filter, Page, ExcelExport, PdfExport, Edit, Inject, Search, Toolbar } from '@syncfusion/ej2-react-grids';
import { ordersData, contextMenuItems, ordersGrid, nftFarmData, nftFarmGrid, myFarmsGrid, depositTokens } from '../../data/dummy';
import { Header } from '../../components';
import { ColumnDirective, ColumnsDirective } from '@syncfusion/ej2-react-charts';
import { useParams } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import { Anchor } from 'ual-anchor';
import { Wax } from '@eosdacio/ual-wax';
import { User } from "universal-authenticator-library";
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { Api, JsonRpc } from "eosjs";
import {Helmet} from "react-helmet";
import axios from 'axios';
import { data } from 'autoprefixer';
import { Name, raw } from "eos-common";
import { AllFarmsCont, FarmsPageCont, FarmsTitle, SingleFarmCont, FarmLogo, FarmTitle, FarmEndDate, PoolAmount, ProposerTypeMessage, MissingInfoMsg, DaoButton, PaymentOptCont, BurnableCont, NFT, NewRow, BuyWojak, ProposalInput, NewProposal, Modal, ModalContent, Spinner, SpinnerRed, SpinnerBlue, SpinnerGreen, GameButton, DisabledButton, SingleDropLogo, DropDown, FilterMenu, DropType } from '../../components/LeftDiv';
import NumberFormat from 'react-number-format';

function getRndApi() {
  const apiList = ["https://wax.greymass.com", "https://wax.pink.gg", "https://wax.eosrio.io"];
  return apiList[Math.floor(Math.random() * (2 - 0) ) + 0];
}


const apiList = ["https://eos.api.eosnation.io", "https://api.eoseoul.io", "https://eos.eosphere.io"];

const atomicApiList = ["https://eos.api.atomicassets.io"];



let zero = '0';

const myChain = {
  chainId: 'aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906',
  rpcEndpoints: [{
      protocol: 'https',
      host: 'eos.greymass.com',
      port: '443'
  }]
};
const wtf = ['526134'];
const rpc = new JsonRpc('https://eos.greymass.com');


const anchor = new Anchor([myChain], {

  appName: 'WaxDAO',

});




const UnboxList = () => {

  const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode, currentUser, tokenName, 
    setTokenName, maxSupply, setMaxSupply, decimals, setDecimals, tokenString, setTokenString, farmData, 
    setFarmData, farmCollection, setFarmCollection, farmSchema, setFarmSchema, farmRewards, setFarmRewards, 
    farmExpires, setFarmExpires, farmIcon, setFarmIcon, farmContract, setFarmContract, createdBy, setCreatedBy, 
    farmList, setFarmList, ramAmount, setRamAmount, dropList, setDropList, isWhitelistedForDrops,
    setIsWhitelistedForDrops, burnable, setBurnable, quantity, setQuantity,
    enterModalText,
    setEnterModalText,
    loadingDisplay, 
    setLoadingDisplay, 
    enterButtonsDisplay,
    setEnterButtonsDisplay,
    enterModalDisplay, 
    setEnterModalDisplay,
    logoHash,
    setLogoHash,
    dropSortMode,
    setDropSortMode,

} = useStateContext();

useEffect(() => {
setDropList([]);
setLogoHash('');
setDropSortMode('newest');





        axios.post(`${apiList[Math.floor(Math.random() * (2 - 0) ) + 0]}/v1/chain/get_table_rows`,{
            table:"drops",
            scope:"waxdaomarket",
            code:"waxdaomarket",
            limit:1000,
            json:true
          })
            .then((response) => {
        
                setDropList([response.data.rows]);
                
              
              
            })
            .catch((error) => console.log(error));
  
  }

, []);



  return (
  <div id="seo">
    <Helmet>
    <title>Unboxing Links</title>
    <meta name="description" content="View all unboxing links on WaxDAO" />
    <link rel="canonical" href="https://eos.waxdao.io/unbox" />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor]} appName={"WaxDAO"}>

    <Modal className={enterModalDisplay}>
      <ModalContent>
        {enterModalText}
        
      </ModalContent>

        <Spinner style={{display:loadingDisplay}}>
        <SpinnerRed  />
        <SpinnerBlue  />
        <SpinnerGreen />
        </Spinner>

      <br/>
      <GameButton onClick={() => setEnterModalDisplay('hidden') }>
        Close Window
      </GameButton>
      </Modal>


    <FarmsPageCont>

      <FarmsTitle>
          Unboxing Links
      </FarmsTitle>


      <AllFarmsCont>

      <form>
        <FilterMenu        
          onChange={(e) => {setDropSortMode(e.target.value)}}
          >
        <option value="" hidden>
          Sort
        </option>
        <option value="newest">Newest</option>
        <option value="oldest">Oldest</option>
        </FilterMenu>
        </form>
<NewRow />

        
{dropList == null && (
<span>
<PaymentOptCont>
<MissingInfoMsg>
  Error connecting to the WaxDAO APIs.<br/><br/>
  Try waiting a few seconds and refreshing the page.
</MissingInfoMsg>
</PaymentOptCont>
</span>
)}

{/* Sort by ending soonest */}

{dropList[1] == null && dropList[0] != null && Array.isArray(dropList[0]) && dropSortMode != null && dropSortMode == "ending_soonest" && (dropList[0].sort((a,b) => a.end_time - b.end_time).map((item, index) => (
  <span key={index} className={(item.end_time != null && (item.end_time * 1000) < new Date() || item.start_time != null && (item.start_time * 1000)  > new Date() || item.drop_type != null && item.drop_type != "premint.pack" ) && "hidden"}>
    <a href={`https://eos.waxdao.io/unbox/${item.ID}`}>
    <SingleFarmCont>



      {item.drop_logo != null && (
        <SingleDropLogo src={`https://ipfs.io/ipfs/${item.drop_logo}`} />
      )}
      {item.collection != null && (
        <FarmTitle>
        {item.collection}
        </FarmTitle>
      )}

{item.ID != null && (
        <PoolAmount>
         {/* <NumberFormat displayType='text' thousandSeparator={true} value={item.price} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} /> */}
         Pack #{item.ID}
        </PoolAmount>
      )}


    </SingleFarmCont>
    </a>
    </span>
    ))) }
    
    
    {dropList[1] != null && Array.isArray(dropList) && dropSortMode != null && dropSortMode == "ending_soonest" && (dropList.sort((a,b) => a.end_time - b.end_time).map((item, index) => (
      <a href={`https://eos.waxdao.io/unbox/${item.ID}`}>
      <SingleFarmCont>




      {item.drop_logo != null && (
        <SingleDropLogo src={`https://ipfs.io/ipfs/${item.drop_logo}`} />
      )}
      {item.collection != null && (
        <FarmTitle>
        {item.collection}
        </FarmTitle>
      )}

{item.ID != null && (
        <PoolAmount>
         {/* <NumberFormat displayType='text' thousandSeparator={true} value={item.price} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} /> */}
         Pack #{item.ID}
        </PoolAmount>
      )}

     </SingleFarmCont>
     </a>
      )))}


{/* Sort by ending latest */}

{dropList[1] == null && dropList[0] != null && Array.isArray(dropList[0]) && dropSortMode != null && dropSortMode == "ending_latest" && (dropList[0].sort((a,b) => b.end_time - a.end_time).map((item, index) => (
  <span key={index} className={(item.end_time != null && (item.end_time * 1000) < new Date() || item.start_time != null && (item.start_time * 1000)  > new Date() || item.drop_type != null && item.drop_type != "premint.pack" ) && "hidden"}>
    <a href={`https://eos.waxdao.io/unbox/${item.ID}`}>
    <SingleFarmCont>



    {item.drop_logo != null && (
        <SingleDropLogo src={`https://ipfs.io/ipfs/${item.drop_logo}`} />
      )}
      {item.collection != null && (
        <FarmTitle>
        {item.collection}
        </FarmTitle>
      )}

{item.ID != null && (
        <PoolAmount>
         {/* <NumberFormat displayType='text' thousandSeparator={true} value={item.price} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} /> */}
         Pack #{item.ID}
        </PoolAmount>
      )}

    </SingleFarmCont>
    </a>
    </span>
    ))) }
    
    
    {dropList[1] != null && Array.isArray(dropList) && dropSortMode != null && dropSortMode == "ending_latest" && (dropList.sort((a,b) => b.end_time - a.end_time).map((item, index) => (
      <a href={`https://eos.waxdao.io/unbox/${item.ID}`}>
      <SingleFarmCont>


      {item.drop_logo != null && (
        <SingleDropLogo src={`https://ipfs.io/ipfs/${item.drop_logo}`} />
      )}
      {item.collection != null && (
        <FarmTitle>
        {item.collection}
        </FarmTitle>
      )}

{item.ID != null && (
        <PoolAmount>
         {/* <NumberFormat displayType='text' thousandSeparator={true} value={item.price} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} /> */}
         Pack #{item.ID}
        </PoolAmount>
      )}

     </SingleFarmCont>
     </a>
      )))}



{/* Sort by newest */}

{dropList[1] == null && dropList[0] != null && Array.isArray(dropList[0]) && dropSortMode != null && dropSortMode == "oldest" && (dropList[0].sort((a,b) => a.start_time - b.start_time).map((item, index) => (
 <span key={index} className={(item.end_time != null && (item.end_time * 1000) < new Date() || item.start_time != null && (item.start_time * 1000)  > new Date() || item.drop_type != null && item.drop_type != "premint.pack"  ) && "hidden"}>
    <a href={`https://eos.waxdao.io/unbox/${item.ID}`}>
    <SingleFarmCont>


    {item.drop_logo != null && (
        <SingleDropLogo src={`https://ipfs.io/ipfs/${item.drop_logo}`} />
      )}
      {item.collection != null && (
        <FarmTitle>
        {item.collection}
        </FarmTitle>
      )}

{item.ID != null && (
        <PoolAmount>
         {/* <NumberFormat displayType='text' thousandSeparator={true} value={item.price} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} /> */}
         Pack #{item.ID}
        </PoolAmount>
      )}

    </SingleFarmCont>
    </a>
    </span>
    ))) }
    
    
    {dropList[1] != null && Array.isArray(dropList) && dropSortMode != null && dropSortMode == "oldest" && (dropList.sort((a,b) => a.start_time - b.start_time).map((item, index) => (
      <a href={`https://eos.waxdao.io/unbox/${item.ID}`}>
      <SingleFarmCont>

      {item.drop_logo != null && (
        <SingleDropLogo src={`https://ipfs.io/ipfs/${item.drop_logo}`} />
      )}
      {item.collection != null && (
        <FarmTitle>
        {item.collection}
        </FarmTitle>
      )}

{item.ID != null && (
        <PoolAmount>
         {/* <NumberFormat displayType='text' thousandSeparator={true} value={item.price} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} /> */}
         Pack #{item.ID}
        </PoolAmount>
      )}

     </SingleFarmCont>
     </a>
      )))}






{/* Sort by oldest */}

{dropList[1] == null && dropList[0] != null && Array.isArray(dropList[0]) && dropSortMode != null && dropSortMode == "newest" && (dropList[0].sort((a,b) => b.start_time - a.start_time).map((item, index) => (
  <span key={index} className={(item.end_time != null && (item.end_time * 1000) < new Date() || item.start_time != null && (item.start_time * 1000)  > new Date() || item.drop_type != null && item.drop_type != "premint.pack"  ) && "hidden"}>
    <a href={`https://eos.waxdao.io/unbox/${item.ID}`}>
    <SingleFarmCont>

    {item.drop_logo != null && (
        <SingleDropLogo src={`https://ipfs.io/ipfs/${item.drop_logo}`} />
      )}
      {item.collection != null && (
        <FarmTitle>
        {item.collection}
        </FarmTitle>
      )}

{item.ID != null && (
        <PoolAmount>
         {/* <NumberFormat displayType='text' thousandSeparator={true} value={item.price} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} /> */}
         Pack #{item.ID}
        </PoolAmount>
      )}

    </SingleFarmCont>
    </a>
    </span>
    ))) }
    
    
    {dropList[1] != null && Array.isArray(dropList) && dropSortMode != null && dropSortMode == "newest" && (dropList.sort((a,b) => b.start_time - a.start_time).map((item, index) => (
      <a href={`https://eos.waxdao.io/unbox/${item.ID}`}>
      <SingleFarmCont>

      {item.drop_logo != null && (
        <SingleDropLogo src={`https://ipfs.io/ipfs/${item.drop_logo}`} />
      )}
      {item.collection != null && (
        <FarmTitle>
        {item.collection}
        </FarmTitle>
      )}

      {item.ID != null && (
        <PoolAmount>
         {/* <NumberFormat displayType='text' thousandSeparator={true} value={item.price} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} /> */}
         Pack #{item.ID}
        </PoolAmount>
      )}

     </SingleFarmCont>
     </a>
      )))}



</AllFarmsCont>

      </FarmsPageCont>
      <br/><br/><br/>
    </UALProvider>
 
    </div>
  )
}

export default UnboxList