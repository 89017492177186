import React, { useEffect } from 'react';
import { GridComponent, Resize, Sort, ContextMenu, Filter, Page, ExcelExport, PdfExport, Edit, Inject, Search, Toolbar } from '@syncfusion/ej2-react-grids';
import { ordersData, contextMenuItems, ordersGrid, nftFarmData, nftFarmGrid, myFarmsGrid, depositTokens } from '../data/dummy';
import { Header } from '../components';
import { ColumnDirective, ColumnsDirective } from '@syncfusion/ej2-react-charts';
import { useParams } from 'react-router-dom';
import { useStateContext } from '../contexts/ContextProvider';
import { Anchor } from 'ual-anchor';
import { Wax } from '@eosdacio/ual-wax';
import { User } from "universal-authenticator-library";
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { Api, JsonRpc } from "eosjs";
import {Helmet} from "react-helmet";
import axios from 'axios';
import { data } from 'autoprefixer';
import { Name, raw } from "eos-common";
import vote from '../data/vote.png';
import { MainContainer, LeftDiv, RightDiv, DaoName, Creator, Proposals, NewProposal, AboutDAO, DaoMenu, DaoMenuItem, Wallet, DaoTitle,
  SingleProposal, ProposalButton, ProposalButtonContainer, ProposalTitle, ProposalAuthor, ProposalDescription, ProposerTypeMessage,
  ProposerList, ProposalInput, PropDescription, ProposalChoices, RemoveButton, AddButton, SubmitButton, DropDown, AboutDaoTitle,
  AboutDaoBody, AboutDaoCont, DaoButton, DaoButtonCont, StakedAmount, CreateDaoCont, CreateDaoInfo, DaoLink, BurnableCont, NewRow,
  NFT, BuyWojak, DAO, DaosCont, MyDaoTitle
  } from '../components/LeftDiv';

let zero = '0';

const myChain = {
  chainId: '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4',
  rpcEndpoints: [{
      protocol: 'https',
      host: 'wax.greymass.com',
      port: '443'
  }]
};

const rpc = new JsonRpc('https://wax.greymass.com');


const anchor = new Anchor([myChain], {

  appName: 'WaxDAO',

});

const wax = new Wax([myChain], {

  appName: 'WaxDAO',

});


const ualLogin = async () => {
 
  console.log('Loading...');
  await anchor.login();
  getCustomersData();
  //localStorage.setItem('waxAccount', 'anchor.users[0].accountName')
  //console.log(anchor.users[0].accountName);

}

const ualLogout = async () => {
 
  console.log('Loading...');
  await anchor.logout();
  //console.log(anchor.users[0].accountName);

}





const DAOs = () => {

  const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode, currentUser, tokenName, setTokenName, maxSupply, setMaxSupply, decimals, setDecimals, tokenString, setTokenString, farmData, setFarmData, farmCollection, setFarmCollection, farmSchema, setFarmSchema, farmRewards, setFarmRewards, farmExpires, setFarmExpires, farmIcon, setFarmIcon, farmContract, setFarmContract, createdBy, setCreatedBy, farmList, setFarmList, ramAmount, setRamAmount } = useStateContext();

const { FarmName } = useParams();

useEffect(() => {
setFarmList([]);


 const waxUsername = localStorage.getItem('waxAccount');
 const walletProvider = localStorage.getItem('walletProvider');
  
  
  axios.post('https://wax.pink.gg/v1/chain/get_table_rows',{
    table:"daos",
    scope:"buildawaxdao",
    code:"buildawaxdao",
    limit:1000,
    json:true
  })
    .then((response) => {

      setFarmList(response.data.rows);
      console.log(response.data.rows);
    })
    .catch((error) => console.log(error));

  }

, []);

  return (
  <div id="seo">
    <Helmet>
    <title>All DAOs</title>
    <meta name="description" content="View all DAOs on WaxDAO" />
    <link rel="canonical" href="https://waxdao.io/daos" />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor, wax]} appName={"WaxDAO"}>
    
    <MainContainer>

      <CreateDaoCont>


      <DaoTitle>
          All DAOs
      </DaoTitle>




      <DaosCont>




      {farmList.map((item, index) => (

<span key={index} className={item.status == "unlocked" && "hidden"} >
   

{item.status == 'unlocked' ? (

''

) : (

<DAO>

<MyDaoTitle>
  {item.daoname}
</MyDaoTitle>


<img 
        alt={`${item.daoname} Logo`} 
        style={{ width:'150px', 
          maxWidth:'150px',
          height:'150px',
          maxHeight:'150px'
          }}
        className="rounded-xl hover:drop-shadow-xl"  
          src={`https://ipfs.io/ipfs/${item.logo}`}
        />



<a href={`https://waxdao.io/daos/${item.daoname}`}>
<DaoButton>
  View DAO
</DaoButton>
</a>
</DAO>






)}


    


</span>

))}







  </DaosCont>





  </CreateDaoCont>
  </MainContainer>

    
    </UALProvider>
    </div>
  )
}

export default DAOs