import React from 'react';
import { Header } from '../../components';
import { useStateContext } from '../../contexts/ContextProvider';
import { Anchor } from 'ual-anchor';
import { User } from "universal-authenticator-library";
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { Api, JsonRpc } from "eosjs";
import { Modal } from '../../components';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import {Helmet} from "react-helmet";
import { FarmsPageCont, FarmsTitle, Suggestion, SuggestionDeets, SuggestionDesc, SuggestionsCont } from '../../components/LeftDiv';


const myChain = {
  chainId: 'aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906',
  rpcEndpoints: [{
      protocol: 'https',
      host: 'eos.greymass.com',
      port: '443'
  }]
};

const rpc = new JsonRpc('https://eos.greymass.com');


const anchor = new Anchor([myChain], {

  appName: 'WaxDAO',

});


const PoolFAQ = () => {
    const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode, currentUser, isOpen, setIsOpen, whitelistStatus, setWhitelistStatus, assetID, setAssetID } = useStateContext();

    return (
      <div id="seo">
      <Helmet>
      <title>Staking Pool FAQ - WaxDAO</title>
      <meta name="description" content="Answers to frequently asked questions about Waxdao staking pools." />
      <link rel="canonical" href="https://waxdao.io/pool-faq" />
      </Helmet>
      <UALProvider chains={[myChain]} authenticators={[anchor]} appName={"WaxDAO"}>
      <FarmsPageCont>
        <FarmsTitle>
          Staking Pool FAQ
        </FarmsTitle>
        <SuggestionsCont>
        
      <Suggestion>
      <SuggestionDesc>
      What Is A Staking Pool?
      </SuggestionDesc>
      <SuggestionDeets>
      WaxDAO staking pools allow creators to reward their token holders.<br/><br/>
      Users can stake their tokens into a pool, and receive hourly staking rewards directly into their EOS wallet.
      </SuggestionDeets>

      </Suggestion>

      <Suggestion>
      <SuggestionDesc>
      How Much Staking Rewards Will I Receive?
      </SuggestionDesc>
      <SuggestionDeets>
      The reward rate is set by the person who creates the staking pool.<br/><br/>
      You can view the current staking rate on the page of any pool. However, note that this amount will fluctuate as people join/leave the pool.
      </SuggestionDeets>

      </Suggestion>


      <Suggestion>
      <SuggestionDesc>
      How Can I Create A Staking Pool?
      </SuggestionDesc>
      <SuggestionDeets>
      All you need to do is go to the <a href="https://eos.waxdao.io/create-pool">Create Pool page</a> and fill out the form.<br/><br/>
      Then, submit the transaction and your pool will be created.<br/><br/>
      Once your pool is created, you can add rewards to it by going to the <a href="https://eos.waxdao.io/my-pools">Manage Pools page.</a>
      </SuggestionDeets>

      </Suggestion>


      <Suggestion>
      <SuggestionDesc>
      How Much Does It Cost?
      </SuggestionDesc>
      <SuggestionDeets>
      The current cost of a staking pool is 25 EOS.
      </SuggestionDeets>

      </Suggestion>


      <Suggestion>
      <SuggestionDesc>
      Any Advice For Creating A Staking Pool?
      </SuggestionDesc>
      <SuggestionDeets>
      One thing that we would definitely suggest, is to make sure that your reward token has a sufficient amount of decimal places (8 is ideal).<br/><br/>
      I won't go too deep into the boring math stuff, but basically here's how it works.<br/><br/>
      If your token only allows whole numbers for example (0 decimal places), then let's say there are 1000 users in your pool.<br/><br/>
      Each one is supposed to get 0.1 tokens per hour. Except "0.1" does not exist, because you used a token with no decimal places.<br/><br/>
      The contract will take 100 tokens from your balance to send these stakers their rewards. That 0.1 gets rounded down to the smallest possible unit (1 whole number), so each user gets 0 tokens.<br/><br/>
      Your pool loses 100 tokens, your stakers get 0. 
      You can avoid that scenario by using a reward token that has enough decimal places to split rewards amongst all of the stakers.<br/><br/>
      Setting a minimum staking amount can also help with this.<br/><br/>
      <span className="font-bold">TL;DR:</span> Use a reward token with 8 or more decimal places if possible. Set a minimum staking amount on your pool.
      Yes, you can use less than 8 decimal places. It's not the end of the world, but it won't be ideal.
      </SuggestionDeets>

      </Suggestion>


        <Suggestion>
          <SuggestionDesc>
          Why Do Users Need To Deposit RAM Before They Can Stake?
          </SuggestionDesc>
          <SuggestionDeets>
          We are providing a service that requires data to be stored on the blockchain. Storing this data has a (temporary) cost.
          <br/><br/>
          Since our prices are so low, there is no profit margin for us to spend on storing data for users. In order to keep these 
          low prices, users need to be responsible for their own data.
          <br/><br/>
          Here's how it works: when you stake, your EOS deposit will be used to purchase RAM for storing your data.
          <br/><br/>
          When you unstake, this RAM will be sold for EOS and returned back to you at the current market value.
          <br/><br/>
          The alternative option would be for us to drastically increase our prices. And I don't think anyone wants that, right?
          </SuggestionDeets>
        </Suggestion>


        <Suggestion>
          <SuggestionDesc>
          How Come I Receive Different Amount Of Rewards Every Day?
          </SuggestionDesc>
          <SuggestionDeets>
          Our staking pools are based on a shared pool model.
          <br/><br/>
          The pool is split up amongst all of the people who stake in it. As people come and go, the reward rate will fluctuate.
          <br/><br/>
          In other words, the more people who join a pool- the less tokens each person gets. Supply and demand.
          </SuggestionDeets>
        </Suggestion>

        <Suggestion>
          <SuggestionDesc>
          When Can I Unstake My Tokens?
          </SuggestionDesc>
          <SuggestionDeets>
          The creator of the pool is able to set a minimum staking period when they create the pool.
          <br/><br/>
          This number will be different for each pool, but never less than 1 day.
          <br/><br/>
          You can view the minimum staking period on the pool's page. Note: this timer resets every time you stake more tokens.
          </SuggestionDeets>
        </Suggestion>


        <Suggestion>
          <SuggestionDesc>
          Can I Add Multiple Tokens To The Staking Pool?
          </SuggestionDesc>
          <SuggestionDeets>
          At this time, you can only add 1 reward token per pool.
          <br/><br/>
          However, you can create as many pools as you like (provided that you pay the pool creation fee).
          </SuggestionDeets>
        </Suggestion>


        <Suggestion>
          <SuggestionDesc>
          Can I Remove Tokens From The Reward Pool?
          </SuggestionDesc>
          <SuggestionDeets>
          You can only add tokens to a pool, there is no option to remove them.
          <br/><br/>
          We don't think that allowing withdrawals is a good idea, since it will lead to rugpulls.
          <br/><br/>
          A project creates a pool, gets people to buy their token, then removes the rewards... we don't see any good reason to add that feature.
          <br/><br/>
          However, if you are worried that no one will use your pool- you can just stake some qualifying tokens into that pool.
          That way, if no one else joins, you will get your deposit back in the form of staking rewards.
          </SuggestionDeets>
        </Suggestion>


        <Suggestion>
          <SuggestionDesc>
          Who Can I Speak To If I Have More Questions?
          </SuggestionDesc>
          <SuggestionDeets>
          Feel free to join us on <a href="https://t.me/hoodpunks" target="none">Telegram</a> if you need to reach out.
          </SuggestionDeets>
        </Suggestion>

         
        </SuggestionsCont>
        </FarmsPageCont>
        <br/><br/><br/>
      </UALProvider>
      </div>
  )
}

export default PoolFAQ