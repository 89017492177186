import React from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import { AiOutlineShoppingCart, AiOutlineUser, AiOutlineUsergroupAdd } from 'react-icons/ai';
import { FiCreditCard } from 'react-icons/fi';
import { BsBoxSeam, BsCurrencyDollar, BsShield, BsFillQuestionCircleFill, BsFillBucketFill } from 'react-icons/bs';
import { ImPower } from 'react-icons/im';

import { IoMdContacts, IoIosTv, IoMdDoneAll, IoMdJet, IoIosAddCircle, IoIosCheckmarkCircleOutline, IoIosPrint, IoIosSwap, IoMdBonfire, IoIosList, IoIosCreate, IoIosPaper, IoIosPeople } from 'react-icons/io';
import { GiFarmer, GiTakeMyMoney, GiVote } from 'react-icons/gi';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import wojak from './wojak.png';
import { useStateContext, ContextProvider } from '../contexts/ContextProvider';
import { Anchor } from 'ual-anchor';
import { Wax } from '@eosdacio/ual-wax';
import { User } from "universal-authenticator-library";
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { Api, JsonRpc } from "eosjs";
import {Helmet} from "react-helmet";
import axios from 'axios';
import { data } from 'autoprefixer';
import { Name, raw } from "eos-common";


let zero = '0';


const myChain = {
  chainId: '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4',
  rpcEndpoints: [{
      protocol: 'https',
      host: 'wax.greymass.com',
      port: '443'
  }]
};

const rpc = new JsonRpc('https://wax.greymass.com');


const anchor = new Anchor([myChain], {

  appName: 'WaxDAO',

});

const wax = new Wax([myChain], {

  appName: 'WaxDAO',

});


const ualLogin = async () => {
  await anchor.login();
  getCustomersData();
}

const ualLogout = async () => {
  await anchor.logout();
}

export const depositTokens = async (farmName, amountToAdd, contract, rewardtoken) => {
  const walletProvider = localStorage.getItem('walletProvider');

  if(walletProvider == 'wax'){
 
    const session = await wax.login()
  
    try {
      
      const action = [{
            account: contract,
            name: 'transfer',
            authorization: [{
              actor: wax.users[0].accountName,
              permission: wax.users[0].requestPermission,
          }],
          data: {
              to: 'waxdaofarmer',
              from: wax.users[0].accountName,
              quantity: amountToAdd + ' ' + rewardtoken,
              memo: '|farm_deposit|' + farmName + '|',
          }
        }]
      // Call transact on the session (compatible with eosjs.transact)
      const response = await session[0].signTransaction({
        actions: action
      }, {
        blocksBehind: 3,
        expireSeconds: 60,
        broadcast: true,
      })
      // Update application state with the responses of the transaction
      alert('Success! Your tokens are now in the reward pool');
    } catch(e) {
      alert(e)
      console.log(e)
      
    }

  } //end if wallet is WAX

  if(walletProvider == 'anchor'){

  // Retrieve current session from state
  const session = await anchor.login()

  try {
    // Reset our response state to clear any previous transaction data
    
    const action = [{
          account: contract,
          name: 'transfer',
          authorization: [{
            actor: anchor.users[0].accountName,
            permission: anchor.users[0].requestPermission,
        }],
        data: {
            to: 'waxdaofarmer',
            from: anchor.users[0].accountName,
            quantity: amountToAdd + ' ' + rewardtoken,
            memo: '|farm_deposit|' + farmName + '|',
        }
      }]
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction({
      actions: action
    }, {
      broadcast: true,
    })
    // Update application state with the responses of the transaction
    alert('Success! Your tokens are now in the reward pool');
  } catch(e) {
    alert(e)
    console.log(e)
    
  }

} //end if wallet = ANCHOR

};




export const depositTokensToStakingPool = async (poolName, amountToAdd, contract, rewardtoken) => {
  const walletProvider = localStorage.getItem('walletProvider');

  if(walletProvider == 'wax'){


    
    const session = await wax.login()
    //console.log(session[0].signTransaction);
  
    try {
      // Reset our response state to clear any previous transaction data
      
      const action = [{
            account: contract,
            name: 'transfer',
            authorization: [{
              actor: wax.users[0].accountName,
              permission: wax.users[0].requestPermission,
          }],
          data: {
              to: 'waxdaofarmer',
              from: wax.users[0].accountName,
              quantity: amountToAdd + ' ' + rewardtoken,
              memo: '|pool_deposit|' + poolName + '|',
          }
        }]
      // Call transact on the session (compatible with eosjs.transact)
      const response = await session[0].signTransaction({
        actions: action
      }, {
        blocksBehind: 3,
        expireSeconds: 60,
        broadcast: true,
      })
      // Update application state with the responses of the transaction
      alert('Success! Your tokens are now in the reward pool');
    } catch(e) {
      alert(e)
      console.log(e)
      
    }



  } //end if wallet is WAX



  if(walletProvider == 'anchor'){

  // Retrieve current session from state
  const session = await anchor.login()
  //console.log(session[0].signTransaction);

  try {
    // Reset our response state to clear any previous transaction data
    
    const action = [{
          account: contract,
          name: 'transfer',
          authorization: [{
            actor: anchor.users[0].accountName,
            permission: anchor.users[0].requestPermission,
        }],
        data: {
            to: 'waxdaofarmer',
            from: anchor.users[0].accountName,
            quantity: amountToAdd + ' ' + rewardtoken,
            memo: '|pool_deposit|' + poolName + '|',
        }
      }]
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction({
      actions: action
    }, {
      broadcast: true,
    })
    // Update application state with the responses of the transaction
    alert('Success! Your tokens are now in the reward pool');
  } catch(e) {
    alert(e)
    console.log(e)
    
  }

} //end if wallet = ANCHOR

};



export const depositRam = async (farmName, ramAmount) => {

  const walletProvider = localStorage.getItem('walletProvider');

  if(walletProvider == 'wax'){


// Retrieve current session from state
const session = await wax.login()
//console.log(session[0].signTransaction);

try {
  // Reset our response state to clear any previous transaction data
  
  const action = [{
        account: 'eosio.token',
        name: 'transfer',
        authorization: [{
          actor: wax.users[0].accountName,
          permission: wax.users[0].requestPermission,
      }],
      data: {
          to: 'waxdaofarmer',
          from: wax.users[0].accountName,
          quantity: ramAmount + '.00000000 ' + "WAX",
          memo: '|deposit_ram|' + farmName + '|',
      }
    }]
  // Call transact on the session (compatible with eosjs.transact)
  const response = await session[0].signTransaction({
    actions: action
  }, {
 
    blocksBehind: 3,
    expireSeconds: 60,
    broadcast: true,
  })
  // Update application state with the responses of the transaction
  alert('Success!');
} catch(e) {
  alert(e)
  console.log(e)
  
}


  } //end if wallet = WAX

  if(walletProvider == 'anchor'){

  // Retrieve current session from state
  const session = await anchor.login()
  //console.log(session[0].signTransaction);

  try {
    // Reset our response state to clear any previous transaction data
    
    const action = [{
          account: 'eosio.token',
          name: 'transfer',
          authorization: [{
            actor: anchor.users[0].accountName,
            permission: anchor.users[0].requestPermission,
        }],
        data: {
            to: 'waxdaofarmer',
            from: anchor.users[0].accountName,
            quantity: ramAmount + '.00000000 ' + "WAX",
            memo: '|deposit_ram|' + farmName + '|',
        }
      }]
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction({
      actions: action
    }, {
      broadcast: true,
    })
    // Update application state with the responses of the transaction
    alert('Success!');
  } catch(e) {
    alert(e)
    console.log(e)
    
  }

} //end if wallet = ANCHOR

};



export const changeLogo = async (farmName, logoHash) => {

  const walletProvider = localStorage.getItem('walletProvider');

  if(walletProvider == 'wax'){


    const session = await wax.login()
    //console.log(session[0].signTransaction);
  
    try {
      // Reset our response state to clear any previous transaction data
      
      const action = [{
            account: 'eosio.token',
            name: 'transfer',
            authorization: [{
              actor: wax.users[0].accountName,
              permission: wax.users[0].requestPermission,
          }],
          data: {
              to: 'waxdaofarmer',
              from: wax.users[0].accountName,
              quantity: "1.00000000 WAX",
              memo: '|logo_change|' + farmName + '|' + logoHash + '|',
          }
        }]
      // Call transact on the session (compatible with eosjs.transact)
      const response = await session[0].signTransaction({
        actions: action
      }, {
        blocksBehind: 3,
        expireSeconds: 60,
        broadcast: true,
      })
      // Update application state with the responses of the transaction
      alert('Success! Your logo has been changed. You may need to refresh the page to see the new logo.');
    } catch(e) {
      alert(e)
      console.log(e)
      
    }

  } //end if wallet = WAX


  if(walletProvider == 'anchor'){


  // Retrieve current session from state
  const session = await anchor.login()
  //console.log(session[0].signTransaction);

  try {
    // Reset our response state to clear any previous transaction data
    
    const action = [{
          account: 'eosio.token',
          name: 'transfer',
          authorization: [{
            actor: anchor.users[0].accountName,
            permission: anchor.users[0].requestPermission,
        }],
        data: {
            to: 'waxdaofarmer',
            from: anchor.users[0].accountName,
            quantity: "1.00000000 WAX",
            memo: '|logo_change|' + farmName + '|' + logoHash + '|',
        }
      }]
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction({
      actions: action
    }, {

      broadcast: true,
    })
    // Update application state with the responses of the transaction
    alert('Success! Your logo has been changed. You may need to refresh the page to see the new logo.');
  } catch(e) {
    alert(e)
    console.log(e)
    
  }

} //end if wallet = ANCHOR

};



export const changePoolLogo = async (farmName, logoHash) => {

  const walletProvider = localStorage.getItem('walletProvider');

  if(walletProvider == 'wax'){


    const session = await wax.login()
    //console.log(session[0].signTransaction);
  
    try {
      // Reset our response state to clear any previous transaction data
      
      const action = [{
            account: 'eosio.token',
            name: 'transfer',
            authorization: [{
              actor: wax.users[0].accountName,
              permission: wax.users[0].requestPermission,
          }],
          data: {
              to: 'waxdaofarmer',
              from: wax.users[0].accountName,
              quantity: "1.00000000 WAX",
              memo: '|pool_logo_change|' + farmName + '|' + logoHash + '|',
          }
        }]
      // Call transact on the session (compatible with eosjs.transact)
      const response = await session[0].signTransaction({
        actions: action
      }, {
        blocksBehind: 3,
        expireSeconds: 60,
        broadcast: true,
      })
      // Update application state with the responses of the transaction
      alert('Success! Your logo has been changed. You may need to refresh the page to see the new logo.');
    } catch(e) {
      alert(e)
      console.log(e)
      
    }

  } //end if wallet = WAX


  if(walletProvider == 'anchor'){


  // Retrieve current session from state
  const session = await anchor.login()
  //console.log(session[0].signTransaction);

  try {
    // Reset our response state to clear any previous transaction data
    
    const action = [{
          account: 'eosio.token',
          name: 'transfer',
          authorization: [{
            actor: anchor.users[0].accountName,
            permission: anchor.users[0].requestPermission,
        }],
        data: {
            to: 'waxdaofarmer',
            from: anchor.users[0].accountName,
            quantity: "1.00000000 WAX",
            memo: '|pool_logo_change|' + farmName + '|' + logoHash + '|',
        }
      }]
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction({
      actions: action
    }, {

      broadcast: true,
    })
    // Update application state with the responses of the transaction
    alert('Success! Your logo has been changed. You may need to refresh the page to see the new logo.');
  } catch(e) {
    alert(e)
    console.log(e)
    
  }

} //end if wallet = ANCHOR

};


const DATE_OPTIONS = {
  weekday: "short",
  year: "numeric",
  month: "short",
  day: "numeric"
};

export const gridOrderImage = (props) => (
  <div>
    <img
      className="rounded-xl h-20 md:ml-3"
      src={`https://ipfs.io/ipfs/${props.logo}`}
      alt={`${props.farmname} Logo`}
    />
<br />

<span className="font-semibold hover:drop-shadow-xl">Pool Size:</span><br />
{props.poolsize}

  </div>
  


);

export const gridFarmLink = (props) => {
  const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode, currentUser, tokenName, setTokenName, maxSupply, setMaxSupply, decimals, setDecimals, tokenString, setTokenString, farmData, setFarmData, farmCollection, setFarmCollection, farmSchema, setFarmSchema, farmRewards, setFarmRewards, farmExpires, setFarmExpires, farmIcon, setFarmIcon, farmContract, setFarmContract, createdBy, setCreatedBy, farmList, setFarmList, amountToAdd, setAmountToAdd, ramString, setRamString } = useStateContext();
 
  return(
  <UALProvider chains={[myChain]} authenticators={[anchor, wax]} appName={"WaxDAO"}>
     <ContextProvider>
     <Link to={`/farm/${props.farmname}`}>
     <img
      className="rounded-xl m-auto"
      src={`https://ipfs.io/ipfs/${props.logo}`}
      alt={`${props.farmname} Logo`}
      style={{ width:'100px', 
      height:'100px',
      maxWidth:'100px',
      maxHeight:'100px'}}
    />
<br />

    
      
    <button
    type="button"
    style={{ background: currentColor}}
    className="text-white py-1 px-2 rounded-2xl text-md m-auto"
  >
      
      {props.farmname}
      </button>   
      
      </Link>
      </ContextProvider>
    </UALProvider>

)};


export const gridPoolLink = (props) => {
  const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode, currentUser, tokenName, setTokenName, maxSupply, setMaxSupply, decimals, setDecimals, tokenString, setTokenString, farmData, setFarmData, farmCollection, setFarmCollection, farmSchema, setFarmSchema, farmRewards, setFarmRewards, farmExpires, setFarmExpires, farmIcon, setFarmIcon, farmContract, setFarmContract, createdBy, setCreatedBy, farmList, setFarmList, amountToAdd, setAmountToAdd, ramString, setRamString } = useStateContext();
 
  return(
  <UALProvider chains={[myChain]} authenticators={[anchor, wax]} appName={"WaxDAO"}>
     <ContextProvider>
     <Link to={`/pool/${props.poolname}`}>
     <img
      className="rounded-xl h-20 m-auto"
      src={`https://ipfs.io/ipfs/${props.logo}`}
      alt={`${props.poolname} Logo`}
    />
<br />

    
      
    <button
    type="button"
    style={{ background: currentColor}}
    className="text-white py-1 px-2 rounded-2xl text-md m-auto"
  >
      
      {props.poolname}
      </button>   
      
      </Link>
      </ContextProvider>
    </UALProvider>

)};




export const gridPoolDeposit = (props) => {
//FARM deposits
  const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode, currentUser, tokenName, setTokenName, maxSupply, setMaxSupply, decimals, setDecimals, tokenString, setTokenString, farmData, setFarmData, farmCollection, setFarmCollection, farmSchema, setFarmSchema, farmRewards, setFarmRewards, farmExpires, setFarmExpires, farmIcon, setFarmIcon, farmContract, setFarmContract, createdBy, setCreatedBy, farmList, setFarmList, amountToAdd, setAmountToAdd } = useStateContext();
  
  const updateAmountToAdd = (e, index) => {
    const updatedAmounts = [...amountToAdd];
    updatedAmounts[index] = e.target.value;
    setAmountToAdd(updatedAmounts);
  }


  return(
  <UALProvider chains={[myChain]} authenticators={[anchor, wax]} appName={"WaxDAO"}>
     <ContextProvider>
     <label htmlFor="depositInput">Enter Amount: <br /></label>
      <TooltipComponent content="Must use correct precision!"
      position="BottomCenter">
    <input 
      id="depositInput"
      style={{ borderColor: currentColor }} 
      className="border-2 rounded-lg"
      type="number"
      placeholder='69.420'
      value={amountToAdd[props.farmname]}
      onChange={(e) => {updateAmountToAdd(e, props.farmname)}}
      
      />
      </TooltipComponent>
    
  <button
  type="button"
  style={{ background: currentColor, marginTop:'5px'}}
  className="text-white py-1 px-2 rounded-2xl text-md"
  onClick={() => {
    depositTokens(props.farmname, amountToAdd[props.farmname], props.contract, props.rewardtoken)
  }}
>
    
    Add {amountToAdd[props.farmname]} To Pool
    </button>   
    </ContextProvider>
    </UALProvider>

 
  )
};



export const gridTokenDeposit = (props) => {

  const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode, currentUser, tokenName, setTokenName, maxSupply, setMaxSupply, decimals, setDecimals, tokenString, setTokenString, farmData, setFarmData, farmCollection, setFarmCollection, farmSchema, setFarmSchema, farmRewards, setFarmRewards, farmExpires, setFarmExpires, farmIcon, setFarmIcon, farmContract, setFarmContract, createdBy, setCreatedBy, farmList, setFarmList, amountToAdd, setAmountToAdd } = useStateContext();
 
  const tknSymbol = props.rwdtoken.substring(props.rwdtoken.indexOf(",") + 1);
//console.log(tknSymbol)
  return(
  <UALProvider chains={[myChain]} authenticators={[anchor, wax]} appName={"WaxDAO"}>
     <ContextProvider>
     <label htmlFor="depositInput">{tknSymbol} Amount: <br /></label>
      <TooltipComponent content="Must use correct precision!"
      position="BottomCenter">
    <input 
      id="depositInput"
      style={{ borderColor: currentColor }} 
      className="border-2 rounded-lg"
      type="number"
      placeholder='69.420'
      value={amountToAdd}
      onChange={(e) => {
        
        
        //setAmountToAdd(e.target.value)
    
        let tknPrecision = props.rwdtoken.substring(0, props.rwdtoken.indexOf(","));
        
        
        if(tknPrecision == 0){
          if(e.target.value < 1){
            setAmountToAdd(1);
          
          }
          else{
            setAmountToAdd(Math.round(e.target.value));
          }
     }

     else{

      if(e.target.value < 1){
        setAmountToAdd(1 + '.' + zero.repeat(tknPrecision));
        
        }
        else{
          setAmountToAdd(Math.round(e.target.value) + '.' + zero.repeat(tknPrecision));
        }

     }
   
      
      }}
      
      />
      </TooltipComponent>
    
  <button
  type="button"
  style={{ background: currentColor, marginTop:'5px'}}
  className="text-white py-1 px-2 rounded-2xl text-md"
  onClick={() => {
    depositTokensToStakingPool(props.poolname, amountToAdd, props.contract, tknSymbol)
  }}
>
    
    Add To Pool
    </button>   
    </ContextProvider>
    </UALProvider>

 
  )
};



export const gridRamDeposit = (props) => {

  const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode, currentUser, tokenName, setTokenName, maxSupply, setMaxSupply, decimals, setDecimals, tokenString, setTokenString, farmData, setFarmData, farmCollection, setFarmCollection, farmSchema, setFarmSchema, farmRewards, setFarmRewards, farmExpires, setFarmExpires, farmIcon, setFarmIcon, farmContract, setFarmContract, createdBy, setCreatedBy, farmList, setFarmList, amountToAdd, setAmountToAdd, ramAmount, setRamAmount } = useStateContext();
 
  return(
  <UALProvider chains={[myChain]} authenticators={[anchor, wax]} appName={"WaxDAO"}>
     <ContextProvider>
     <label htmlFor="ramDepositInput">Wax Amount: <br /></label>
      <TooltipComponent content="1 Wax Increments"
      position="BottomCenter">
    <input 
      id="ramDepositInput"
      style={{ borderColor: currentColor }} 
      className="border-2 rounded-lg"
      type="number"
      placeholder='10'
      value={ramAmount}
      onChange={(e) => {

        if(e.target.value < 1){
          setRamAmount(1)
        }

        else{
          setRamAmount(Math.round(e.target.value))
        }
 
      
      }}
      
      />
      </TooltipComponent>
    
  <button
  type="button"
  style={{ background: currentColor, marginTop:'5px'}}
  className="text-white py-1 px-2 rounded-2xl text-md"
  onClick={() => {
    depositRam(props.farmname, ramAmount)
  }}
>
    
    Deposit RAM
    </button>   
    </ContextProvider>
    </UALProvider>

 
  )
};



export const gridLogoChange = (props) => {

  const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode, currentUser, tokenName, setTokenName, maxSupply, setMaxSupply, decimals, setDecimals, tokenString, setTokenString, farmData, setFarmData, farmCollection, setFarmCollection, farmSchema, setFarmSchema, farmRewards, setFarmRewards, farmExpires, setFarmExpires, farmIcon, setFarmIcon, farmContract, setFarmContract, createdBy, setCreatedBy, farmList, setFarmList, amountToAdd, setAmountToAdd, logoHash, setLogoHash } = useStateContext();
 
  const updateLogo = (e, index) => {
    const updatedLogo = [...logoHash];
    updatedLogo[index] = e.target.value;
    setLogoHash(updatedLogo);
  }

  return(
  <UALProvider chains={[myChain]} authenticators={[anchor, wax]} appName={"WaxDAO"}>
     <ContextProvider>
     <label htmlFor="ipfsHash">IPFS Hash: <br /></label>
      <TooltipComponent content="IPFS only, just the hash"
      position="BottomCenter">
    <input 
      id="ipfsHash"
      style={{borderColor: currentColor }} 
      type="text"
      className="border-2 rounded-lg"
      placeholder='Qm69420abcde'
      value={logoHash[props.poolname]}
      onChange={(e) => {updateLogo(e, props.poolname)}}
      
      />
      </TooltipComponent>
    
  <button
  type="button"
  style={{ background: currentColor, marginTop:'5px'}}
  className="text-white py-1 px-2 rounded-2xl text-md"
  onClick={() => {
    changePoolLogo(props.poolname, logoHash[props.poolname])
  }}
>
    
    Change Logo (1 WAX) 
    </button>   
    </ContextProvider>
    </UALProvider>

 
  )
};


export const gridFarmCollection = (props) => (
  <div>
    {props.collection}
  </div>
);


export const gridPoolToken = (props) => (
  <div>
    {props.tokentostake.substring(props.tokentostake.indexOf(",") + 1)}@{props.stkcontract}
  </div>
);



export const gridRewardToken = (props) => (
  <div>
    {props.poolsize.substring(props.poolsize.indexOf(" ") + 1)}@{props.contract}
  </div>
);


export const editFarmLink = (props) => {

  const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode, currentUser, tokenName, setTokenName, maxSupply, setMaxSupply, decimals, setDecimals, tokenString, setTokenString, farmData, setFarmData, farmCollection, setFarmCollection, farmSchema, setFarmSchema, farmRewards, setFarmRewards, farmExpires, setFarmExpires, farmIcon, setFarmIcon, farmContract, setFarmContract, createdBy, setCreatedBy, farmList, setFarmList, amountToAdd, setAmountToAdd, logoHash, setLogoHash } = useStateContext();
 return(
  <div>
    <a href={`https://waxdao.io/edit-farm/${props.farmname}`}>
      <button
  type="button"
  style={{ background: currentColor, marginTop:'5px', minWidth:'60px'}}
  className="text-white py-1 px-2 rounded-2xl text-md"
>
  Edit Farm
  </button>

  </a>


  </div>
 )
};




export const gridFarmSchema = (props) => (
  <div>
    {props.schema}
  </div>
);


export const gridFarmExpires = (props) => (
<div>

{new Date(props.enddate * 1000).toLocaleDateString()}

</div>
   
  
);

export const gridPoolSize = (props) => {
  const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode, currentUser, tokenName, setTokenName, maxSupply, setMaxSupply, decimals, setDecimals, tokenString, setTokenString, farmData, setFarmData, farmCollection, setFarmCollection, farmSchema, setFarmSchema, farmRewards, setFarmRewards, farmExpires, setFarmExpires, farmIcon, setFarmIcon, farmContract, setFarmContract, createdBy, setCreatedBy, farmList, setFarmList, amountToAdd, setAmountToAdd, logoHash, setLogoHash } = useStateContext();
return(
  
  <div>
  <span className="font-semibold">Pool Size:</span><br />
  <button
  type="button"
  style={{ background: currentColor, marginTop:'5px', minWidth:'60px'}}
  className="text-white py-1 px-2 rounded-2xl text-md"
>
  {props.poolsize} 
  </button>

  </div>
     
)
};


export const farmPoolSize = (props) => {
  const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode, currentUser, tokenName, setTokenName, maxSupply, setMaxSupply, decimals, setDecimals, tokenString, setTokenString, farmData, setFarmData, farmCollection, setFarmCollection, farmSchema, setFarmSchema, farmRewards, setFarmRewards, farmExpires, setFarmExpires, farmIcon, setFarmIcon, farmContract, setFarmContract, createdBy, setCreatedBy, farmList, setFarmList, amountToAdd, setAmountToAdd, logoHash, setLogoHash } = useStateContext();
return(
  
  <div>
  <span className="font-semibold">Pool Size:</span><br />
  <button
  type="button"
  style={{ background: currentColor, marginTop:'5px', minWidth:'60px'}}
  className="text-white py-1 px-2 rounded-2xl text-md"
>
  {props.poolsize} 
  </button>

  
  <br />

  </div>
     
)
};


export const gridOrderStatus = (props) => (
  <button
    type="button"
    style={{ background: props.StatusBg }}
    className="text-white py-1 px-2 capitalize rounded-2xl text-md"
  >
    {props.Status}
  </button>
);

export const myFarmsGrid = [


  {
    field: 'farmname',
    template: gridFarmLink,
    headerText: 'Farm',
    width: '140',
    //editType: 'dropdownedit',
    textAlign: 'Center',
  },

/*

  {
    //field: 'deposit',
    template: gridPoolDeposit,
    headerText: 'Deposit',
    width: '160',
    textAlign: 'Center',
  },

  {
    //field: 'deposit',
    template: gridLogoChange,
    headerText: 'Logo',
    width: '190',
    textAlign: 'Center',
  },


  {
    field: 'poolsize',
    template: gridPoolSize,
    headerText: 'Details',
    width: '120',
    textAlign: 'Center',
  },
*/

{
  field: 'editFarm',
  template: editFarmLink,
  headerText: 'Actions',
  width: '200',
  textAlign: 'Center',
},

];




export const myPoolsGrid = [


    {
      field: 'poolname',
      template: gridPoolLink,
      headerText: 'Pool',
      width: '140',
      //editType: 'dropdownedit',
      textAlign: 'Center',
    },
  
    {
      //field: 'deposit',
      template: gridTokenDeposit,
      headerText: 'Deposit',
      width: '160',
      textAlign: 'Center',
    },
  
    {
      //field: 'deposit',
      template: gridLogoChange,
      headerText: 'Logo',
      width: '190',
      textAlign: 'Center',
    },
  

  
    {
      field: 'poolsize',
      template: gridPoolSize,
      headerText: 'Details',
      width: '120',
      textAlign: 'Center',
    },
  
  
  ];


export const nftFarmGrid = [

  {
    field: 'farmname',
    template: gridFarmLink,
    headerText: 'Farm',
    width: '150',
    editType: 'dropdownedit',
    textAlign: 'Center',
  },
  { field: 'collection',
    headerText: 'Collection',
    template: gridFarmCollection,
    width: '150',
    textAlign: 'Center',
  },

  { field: 'schema',
  headerText: 'Schema',
  template: gridFarmSchema,
  width: '150',
  textAlign: 'Center',
},

  /*
  {
    headerText: 'Expires',
    template: gridFarmExpires,
    field: 'enddate',
    textAlign: 'Center',
    width: '120',
  },
  */

  {
    field: 'poolsize',
    template: farmPoolSize,
    headerText: 'Details',
    width: '200',
    textAlign: 'Center',
  },

];



export const nftPoolGrid = [

  {
    field: 'farmname',
    template: gridPoolLink,
    headerText: 'Pool',
    width: '150',
    editType: 'dropdownedit',
    textAlign: 'Center',
  },
  { field: 'token',
    headerText: 'Staking Token',
    template: gridPoolToken,
    width: '160',
    textAlign: 'Center',
  },


  { field: 'reward-token',
  headerText: 'Reward Token',
  template: gridRewardToken,
  width: '160',
  textAlign: 'Center',
},


  /*
  {
    headerText: 'Expires',
    template: gridFarmExpires,
    field: 'enddate',
    textAlign: 'Center',
    width: '120',
  },
*/

  {
    field: 'poolsize',
    template: farmPoolSize,
    headerText: 'Details',
    width: '200',
    textAlign: 'Center',
  },

];








export const EditorData = () => (
  <div>
    <h3>
      Try React
      React has been designed from the start for gradual adoption, and you can use as little or as much React as you need. Whether you want to get a taste of React, add some interactivity to a simple HTML page, or start a complex React-powered app, the links in this section will help you get started.

      Online Playgrounds
      If you’re interested in playing around with React, you can use an online code playground. Try a Hello World template on CodePen, CodeSandbox, or Stackblitz.

      If you prefer to use your own text editor, you can also download this HTML file, edit it, and open it from the local filesystem in your browser. It does a slow runtime code transformation, so we’d only recommend using this for simple demos.

      Add React to a Website
      You can add React to an HTML page in one minute. You can then either gradually expand its presence, or keep it contained to a few dynamic widgets.

      Create a New React App
      When starting a React project, a simple HTML page with script tags might still be the best option. It only takes a minute to set up!

      As your application grows, you might want to consider a more integrated setup. There are several JavaScript toolchains we recommend for larger applications. Each of them can work with little to no configuration and lets you take full advantage of the rich React ecosystem. Learn how.

      Learn React
      People come to React from different backgrounds and with different learning styles. Whether you prefer a more theoretical or a practical approach, we hope you’ll find this section helpful.

      If you prefer to learn by doing, start with our practical tutorial.
      If you prefer to learn concepts step by step, start with our guide to main concepts.
      Like any unfamiliar technology, React does have a learning curve. With practice and some patience, you will get the hang of it.

      First Examples
      The React homepage contains a few small React examples with a live editor. Even if you don’t know anything about React yet, try changing their code and see how it affects the result.

      React for Beginners
      If you feel that the React documentation goes at a faster pace than you’re comfortable with, check out this overview of React by Tania Rascia. It introduces the most important React concepts in a detailed, beginner-friendly way. Once you’re done, give the documentation another try!

      React for Designers
      If you’re coming from a design background, these resources are a great place to get started.

      JavaScript Resources
      The React documentation assumes some familiarity with programming in the JavaScript language. You don’t have to be an expert, but it’s harder to learn both React and JavaScript at the same time.

      We recommend going through this JavaScript overview to check your knowledge level. It will take you between 30 minutes and an hour but you will feel more confident learning React.
    </h3>
  </div>
);



export const links = [
  {
    title: 'NFT Farming',
    links: [
      {
        name: 'farms',
        displayName: 'farms',
        icon: <GiFarmer />,
      },

      {
        name: 'my-farms',
        displayName: 'my farms',
        icon: <AiOutlineUser />,
      },

      {
        name: 'create-farm',
        displayName: 'create farm',
        icon: <IoIosCreate />,
      },

      {
        name: 'farm-faq',
        displayName: 'farm FAQ',
        icon: <BsFillQuestionCircleFill />,
      },

    ],
  }, 

  {
    title: 'Wojak NFTs',
    links: [
      {
        name: 'wojak/swap',
        displayName: 'swap',
        icon: <IoIosSwap />,
      },

      {
        name: 'wojak/drop',
        displayName: 'drop',
        icon: <AiOutlineShoppingCart />,
      },

      {
        name: 'wojak/unpack',
        displayName: 'unpack',
        icon: <BsBoxSeam />,
      },

      {
        name: 'wojak/staking',
        displayName: 'my NFTs',
        icon: <ImPower />,
      },

/*

      {
        name: './wojak/token',
        displayName: 'token',
        icon: <SiBitcoin />,
      },

      {
        name: './wojak/whitepaper',
        displayName: 'whitepaper',
        icon: <IoIosPaper />,
      },

      {
        name: './wojak/search',
        displayName: 'search',
        icon: <AiOutlineSearch />,
      },

      */

    ],
  }, 


  {
    title: 'Staking Pools',
    links: [
      {
        name: 'pools',
        displayName: 'pools',
        icon: <BsFillBucketFill />,
      },

      {
        name: 'my-pools',
        displayName: 'my pools',
        icon: <AiOutlineUser />,
      },

      {
        name: 'create-pool',
        displayName: 'create pool',
        icon: <IoIosCreate />,
      },

      {
        name: 'pool-faq',
        displayName: 'pool FAQ',
        icon: <BsFillQuestionCircleFill />,
      },

    ],
  }, 




  {
    title: 'TokenGen',
    links: [

      
      {
        name: 'tutorial',
        displayName: 'tutorial',
        icon: <IoIosTv />,
      },
      {
        name: 'whitelisting',
        displayName: 'whitelisting',
        icon: <IoIosCheckmarkCircleOutline />,
      },
      {
        name: 'deploy-contract',
        displayName: 'deploy contract',
        icon: <IoMdJet />,
      },
      {
        name: 'create-token',
        displayName: 'create token',
        icon: <IoIosAddCircle />,
      },
      {
        name: 'issue-tokens',
        displayName: 'issue tokens',
        icon: <IoIosPrint />,
      },
      {
        name: 'transfer-tokens',
        displayName: 'transfer tokens',
        icon: <IoIosSwap />,
      },
      {
        name: 'burn-tokens',
        displayName: 'burn tokens',
        icon: <IoMdBonfire />,
      },
      {
        name: 'listing-request',
        displayName: 'listing request',
        icon: <IoIosList />,
      },
    ],
  },

  {
    title: 'Governance',
    links: [

      {
        name: 'create-dao',
        displayName: 'create DAO',
        icon: <AiOutlineUsergroupAdd />,
      },
      {
        name: 'daos',
        displayName: 'All DAOs',
        icon: <IoIosPeople />,
      },
      {
        name: 'my-daos',
        displayName: 'My DAOs',
        icon: <IoIosPeople />,
      },


    ],
  }, 
 
];



export const themeColors = [
  {
    name: 'blue-theme',
    color: '#1A97F5',
  },
  {
    name: 'green-theme',
    color: '#03C9D7',
  },
  {
    name: 'purple-theme',
    color: '#7352FF',
  },
  {
    name: 'red-theme',
    color: '#FF5C8E',
  },
  {
    name: 'indigo-theme',
    color: '#1E4DB7',
  },
  {
    color: '#FB9678',
    name: 'orange-theme',
  },
];

export const userProfileData = [
  {
    icon: <BsCurrencyDollar />,
    title: 'My Profile',
    desc: 'Account Settings',
    iconColor: '#03C9D7',
    iconBg: '#E5FAFB',
  },
  {
    icon: <BsShield />,
    title: 'My Inbox',
    desc: 'Messages & Emails',
    iconColor: 'rgb(0, 194, 146)',
    iconBg: 'rgb(235, 250, 242)',
  },
  {
    icon: <FiCreditCard />,
    title: 'My Tasks',
    desc: 'To-do and Daily Tasks',
    iconColor: 'rgb(255, 244, 229)',
    iconBg: 'rgb(254, 201, 15)',
  },
];


export const contextMenuItems = [
  'AutoFit',
  'AutoFitAll',
  'SortAscending',
  'SortDescending',
  'Copy',
  'Edit',
  'Delete',
  'Save',
  'Cancel',
  'PdfExport',
  'ExcelExport',
  'CsvExport',
  'FirstPage',
  'PrevPage',
  'LastPage',
  'NextPage',
];