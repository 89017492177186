import React, { useEffect } from 'react';
import { Header } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { Anchor } from 'ual-anchor';
import { Wax } from '@eosdacio/ual-wax';
import { User } from "universal-authenticator-library";
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { Api, JsonRpc } from "eosjs";
import {Helmet} from "react-helmet";
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import Select from 'react-select';
import axios from 'axios';
import { MainContainer, LeftDiv, RightDiv, DaoName, Creator, Proposals, NewProposal, AboutDAO, DaoMenu, DaoMenuItem, Wallet, DaoTitle,
  SingleProposal, ProposalButton, ProposalButtonContainer, ProposalTitle, ProposalAuthor, ProposalDescription, ProposerTypeMessage,
  ProposerList, ProposalInput, PropDescription, ProposalChoices, RemoveButton, AddButton, SubmitButton, DropDown, AboutDaoTitle,
  AboutDaoBody, AboutDaoCont, DaoButton, DaoButtonCont, StakedAmount, CreateDaoCont, CreateDaoInfo, DaoLink, BurnableCont, NewRow,
  NFT, BuyWojak, AssetNote, FarmsPageCont, MissingInfoMsg
  } from '../components/LeftDiv';

const options = [
    { value: 'Pay 250 Wax', label: 'Pay 250 Wax' },
    { value: 'Pay 25,000 WOJAK', label: 'Pay 25,000 WOJAK' },
    { value: 'Burn 1 Wojak NFT', label: 'Burn 1 Wojak NFT' }
  ]


let zero = '0';

const myChain = {
  chainId: 'aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906',
  rpcEndpoints: [{
      protocol: 'https',
      host: 'eos.greymass.com',
      port: '443'
  }]
};

const rpc = new JsonRpc('eos://wax.greymass.com');


const anchor = new Anchor([myChain], {

  appName: 'WaxDAO',

});

const wax = new Wax([myChain], {

  appName: 'WaxDAO',

});


const ualLogin = async () => {
 
  console.log('Loading...');
  await anchor.login();
  //localStorage.setItem('waxAccount', 'anchor.users[0].accountName')
  //console.log(anchor.users[0].accountName);

}

const ualLogout = async () => {
 
  console.log('Loading...');
  await anchor.logout();
  //console.log(anchor.users[0].accountName);

}


const createDAO = async (paymentMethod, twoAssetsToBurn) => {
  const walletProvider = localStorage.getItem('walletProvider');


  if(walletProvider == 'wax'){


    
  


  // Retrieve current session from state
  const session = await wax.login()
  //console.log(session[0].signTransaction);
//console.log(wax.users[0].accountName);

  if(paymentMethod == "Pay 500 Wax"){

  try {
    // Reset our response state to clear any previous transaction data
    
    const action = [{
          account: 'eosio.token',
          name: 'transfer',
          authorization: [{
            actor: wax.users[0].accountName,
            permission: wax.users[0].requestPermission,
        }],
        data: {
            to: 'buildawaxdao',
            from: wax.users[0].accountName,
            quantity: '500.00000000 WAX',
            memo: 'create_dao',
        }
      }]
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction({
      actions: action
    }, {

      blocksBehind: 3,
      expireSeconds: 60,
      broadcast: true,
    })
    // Update application state with the responses of the transaction
    alert('Success! You can customize your DAO on the "My DAOs" page');
  } catch(e) {
    alert(e)
    console.log(e)
    
  }

  } //end if paymentMethod == WAX


  if(paymentMethod == "Pay 50,000 WOJAK"){

    try {
      // Reset our response state to clear any previous transaction data
      
      const action = [{
            account: 'mdcryptonfts',
            name: 'transfer',
            authorization: [{
              actor: wax.users[0].accountName,
              permission: wax.users[0].requestPermission,
          }],
          data: {
              to: 'buildawaxdao',
              from: wax.users[0].accountName,
              quantity: '50000 WOJAK',
              memo: 'create_dao',
          }
        }]
      // Call transact on the session (compatible with eosjs.transact)
      const response = await session[0].signTransaction({
        actions: action
      }, {

        blocksBehind: 3,
        expireSeconds: 60,
        broadcast: true,
      })
      // Update application state with the responses of the transaction
      alert('Success! You can customize your DAO on the "My DAOs" page');
    } catch(e) {
      alert(e)
      console.log(e)
      
    }
  
    } //end if paymentMethod == WOJAK


    if(paymentMethod == "Burn 2 Wojak NFTs"){

      try {
        // Reset our response state to clear any previous transaction data
        
        const action = [{
          account: 'atomicassets',
          name: 'transfer',
          authorization: [{
          actor: wax.users[0].accountName,
          permission: wax.users[0].requestPermission,
          }],
          data: {
          asset_ids: twoAssetsToBurn,
          from: wax.users[0].accountName,
          to: 'buildawaxdao',
          memo: 'create_dao',
            }
          }]
        // Call transact on the session (compatible with eosjs.transact)
        const response = await session[0].signTransaction({
          actions: action
        }, {

          blocksBehind: 3,
          expireSeconds: 60,
          broadcast: true,
        })
        // Update application state with the responses of the transaction
        alert('Success! You can customize your DAO on the "My DAOs" page');
        
      } catch(e) {
        alert(e)
        console.log(e)
        
      }
    
      } //end if paymentMethod == burn NFT

    } //end if wallet = wax



    if(walletProvider == 'anchor'){

    // Retrieve current session from state
    const session = await anchor.login()
    //console.log(session[0].signTransaction);
  
  
    if(paymentMethod == "Pay 500 Wax"){
  
    try {
      // Reset our response state to clear any previous transaction data
      
      const action = [{
            account: 'eosio.token',
            name: 'transfer',
            authorization: [{
              actor: anchor.users[0].accountName,
              permission: anchor.users[0].requestPermission,
          }],
          data: {
              to: 'buildawaxdao',
              from: anchor.users[0].accountName,
              quantity: '500.00000000 WAX',
              memo: 'create_dao',
          }
        }]
      // Call transact on the session (compatible with eosjs.transact)
      const response = await session[0].signTransaction({
        actions: action
      }, {

        broadcast: true,
      })
      // Update application state with the responses of the transaction
      alert('Success! You can customize your DAO on the "My DAOs" page');
    } catch(e) {
      alert(e)
      console.log(e)
      
    }
  
    } //end if paymentMethod == WAX
  
  
    if(paymentMethod == "Pay 50,000 WOJAK"){
  
      try {
        // Reset our response state to clear any previous transaction data
        
        const action = [{
              account: 'mdcryptonfts',
              name: 'transfer',
              authorization: [{
                actor: anchor.users[0].accountName,
                permission: anchor.users[0].requestPermission,
            }],
            data: {
                to: 'buildawaxdao',
                from: anchor.users[0].accountName,
                quantity: '50000 WOJAK',
                memo: 'create_dao',
            }
          }]
        // Call transact on the session (compatible with eosjs.transact)
        const response = await session[0].signTransaction({
          actions: action
        }, {

          broadcast: true,
        })
        // Update application state with the responses of the transaction
        alert('Success! You can customize your DAO on the "My DAOs" page');
      } catch(e) {
        alert(e)
        console.log(e)
        
      }
    
      } //end if paymentMethod == WOJAK
  
  
      if(paymentMethod == "Burn 2 Wojak NFTs"){
  
        try {
          // Reset our response state to clear any previous transaction data
          
          const action = [{
            account: 'atomicassets',
            name: 'transfer',
            authorization: [{
            actor: anchor.users[0].accountName,
            permission: anchor.users[0].requestPermission,
            }],
            data: {
            asset_ids: twoAssetsToBurn,
            from: anchor.users[0].accountName,
            to: 'buildawaxdao',
            memo: 'create_dao',
              }
            }]
          // Call transact on the session (compatible with eosjs.transact)
          const response = await session[0].signTransaction({
            actions: action
          }, {

            broadcast: true,
          })
          // Update application state with the responses of the transaction
          alert('Success! You can customize your DAO on the "My DAOs" page');
          
        } catch(e) {
          alert(e)
          console.log(e)
          
        }
      
        } //end if paymentMethod == burn NFT
  
      } //end if wallet = anchor

}









const buyAWojak = async (quantity) => {
  const walletProvider = localStorage.getItem('walletProvider');
  const amountToSend = (quantity * 109) + '.00000000 WAX';

  if(walletProvider == 'wax'){


  // Retrieve current session from state
  const session = await wax.login()
  //console.log(session[0].signTransaction);
//console.log(wax.users[0].accountName);


  try {
    // Reset our response state to clear any previous transaction data
    
    const action = [{
          account: 'eosio.token',
          name: 'transfer',
          authorization: [{
            actor: wax.users[0].accountName,
            permission: wax.users[0].requestPermission,
        }],
        data: {
            to: 'waxywojaknft',
            from: wax.users[0].accountName,
            quantity: amountToSend,
            memo: 'purchase',
        }
      }]
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction({
      actions: action
    }, {
      // Optional: Prevent anchor-link from broadcasting this transaction (default: True)
      //
      //    The wallet/signer connected to this app will NOT broadcast the transaction
      //    as is defined by the anchor-link protocol. Broadcasting is the responsibility
      //    of anchor-link running inside an application (like this demo).
      //
      //    For this demo specifically we do NOT want the transaction to ever be broadcast
      //    to the blockchain, so we're disabling it here.
      //
      //    For all normal applications using anchor-link, you can omit this.
      //
      blocksBehind: 3,
      expireSeconds: 60,
      broadcast: true,
    })
    // Update application state with the responses of the transaction
    alert('Success! You can view your packs on the "My NFTs" page');
  } catch(e) {
    alert(e)
    console.log(e)
    
  }

    } //end if wallet = wax



    if(walletProvider == 'anchor'){

    // Retrieve current session from state
    const session = await anchor.login()
    //console.log(session[0].signTransaction);
  

  
    try {
      // Reset our response state to clear any previous transaction data
      
      const action = [{
            account: 'eosio.token',
            name: 'transfer',
            authorization: [{
              actor: anchor.users[0].accountName,
              permission: anchor.users[0].requestPermission,
          }],
          data: {
              to: 'waxywojaknft',
              from: anchor.users[0].accountName,
              quantity: amountToSend,
              memo: 'purchase',
          }
        }]
      // Call transact on the session (compatible with eosjs.transact)
      const response = await session[0].signTransaction({
        actions: action
      }, {
        // Optional: Prevent anchor-link from broadcasting this transaction (default: True)
        //
        //    The wallet/signer connected to this app will NOT broadcast the transaction
        //    as is defined by the anchor-link protocol. Broadcasting is the responsibility
        //    of anchor-link running inside an application (like this demo).
        //
        //    For this demo specifically we do NOT want the transaction to ever be broadcast
        //    to the blockchain, so we're disabling it here.
        //
        //    For all normal applications using anchor-link, you can omit this.
        //
        broadcast: true,
      })
      // Update application state with the responses of the transaction
      alert('Success! You can view your packs on the "My NFTs" page');
    } catch(e) {
      alert(e)
      console.log(e)
      
    }
  
  
      } //end if wallet = anchor

}








const CreateDAO = () => {
  
  const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode, currentUser, tokenName,
     setTokenName, maxSupply, setMaxSupply, decimals, setDecimals, tokenString, setTokenString, memo, setMemo, 
     farmName, setFarmName, rewardToken, setRewardToken, rewardContract, setRewardContract, poolSize, setPoolSize, 
     collection, setCollection, schema, setSchema, farmLogo, setFarmLogo, paymentMethod, setPaymentMethod, timestamp, 
     setTimestamp, setEpochTime, assetDisplay, setAssetDisplay, assetToBurn, setAssetToBurn, burnable, setBurnable, 
     burnableDisplay, setBurnableDisplay, stakingPeriod, setStakingSeconds, stakingSeconds, setStakingPeriod, 
     quantity, setQuantity, farmType, setFarmType, farmTypeDisplay, setFarmTypeDisplay, farmTypeValue, setFarmTypeValue, 
     templates, setTemplates, schemaDisplay, setSchemaDisplay, templateMessage, setTemplateMessage, twoAssetsToBurn,
     setTwoAssetsToBurn

    } = useStateContext();
  
    useEffect(() => {
    const abortCont2 = new AbortController();

    //clear any previous state data

    setBurnable([]);
    setBurnableDisplay('hidden');
    setDecimals('');
    setFarmName('');
    setRewardToken('');
    setRewardContract('');
    setFarmLogo('');
    setPaymentMethod('Payment Method');
    setStakingPeriod('');
    setCollection('');
    setSchema('');
    setQuantity('');
    setFarmType('Select Farm Type');
    setTwoAssetsToBurn(Array(2));
    




     const waxUsername = localStorage.getItem('waxAccount');
     const walletProvider = localStorage.getItem('walletProvider');
      
     if(waxUsername){
       //console.log('You have an active session');
       '';
       //setProfileDisplay('flex');
     }else{
       //console.log('You are not logged in');
       return('');
     }

          axios.get(`https://wax.api.atomicassets.io/atomicassets/v1/assets?collection_name=hoodpunknfts&schema_name=waxywojaks&owner=${waxUsername}&page=1&limit=8&order=desc&sort=asset_id`, {signal: abortCont2.signal})
            .then((burnResponse) => {
        
              setBurnable(burnResponse.data.data);
              //console.log(burnResponse);
            })


        .catch((error) => console.log(error));
    
        return() => {
        
          abortCont2.abort();
          
        }

      }

    
    , []);




    if(true == false){  

  return (
    <div id="seo">
    <Helmet>
    <title>Create A DAO - WaxDAO</title>
    <meta name="description" content="Create your own DAO on the WAX blockchain in just a few clicks." />
    <link rel="canonical" href="https://waxdao.io/create-dao" />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor, wax]} appName={"WaxDAO"}>
    
    <MainContainer>

    <CreateDaoCont>


    <DaoTitle>
        Create A DAO
    </DaoTitle>

    <CreateDaoInfo>
      Want to create your own DAO on WAX? WaxDAO is here to help.<br/>
      Simply submit your payment below, and a 'blank' DAO will be created for you.<br/>
      Then, you can go to the <DaoLink href="https://waxdao.io/my-daos">My DAOs page </DaoLink>to customize your new DAO.
    </CreateDaoInfo>
    




<NewProposal>

        <form>
        <DropDown         
         onChange={(e) => {if(e.target.value == 'Burn 2 Wojak NFTs'){setAssetDisplay(''); setBurnableDisplay('flex');} else{setAssetDisplay('none'); setBurnableDisplay('hidden');}setPaymentMethod(e.target.value)}}
          >
        <option value="" hidden>
          Payment Method
        </option>
        <option value="Pay 500 Wax">Pay 500 Wax</option>
        <option value="Pay 50,000 WOJAK">Pay 50,000 WOJAK</option>
        <option value="Burn 2 Wojak NFTs">Burn 2 Wojak NFTs</option>
        </DropDown>
        </form>


      <label htmlFor="assetID" style={{display: assetDisplay}} ><br/>First Asset ID To Burn:<br/></label>
      <ProposalInput type="text"
      id="assetID" 
      style={{display: assetDisplay}}
      value={twoAssetsToBurn[0]}
      onChange={(e) => {
        const assetList = [...twoAssetsToBurn];
        assetList[0] = e.target.value;
        setTwoAssetsToBurn(assetList);
      }}
      />

      <label htmlFor="assetID" style={{display: assetDisplay}} ><br/>Second Asset ID To Burn:<br/></label>
      <ProposalInput type="text"
      id="assetID" 
      style={{display: assetDisplay}}
      value={twoAssetsToBurn[1]}
      onChange={(e) => {
        const assetList = [...twoAssetsToBurn];
        assetList[1] = e.target.value;
        setTwoAssetsToBurn(assetList);
      }}
      />
<br/>
      <DaoButton 
          type="button"
          onClick={() => createDAO(paymentMethod, twoAssetsToBurn) }  >
          Create DAO Now
        </DaoButton>

        <BurnableCont
          className={burnableDisplay}
          
          >

          {burnable.map((item, index) => (

            <NFT>
         
              <p key={index}>Asset ID: <br /><span className="font-bold">{item.asset_id}</span></p>
              <img key={index} 
                src={`https://ipfs.io/ipfs/${item.data.img}`} 
                style={{width:'150px', maxWidth:'150px'}} 
                className="hover:drop-shadow-xl rounded-xl" 
              
              />
              
              Wojak {item.name}

            </NFT>

))}

<NewRow/>
<BuyWojak className={`${burnableDisplay}`} >

  <div className="items-center justify-center text-center align-center">
<h2 className='text-2xl font-semibold text-center'>Don't Have Any Wojaks To Burn?</h2>

<br />


              <img 
                src={`https://ipfs.io/ipfs/QmcMHEk3SLzQEoYDykiCy1bJ6DuYy7fwQWsByVhfQuY7pL`} 
                style={{width:'250px', maxWidth:'250px'}} 
                className="hover:drop-shadow-xl m-auto rounded-2xl" 
              
              />

Price: 109 Wax <br />
<label htmlFor="quantity" ><br />Quantity:<br /></label>
      <ProposalInput type="number" 
      placeholder='10'
      id="quantity" 
      value={quantity}
      onChange={(e) => {

        if(e.target.value < 1){
          //console.log("false");
          setQuantity(1)
        }

        else{
          setQuantity(Math.round(e.target.value))
        }

      }}
      />
      <span className="font-bold">
        <br/>Total: {(quantity * 109)} WAX<br/>
        </span>

    <DaoButton onClick={() => buyAWojak(quantity)}>
      Buy Now
    </DaoButton>


        </div>



      </BuyWojak>

    </BurnableCont>
    </NewProposal>
    </CreateDaoCont>

    </MainContainer>
    </UALProvider>
    <br/><br/><br/>
    </div>
  )



}//end if true == false

else {

  return(
  
    <div id="seo">
    <Helmet>
    <title>Create A DAO - WaxDAO</title>
    <meta name="description" content="Create your own DAO on the WAX blockchain in just a few clicks." />
    <link rel="canonical" href="https://waxdao.io/create-dao" />
    </Helmet>
  
    <UALProvider chains={[myChain]} authenticators={[anchor]} appName={"WaxDAO"}>
      
      <FarmsPageCont>
  
    <MissingInfoMsg>
    This page is currently only available for WAX Mainnet. Check back soon.
  
  
    </MissingInfoMsg>
  
  
      </FarmsPageCont>
  
  
  
      
    </UALProvider>
  
  </div>
  
  )
  
  
  
  
  }//end else true != false
  








}

export default CreateDAO