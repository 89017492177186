import React, { useEffect } from 'react';
import { Header } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import { Anchor } from 'ual-anchor';
import { User } from "universal-authenticator-library";
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { Api, JsonRpc } from "eosjs";
import { Modal } from '../components';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import {Helmet} from "react-helmet";
import axios from 'axios';
import { DaoButton, DeployCont, FarmsPageCont, FarmsTitle, GameButton, ModalContent, Spinner, SpinnerBlue, SpinnerGreen, SpinnerRed, Suggestion, SuggestionDeets, SuggestionDesc } from '../components/LeftDiv';


const myChain = {
  chainId: 'aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906',
  rpcEndpoints: [{
      protocol: 'https',
      host: 'eos.greymass.com',
      port: '443'
  }]
};

const rpc = new JsonRpc('eos://wax.greymass.com');


const anchor = new Anchor([myChain], {

  appName: 'WaxDAO',

});



const payWithEOS = async (setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {
  // Retrieve current session from state
  const session = await anchor.login()

  try {
    // Reset our response state to clear any previous transaction data
    
    const action = [{
      account: 'eosio.token',
      name: 'transfer',
      authorization: [{
      actor: anchor.users[0].accountName,
      permission: anchor.users[0].requestPermission,
      }],
      data: {
      from: anchor.users[0].accountName,
      quantity: '25.0000 EOS',
      memo: 'createatoken',
      to: 'createatoken'
        }
      }]
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction({
      actions: action
    }, {
      broadcast: true,
      blocksBehind: 3,
      expireSeconds: 180,
    })
    // Update application state with the responses of the transaction
    setLoadingDisplay('');
    setEnterButtonsDisplay('hidden');
    setEnterModalText('Processing your transaction...')
    const timer = setTimeout(() => {
      
        setEnterModalText('Your contract has been deployed');
        setLoadingDisplay('none');
        setEnterButtonsDisplay('');
  
    }, 4000);
    localStorage.setItem('eoswlstatus', "true")
    return () => clearTimeout(timer);

  } catch(e) {


          setEnterModalText(JSON.stringify(e.message));

    console.log(e)
    
  }
}


const Whitelisting = () => {
  const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode, 
    currentUser, isOpen, setIsOpen, whitelistStatus, setWhitelistStatus, assetID, 
    setAssetID, burnable, setBurnable, burnableDisplay, setBurnableDisplay, quantity, 
    setQuantity,
    enterModalText,
    setEnterModalText,
    loadingDisplay, 
    setLoadingDisplay, 
    enterButtonsDisplay,
    setEnterButtonsDisplay,
    enterModalDisplay, 
    setEnterModalDisplay, 
  
  } = useStateContext();




  return (
    <div id="seo">
    <Helmet>
    <title>Whitelisting - WaxDAO</title>
    <meta name="description" content="Receive exclusive whitelist access to TokenGen, our EOS Token Creator app." />
    <link rel="canonical" href="https://waxdao.io/whitelisting" />
    </Helmet>
    <UALProvider chains={[myChain]} authenticators={[anchor]} appName={"WaxDAO"}>

    <Modal className={enterModalDisplay}>
      <ModalContent>
        {enterModalText}
        
      </ModalContent>

        <Spinner style={{display:loadingDisplay}}>
        <SpinnerRed  />
        <SpinnerBlue  />
        <SpinnerGreen />
        </Spinner>

      <br/>
      <GameButton onClick={() => setEnterModalDisplay('hidden') }>
        Close Window
      </GameButton>
      </Modal>

      <FarmsPageCont>
      <FarmsTitle>
        Whitelisting
      </FarmsTitle>

      <DeployCont>
        <Suggestion>
      In order to use our EOS token creator, you will first need to pay a small, very reasonable whitelisting fee.
      <br/><br/>
      That fee is 25 EOS.

      </Suggestion>
      <br/>

    <DaoButton onClick={() => { payWithEOS() }} >
      Pay Now
    </DaoButton>
<br/><br/>
<Suggestion>
  <SuggestionDesc>
  <h2 className="text-2xl mt-2 font-semibold">But why would I want to pay?</h2>
  </SuggestionDesc>
  <SuggestionDeets>
  <p className="text-lg">Here are a few reasons:</p>

  <ul className="ml-5 mt-2 text-lg">
          <li>• You can save yourself valuable time and focus on building your project </li>
          <li>• No coding experience is needed</li>
          <li>• It's about $30, much less than you would pay to hire a developer</li>
          <li>• Once you are whitelisted, you can create <span className="font-semibold">UNLIMITED EOS TOKENS</span> for life!</li>

        </ul>

  </SuggestionDeets>

  <h2 className="text-2xl mt-6 font-semibold">But Wait, There's More!</h2>
      <p className="mt-4 text-xl">Imagine the following scenario:</p>

      <div className="text-white text-xl text-left p-3 mt-3 rounded-lg items-center justify-center"
      style={{ backgroundColor: '#696969'}}
       >
       <span className="font-semibold">You: </span>
       "We're launching a project!" <br /><br />

       <span className="font-semibold">EOS Community: </span>
       "Awesome! We want to join!"
       </div>
       

      <p className="mt-4 text-xl">A few months later...</p>

      <div className="text-white text-xl text-left p-3 mt-3 rounded-lg items-center justify-center"
      style={{ backgroundColor: '#696969'}}
       >
       <span className="font-semibold">You: </span>
       "We're doing a token burn! Aren't you excited?!"<br /><br />

       <span className="font-semibold">EOS Community: </span>
       "It looks like the max supply is still the same, are you trying to scam us?"
       </div>

      <p className="mt-4 text-xl font-semibold">Not a good look, right?</p>

      <p className="mt-4 text-lg">That's what happens when you use the standard EOS token contract, which does not support real burns.</p>

      <p className="mt-4 text-lg">When you use our EOS token generator, you are getting a modified contract that actually has a <span className="font-semibold">real</span> burn function.</p>

      <p className="mt-4 text-lg">Your community will be able to sleep well at night, knowing that they don't need to "take your word for it" when you say that you burned tokens.</p>
      <p className="mt-4 text-lg">And that is something you can't put a price on.</p>

      




</Suggestion>

<br/><br/>
<DaoButton onClick={() => { payWithEOS(setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) }} >
  Pay Now
</DaoButton>


    </DeployCont>
      </FarmsPageCont>

   






  
   


     
    

    </UALProvider>
    <br/><br/><br/>
    </div>
  )
}

export default Whitelisting