import React, { useEffect } from 'react';
import { GridComponent, Resize, Sort, ContextMenu, Filter, Page, ExcelExport, PdfExport, Edit, Inject, Search, Toolbar } from '@syncfusion/ej2-react-grids';
import { ordersData, contextMenuItems, ordersGrid, nftFarmData, nftFarmGrid, myFarmsGrid, depositTokens } from '../../data/dummy';
import { Header } from '../../components';
import { ColumnDirective, ColumnsDirective } from '@syncfusion/ej2-react-charts';
import { useParams } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import { Anchor } from 'ual-anchor';
import { Wax } from '@eosdacio/ual-wax';
import { User } from "universal-authenticator-library";
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { Api, JsonRpc } from "eosjs";
import {Helmet} from "react-helmet";
import axios from 'axios';
import { data } from 'autoprefixer';
import { Name, raw } from "eos-common";
import { AllFarmsCont, FarmsPageCont, FarmsTitle, SingleFarmCont, FarmLogo, FarmTitle, FarmEndDate, PoolAmount, ProposerTypeMessage, MissingInfoMsg, DaoButton, PaymentOptCont, BurnableCont, NFT, NewRow, BuyWojak, ProposalInput, NewProposal, Modal, ModalContent, Spinner, SpinnerRed, SpinnerBlue, SpinnerGreen, GameButton, DisabledButton, CancelButton, SingleDropCont, DropLogo, DropDescription, DropTitle, DropBody, MyDropsCont, MyDropsContainer, SingleDropLogo, FinalizeButton, RemoveButton, SuggestionBtn } from '../../components/LeftDiv';
import NumberFormat from 'react-number-format';

function getRndApi() {
  const apiList = ["https://wax.greymass.com", "https://wax.pink.gg", "https://wax.eosrio.io"];
  return apiList[Math.floor(Math.random() * (2 - 0) ) + 0];
}


const apiList = ["https://eos.api.eosnation.io", "https://api.eoseoul.io", "https://eos.eosphere.io"];

const atomicApiList = ["https://eos.api.atomicassets.io"];



let zero = '0';

const myChain = {
  chainId: 'aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906',
  rpcEndpoints: [{
      protocol: 'https',
      host: 'eos.greymass.com',
      port: '443'
  }]
};
const wtf = ['526134'];
const rpc = new JsonRpc('https://eos.greymass.com');


const anchor = new Anchor([myChain], {

  appName: 'WaxDAO',

});




  const cancelDrop = async (dropID, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {

    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');

    const walletProvider = localStorage.getItem('eosWalletProvider');

    if(walletProvider != 'anchor'){

      setEnterModalText('You are not logged in. Click "Wallet" in the top menu');
  
    }
  
  
      if(walletProvider == 'anchor'){
  
      // Retrieve current session from state
      const session = await anchor.login()
      //console.log(session[0].signTransaction);
    
  
    
      try {
        // Reset our response state to clear any previous transaction data
        
        const action = [{
            account: 'waxdaomarket',
            name: 'removedrop',
              authorization: [{
                actor: anchor.users[0].accountName,
                permission: anchor.users[0].requestPermission,
            }],
            data: {
                ID: dropID,
                user: anchor.users[0].accountName,
            }
          }]
        // Call transact on the session (compatible with eosjs.transact)
        const response = await session[0].signTransaction({
          actions: action
        }, {
          blocksBehind: 3,
          expireSeconds: 60,
          broadcast: true,
        })
        // Update application state with the responses of the transaction
        setLoadingDisplay('');
        setEnterButtonsDisplay('hidden');
        setEnterModalText('Processing your transaction...')
        const timer = setTimeout(() => {
          
            setEnterModalText('Your drop has been cancelled');
            setLoadingDisplay('none');
            setEnterButtonsDisplay('');
      
        }, 4000);
        return () => clearTimeout(timer);
    
      } catch(e) {
        setEnterModalText(JSON.stringify(e.message));
        console.log(e)
        
      }
    
    
        } //end if wallet = anchor
  
  }//end cancelDrop  







const MyDrops = () => {

  const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode, currentUser, tokenName, 
    setTokenName, maxSupply, setMaxSupply, decimals, setDecimals, tokenString, setTokenString, farmData, 
    setFarmData, farmCollection, setFarmCollection, farmSchema, setFarmSchema, farmRewards, setFarmRewards, 
    farmExpires, setFarmExpires, farmIcon, setFarmIcon, farmContract, setFarmContract, createdBy, setCreatedBy, 
    farmList, setFarmList, ramAmount, setRamAmount, dropList, setDropList, isWhitelistedForDrops,
    setIsWhitelistedForDrops, burnable, setBurnable, quantity, setQuantity,
    enterModalText,
    setEnterModalText,
    loadingDisplay, 
    setLoadingDisplay, 
    enterButtonsDisplay,
    setEnterButtonsDisplay,
    enterModalDisplay, 
    setEnterModalDisplay,
    myCollections,
    setMyCollections,
    myPremintedPools,
    setMyPremintedPools,

} = useStateContext();

useEffect(() => {
setDropList([]);




 const eosUsername = localStorage.getItem('eosAccount');
 const walletProvider = localStorage.getItem('eosWalletProvider');
  
 if(eosUsername){
   //console.log('You have an active session');
   '';
   //setProfileDisplay('flex');
 }else{
   //console.log('You are not logged in');
   return('');
 }


    

      axios.post(`${apiList[Math.floor(Math.random() * (2 - 0) ) + 0]}/v1/chain/get_table_rows`,{
            table:"drops",
            scope:"waxdaomarket",
            code:"waxdaomarket",
            key_type: 'name',
            index_position: 2,
            limit:100,
            lower_bound: eosUsername,
            upper_bound: eosUsername,
            json:true
          })
            .then((response) => {
        
                setDropList([response.data.rows]);
              
              
            })
            .catch((error) => console.log(error));





  

  }

, []);



useEffect(() => {
    setMyCollections([]);
    
    
    
     const eosUsername = localStorage.getItem('eosAccount');
     const walletProvider = localStorage.getItem('eosWalletProvider');
      
     if(eosUsername){
       //console.log('You have an active session');
       '';
       //setProfileDisplay('flex');
     }else{
       //console.log('You are not logged in');
       return('');
     }
    
     const nameValue = new Name(eosUsername).raw().toString();
    

    
     axios.get(`${atomicApiList[Math.floor(Math.random() * (0 - 0) ) + 0]}/atomicassets/v1/collections?authorized_account=${eosUsername}&page=1&limit=100&order=desc&sort=created`)
                .then((response) => {
            
                    setMyCollections([response.data.data]);
                    console.log(response.data.data)
                  
                  
                })
                .catch((error) => console.log(error));

      }
    
    , []);



    useEffect(() => {
      setMyPremintedPools([]);
      
      
      
      
       const eosUsername = localStorage.getItem('eosAccount');
       const walletProvider = localStorage.getItem('eosWalletProvider');
        
       if(eosUsername){
         //console.log('You have an active session');
         '';
         //setProfileDisplay('flex');
       }else{
         //console.log('You are not logged in');
         return('');
       }
      
      
          
      
        axios.post(`${apiList[Math.floor(Math.random() * (2 - 0) ) + 0]}/v1/chain/get_table_rows`,{
                  table:"premintpools",
                  scope:"waxdaomarket",
                  code:"waxdaomarket",
                  key_type: 'name',
                  index_position: 2,
                  limit:100,
                  lower_bound: eosUsername,
                  upper_bound: eosUsername,
                  json:true
                })
                  .then((response) => {
              
                    setMyPremintedPools([response.data.rows]);
                    
                    
                  })
                  .catch((error) => console.log(error));
      
      
      
      
      
        
      
        }
      
      , []);





  return (
  <div id="seo">
    <Helmet>
    <title>My Drops</title>
    <meta name="description" content="Manage your NFT drops on WaxDAO" />
    <link rel="canonical" href="https://eos.waxdao.io/my-drops" />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor]} appName={"WaxDAO"}>

    <Modal className={enterModalDisplay}>
      <ModalContent>
        {enterModalText}
        
      </ModalContent>

        <Spinner style={{display:loadingDisplay}}>
        <SpinnerRed  />
        <SpinnerBlue  />
        <SpinnerGreen />
        </Spinner>

      <br/>
      <GameButton onClick={() => setEnterModalDisplay('hidden') }>
        Close Window
      </GameButton>
      </Modal>


    <FarmsPageCont>

      <FarmsTitle>
        Manage Drops
        &nbsp;&nbsp;

        <a href="https://eos.waxdao.io/create-drop">
        <DaoButton>
        + New Drop
        </DaoButton>
        </a>
      </FarmsTitle>

      <MyDropsContainer>



{dropList == null && (
<span>
<PaymentOptCont>
<MissingInfoMsg>
You have not created any drops yet.<br/><br/>

<a href="https://eos.waxdao.io/create-drop">
<DaoButton>
    Create Drop Now
</DaoButton>
</a>
</MissingInfoMsg>
</PaymentOptCont>
</span>
)}

{dropList != null && dropList[0] != null && dropList[0].length == 0 && (

<span>
<PaymentOptCont>
<MissingInfoMsg>
    You have not created any drops yet.<br/><br/>

    <a href="https://eos.waxdao.io/create-drop">
    <SuggestionBtn>
        Create Drop Now
    </SuggestionBtn>
    </a>
    </MissingInfoMsg>
</PaymentOptCont>
</span>
)}



{dropList[1] == null && dropList[0] != null && Array.isArray(dropList[0]) && dropList[0].length >= 1 ? (
   
   <span>
    <FarmsTitle>
        My Drops
    </FarmsTitle>

    <MyDropsCont>


    {dropList[0].map((item, index) => (



<span className={item.drop_type != null && item.drop_type == "premint.pack" && "hidden"}>
    <SingleDropCont>
      {item.drop_logo != null && (
        <SingleDropLogo src={`https://ipfs.io/ipfs/${item.drop_logo}`} />
      )}
      {item.collection != null && (
        <FarmTitle>
        {item.collection}
        </FarmTitle>
      )}

      {item.price != null && (
        <PoolAmount>
         {/* <NumberFormat displayType='text' thousandSeparator={true} value={item.price} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} /> */}
         {item.price}
        </PoolAmount>
      )}

      {item.end_time != null && (
        <FarmEndDate>
          Ends {new Date(item.end_time * 1000).toLocaleDateString()}
        </FarmEndDate>
      )}
      
      <a href={`https://eos.waxdao.io/drops/${item.ID}`}>
        <DaoButton>
            View Drop
        </DaoButton>

      </a>
      <br/>

      <CancelButton onClick={() => { cancelDrop(item.ID, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) }}>
          Cancel Drop
      </CancelButton>

    </SingleDropCont>
    </span>

    ))}
    </MyDropsCont>
    </span>
    
    ) : dropList[1] != null && Array.isArray(dropList) && 
    
    (
<span>
<MyDropsCont>

    {dropList.map((item, index) => (
      <span className={item.drop_type != null && item.drop_type == "premint.pack" && "hidden"}>
      <a href={`https://eos.waxdao.io/drops/${item.ID}`}>
      <SingleDropCont>
      {item.drop_logo != null && (
        <SingleDropLogo src={`https://ipfs.io/ipfs/${item.drop_logo}`} />
      )}
      {item.collection != null && (
        <FarmTitle>
        {item.collection}
        </FarmTitle>
      )}

      {item.price != null && (
        <PoolAmount>
         {/* <NumberFormat displayType='text' thousandSeparator={true} value={item.price} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} /> */}
         {item.price}
        </PoolAmount>
      )}

      {item.end_time != null && (
        <FarmEndDate>
          Ends {new Date(item.end_time * 1000).toLocaleDateString()}
        </FarmEndDate>
      )}

      <DaoButton>
          Cancel Drop
      </DaoButton>

     </SingleDropCont>
     </a>
     </span>
      ))}
     
     </MyDropsCont>
      </span>
    )}





{/* COLLECTIONS */}



{/* 
{myCollections == null && (
<span>
<PaymentOptCont>
<MissingInfoMsg>
  You have not created any drops yet.<br/><br/>
</MissingInfoMsg>
</PaymentOptCont>
</span>
)}

{myCollections != null && myCollections[0] != null && myCollections[0].length == 0 && (

<span>
<PaymentOptCont>
<MissingInfoMsg>
    You have not created any drops yet.<br/><br/>

    </MissingInfoMsg>
</PaymentOptCont>
</span>
)}

 */}

{myCollections[1] == null && myCollections[0] != null && Array.isArray(myCollections[0]) ? (
   
   <span>
    <FarmsTitle>
        My Collections
    </FarmsTitle>

    <MyDropsCont>


    {myCollections[0].map((item, index) => (




    <SingleDropCont>
      {item.img != null && (
        <SingleDropLogo src={`https://ipfs.io/ipfs/${item.img}`} />
      )}
      {item.collection_name != null && (
          <span>
        <FarmTitle>
        {item.collection_name}
        </FarmTitle>

          <a href={`https://eos.waxdao.io/collection/${item.collection_name}`}>
          <DaoButton>
              View Collection
          </DaoButton>
        </a>
        </span>
    )}

    


    </SingleDropCont>


    ))}
    </MyDropsCont>
    </span>
    
    ) : myCollections[1] != null && Array.isArray(myCollections) && 
    
    (
<span>
<MyDropsCont>

    {myCollections.map((item, index) => (

      <SingleDropCont>
      {item.img != null && (
        <SingleDropLogo src={`https://ipfs.io/ipfs/${item.img}`} />
      )}
      {item.collection_name != null && (
          <span>
        <FarmTitle>
        {item.collection_name}
        </FarmTitle>

          <a href={`https://eos.waxdao.io/collection/${item.collection_name}`}>
          <DaoButton>
              View Collection
          </DaoButton>
        </a>
        </span>
    )}



     </SingleDropCont>


      ))}
     
     </MyDropsCont>
      </span>
    )}







{/* PRE MINTED POOLS */}



{/* 
{myCollections == null && (
<span>
<PaymentOptCont>
<MissingInfoMsg>
  You have not created any drops yet.<br/><br/>
</MissingInfoMsg>
</PaymentOptCont>
</span>
)}

{myCollections != null && myCollections[0] != null && myCollections[0].length == 0 && (

<span>
<PaymentOptCont>
<MissingInfoMsg>
    You have not created any drops yet.<br/><br/>

    </MissingInfoMsg>
</PaymentOptCont>
</span>
)}

 */}

{myPremintedPools[1] == null && myPremintedPools[0] != null && Array.isArray(myPremintedPools[0]) ? (
   
   <span>
    <FarmsTitle>
        Pre-Minted Pools
        &nbsp;&nbsp;

        <a href="https://eos.waxdao.io/create-premint">
        <DaoButton>
        + New Pool
        </DaoButton>
        </a>
    </FarmsTitle>

    <MyDropsCont>


    {myPremintedPools[0].map((item, index) => (




    <SingleDropCont>
      {item.display_name != null && (
                <FarmTitle>
                {item.display_name}
                </FarmTitle>
      )}

      {item.collection != null && (
          <span>
            <br/>
        {item.collection}

        </span>
    )}


{item.amount_of_assets != null && (
          <span>
            <br/>
        {item.amount_of_assets} Assets

        </span>
    )}

{item.ID != null && (
          <span>
            <br/><br/>
          <a href={`https://eos.waxdao.io/premint-pool/${item.ID}`}>
          <SuggestionBtn>
              Manage Pool
          </SuggestionBtn>
        </a>
        </span>
    )}

{item.status != null && item.status == "available" && (
          <span>
            <br/>
            <br/>
          <a href={`https://eos.waxdao.io/create-pack/${item.ID}`}>
          <FinalizeButton>
              Create Pack
          </FinalizeButton>
        </a>
        <br/>
            <br/>
          <a href={`https://eos.waxdao.io/create-preminted-drop/${item.ID}`}>
          <FinalizeButton>
              Create Preminted Drop
          </FinalizeButton>
        </a>






        </span>
    )}






    </SingleDropCont>


    ))}
    </MyDropsCont>
    </span>
    
    ) : myPremintedPools[1] != null && Array.isArray(myPremintedPools) && 
    
    (
<span>
<MyDropsCont>

    {myPremintedPools.map((item, index) => (

      <SingleDropCont>
      {item.img != null && (
        <SingleDropLogo src={`https://ipfs.io/ipfs/${item.img}`} />
      )}
      {item.collection_name != null && (
          <span>
        <FarmTitle>
        {item.collection_name}
        </FarmTitle>

          <a href={`https://eos.waxdao.io/collection/${item.collection_name}`}>
          <DaoButton>
              View Collection
          </DaoButton>
        </a>
        </span>
    )}



     </SingleDropCont>


      ))}
     
     </MyDropsCont>
      </span>
    )}



{/* PRE MINTED PACKS */}

{dropList[1] == null && dropList[0] != null && Array.isArray(dropList[0]) && dropList[0].length >= 1 ? (
   
   <span>
    <FarmsTitle>
        Preminted Packs
    </FarmsTitle>

    <MyDropsCont>


    {dropList[0].map((item, index) => (



<span className={item.drop_type != null && item.drop_type != "premint.pack" && "hidden"}>
    <SingleDropCont>
      {item.drop_logo != null && (
        <SingleDropLogo src={`https://ipfs.io/ipfs/${item.drop_logo}`} />
      )}
      {item.collection != null && (
        <FarmTitle>
        {item.collection}
        </FarmTitle>
      )}

{item.ID != null && (
        <PoolAmount>
         {/* <NumberFormat displayType='text' thousandSeparator={true} value={item.price} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} /> */}
         Pack #{item.ID}
        </PoolAmount>
      )}
      
      <a href={`https://eos.waxdao.io/unbox/${item.ID}`}>
        <DaoButton>
            View Page
        </DaoButton>

      </a>
  

    </SingleDropCont>
    </span>

    ))}
    </MyDropsCont>
    </span>
    
    ) : dropList[1] != null && Array.isArray(dropList) && 
    
    (
<span>
<MyDropsCont>

    {dropList.map((item, index) => (
      <span className={item.drop_type != null && item.drop_type != "premint.pack" && "hidden"}>
      <a href={`https://eos.waxdao.io/unbox/${item.ID}`}>
      <SingleDropCont>
      {item.drop_logo != null && (
        <SingleDropLogo src={`https://ipfs.io/ipfs/${item.drop_logo}`} />
      )}
      {item.collection != null && (
        <FarmTitle>
        {item.collection}
        </FarmTitle>
      )}

{item.ID != null && (
        <PoolAmount>
         {/* <NumberFormat displayType='text' thousandSeparator={true} value={item.price} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} /> */}
         Pack #{item.ID}
        </PoolAmount>
      )}


     </SingleDropCont>
     </a>
     </span>
      ))}
     
     </MyDropsCont>
      </span>
    )}











</MyDropsContainer>

      </FarmsPageCont>
      <br/><br/><br/>
    </UALProvider>
 
    </div>
  )
}

export default MyDrops